import '../App.scss';
import React from 'react';
import { CustomerContext } from "../helpers/common";
import {Outlet, useSearchParams} from "react-router-dom";

function CustomerAuthorization(props) {
    const [ searchParams ] = useSearchParams();
    let token = searchParams.get('token');

    return (
        <CustomerContext.Provider value={{
            accessToken: token
        }}>
        {
            token && <Outlet />
        }
        </CustomerContext.Provider>
    );
}

export default CustomerAuthorization;

import '../App.scss';
import React from 'react';
import { useEffect, useState } from 'react';
import { useSearchParams, Link, useNavigate } from "react-router-dom";
import { getUrl, serverPost } from '../helpers/server';
import BaseForm from '../components/BaseForm';
import SubmitButton from '../components/SubmitButton';
import BaseContainerWithoutNav from '../components/BaseContainerWithoutNav';
import { Row, Col, Container, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
const _ = require('lodash');

function Register() {
    const { t } = useTranslation('common');
    const navigate = useNavigate();
    const [ searchParams ] = useSearchParams();
    const [succeeded, setSucceeded] = useState(false);
    let code = searchParams.get('code');
    const [initialRegisterFields, setInitialRegisterFields] = useState({});
    const [forcedEmail, setForcedEmail] = useState(null);

    useEffect(() => {
        if (code) {
            serverPost(getUrl(`/users/find_by_code`), { code: code }).then((res) => {
                if (res) {
                    setForcedEmail(res.email);
                    setInitialRegisterFields({ email: res.email });
                }
            });
        }
    }, [code]);

    const onRegister = (data, errorHandler) => {
        const registerData = {
            ...data,
            email: data.email.toLowerCase(),
            code
        };
        serverPost(getUrl('/users/register'), registerData, {}, errorHandler).then((res) => {
            if (res) {
                setSucceeded(true);
            }
        });
    }

    return (
        <BaseContainerWithoutNav>
            <Container>
                <Row>
                    {
                        !succeeded &&
                            <Col md={{span: 6, offset:3}}>
                                <h3>Register For Maple</h3>
                                <br/>
                                <Alert>{t('auth.already_have_account')} <Link to="/login">{t('auth.login_here')}</Link></Alert>
                                <BaseForm initialFormFields={initialRegisterFields} onSubmit={onRegister}>
                                    <BaseForm.Input type="text" name="name" label={t('common.fullname')} validations={{ required: true, minLength: 2 }} />
                                    <BaseForm.Input type="text" name="email" label={t('common.email')} validations={{ required: true, validEmail: true }} disabled={!_.isNil(forcedEmail)}/>
                                    <BaseForm.Input type="password" name="password" label={t('auth.password')} validations={{ required: true, minLength: 2 }} />
                                    <SubmitButton>{t('auth.register')}</SubmitButton>
                                </BaseForm>
                            </Col>
                    }
                    {
                        succeeded && forcedEmail &&
                            <Col md={{span: 6, offset:3}}>
                                <span className="body1 text-center">Successfully registered. <a href="/login">Login</a> to continue</span>
                            </Col>
                    }
                    {
                        succeeded && !forcedEmail &&
                            <Col md={{span: 6, offset:3}}>
                                <span className="body1 text-center">Successfully registered. Please refer to your email for verification and continue to <a href="/login">login</a>.</span>
                            </Col>
                    }
                </Row>
            </Container>
        </BaseContainerWithoutNav>
    );
}

export default Register;

import '../../App.scss';
import React, { useEffect, useState, useContext } from 'react';
import { serverFetch } from '../../helpers/server';
import {BaseContext, UserContext} from '../../helpers/common';
import BaseContainer from '../../components/BaseContainer';
import ContentContainer from '../../components/ContentContainer';
import Metric from '../../components/Metric';
import Section from '../../components/Section';
import Checklist from '../../components/Checklist';
import { Row, Col } from 'react-bootstrap';
import GoalProgress from "../../components/GoalProgress";
const _ = require('lodash');

function CompanyHome() {
    const { userInfo } = useContext(UserContext);
    const { company, getApiUrl, setPageTitle, hasAccess } = useContext(BaseContext);
    const [checklistItems, setChecklistItems] = useState([]);

    const hasMetricsPermission = hasAccess("reports", userInfo, "read");

    useEffect(() => {
        setPageTitle("Dashboard");
    }, []);

    useEffect(() => {
        serverFetch(getApiUrl("/checklist")).then((res) => {
            setChecklistItems(res);
        });
    }, []);

    return (
        <BaseContainer>
            <ContentContainer title={`${company.name}`}>
                <GoalProgress variant="success" />
                <Section title="Get Started" className={"hide"}>
                    <Checklist checklistItems={checklistItems} />
                </Section>
                {
                    hasMetricsPermission &&
                        <Row className="gy-3 gx-3">
                            <Col lg="12">
                                <Metric
                                    metricKey="MRR"
                                    chartType="line"
                                    label="Monthly Recurring Revenue"
                                    yLabel="MRR"
                                    type="currency"
                                    defaultDateRange='This Month'
                                    showGroupingOptions={false}
                                />
                            </Col>
                            <Col lg="6" md="6">
                                <Metric
                                    metricKey="ACTIVE_CUSTOMERS"
                                    chartType="line"
                                    label="Active Customers"
                                    yLabel="Customers"
                                    defaultDateRange='This Month'
                                    showGroupingOptions={false}
                                />
                            </Col>
                            <Col lg="6" md="6">
                                <Metric
                                    metricKey="ACTIVE_SUBS"
                                    chartType="line"
                                    label="Active Subscriptions"
                                    yLabel="Subscriptions"
                                    defaultDateRange='This Month'
                                    showGroupingOptions={false}
                                />
                            </Col>
                            <Col lg="6" md="6">
                                <Metric
                                    metricKey="NEW_CUSTOMERS"
                                    chartType="bar"
                                    label="New Customers"
                                    yLabel="Customers"
                                    defaultDateRange='This Month'
                                    showGroupingOptions={false}
                                />
                            </Col>
                            <Col lg="6" md="6">
                                <Metric
                                    metricKey="NEW_SUBS"
                                    chartType="bar"
                                    label="New Subscriptions"
                                    yLabel="Subscriptions"
                                    defaultDateRange='This Month'
                                    showGroupingOptions={false}
                                />
                            </Col>
                            <Col lg="6" md="6">
                                <Metric
                                    metricKey="PAYMENTS"
                                    chartType="bar"
                                    label="Payments"
                                    yLabel="Payments"
                                    defaultDateRange='This Month'
                                    showGroupingOptions={false}
                                />
                            </Col>
                            <Col lg="6" md="6">
                                <Metric
                                    metricKey="NEW_INVOICES"
                                    chartType="bar"
                                    label="Invoices"
                                    yLabel="Invoices"
                                    defaultDateRange='This Month'
                                    showGroupingOptions={false}
                                />
                            </Col>
                        </Row>
                }
            </ContentContainer>
        </BaseContainer>
    );
}

export default CompanyHome;

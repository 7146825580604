import '../App.scss';
import React, {useState, useEffect, useContext, forwardRef, useImperativeHandle, useMemo} from 'react';
import {BaseContext, currencyFormatFromPrice, getCurrencyOptions, getNameForProviderType} from '../helpers/common';
import { serverPost } from '../helpers/server';
import { useNavigate } from 'react-router-dom';
import Columns from './Columns';
import Link from './Link';
import MapleTable from './MapleTable';
import InfiniteScroll from "react-infinite-scroll-component";
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import Label from './Label';
import Section from './Section';
import moment from 'moment';
import BaseOverlayTrigger from './BaseOverlayTrigger';
import MapleTableHeaderWithActions from "./MapleTableHeaderWithActions";
import SortableTableHeader from "./SortableTableHeader";
const _ = require('lodash');

const PaymentListSection = forwardRef((props, ref)  => {
    useImperativeHandle(ref, () => ({
        refresh() {
            onSearch(true);
        },
    }));

    const { t } = useTranslation('common')
    const navigate = useNavigate();
    const { company, getApiUrl, getCompanySpecificUrl } = useContext(BaseContext);
    const [payments, setPayments] = useState([]);
    const [includeCustomer, setIncludeCustomer] = useState(false);
    const [hideTableIfEmpty, setHideTableIfEmpty] = useState(false);
    const [showTableActions, setShowTableActions] = useState(_.isNil(props.showTableActions) ? true: props.showTableActions);
    const [loading, setLoading] = useState(true);

    const [sort, setSort] = useState("createdAtDesc");
    const [filters, setFilters] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [fromKey, setFromKey] = useState(null);
    const [meta, setMeta] = useState({});
    const [initialFields, setInitialFields] = useState({ sort: "createdAtDesc" })

    const defaultSelectedFilters = useMemo(() => {
        return {"show_connected_account": true}
    }, []);
    const cachedSelectedFilters = useMemo(() => {
        const cachedString = localStorage.getItem(company.id + "_filter_cache_payments");
        if (cachedString && showTableActions) {
            return JSON.parse(cachedString);
        } else {
            return null;
        }
    }, [showTableActions])
    const [selectedFilters, setSelectedFilters] = useState(cachedSelectedFilters || defaultSelectedFilters);

    useEffect(() => {
        if (!_.isNil(props.includeCustomer)) {
            setIncludeCustomer(props.includeCustomer);
        }
    }, [props.includeCustomer])

    useEffect(() => {
        if (!_.isNil(props.showTableActions)) {
            setShowTableActions(props.showTableActions);
        }
    }, [props.showTableActions])

    useEffect(() => {
        if (!_.isNil(props.hideTableIfEmpty)) {
            setHideTableIfEmpty(props.hideTableIfEmpty);
        }
    }, [props.hideTableIfEmpty])

    const onSearch = (restart = true) => {
        const limit = 50;
        const params = {
            company_id: company.id,
            sort_key: sort || "createdAtDesc",
            pagination: {
                from_key: restart ? null: fromKey,
                limit: limit
            },
            query: {
                ...props.extraQueryFilters,
                ...selectedFilters
            },
            include_meta: restart
        }
        serverPost(getApiUrl("/payments/transactions/find"), params).then((res) => {
            setLoading(false);
            if (res) {
                const results = res.results || [];
                if (restart) {
                    setPayments(results);
                    setMeta(res.meta);
                } else {
                    setPayments(_.concat(payments, results));
                }
                setFromKey(res.pagination.from_key);
                setHasMore(results.length === limit);
            }
        });
    };

    useEffect(() => {
        const paymentMechanismOptions = [
            { value: null, label: "All" },
            { value: true, label: "Automatically charge" },
            { value: false, label: "Email invoice" },
        ]

        const statusOptions = [
            { value: null, label: "All" },
            { value: "SUCCESS", label: "Success" },
            { value: "REFUNDED", label: "Refunded" },
            { value: "PARTIAL_REFUNDED", label: "Partially Refunded" },
            { value: "FAILED", label: "Failed"},
            { value: "CANCELLED", label: "Cancelled"},
            { value: "PROCESSING", label: "Processing"},
        ]

        const currencyOptions = getCurrencyOptions();
        currencyOptions.unshift({ value: null, label: "All" })

        const providerTypeOptions = [
            { value: null, label: "All" },
            { value: "STRIPE", label: "Stripe" },
            { value: "CHEQUE", label: "Check" },
            { value: "CASH", label: "Cash" },
            { value: "WIRE", label: "Wire" },
            { value: "CREDIT_CARD_OFFLINE", label: "Credit Card Offline" },
            { value: "BILL_COM", label: "Bill.com" },
        ]

        const transactionTypeOptions = [
            { value: null, label: "All" },
            { value: "PAYMENT", label: "Payment" },
            { value: "REFUND", label: "Refund" },
        ]

        setFilters([
            { title: "Status", type: "select", name: "status", options: statusOptions},
            { title: "Currency", type: "select", name: "currency", options: currencyOptions },
            { title: "Provider Type", type: "select", name: "provider_type", options: providerTypeOptions },
            { title: "Transaction Type", type: "select", name: "transaction_type", options: transactionTypeOptions },
            { title: "Connected Account", type: "switch", name: "show_connected_account", label: "Show Transactions" }
        ])
    }, [])

    useEffect(() => {
        onSearch(true);
    }, [sort, selectedFilters]);

    const getStatus = (payment) => {
        if (payment.status === "SUCCESS") {
            return <Label.Success><span className="d-none d-md-inline">Succeeded&nbsp;</span><i className="fa fa-check"/></Label.Success>
        } else if (payment.status === "FAILED" || payment.status === "CANCELLED") {
            return <Label.Danger><span className="d-none d-md-inline">Failed&nbsp;</span><i className="fa fa-x"/></Label.Danger>
        } else if (payment.status === "REFUNDED") {
            return <Label.Info><span className="d-none d-md-inline">Refunded&nbsp;</span><i className="fa fa-rotate-left"/></Label.Info>
        } else if (payment.status === "PARTIAL_REFUNDED") {
            return <Label.Info><span className="d-none d-md-inline">Partially Refunded</span></Label.Info>
        } else if (payment.status === "PROCESSING") {
            return <Label.Danger><span className="d-none d-md-inline">Processing</span></Label.Danger>
        } else {
            return <Label.Info><span className="d-none d-md-inline">{ payment.status }</span></Label.Info>
        }
    }

    const onParamsChange = (params) => {
        setSelectedFilters(params);
        localStorage.setItem(company.id + "_filter_cache_payments", JSON.stringify(params));
    }

    const getIndicatorForPayment = (row) => {
        if (row.connected_account_transaction) {
            return (
                <div>
                    <BaseOverlayTrigger content={"Connect Account Transaction"} placement="left">
                        <i className="fa fa-link"/>
                    </BaseOverlayTrigger>
                </div>
            )
        }
        return null;
    }

    const renderTypeLabel = (payment) => {
        if (payment.transaction_type === "PAYMENT") {
            return (<Label.Success>Payment</Label.Success>);
        } else if (payment.transaction_type === "REFUND") {
            return (<Label.Info>Refund</Label.Info>);
        }
        return (
            <Label.Info>{payment.transaction_type}</Label.Info>
        )
    }

    return (
        <InfiniteScroll
            dataLength={payments.length}
            next={() => onSearch(false)}
            hasMore={hasMore}
            scrollableTarget="content-wrapper"
        >
            <Section title="Payments" loading={loading}>
                {
                    <MapleTable>
                        {
                            showTableActions &&
                                <MapleTableHeaderWithActions
                                    showSearch={false}
                                    searchPlaceholder="Search Payments"
                                    showFilters={true}
                                    filters={filters}
                                    meta={meta}
                                    defaultSelectedFilters={defaultSelectedFilters}
                                    cachedSelectedFilters={cachedSelectedFilters}
                                    onParamsChange={onParamsChange}
                                />
                        }
                        <MapleTable.Content>
                            <thead>
                                <tr>
                                {
                                    includeCustomer &&
                                        <MapleTable.TH>Customer</MapleTable.TH>
                                }
                                    <MapleTable.TH className="d-none d-md-table-cell">Invoice</MapleTable.TH>
                                    <SortableTableHeader
                                        onSortChange={setSort} sortKeyUp="createdAtAsc" sortKeyDown="createdAtDesc"
                                        currentSort={sort}>
                                        Payment Date
                                    </SortableTableHeader>
                                    <MapleTable.TH className="d-none d-lg-table-cell">Payment Type</MapleTable.TH>
                                    <SortableTableHeader
                                        onSortChange={setSort} sortKeyUp="totalAmountAsc" sortKeyDown="totalAmountDesc"
                                        currentSort={sort} className="d-table-cell d-lg-none d-xl-table-cell" innerClassName="justify-end">
                                        Amount
                                    </SortableTableHeader>
                                    <MapleTable.TH>Status</MapleTable.TH>
                                    <MapleTable.TH className="d-none d-xl-table-cell">Provider</MapleTable.TH>
                                    <MapleTable.TH className="d-none d-md-table-cell"></MapleTable.TH>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                            {
                                _.map(payments, (row, i) =>
                                    <tr key={i} className={classnames("cursor-pointer")} onClick={() => navigate(getCompanySpecificUrl(`/payment/${row.id}`))}>
                                    {
                                        includeCustomer &&
                                            <td>
                                                <Columns.CustomerName customer={row.customer} />
                                            </td>
                                    }
                                        <td className="d-none d-md-table-cell"><Link href={getCompanySpecificUrl(`/invoice/${row.invoice_uuid}`)}>{ row.invoice_number || row.invoice_uuid }</Link></td>
                                        <td>{ moment(row.created_at).format("MMM D, YYYY h:mm:ss a") }</td>
                                        <td className="d-none d-lg-table-cell">
                                            { renderTypeLabel(row) }
                                            <div className="text-start d-block d-xl-none" style={{ marginTop: "3px" }}>{ currencyFormatFromPrice(row.total_amount) }</div>
                                        </td>
                                        <td className="d-table-cell d-lg-none d-xl-table-cell text-end">{ currencyFormatFromPrice(row.total_amount) }</td>
                                        <td>{ getStatus(row) }</td>
                                        <td className="d-none d-xl-table-cell">{ getNameForProviderType(row.provider_type) }</td>
                                        <td className="d-none d-md-table-cell">{ getIndicatorForPayment(row) }</td>
                                    </tr>
                                )
                            }
                            {
                                hasMore &&
                                    <tr>
                                        <td colSpan={10} className="text-center">
                                            <div className="spinner-border text-secondary"/>
                                        </td>
                                    </tr>
                            }
                            </tbody>
                        </MapleTable.Content>
                    </MapleTable>
                }
            </Section>
        </InfiniteScroll>
    );
})

export default PaymentListSection;

import '../App.scss';
import React from 'react';
import SingleSelectDropdown from './SingleSelectDropdown';
const _ = require('lodash');

function MoreButton(props) {

    return (
        <SingleSelectDropdown items={props.items} menuOnly onSelect={props.onSelect} align="end"
                              className={"d-inline"} hideCaret={true} toggleLabel={<i className="fa fa-ellipsis"/>} />
    );
}

export default MoreButton;

import '../App.scss';
import React, { useContext } from 'react';
import { BaseFormContext } from './BaseForm';
import { Button, Spinner } from 'react-bootstrap';
const _ = require('lodash');

function SubmitButton(props) {
    const { customErrorFields } = useContext(BaseFormContext);
    const hasError = !_.isNil(customErrorFields) && !_.isUndefined(customErrorFields) && !_.isNil(customErrorFields.error) && !_.isEmpty(customErrorFields.error);

    return (
        <div className="flex items-center gap-2">
            {
                hasError &&
                    <span className="form-error-message">{customErrorFields.error}</span>
            }
            <Button type="submit" {...props} style={props.color && { background: props.color }} className="whitespace-nowrap" >
                <div className="d-flex flex-column align-items-center">
                <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                >&nbsp;</Spinner>
                <span className="submit-button-content">
                    {props.children}
                </span>
                </div>
            </Button>
        </div>
    );
}

export default SubmitButton;

import '../../App.scss';
import '../../css/modals.scss';
import React from 'react';
import BaseForm from '../BaseForm';
import ItemPricingFields from '../ItemPricingFields';
import BaseAddOrEditItemModal from './BaseAddOrEditItemModal';
import { useState, createRef } from 'react';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
const _ = require('lodash');

function AddOrEditBillableItemPricingModal(props) {
    const { t } = useTranslation('common');
    const fieldsRef = createRef();

    const [initialFields, setInitialFields] = useState(null);

    const onFieldChange = (key, value) => {
        if (fieldsRef.current) {
            fieldsRef.current.onFieldChange(key, value);
        }
    }

    const cleanupFields = (fields) => {
        let newFields = null;
        if (fields.type === "GRADIENT") {
            fields.gradient_price = initialFields.gradient_price;
        }
        if (fieldsRef.current) {
            newFields = fieldsRef.current.processFields(fields);
        } else {
            newFields = {...fields};
        }
        return newFields;
    }

    return (
        <BaseAddOrEditItemModal
            {...props}
            size="lg"
            itemLabel={t('billable_item_pricing.pricing')}
            itemBaseUrl="/item_pricings"
            processAddFields={cleanupFields}
            processUpdateFields={cleanupFields}
            itemToEdit={initialFields}
            isEditing={!_.isNil(props.itemToEdit)}
            submitDisabled={fieldsRef.current && fieldsRef.current.isDisabled()}
            onFieldChange={onFieldChange}
        >
            <p>You can create individual pricing objects that can be used across different products.</p>
            <Row>
                <BaseForm.Input colSpan="6" name="name" label={t('common.name')} type="text" required />
                <BaseForm.Input colSpan="6" name="description" label={t('common.description')} type="textarea" />
            </Row>
            <ItemPricingFields ref={fieldsRef} setInitialFields={setInitialFields} initialFields={initialFields}
                               itemToEdit={props.itemToEdit} onCreateBillableItem={props.onCreateBillableItem} />
        </BaseAddOrEditItemModal>
    );
}

export default AddOrEditBillableItemPricingModal;

import '../App.scss';
import {serverFetch, subscribeToEvent} from '../helpers/server'
import { BaseContext, IntegrationContext } from '../helpers/common'
import React, {useEffect, useState, useContext, useMemo} from 'react';
import { Outlet, useOutletContext, useParams } from "react-router-dom";
import {getIntegrationInterfaceForKey} from "../helpers/integrations";
const _ = require('lodash');

function IntegrationComponent(props) {
    const { getApiUrl, getCompanySpecificUrl } = useContext(BaseContext);
    const [integration, setIntegration] = useState(null);
    const [loadingIntegration, setLoadingIntegration] = useState(true);
    const [integrationInterface, setIntegrationInterface] = useState(null);
    const outletContext = useOutletContext();
    let { id } = useParams();

    const fetchData = (skipCache=false) => {
        if (id === "new") {
            setIntegration(null);
            setLoadingIntegration(false);
            setIntegrationInterface(null);
        } else {
            serverFetch(getApiUrl(`/integrations/${id}`), { skipCache }).then((res) => {
                setIntegration(res);
                setLoadingIntegration(false)
                setIntegrationInterface(getIntegrationInterfaceForKey(res.key));
            })
        }
    }

    useEffect(() => {
        subscribeToEvent("integration_settings", (data) => { fetchData(true) });
    }, [])

    useEffect(() => {
        fetchData(_.isNil(integration));
    }, [id])

    const getIntegrationSpecificUrl = (url) => {
        return getCompanySpecificUrl(`/integrations/${id}${url}`);
    }

    return (
        <IntegrationContext.Provider value={{
            integration,
            integrationInterface,
            getIntegrationSpecificUrl
        }}>
        {
            !loadingIntegration && <Outlet context={outletContext} />
        }
        </IntegrationContext.Provider>
    );
}

export default IntegrationComponent;

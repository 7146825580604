import '../../App.scss';
import React, {useEffect, useState, useContext, useCallback, createRef, useMemo} from 'react';
import { useSearchParams, useParams, useNavigate } from 'react-router-dom';
import {serverDelete, serverPost, serverFetch, serverPatch} from '../../helpers/server';
import {
    currencyFormatFromPrice, BaseContext, UserContext, renderPaymentMethod,
    getFormattedAddressForAddress, getNameForProviderType, getDescriptionForDiscount,
    getDescriptionForCreditState, isPaymentMethodExpiring, isPaymentMethodExpired
} from '../../helpers/common';
import { getExternalCustomerReferenceURL } from '../../helpers/integrations';
import { useTranslation } from 'react-i18next';
import AdjustCreditModal from '../../components/modals/AdjustCreditModal';
import SetupPaymentMethodModal from '../../components/modals/SetupPaymentMethodModal';
import AddOneTimeChargesToCustomerModal from '../../components/modals/AddOneTimeChargesToCustomerModal';
import AddOrEditCustomerModal from '../../components/modals/AddOrEditCustomerModal';
import MoreButton from '../../components/MoreButton';
import SubscriptionListSection from '../../components/SubscriptionListSection';
import BaseContainer from '../../components/BaseContainer';
import ContentContainer from '../../components/ContentContainer';
import InvoiceListSection from '../../components/InvoiceListSection';
import PaymentListSection from '../../components/PaymentListSection';
import KeyValueDisplay2 from '../../components/KeyValueDisplay2';
import KeyValueDisplay from '../../components/KeyValueDisplay';
import Section from '../../components/Section';
import DeleteButton from '../../components/DeleteButton';
import BaseForm from '../../components/BaseForm';
import SubmitButton from '../../components/SubmitButton';
import MapleTable from '../../components/MapleTable';
import Label from '../../components/Label';
import ConfirmationButton from '../../components/ConfirmationButton';
import { Link } from 'react-router-dom';
import { Table, Row, Col, Alert } from 'react-bootstrap';
import classnames from 'classnames';
import moment from 'moment';
import DiscountListSection from "../../components/DiscountListSection";
import SingleSelectDropdown from "../../components/SingleSelectDropdown";
import Notification from "../../components/Notification";
const _ = require('lodash');

function Customer() {
    const navigate = useNavigate();
    const { t } = useTranslation('common');

    const [ searchParams ] = useSearchParams();
    let redirectStatus = searchParams.get('redirect_status');

    const { userInfo, isSuperUser } = useContext(UserContext);
    const { company, getApiUrl, getCompanySpecificUrl, setPageTitle, hasAccess } = useContext(BaseContext);
    const { customerId } = useParams();
    const [showAdjustCreditModal, setShowAdjustCreditModal] = useState(false);
    const [showSetupPaymentMethodModal, setShowSetupPaymentMethodModal] = useState(false);
    const [showAddOneTimeChargesToCustomerModal, setShowAddOneTimeChargesToCustomerModal] = useState(false);
    const [showAddOrEditCustomerModal, setShowAddOrEditCustomerModal] = useState(false);
    const [customerDetails, setCustomerDetails] = useState({});
    const [credits, setCredits] = useState([]);
    const [taxes, setTaxes] = useState([]);
    const [details, setDetails] = useState([]);
    const [settings, setSettings] = useState({});
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [showCustomTaxForm, setShowCustomTaxForm] = useState(false);
    const [isEditingMetadata, setIsEditingMetadata] = useState(false);
    const subscriptionListRef = createRef();
    const invoiceListRef = createRef();
    const paymentListRef = createRef();
    const formRef = createRef();
    const addTaxInitialFields = useMemo(() => {
        return {
            rate: 0
        }
    }, [taxes]);

    const hasPaymentsPermission = hasAccess("payments", userInfo, "read");
    const hasInvoicesPermission = hasAccess("invoices", userInfo, "read");
    const hasInvoicesWritePermission = hasInvoicesPermission && !customerDetails.managed_externally;
    const hasPricingPermission = hasAccess("pricing", userInfo, "read");
    const hasPricingWritePermission = hasPricingPermission && !customerDetails.managed_externally;
    const hasSubscriptionsPermission = hasAccess("subscriptions", userInfo, "read");
    const hasWritePermission = hasAccess("customers", userInfo, "write");
    const canEditCustomer = hasWritePermission && !customerDetails.managed_externally;

    useEffect(() => {
        setPageTitle(`Customer`);
    }, []);

    useEffect(() => {
        serverFetch(getApiUrl("/settings")).then((res) => {
            setSettings(res);
        })
    }, [])

    useEffect(() => {
        if (_.isNil(redirectStatus)) {
            return;
        }
        setTimeout(() => {
            let urlParser = new URL(window.location.href);
            urlParser.searchParams.delete('setup_intent');
            urlParser.searchParams.delete('setup_intent_client_secret');
            urlParser.searchParams.delete('redirect_status');
            navigate(urlParser.pathname + urlParser.search, { replace: true });
        }, 100);
    }, [redirectStatus])

    const fetchCredits = (skipCache=false) => {
        serverPost(getApiUrl("/credits/find"), { company_id: company.id, customer_id: customerId }, { skipCache: skipCache }).then((res) => {
            setCredits(res);
        });
    }

    const fetchPaymentMethods = (skipCache=false) => {
        serverFetch(getApiUrl(`/customers/${customerId}/payment_methods`), { skipCache: skipCache }).then((res) => {
            if (res) {
                setPaymentMethods(res.methods);
            }
        });
    }

    const fetchTaxes = (skipCache=false) => {
        serverPost(getApiUrl(`/taxes/find`), { customer_id: customerId }).then((res) => {
            if (res) {
                setTaxes(res);
            }
        });
    }

    const fetchCustomerDetails = (skipCache=false) => {
        serverFetch(getApiUrl(`/customers/${customerId}`), { skipCache }).then((res) => {
            setCustomerDetails(res);
        });
    }

    useEffect(() => {
        fetchCustomerDetails();
        fetchCredits();
        fetchPaymentMethods();
        if (!_.isEmpty(settings) && settings.collect_taxes_type !== "none") {
            fetchTaxes();
        }
    }, [company, customerId, settings, getApiUrl]);

    useEffect(() => {
        if (_.isEmpty(customerDetails)) {
            return;
        }
        let data = {
            'ID': customerDetails.id,
        }
        if (!_.isNil(customerDetails.imported_from)) {
            const externalReferenceUrl = getExternalCustomerReferenceURL(customerDetails);
            if (externalReferenceUrl) {
                data[`${getNameForProviderType(customerDetails.imported_from)} Reference`] =
                    <a href={externalReferenceUrl} target="_blank">{ customerDetails.identifier }</a>;
            }
        }
        if (customerDetails.identifier !== customerDetails.email && _.isNil(customerDetails.imported_from)) {
            data['External Identifier'] = customerDetails.identifier;
        }
        data = _.extend(data, {
            'Name': customerDetails.name,
            'Email': customerDetails.email,
            'Organization Name': customerDetails.org_name,
            'Phone Number': customerDetails.phone,
            'Address': getFormattedAddressForAddress(customerDetails.address),
            'MRR': <Link to={getCompanySpecificUrl(`/mrr_logs?c=${customerDetails.id}`)}>{currencyFormatFromPrice(customerDetails.mrr)}</Link>
        });
        if (customerDetails.exclude_from_metrics) {
            data['Excluded from Metrics'] = "True"
        }
        setDetails(data);
    }, [customerDetails]);

    const adjustCredit = () => {
        setShowAdjustCreditModal(true);
    }

    const editCustomer = () => {
        setShowAddOrEditCustomerModal(true);
    }

    const manageCustomer = () => {
        const data = {};
        serverPatch(getApiUrl(`/customers/${customerId}/start_managing`), data).then((res) => {
            if (res) {
                fetchCustomerDetails(true);
            }
        });
    }

    const revokeCredit = (credit) => {
        serverDelete(getApiUrl(`/credits/${credit.id}`)).then((res) => {
            if (res) {
                fetchCredits(true);
            }
        })
    }

    const onModalClose = (didUpdate) => {
        setShowAdjustCreditModal(false);
        setShowSetupPaymentMethodModal(false);
        setShowAddOneTimeChargesToCustomerModal(false);
        setShowAddOrEditCustomerModal(false);
        if (didUpdate) {
            fetchCredits(true);
            fetchCustomerDetails(true);
            if (subscriptionListRef.current) {
                subscriptionListRef.current.refresh();
            }
            if (invoiceListRef.current) {
                invoiceListRef.current.refresh();
            }
            if (paymentListRef.current) {
                paymentListRef.current.refresh();
            }
        }
    }

    const editMetadata = () => {
        setIsEditingMetadata(true);
    }

    const saveMetadata = (data) => {
        const processedData = {
            metadata: data
        };

        serverPatch(getApiUrl(`/customers/${customerId}`), processedData).then((res) => {
            if (res) {
                fetchCustomerDetails(true);
            }
            setIsEditingMetadata(false);
        });
    }

    const onPaymentActionSelected = (action) => {
        console.log("The action is " + action);
    }

    const showManageTaxes = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setShowCustomTaxForm(true);
    }

    const addTax = (data) => {
        data.rate = parseFloat(data.rate)/100;
        data.name = data.type;
        data.state = "ACTIVE";
        data.inclusive = false;
        data.customer_id = customerDetails.id;
        serverPost(getApiUrl(`/taxes`), data).then(res => {
            if (res) {
                fetchTaxes(true);
                if (formRef.current) {
                    formRef.current.onResetFields({ type: null, rate: 10 })
                }
            }
        });
    }

    const deleteTax = (tax) => {
        serverDelete(getApiUrl(`/taxes/${tax.id}`)).then(res => {
            fetchTaxes(true);
            if (formRef.current) {
                formRef.current.onResetFields({ type: null, rate: 0 })
            }
        });
    }

    const taxTypeOptions = [
        { value: "GST", label: "GST" },
        { value: "HST", label: "HST" },
        { value: "VAT", label: "VAT" },
        { value: "PST", label: "PST" },
        { value: "QST", label: "QST" },
        { value: "SALES", label: "Sales" },
        { value: "CUSTOM", label: "Custom" },
    ]
    const existingTaxTypes = _.map(taxes, (t) => t.type);
    const filteredTaxTypeOptions = _.filter(taxTypeOptions, (op) => !_.includes(existingTaxTypes, op.value));

    const paymentActionOptions = [
        { id: "set_default", label: "Set as default" }
    ]

    const onActionSelected = (type) => {
        if (type === "edit") {
            editCustomer();
        } else if (type === "exclude_metrics") {
            const data = {
                exclude_from_metrics: true
            };
            serverPatch(getApiUrl(`/customers/${customerId}`), data).then((res) => {
                if (res) {
                    fetchCustomerDetails(true);
                    Notification.Success("Customer successfully excluded from metrics.");
                } else {
                    Notification.Danger("Customer update failed.")
                }
            });
        } else if (type === "include_metrics") {
            const data = {
                exclude_from_metrics: false
            };
            serverPatch(getApiUrl(`/customers/${customerId}`), data).then((res) => {
                if (res) {
                    fetchCustomerDetails(true);
                    Notification.Success("Customer successfully included in metrics.");
                } else {
                    Notification.Danger("Customer update failed.")
                }
            });
        }
    }

    const actionOptions = [
        canEditCustomer && { id: "edit", label: "Edit", icon: "fa fa-edit" },
        !customerDetails.exclude_from_metrics && { id: "exclude_metrics", label: "Exclude From Metrics", icon: "fa fa-x" },
        customerDetails.exclude_from_metrics && { id: "include_metrics", label: "Include In Metrics", icon: "fa fa-check" }
    ];

    const getCustomerActions = () => {
        if (hasWritePermission) {
            return <SingleSelectDropdown items={actionOptions} onSelect={onActionSelected} align="end"
                                         menuOnly toggleLabel={<span><i className="fa fa-small fa-edit"/> Actions</span>} />
        }
        return []
    }

    return (
        <BaseContainer>
            <ContentContainer title={ customerDetails.org_name || customerDetails.name || customerDetails.email }
                              customActions={getCustomerActions()}>
                {
                    customerDetails.managed_externally &&
                        <>
                            <Row className="align-items-center">
                                <Col lg="9">
                                    <Alert variant="info">
                                        This customer is currently managed in { getNameForProviderType(customerDetails.imported_from) }.
                                        We will pull in any changes in { getNameForProviderType(customerDetails.imported_from) } for this customer.
                                        However, you will not be able to make any changes to this customer here.
                                    </Alert>
                                </Col>
                                <Col lg="6">
                                    <div className="d-flex flex-column">
                                        <h5>Start Managing in Maple</h5>
                                        <span className="body1">Note: Once the customer is being managed in Maple, changes
                                            in { getNameForProviderType(customerDetails.imported_from) } will not be copied over here.
                                        Please make sure all changes are made in Maple going forward.
                                    </span>
                                    </div>
                                </Col>
                                <Col lg="3">
                                    <ConfirmationButton
                                        body={<>
                                            <p>Are you sure you want to proceed?</p>
                                            <span className="caption">Note: Once the customer is being managed in Maple,
                                                changes in { getNameForProviderType(customerDetails.imported_from) } will not be
                                                copied over here. Please make sure all changes are made in Maple going
                                                forward.</span>
                                        </>}
                                        onConfirm={() => manageCustomer()}
                                    >
                                        Start Managing
                                    </ConfirmationButton>
                                </Col>
                            </Row>
                            <br/>
                        </>
                }
                <Section title="Details" actions={[]}>
                    <KeyValueDisplay2 items={details} />
                </Section>
                <Section title="Metadata" className="mt-4" actions={canEditCustomer && [
                    {variant: "text-primary", icon: "fa-edit", label: t('common.edit'), onClick: editMetadata}
                ]}>
                    {
                        (!isEditingMetadata && (_.isNil(customerDetails.metadata) || _.isEmpty(customerDetails.metadata))) ?
                            <p>No metadata</p>
                            : <KeyValueDisplay items={customerDetails.metadata} isEditing={isEditingMetadata}
                                               onCancelEditing={() => setIsEditingMetadata(false)}
                                               onUpdateKeyValues={saveMetadata}/>
                    }
                </Section>
                {
                    hasPricingPermission && !_.isEmpty(settings) && settings.collect_taxes_type !== "none" &&
                        <Section title="Taxes" className="mt-4">
                            {
                                settings.collect_taxes_type === "automatic" && _.isEmpty(taxes) &&
                                    <span>Taxes are calculated automatically for this customer based on their address. Want to manually manage the taxes for this customer? <a href="#" onClick={showManageTaxes}>Manually Manage</a></span>
                            }
                            {
                                settings.collect_taxes_type === "automatic" && !_.isEmpty(taxes) &&
                                    <span>You are manually managing taxes for this customer. If you wish to switch to automatic tax collection, please delete all the taxes listed below.</span>
                            }
                            {
                                (showCustomTaxForm || !_.isEmpty(taxes)) &&
                                    <>
                                    <BaseForm ref={formRef} initialFormFields={addTaxInitialFields} onSubmit={addTax}>
                                        <Row>
                                            <Col lg="6">
                                                <Table borderless className="no-background">
                                                    <tr>
                                                        <th width="40%">Type</th>
                                                        <th width="40%">Rate</th>
                                                        <th></th>
                                                    </tr>
                                                    {
                                                        _.map(taxes, (tax, i) =>
                                                            <tr className="thin" key={i}>
                                                                <td><span className="body2">{ tax.type }</span></td>
                                                                <td><span className="body2">{ tax.rate * 100 }%</span></td>
                                                                <td><DeleteButton onDelete={() => deleteTax(tax)}></DeleteButton></td>
                                                            </tr>
                                                        )
                                                    }
                                                    {
                                                        !_.isEmpty(filteredTaxTypeOptions) &&
                                                            <tr className="thin-horizontal">
                                                                <td width="40%">
                                                                    <BaseForm.Input type="select" name="type" options={filteredTaxTypeOptions} showSearch={false} />
                                                                </td>
                                                                <td width="40%">
                                                                    <BaseForm.Input type="number" name="rate" step="0.01" label="Rate" hideLabel required />
                                                                </td>
                                                                <td style={{verticalAlign: "bottom", paddingBottom: "5px"}}>
                                                                    <SubmitButton variant="primary">Add</SubmitButton>
                                                                </td>
                                                            </tr>
                                                    }
                                                </Table>
                                            </Col>
                                        </Row>
                                    </BaseForm>
                                    </>
                            }
                        </Section>
                }
                <div className="mt-4"/>
                {
                    hasSubscriptionsPermission &&
                    <SubscriptionListSection
                        ref={subscriptionListRef}
                        customer={customerDetails}
                        extraQueryFilters={{ customer_id: customerId }}
                        includeCustomer={false}
                        showTableActions={false}
                        hideTableIfEmpty={true}
                    />
                }
                {
                    hasPaymentsPermission &&
                        <Section title="Payment Methods" className="mt-4" actions={[{
                            variant: "primary",
                            icon: "fa-plus",
                            label: "Add Payment Method",
                            onClick: () => setShowSetupPaymentMethodModal(true)
                        }]}>
                            {
                                <MapleTable>
                                    <MapleTable.Content>
                                        <thead>
                                        <tr>
                                            <MapleTable.TH>Payment Method</MapleTable.TH>
                                            <MapleTable.TH></MapleTable.TH>
                                            <MapleTable.TH></MapleTable.TH>
                                        </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200">
                                        {
                                            _.map(paymentMethods, (row, i) =>
                                                <tr key={i}>
                                                    <td>{ renderPaymentMethod(t, row) }</td>
                                                    <td>
                                                        <div className="d-flex flex-row gap-2">
                                                            {
                                                                row.default &&
                                                                <Label.Success><i className="fa fa-check"/> Default</Label.Success>
                                                            }
                                                            {
                                                                isPaymentMethodExpiring(row) &&
                                                                <Label.Warning>Expiring</Label.Warning>
                                                            }
                                                            {
                                                                isPaymentMethodExpired(row) &&
                                                                <Label.Danger>Expired</Label.Danger>
                                                            }
                                                        </div>
                                                    </td>
                                                    <td className="text-end">
                                                        <div className="hide">
                                                            <MoreButton items={paymentActionOptions} onSelect={onPaymentActionSelected} />
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        </tbody>
                                    </MapleTable.Content>
                                </MapleTable>
                            }
                        </Section>
                }
                {
                    hasPricingPermission &&
                    <Section title="Credits" className="mt-4" actions={hasPricingWritePermission && [{
                        variant: "primary",
                        icon: "fa-plus",
                        label: "Adjust Credit",
                        onClick: adjustCredit
                    }]}>
                        {
                            <MapleTable>
                                <MapleTable.Content>
                                    <thead>
                                        <tr>
                                            <MapleTable.TH>Issued Date</MapleTable.TH>
                                            <MapleTable.TH>Name</MapleTable.TH>
                                            <MapleTable.TH>Quantity</MapleTable.TH>
                                            <MapleTable.TH>Expiry</MapleTable.TH>
                                            <MapleTable.TH>Status</MapleTable.TH>
                                            <MapleTable.TH></MapleTable.TH>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200">
                                    {
                                        _.map(credits, (row, i) =>
                                            <tr key={i} className={classnames(row.state !== "ACTIVE" && "de-highlighted")}>
                                                <td>{moment(row.created_at).format("MMM D, YYYY")}</td>
                                                <td>{row.name}</td>
                                                <td>
                                                    <span>{row.type === "AMOUNT" ? currencyFormatFromPrice(row.issued_amount) : row.issued_units}</span><br/>
                                                    <span
                                                        className="caption">Remaining: {row.type === "AMOUNT" ? currencyFormatFromPrice(row.amount) : row.units}</span>
                                                </td>
                                                <td>
                                                    {
                                                        row.expiration_date ?
                                                            moment(row.expiration_date).format("MMM D, YYYY h:mma")
                                                            : <div className="gray2">None</div>
                                                    }
                                                </td>
                                                <td>{getDescriptionForCreditState(row.state)}</td>
                                                <td>
                                                    {
                                                        row.state === "ACTIVE" &&
                                                        <DeleteButton title="Revoke Credit?"
                                                                      body="Are you sure you want to revoke the credit?"
                                                                      onDelete={() => revokeCredit(row)}/>
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    }
                                    </tbody>
                                </MapleTable.Content>
                            </MapleTable>
                        }
                    </Section>
                }
                <div className="mt-4"/>
                <DiscountListSection customer={customerDetails} />
                {
                    hasInvoicesPermission &&
                        <div className="mt-4"/>
                }
                {
                    hasInvoicesPermission &&
                    <InvoiceListSection
                        ref={invoiceListRef}
                        includeCustomer={false}
                        showTableActions={false}
                        actions={hasInvoicesWritePermission && [{
                            variant: "primary",
                            icon: "fa-plus",
                            label: "Add Charges",
                            onClick: () => setShowAddOneTimeChargesToCustomerModal(true)
                        }]}
                        extraQueryFilters={{customer_id: customerId}}
                        hideTableIfEmpty
                    />
                }
                {
                    hasPaymentsPermission &&
                        <div className="mt-4"/>
                }
                {
                    hasPaymentsPermission &&
                    <PaymentListSection
                        ref={paymentListRef}
                        includeCustomer={false}
                        showTableActions={false}
                        extraQueryFilters={{customer_id: customerId}}
                        hideTableIfEmpty
                    />
                }
            </ContentContainer>
            <AdjustCreditModal show={showAdjustCreditModal} onClose={onModalClose} customer={customerDetails} />
            <SetupPaymentMethodModal show={showSetupPaymentMethodModal} onClose={onModalClose}
                paymentConfig={settings.payment_config} customer={customerDetails} company={company} />
            <AddOneTimeChargesToCustomerModal show={showAddOneTimeChargesToCustomerModal} onClose={onModalClose}
                customer={customerDetails} />
            <AddOrEditCustomerModal show={showAddOrEditCustomerModal} onClose={onModalClose} itemToEdit={customerDetails} />
        </BaseContainer>
    );
}

export default Customer;

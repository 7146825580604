import '../../App.scss';
import React, { useState, useEffect, useMemo, useContext } from 'react';
import '../../css/modals.scss';
import '../../css/forms.scss';
import BaseForm from '../BaseForm';
import { BaseContext, getCurrencyOptions, renderDescriptionForItemPricing } from '../../helpers/common';
import { serverPost } from '../../helpers/server';
import BaseAddOrEditItemModal from './BaseAddOrEditItemModal';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
const _ = require('lodash');

function AddOrEditCouponModal(props) {
    const { company, getApiUrl } = useContext(BaseContext);
    const { t } = useTranslation('common');
    const [itemToEdit, setItemToEdit] = useState(null);
    const [initialFields, setInitialFields] = useState({ isInclusive: false });
    const [doesExpire, setDoesExpire] = useState(false);
    const [billableItems, setBillableItems] = useState([]);
    const [products, setProducts] = useState([]);
    const [productPricings, setProductPricings] = useState([]);
    const [hasCap, setHasCap] = useState(false);
    const [hasRestrictions, setHasRestrictions] = useState(false);
    const [hasRedemptionLimits, setHasRedemptionLimits] = useState(false);
    const [restrictionType, setRestrictionType] = useState("PRODUCT");
    const [couponType, setCouponType] = useState("DISCOUNT_PERCENT_COUPON");
    const [durationType, setDurationType] = useState("FOREVER");
    const [sort, setSort] = useState("createdAtDesc");

    useEffect(() => {
        if (!_.isNil(props.itemToEdit)) {
            const item = {...props.itemToEdit};
            item.percent = parseFloat(item.percent) * 100;
            item.amount = {
                value: item.amount.value_in_cents/100,
                currency: item.amount.currency
            }
            item.cap = {
                value: item.cap.value_in_cents/100,
                currency: item.cap.currency
            }
            setCouponType(item.type);
            setDoesExpire(!_.isNil(item.expiration_date));
            setItemToEdit(item);
        } else {
            setDoesExpire(false);
            setHasCap(false);
            setHasRedemptionLimits(false);
            setItemToEdit(null)
        }
    }, [props.itemToEdit])

    useEffect(() => {
        if (props.show) {
            serverPost(getApiUrl("/billable/items/find")).then((res) => {
                setBillableItems(_.filter(res, (r) => !r.standard));
            });

            serverPost(getApiUrl("/products/find")).then((res) => {
                setProducts(res);
            });
            const limit = 100
            const params = {
                company_id: company.id,
                sort_key: sort,
                pagination: {
                    limit: limit
                },
            }
            serverPost(getApiUrl("/product_pricings/find"), params).then((res) => {
                if (res) {
                    const results = res.results || [];
                    setProductPricings(results);
                }
            });
        } else {
            setDoesExpire(false);
            setHasCap(false);
            setHasRestrictions(false);
            setHasRedemptionLimits(false);
            setItemToEdit(null);
        }
    }, [props.show])

    const cleanupFields = (fields) => {
        const cleanedFields = {...fields};
        if (fields.type === "DISCOUNT_PERCENT_COUPON") {
            cleanedFields.percent = parseFloat(fields.percent)/100;
            if (fields.has_cap) {
                cleanedFields.cap = {
                    value_in_cents: Math.round(parseFloat(fields.cap.value) * 100),
                    currency: fields.cap.currency
                }
            } else {
                delete cleanedFields['cap'];
            }
        } else if (fields.type === "DISCOUNT_AMOUNT_COUPON") {
            cleanedFields.amount = {
                value_in_cents: Math.round(parseFloat(fields.amount.value) * 100),
                currency: fields.amount.currency
            }
        } else if (fields.type === "CREDIT_UNITS_COUPON") {
            cleanedFields.units = parseInt(fields.units);
        } else if (fields.type === "CREDIT_AMOUNT_COUPON") {
            cleanedFields.amount = {
                value_in_cents: Math.round(parseFloat(fields.amount.value) * 100),
                currency: fields.amount.currency
            }
        }
        if (!_.isNil(fields.months)) {
            cleanedFields.months = parseInt(fields.months);
        }
        if (!_.isNil(fields.max_redemptions)) {
            cleanedFields.max_redemptions = parseInt(fields.max_redemptions);
        }
        cleanedFields.state = "ACTIVE";
        return cleanedFields;
    }

    const onFieldChange = (name, value) => {
        if (name === "type") {
            setCouponType(value);
        } else if (name === "does_expire") {
            setDoesExpire(value);
        } else if (name === "has_cap") {
            setHasCap(value);
        } else if (name === "has_restrictions") {
            setHasRestrictions(value);
        } else if (name === "restriction_type") {
            setRestrictionType(value);
        } else if (name === "duration") {
            setDurationType(value);
        } else if (name === "has_redemption_limits") {
            setHasRedemptionLimits(value);
        }
    }

    const typeOptions = [
        { value: "DISCOUNT_PERCENT_COUPON", label: "Discount Percent"},
        { value: "DISCOUNT_AMOUNT_COUPON", label: "Discount Amount"},
        { value: "CREDIT_UNITS_COUPON", label: "Credit Units"},
    ];
//    { value: "CREDIT_AMOUNT_COUPON", label: "Credit Amount"}

    const restrictionOptions = [
        { value: "PRODUCT", label: "Product"},
        { value: "PRICING", label: "Pricing"},
    ];

    const durationOptions = [
        { value: "FOREVER", label: "Forever"},
        { value: "MONTHS", label: "Multiple Months"},
    ];
//        { value: "ONCE", label: "Once"},

    const billableItemOptions = _.map(billableItems, (bi) => {
        return { value: bi.id, label: bi.name }
    });

    const productOptions = _.map(products, (p) => {
        return { value: p.id, label: p.name || p.description }
    })

    const productPricingOptions = _.map(productPricings, (pp) => {
        return {
            value: pp.id,
            label: pp.name || pp.description || <div>
                {
                    _.map(pp.product_metric_pricings, (pmp, i) =>
                        <span key={i}>{renderDescriptionForItemPricing(pmp.item_pricing, false, pmp.item)}</span>
                    )
                }
            </div>
        }
    })

    return (
        <BaseAddOrEditItemModal
            {...props}
            initialFormFields={initialFields}
            itemToEdit={itemToEdit}
            processAddFields={cleanupFields}
            processUpdateFields={cleanupFields}
            onFieldChange={onFieldChange}
            itemLabel={t('coupons.coupon')}
            itemBaseUrl="/coupons"
        >
            <Row>
                <BaseForm.Input colSpan="12" name="name" label={t('common.name')} type="text" required />
                <BaseForm.Input colSpan="6" name="type" label={t('coupons.type')} type="select" options={typeOptions} showSearch={false}/>
            </Row>
            {
                couponType === "DISCOUNT_PERCENT_COUPON" &&
                    <>
                    <Row>
                        <BaseForm.Input colSpan="6" name="percent" label={t('coupons.percent')} type="number" step="1" min="0" max="100" required />
                    </Row>
                    <Row>
                        <span className="body2">Maximum</span>
                        <BaseForm.Input colSpan="10" name="has_cap" label={t('coupons.has_cap')} type="switch" />
                        {
                            hasCap &&
                                <>
                                    <BaseForm.Input colSpan="6" name="cap.value" label={t('coupons.cap')} type="number" step="0.01" min="0" required />
                                    <BaseForm.Input colSpan="6" name="cap.currency" label={t('common.currency')} type="select" options={getCurrencyOptions()} required />
                                </>
                        }
                    </Row>
                    </>
            }
            {
                couponType === "DISCOUNT_AMOUNT_COUPON" &&
                    <Row>
                        <BaseForm.Input colSpan="6" name="amount.value" label={t('discounts.amount')} type="number" step="0.01" min="0" required />
                        <BaseForm.Input colSpan="6" name="amount.currency" label={t('common.currency')} type="select" options={getCurrencyOptions()} required />
                    </Row>
            }
            {
                couponType === "CREDIT_UNITS_COUPON" &&
                    <Row>
                        <BaseForm.Input colSpan="6" name="units" label={t('coupons.units')} type="number" step="1" min="0" required />
                        <BaseForm.Input colSpan="6" name="item_id" label={t('coupons.item')} type="select" options={billableItemOptions} required align="end"/>
                    </Row>
            }
            {
                couponType === "CREDIT_AMOUNT_COUPON" &&
                    <Row>
                        <BaseForm.Input colSpan="6" name="amount.value" label={t('discounts.amount')} type="number" step="0.01" min="0" required />
                        <BaseForm.Input colSpan="6" name="amount.currency" label={t('common.currency')} type="select" options={getCurrencyOptions()} required />
                    </Row>
            }
            <br/>
            <Row>
                <span className="body2">Restrictions</span>
                <BaseForm.Input colSpan="10" name="has_restrictions" label={t('coupons.has_restrictions')} type="switch" />
                {
                    hasRestrictions &&
                        <>
                            <Row>
                            <BaseForm.Input colSpan="6" name="restriction_type" label={t('coupons.restriction_type')} type="select" options={restrictionOptions} showSearch={false}/>
                            {
                                restrictionType === "PRODUCT" &&
                                    <BaseForm.Input colSpan="6" name="product_id" label={t('coupons.product')} type="select" options={productOptions} align="end" showSearch={false}/>
                            }
                            {
                                restrictionType === "PRICING" &&
                                    <BaseForm.Input colSpan="6" name="product_pricing_id" label={t('coupons.pricing')} type="select" options={productPricingOptions} align="end" showSearch={true}/>
                            }
                            </Row>
                        </>
                }
            </Row>
            <br/>
            <Row>
                <span className="body2">Duration</span>
                <span className="body1">How long is the coupon valid till once it is redeemed?</span>
                <BaseForm.Input colSpan="6" name="duration" label={t('coupons.duration')} type="select" options={durationOptions} showSearch={false} />
                {
                    durationType === "MONTHS" &&
                        <BaseForm.Input colSpan="6" name="months" label={t('coupons.months')} type="number" step="1" min="1" required />
                }
            </Row>
            <br/>
            <Row>
                <span className="body2">Redemption Limits</span>
                <BaseForm.Input colSpan="10" name="does_expire" label={t('coupons.does_expire')} type="switch" />
                {
                    doesExpire &&
                        <>
                            <BaseForm.Input colSpan="6" name="expiration_date" label={t('coupons.redeem_by')}
                                type="date" includeTime={true} minDate={moment()} required/>
                            <span className="caption">Date/Time is based on your local timezone</span>
                        </>
                }
                <BaseForm.Input colSpan="10" name="has_redemption_limits" label={t('coupons.has_redemption_limits')} type="switch" />
                {
                    hasRedemptionLimits &&
                        <>
                            <BaseForm.Input colSpan="6" name="max_redemptions" label={t('coupons.max_redemptions')} type="number" min="1" required/>
                        </>
                }
            </Row>
        </BaseAddOrEditItemModal>
    );

}

export default AddOrEditCouponModal;

import '../../App.scss';
import React, { useEffect, useState, useContext } from 'react';
import { useParams, Link } from 'react-router-dom';
import { serverFetch, serverPatch, serverPost } from '../../helpers/server';
import {
    BaseContext,
    UserContext,
    currencyFormatFromPrice,
    getNameForProviderType,
} from '../../helpers/common';
import {Alert, Button, Col, Row} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import BaseContainer from '../../components/BaseContainer';
import InvoiceListSection from '../../components/InvoiceListSection';
import KeyValueDisplay2 from '../../components/KeyValueDisplay2';
import KeyValueDisplay from '../../components/KeyValueDisplay';
import ContentContainer from '../../components/ContentContainer';
import Section from '../../components/Section';
import BundlePricingTable from '../../components/BundlePricingTable';
import Loader from '../../components/Loader';
import PausePaymentsModal from '../../components/modals/PausePaymentsModal';
import SimpleModal from '../../components/modals/SimpleModal';
import SingleSelectDropdown from '../../components/SingleSelectDropdown';
import UpdateSubscriptionModal from '../../components/modals/UpdateSubscriptionModal';
import CancelSubscriptionModal from '../../components/modals/CancelSubscriptionModal';
import UpdateSubscriptionAutoChargeModal from '../../components/modals/UpdateSubscriptionAutoChargeModal';
import ContentBox from '../../components/ContentBox';
import Label from '../../components/Label';
import moment from 'moment';
import UpdateTrialEndDateModal from "../../components/modals/UpdateTrialEndDateModal";
import DiscountListSection from "../../components/DiscountListSection";
import ConfirmationButton from "../../components/ConfirmationButton";
import SelectEntitlements from "../../components/SelectEntitlements";
const _ = require('lodash');

function Subscription(props) {
    const { t } = useTranslation('common');
    const { subscriptionId } = useParams();
    const { userInfo, isSuperUser } = useContext(UserContext);
    const { getApiUrl, getCompanySpecificUrl, company, setPageTitle, hasAccess } = useContext(BaseContext);
    const [showUpdateSubscriptionModal, setShowUpdateSubscriptionModal] = useState(false);
    const [showCancelSubscriptionModal, setShowCancelSubscriptionModal] = useState(false);
    const [showDeleteCancellationSubscriptionModal, setShowDeleteCancellationSubscriptionModal] = useState(false);
    const [showPausePaymentsModal, setShowPausePaymentsModal] = useState(false);
    const [showResumePaymentsModal, setShowResumePaymentsModal] = useState(false);
    const [showUpdateTrialEndModal, setShowUpdateTrialEndModal] = useState(false);
    const [showUpdateSubscriptionAutoChargeModal, setShowUpdateSubscriptionAutoChargeModal] = useState(false);
    const [loadingSubscription, setLoadingSubscription] = useState(true);
    const [subscription, setSubscription] = useState({});
    const [discounts, setDiscounts] = useState([]);
    const [nextInvoice, setNextInvoice] = useState(null);
    const [details, setDetails] = useState({});
    const [isEditingMetadata, setIsEditingMetadata] = useState(false);
    const [isEditingEntitlements, setIsEditingEntitlements] = useState(false);
    const allowEntitlements = isSuperUser;

    const hasWritePermission = hasAccess("subscriptions", userInfo, "write") && subscription.customer && !subscription.customer.managed_externally;
    const hasInvoicesPermission = hasAccess("invoices", userInfo, "read");

    useEffect(() => {
        setPageTitle(`Subscription`);
    }, []);

    const fetchData = (skipCache=false) => {
        serverFetch(getApiUrl(`/subscriptions/${subscriptionId}`), { skipCache }).then((res) => {
            if (res) {
                res.isCancelled = moment().isAfter(res.end_date);
                setSubscription(res);
                setLoadingSubscription(false);
            }
        });

        serverPost(getApiUrl(`/discounts/find`), { subscription_id: subscriptionId }).then((res) => {
            if (res) {
                setDiscounts(res);
            }
        });
    }

    const fetchNextInvoice = () => {
        if (!hasInvoicesPermission) {
            setNextInvoice(null);
            return;
        }
        if (_.isNil(subscription) || _.isEmpty(subscription) || _.isNil(subscription.next_invoice_date) || subscription.isCancelled) {
            setNextInvoice(null);
        } else {
            const data = {
                subscription_id: subscriptionId,
                customer_id: subscription.customer.id,
                currency: subscription.bundle_pricing.currency
            }
            serverPost(getApiUrl(`/invoices/next`), data).then((res) => {
                if (res) {
                    setNextInvoice(res);
                }
            });
        }
    }

    const editEntitlements = () => {
        setIsEditingEntitlements(true);
    }

    const saveEntitlements = (newEntitlements) => {
        const processedData = {
            override_entitlements: newEntitlements
        };

        serverPatch(getApiUrl(`/subscriptions/${subscriptionId}`), processedData).then((res) => {
            if (res) {
                fetchData(true);
            }
            setIsEditingEntitlements(false);
        });
    }

    useEffect(() => {
        fetchNextInvoice();
    }, [subscription])

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (_.isEmpty(subscription)) {
            return;
        }
        let endDate = null;
        if (subscription.end_date) {
            if (moment().isAfter(subscription.end_date)) {
                endDate = moment(subscription.end_date).format("D MMM, YYYY h:mm:ssa");
            } else if (!_.isNil(subscription.change_config)) {
                endDate = null;
            } else {
                endDate = `${moment(subscription.end_date).format("D MMM, YYYY h:mm:ssa")}`;
            }
        }
        const data = {
            'Customer': <Link to={getCompanySpecificUrl(`/customer/${subscription.customer.id}`)}>{subscription.customer.org_name || subscription.customer.name || subscription.customer.email}</Link>,
            'ID': subscription.id,
            'Next Invoice': nextInvoice && <Link to={getCompanySpecificUrl(`/subscription/${subscription.id}/next_invoice`)}>{ currencyFormatFromPrice(nextInvoice.due) } on { moment(nextInvoice.invoice_date).format("MMM D, YYYY h:mm:ss a") }</Link>,
            'Start Date': moment(subscription.start_date).format("D MMM, YYYY h:mm:ssa"),
            'End Date': endDate,
            'Renewal Date': subscription.renewal_date && moment(subscription.renewal_date).format("DD MMM, YYYY h:mm:ssa"),
            'Billing Method':
                <div className="d-flex flex-row gap-3 align-items-center">
                    <span>
                        { subscription.auto_charges ? "Automatic": "Manual" }
                    </span>
                    <Button onClick={() => setShowUpdateSubscriptionAutoChargeModal(true)} variant="text" size="sm"><i className="fa fa-edit"/></Button>
                </div>,
            'MRR': currencyFormatFromPrice(subscription.mrr),
        };
        if (!_.isNil(subscription.imported_from)) {
            data[`${getNameForProviderType(subscription.imported_from)} Reference`] =
                <a href={`https://dashboard.stripe.com/subscriptions/${subscription.import_ref}`} target="_blank">{ subscription.import_ref }</a>;
        }
        if (subscription.trial) {
            delete data['End Date'];
            delete data['Renewal Date'];
            delete data['Billing Method'];
            data['Trial End'] = endDate;
        }
        if (!hasInvoicesPermission) {
            delete data['Next Invoice'];
        }
        setDetails(data);
    }, [subscription, nextInvoice, getCompanySpecificUrl]);

    const onModalClose = (didUpdate=false) => {
        setShowCancelSubscriptionModal(false);
        setShowDeleteCancellationSubscriptionModal(false);
        setShowUpdateSubscriptionModal(false);
        setShowPausePaymentsModal(false);
        setShowResumePaymentsModal(false);
        setShowUpdateTrialEndModal(false);
        setShowUpdateSubscriptionAutoChargeModal(false);
        fetchData(didUpdate);
    }

    const editMetadata = () => {
        setIsEditingMetadata(true);
    }

    const saveMetadata = (data) => {
        const processedData = {
            metadata: data
        };

        serverPatch(getApiUrl(`/subscriptions/${subscriptionId}`), processedData).then((res) => {
            if (res) {
                fetchData(true);
            }
            setIsEditingMetadata(false);
        });
    }

    const onActionSelected = (type) => {
        if (type === "update") {
            setShowUpdateSubscriptionModal(true);
        } else if (type === "cancel") {
            setShowCancelSubscriptionModal(true);
        } else if (type === "pause") {
            setShowPausePaymentsModal(true);
        } else if (type === "resume") {
            setShowResumePaymentsModal(true);
        } else if (type === "delete_cancellation") {
            setShowDeleteCancellationSubscriptionModal(true);
        } else if (type === "update_trial_end") {
            setShowUpdateTrialEndModal(true);
        }
    }

    const onConfirmResumePayments = () => {
        serverPost(getApiUrl(`/subscriptions/${subscription.id}/resume`)).then((res) => {
            fetchData(true);
        })
    }

    const onConfirmDeleteScheduledCancellation = () => {
        serverPost(getApiUrl(`/subscriptions/${subscription.id}/undo_cancel`)).then((res) => {
            fetchData(true);
        })
    }

    const isActiveTrial = subscription.trial && moment().isBefore(subscription.end_date);
    const actionOptions = [
        { id: "update", label: "Change Subscription" },
        isActiveTrial && { id: "update_trial_end", label: "Update Trial End Date" },
        !isActiveTrial && { divider: true },
        !isActiveTrial && _.isNil(subscription.pause_config) && { id: "pause", label: "Pause Payments" },
        !isActiveTrial && !_.isNil(subscription.pause_config) && { id: "resume", label: "Resume Payments" },
        !isActiveTrial && _.isNil(subscription.cancel_config) && { id: "cancel", label: "Cancel Subscription", className: "error" },
        !isActiveTrial && !_.isNil(subscription.cancel_config) && { id: "delete_cancellation", label: "Delete Scheduled Cancellation", className: "error" },
    ];

    const renderActions = () => {
        if (!hasWritePermission) {
            return [];
        }
        if (subscription.isCancelled) {
            return [];
        }
        return (
            <SingleSelectDropdown items={actionOptions} onSelect={onActionSelected} align="end"
                menuOnly toggleLabel={<span><i className="fa fa-small fa-edit"/> Actions</span>} />
        )
    }

    const renderSubscriptionStatus = (subscription) => {
        let endDate = null;
        let statusDescription = null;
        if (subscription.end_date) {
            if (moment().isAfter(subscription.end_date)) {
                statusDescription = <Label.Info className="d-flex align-items-center">Cancelled</Label.Info>
            } else if (subscription.trial) {
                statusDescription = <Label.Neutral className="d-flex align-items-center">Trials till {moment(subscription.end_date).format("MMM D")}</Label.Neutral>
            } else if (!_.isNil(subscription.change_config)) {
                statusDescription = <Label.Warning className="d-flex align-items-center">Changes on {moment(subscription.end_date).format("MMM D, YYYY")}</Label.Warning>
            } else {
                statusDescription = <Label.Info className="d-flex align-items-center">Cancels on {moment(subscription.end_date).format("MMM D, YYYY")}</Label.Info>
            }
        } else if (subscription.status === "UNPAID") {
            statusDescription = <Label.Danger className="d-flex align-items-center">Unpaid</Label.Danger>
        } else if (subscription.status === "ACTIVE") {
            statusDescription = <Label.Success className="d-flex align-items-center">Active</Label.Success>
        } else {
            statusDescription = <Label.Info className="d-flex align-items-center">{ subscription.status }</Label.Info>
        }
        return statusDescription
    }

    const renderPauseStatus = (subscription) => {
        let endDate = null;
        if (!_.isNil(subscription.pause_config)) {
            let resumeDescription = "";
            if (!_.isNil(subscription.pause_config.resume_date)) {
                resumeDescription = ` till ${moment(subscription.pause_config.resume_date).format("MMM D hh:mma")}`
            }
            return <Label.Info>
                <i className="fa fa-pause"/>&nbsp;Paused Collections{ resumeDescription }
            </Label.Info>
        } else {
            return null
        }
    }

    const manageCustomer = () => {
        const data = {};
        serverPatch(getApiUrl(`/customers/${subscription.customer_id}/start_managing`), data).then((res) => {
            if (res) {
                fetchData(true);
            }
        });
    }

    return (
        <BaseContainer>
            <Loader loading={loadingSubscription}>
            {
                () =>
                    <>
                        <ContentContainer title={
                                <div className="d-flex flex-row gap-3">
                                    <span>Subscription for {subscription.customer && (subscription.customer.name || subscription.customer.email)}</span>
                                    <div className="d-flex align-items-center gap-3">
                                        { renderSubscriptionStatus(subscription) }
                                        { renderPauseStatus(subscription) }
                                    </div>
                                </div>
                            }
                            customActions={ renderActions() }>
                            {
                                !_.isNil(subscription.change_config) && !subscription.isCancelled &&
                                    <ContentBox>
                                        <ContentBox.Body>
                                            <span className="body2">Scheduled Plan Change</span>
                                            <br/>
                                            <div>
                                                <span>A change has been scheduled for { moment(subscription.change_date).format("D MMM, YYYY h:mm:ssa") } (local timezone).</span>
                                            </div>
                                            <br/>
                                            <BundlePricingTable
                                                bundlePricing={subscription.change_config.bundle_pricing}
                                                configItems={subscription.change_config.config_items}
                                            />
                                        </ContentBox.Body>
                                    </ContentBox>
                            }
                            {
                                subscription.customer.managed_externally &&
                                    <>
                                        <Row className="align-items-center">
                                            <Col lg="9">
                                                <Alert variant="info">
                                                    This subscription is currently managed in { getNameForProviderType(subscription.customer.imported_from) }.
                                                    We will pull in any changes in { getNameForProviderType(subscription.customer.imported_from) } for this subscription.
                                                    However, you will not be able to make any changes to this subscription here.
                                                </Alert>
                                            </Col>
                                            <Col lg="6">
                                                <div className="d-flex flex-column">
                                                    <h5>Start Managing in Maple</h5>
                                                    <span className="body1">Note: Once the customer is being managed in Maple, changes
                                                in { getNameForProviderType(subscription.customer.imported_from) } will not be copied over here.
                                            Please make sure all changes are made in Maple going forward.
                                        </span>
                                                </div>
                                            </Col>
                                            <Col lg="3">
                                                <ConfirmationButton
                                                    body={<>
                                                        <p>Are you sure you want to proceed?</p>
                                                        <span className="caption">Note: Once the customer is being managed in Maple,
                                                    changes in { getNameForProviderType(subscription.customer.imported_from) } will not be
                                                    copied over here. Please make sure all changes are made in Maple going
                                                    forward.</span>
                                                    </>}
                                                    onConfirm={() => manageCustomer()}
                                                >
                                                    Start Managing
                                                </ConfirmationButton>
                                            </Col>
                                        </Row>
                                        <br/>
                                    </>
                            }
                            <Section className="mt-4" title="Details" actions={[]}>
                                <KeyValueDisplay2 items={details} />
                            </Section>
                            {
                                allowEntitlements &&
                                    <Section title="Entitlements" actions={!isEditingEntitlements && [
                                        {variant: "text-primary", icon: "fa-edit", label: "Edit", onClick: editEntitlements}
                                    ]}>
                                        <Row>
                                            <Col lg="8">
                                                <SelectEntitlements
                                                    entitlements={subscription.computed_entitlements}
                                                    isEditing={isEditingEntitlements}
                                                    onUpdateEntitlements={saveEntitlements}
                                                    onCancelEditing={() => setIsEditingEntitlements(false)}
                                                />
                                            </Col>
                                        </Row>
                                    </Section>
                            }
                            <Section title="Metadata" className="mt-4" actions={hasWritePermission && [
                                {variant: "text-primary", icon: "fa-edit", label: t('common.edit'), onClick: editMetadata}
                            ]}>
                                {
                                    (!isEditingMetadata && (_.isNil(subscription.metadata) || _.isEmpty(subscription.metadata))) ?
                                            <p>No metadata</p>
                                        : <KeyValueDisplay items={subscription.metadata} isEditing={isEditingMetadata}
                                            onCancelEditing={() => setIsEditingMetadata(false)}
                                            onUpdateKeyValues={saveMetadata}/>
                                }
                            </Section>
                            <Section title="Plan" className="mt-4">
                                <BundlePricingTable
                                    bundlePricing={subscription.bundle_pricing}
                                    configItems={subscription.config_items}
                                    discounts={discounts}
                                    showUsage={isSuperUser}
                                    subscription={subscription}
                                    onUsageUpdated={() => fetchNextInvoice()}
                                    hideDiscountAdditionalDetails={true}
                                />
                            </Section>
                            <DiscountListSection
                                customer={subscription.customer}
                                extraQueryFilters={{
                                    subscription_id: subscription.id
                                }}
                                allowCreation={false}
                            />
                            {
                                hasInvoicesPermission &&
                                <InvoiceListSection
                                    includeCustomer={false}
                                    showTableActions={false}
                                    extraQueryFilters={{ subscription_id: subscriptionId }}
                                    hideTableIfEmpty={true}
                                />
                            }
                        </ContentContainer>
                        <CancelSubscriptionModal show={showCancelSubscriptionModal} onClose={onModalClose} subscription={subscription} />
                        <PausePaymentsModal show={showPausePaymentsModal} onClose={onModalClose} subscription={subscription} />
                        <SimpleModal show={showResumePaymentsModal} onClose={onModalClose} title="Resume Payments?"
                            buttonTitle="Resume" onConfirm={onConfirmResumePayments}
                            body={
                                <>
                                <span>Resuming payments will start processing any new invoices created from now. </span><br/><br/>
                                <span className="caption">Note: Any invoices
                                created while the payments were payments will not be collected automatically. You can change the
                                status on any draft invoices manually to collect on them.</span>
                                </>
                            }
                        />
                        <UpdateSubscriptionModal
                            show={showUpdateSubscriptionModal}
                            onClose={onModalClose}
                            subscription={subscription}
                            discounts={discounts}
                        />
                        <SimpleModal show={showDeleteCancellationSubscriptionModal} onClose={onModalClose} title="Delete Scheduled Cancellation?"
                            buttonTitle="Delete" buttonVariant="danger" onConfirm={onConfirmDeleteScheduledCancellation}
                            body={<span>Are you sure you want to delete the scheduled cancellation?</span>}
                        />
                        <UpdateTrialEndDateModal show={showUpdateTrialEndModal} onClose={onModalClose}
                                                 subscription={subscription} />
                        <UpdateSubscriptionAutoChargeModal
                            show={showUpdateSubscriptionAutoChargeModal}
                            onClose={onModalClose}
                            subscription={subscription} />
                    </>
            }
            </Loader>
        </BaseContainer>
    );
}

export default Subscription;

import '../../../App.scss';
import React, { useEffect, useContext } from 'react';
import {BaseContext, IntegrationContext} from '../../../helpers/common';
import ContentContainer from '../../../components/ContentContainer';

function Dashboard() {
    const { setPageTitle } = useContext(BaseContext);
    const { integration } = useContext(IntegrationContext);

    useEffect(() => {
        setPageTitle(`Integrations`);
    }, []);

    return (
        <ContentContainer>
            <div className="py-3">
                <h5>Dashboard</h5>
            </div>
        </ContentContainer>
    );
}

export default Dashboard;

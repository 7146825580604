import '../../App.scss';
import React, { useEffect, useState, useContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { serverFetch, serverPost } from '../../helpers/server';
import {currencyFormatFromPrice, BaseContext, UserContext, getFormattedAddressForAddress } from '../../helpers/common';
import AddOrEditCustomerModal from '../../components/modals/AddOrEditCustomerModal';
import Columns from '../../components/Columns';
import BaseContainer from '../../components/BaseContainer';
import ContentContainer from '../../components/ContentContainer';
import BaseOverlayTrigger from '../../components/BaseOverlayTrigger';
import Section from '../../components/Section';
import MapleTable from '../../components/MapleTable';
import InfiniteScroll from "react-infinite-scroll-component";
import moment from 'moment';
import SortableTableHeader from "../../components/SortableTableHeader";
import MapleTableHeaderWithActions from "../../components/MapleTableHeaderWithActions";
import { PlusIcon, UsersIcon } from "@heroicons/react/20/solid";
import EmptyState from "../../components/EmptyState";
const _ = require('lodash');

function Customers() {
    const navigate = useNavigate();
    const { userInfo } = useContext(UserContext);
    const { company, getApiUrl, setPageTitle, getCompanySpecificUrl, hasAccess } = useContext(BaseContext);
    const [showAddOrEditCustomerModal, setShowAddOrEditCustomerModal] = useState(false);
    const [customers, setCustomers] = useState([]);
    const [settings, setSettings] = useState({});
    const [hasMore, setHasMore] = useState(true);
    const [fromKey, setFromKey] = useState(null);
    const [loading, setLoading] = useState(true);
    const [sort, setSort] = useState("createdAtDesc");
    const [meta, setMeta] = useState({});
    const [initialFields, setInitialFields] = useState({ sort: "createdAtDesc" })
    const [query, setQuery] = useState(null)

    const hasReadPermissions = hasAccess("customers", userInfo, "read");
    const hasWritePermissions = hasAccess("customers", userInfo, "write");

    useEffect(() => {
        setPageTitle(`Customers`);
    }, []);

    useEffect(() => {
        serverFetch(getApiUrl(`/settings`)).then((res) => {
            setSettings(res)
        })
    }, []);

    const onSearch = (restart = true) => {
        const limit = 50;
        const params = {
            company_id: company.id,
            sort_key: sort || "createdAtDesc",
            pagination: {
                from_key: restart ? null: fromKey,
                limit: limit
            },
            query: {
                search: query,
            },
            include_meta: restart
        }
        serverPost(getApiUrl("/customers/find"), params).then((res) => {
            if (res) {
                const results = res.results || [];
                if (restart) {
                    setCustomers(results);
                    setMeta(res.meta);
                } else {
                    setCustomers(_.concat(customers, results));
                }
                setLoading(false);
                setFromKey(res.pagination.from_key);
                setHasMore(results.length === limit);
            }
        });
    };

    useEffect(() => {
        onSearch(true);
    }, [sort, query]);

    const onAddCustomer = () => {
        setShowAddOrEditCustomerModal(true);
    }

    const onModalClose = (didUpdate) => {
        setShowAddOrEditCustomerModal(false);
        if (didUpdate) {
            onSearch(true);
        }
    }

    const onParamsChange = (data) => {
        setQuery(data.search);
    }

    const isTaxAutomatic = settings.collect_taxes_type === "automatic";
    const isEmpty = customers.length === 0 && !hasMore && _.isEmpty(query);
    return (
        <BaseContainer>
            <ContentContainer>
                <InfiniteScroll
                    dataLength={customers.length}
                    next={() => onSearch(false)}
                    hasMore={hasMore}
                    scrollableTarget="content-wrapper"
                >
                    <Section title="Customers"
                             loading={loading}
                             actions={hasWritePermissions && !isEmpty && !loading && [{
                                variant: "primary",
                                icon: "fa-plus",
                                label: "Create Customer",
                                onClick: onAddCustomer
                             }]}>
                        {
                            isEmpty ?
                                <EmptyState
                                    icon={UsersIcon}
                                    title={"No customers."}
                                    subtitle={"Get started by creating a new one."}
                                    buttonLabel={"New Customer"}
                                    hasWritePermissions={hasWritePermissions}
                                    onClick={onAddCustomer}
                                />
                            : <MapleTable>
                                    <MapleTableHeaderWithActions
                                        showSearch={true}
                                        searchPlaceholder="Search Customers"
                                        showFilters={false}
                                        meta={meta}
                                        onParamsChange={onParamsChange}
                                    />
                                    <MapleTable.Content>
                                        <thead>
                                            <tr>
                                                <SortableTableHeader
                                                    onSortChange={setSort} sortKeyUp="orgAToZ" sortKeyDown="orgZToA"
                                                    currentSort={sort}>
                                                    Name
                                                </SortableTableHeader>
                                                <SortableTableHeader
                                                    onSortChange={setSort} sortKeyUp="mrrAsc" sortKeyDown="mrrDesc"
                                                    currentSort={sort} innerClassName="justify-end">
                                                    MRR
                                                </SortableTableHeader>
                                                <th className="thin-horizontal"></th>
                                                <SortableTableHeader
                                                    onSortChange={setSort} sortKeyUp="createdAtAsc" sortKeyDown="createdAtDesc"
                                                    currentSort={sort} innerClassName="w-px whitespace-nowrap">
                                                    Since
                                                </SortableTableHeader>
                                                <th className="d-none d-lg-table-cell w-px whitespace-nowrap"></th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200">
                                        {
                                            _.map(customers, (row, i) =>
                                                 <tr key={i} className="cursor-pointer" onClick={() => navigate(getCompanySpecificUrl(`/customer/${row.id}`))}>
                                                     <td>
                                                         <Columns.CustomerName customer={row} />
                                                     </td>
                                                     <td className="text-end">
                                                         { currencyFormatFromPrice(row.mrr) }
                                                     </td>
                                                     <td>
                                                     {
                                                         isTaxAutomatic && _.isEmpty(getFormattedAddressForAddress(row.address)) &&
                                                             <span><i className="fa fa-triangle-exclamation warning-color"/> Address Missing</span>
                                                     }
                                                     </td>
                                                     <td className="w-px whitespace-nowrap">
                                                         { moment(row.created_at).format("MMM D, YYYY") }
                                                     </td>
                                                     <td className="d-none d-lg-table-cell w-px whitespace-nowrap">
                                                         {
                                                             row.exclude_from_metrics &&
                                                                <BaseOverlayTrigger content={"This customer is excluded from any metrics calculations"}>
                                                                    <i className="fa fa-right-from-bracket"/>
                                                                </BaseOverlayTrigger>
                                                         }
                                                     </td>
                                                 </tr>
                                            )
                                        }
                                        {
                                            hasMore &&
                                                <tr>
                                                    <td colSpan="10" className="text-center">
                                                        <div className="spinner-border text-secondary"/>
                                                    </td>
                                                </tr>
                                        }
                                        </tbody>
                                    </MapleTable.Content>
                                </MapleTable>
                        }
                    </Section>
                </InfiniteScroll>
            </ContentContainer>
            <AddOrEditCustomerModal show={showAddOrEditCustomerModal} onClose={onModalClose} />
        </BaseContainer>
    );
}

export default Customers;

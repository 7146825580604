import '../App.scss';
import React from 'react';
import BaseContainerWithoutNav from '../components/BaseContainerWithoutNav';
import LoginComponent from '../components/LoginComponent';
import { Row, Col, Container } from 'react-bootstrap';

function Login() {
    return (
        <BaseContainerWithoutNav>
            <Container>
                <Row>
                    <Col md={{span: 6, offset:3}}>
                        <LoginComponent/>
                    </Col>
                </Row>
            </Container>
        </BaseContainerWithoutNav>
    );
}

export default Login;

import '../../App.scss';
import React, { useEffect, useState, useContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {serverFetch, serverPatch, serverPost} from '../../helpers/server';
import { BaseContext } from '../../helpers/common';
import { useTranslation } from 'react-i18next';
import BaseContainer from '../../components/BaseContainer';
import AddOrEditProductModal from '../../components/modals/AddOrEditProductModal';
import MapleTable from '../../components/MapleTable';
import ContentContainer from '../../components/ContentContainer';
import Section from '../../components/Section';
import { Button, Image } from 'react-bootstrap';
import SectionNav from "../../components/SectionNav";
import ArchiveButton from "../../components/ArchiveButton";
import {FolderPlusIcon} from "@heroicons/react/24/outline";
import {PlusIcon} from "@heroicons/react/20/solid";
import EmptyState from "../../components/EmptyState";
const _ = require('lodash');

function Products() {
    const navigate = useNavigate();
    const { t } = useTranslation('common');
    const { company, getApiUrl, setPageTitle, getCompanySpecificUrl } = useContext(BaseContext);
    const [products, setProducts] = useState([]);
    const [showAddOrEditProductModal, setShowAddOrEditProductModal] = useState(false);
    const [productToEdit, setProductToEdit] = useState(false);
    const [loading, setLoading] = useState(true);
    const [settings, setSettings] = useState({});
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [hasArchivedProducts, setHasArchivedProducts] = useState(false);
    const [activeNav, setActiveNav] = useState("ACTIVE");

    useEffect(() => {
        setPageTitle(`Products`);
    }, []);

    const fetchData = (skipCache=false) => {
        serverFetch(getApiUrl(`/settings`)).then((res) => {
            setSettings(res)
        })

        serverPost(getApiUrl("/products/find"), { company_id: company.id }, { skipCache }).then((res) => {
            setProducts(res);
            setLoading(false);
        });
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        setHasArchivedProducts(!_.isEmpty(_.filter(products, p => p.state === "ARCHIVED")));
        setFilteredProducts(_.filter(products, p => p.state === activeNav));
    }, [products, activeNav])

    const onModalClose = () => {
        setShowAddOrEditProductModal(false);
        fetchData(true);
    }

    const addProduct = () => {
        setProductToEdit(null);
        setShowAddOrEditProductModal(true);
    }

    const archiveProduct = (product) => {
        serverPatch(getApiUrl(`/products/${product.id}`), { state: "ARCHIVED" }).then((res) => {
            if (res) {
                fetchData(true);
            }
        })
    }

    const editProduct = (event, product) => {
        event.stopPropagation();
        setProductToEdit(product);
        setShowAddOrEditProductModal(true);
    }

    const onNavClick = (tabId) => {
        setActiveNav(tabId);
    }

    const tabItems = [{
        'label': 'Active',
        'id': 'ACTIVE',
        active: activeNav === "ACTIVE"
    }, {
        'label': 'Archived',
        'id': 'ARCHIVED',
        active: activeNav === "ARCHIVED"
    }]

    const isTaxAutomatic = settings.collect_taxes_type === "automatic";

    return (
        <BaseContainer>
            <ContentContainer>
                <Section title={t('common.products')} loading={loading} actions={!loading && !_.isEmpty(filteredProducts) && [{
                        variant: "primary",
                        icon: "fa-plus",
                        label: "Create Product",
                        onClick: addProduct
                    }]}>
                    {
                        hasArchivedProducts &&
                        <>
                            <SectionNav items={tabItems} onClick={onNavClick} />
                        </>
                    }
                    {
                        _.isEmpty(filteredProducts) ?
                            <EmptyState
                                title={"No products."}
                                subtitle={"Get started by creating a new one."}
                                buttonLabel={"New Product"}
                                onClick={addProduct}
                            />
                        : <MapleTable className="mt-2">
                                <MapleTable.Content>
                                    <thead>
                                        <tr>
                                            <MapleTable.TH>Name</MapleTable.TH>
                                            <MapleTable.TH>Description</MapleTable.TH>
                                            <MapleTable.TH></MapleTable.TH>
                                            <MapleTable.TH className="text-end"></MapleTable.TH>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200">
                                    {
                                        _.map(filteredProducts, (product, i) =>
                                             <tr key={i} className="cursor-pointer" onClick={() => navigate(getCompanySpecificUrl(`/product/${product.id}`))}>
                                                <td>
                                                    <div className="d-flex align-items-center gap-3">
                                                    {
                                                        product.image_url ?
                                                            <div className="small-product-logo align-self-center">
                                                                <Image src={product.image_url} />
                                                            </div>
                                                        : <i className="fa fa-product-hunt fa-primary" style={{ fontSize: "35px" }}/>
                                                    }
                                                    <span>{ product.name }</span>
                                                    </div>
                                                </td>
                                                <td>{ product.description }</td>
                                                <td>
                                                {
                                                    isTaxAutomatic && _.isNil(product.tax_code) &&
                                                        <span><i className="fa fa-triangle-exclamation warning-color"/> Tax Code Missing</span>
                                                }
                                                </td>
                                                <td className="text-end">
                                                {
                                                    product.state !== "ARCHIVED" &&
                                                        <div className="flex flex-row justify-content-end gap-1">
                                                            <Button variant="text-primary" size="sm"
                                                                    onClick={(event) => editProduct(event, product)}>Edit</Button>
                                                            <span className="hidden sm:inline">·</span>
                                                            <ArchiveButton size="sm" onDelete={() => archiveProduct(product)}></ArchiveButton>
                                                        </div>
                                                }
                                                </td>
                                             </tr>
                                        )
                                    }
                                    </tbody>
                                </MapleTable.Content>
                            </MapleTable>
                    }
                </Section>
            </ContentContainer>
            <AddOrEditProductModal show={showAddOrEditProductModal} onClose={onModalClose} itemToEdit={productToEdit} />
        </BaseContainer>
    );
}

export default Products;

import '../../App.scss';
import '../../css/modals.scss';
import React, { useState, useEffect, useContext, createRef } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import BaseModal from './BaseModal';
import BaseForm from '../BaseForm';
import SubmitButton from '../SubmitButton';
import { Row, Col } from 'react-bootstrap';
import StripeSetupPaymentMethod from '../StripeSetupPaymentMethod';
import { BaseContext } from '../../helpers/common';
import { useTranslation } from 'react-i18next';
const _ = require('lodash');

function SetupPaymentMethodModal(props) {
    const { t } = useTranslation('common');
    const { getCompanySpecificUrl } = useContext(BaseContext)
    const [paymentConfig, setPaymentConfig] = useState({});
    const setupRef = createRef();

    useEffect(() => {
        setPaymentConfig(props.paymentConfig);
    }, [props.paymentConfig])

    const renderPaymentProviderNotSetup = () => {
        return (
            <BaseModal {...props}>
                <BaseModal.Header>
                    <BaseModal.Title>Payment Provider not setup</BaseModal.Title>
                </BaseModal.Header>
                <BaseModal.Body>
                    <p>No payment provider has been setup for your company. Please connect your existing payment provider to manage customer's payment methods.</p>
                    <Link to={getCompanySpecificUrl("/settings/payment_providers")}><Button variant="primary">Connect</Button></Link>
                </BaseModal.Body>
            </BaseModal>
        )
    }

    const renderPaymentProviderNotSupported = (paymentConfig) => {
        return (
            <BaseModal {...props}>
                <BaseModal.Header>
                    <BaseModal.Title>{t('common.edit')} Provider not supported</BaseModal.Title>
                </BaseModal.Header>
                <BaseModal.Body>
                    The provider { paymentConfig.provider } is not supported. Please contact your company admin.
                </BaseModal.Body>
                <BaseModal.Footer>
                </BaseModal.Footer>
            </BaseModal>
        )
    }

    const onSubmit = async (data) => {
        if (setupRef.current) {
            await setupRef.current.onSubmit(data);
        }
    }
    const domain = window.location.origin;
    const relativeUrl = getCompanySpecificUrl(`/customer/${props.customer.id}`)
    const redirectUrl = props.redirectUrl || `${domain}${relativeUrl}`

    if (!props.show) {
        return null;
    }

    if (_.isNil(paymentConfig) || _.isNil(paymentConfig.provider)) {
        return renderPaymentProviderNotSetup();
    } else {
        const isPaymentProviderSupported = paymentConfig.provider === "STRIPE"
        if (!isPaymentProviderSupported) {
            return renderPaymentProviderNotSupported(paymentConfig)
        } else {
            return (
                <BaseModal {...props}>
                    <BaseModal.Header>
                        <BaseModal.Title>{t('payment_methods.add.title')}</BaseModal.Title>
                    </BaseModal.Header>
                    <BaseForm onSubmit={onSubmit}>
                        <BaseModal.Body>
                            <div className="body1">
                                Add payment method to { props.customer.name || props.customer.email }
                            </div>
                            <div className="payment_input">
                            {
                                paymentConfig.provider === "STRIPE" &&
                                    <StripeSetupPaymentMethod ref={setupRef} {...props} redirectUrl={redirectUrl} />
                            }
                            </div>
                        </BaseModal.Body>
                        <BaseModal.Footer>
                            <Row>
                                <Col md="12" className="text-end">
                                    <SubmitButton variant="primary">{t('payment_methods.add.action')}</SubmitButton>
                                </Col>
                            </Row>
                        </BaseModal.Footer>
                    </BaseForm>
                </BaseModal>
            );
        }
    }
}

export default SetupPaymentMethodModal;

import '../App.scss';
import React, { useEffect, useState } from 'react';
import { serverFetch, getUrl } from "../helpers/server";
import { UserContext } from "../helpers/common";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
const _ = require('lodash');

function OptionalAuthorization(props) {
    const isLoggedIn = localStorage.getItem("auth_token") !== null;
    const navigate = useNavigate();
    const [loadingUserInfo, setLoadingUserInfo] = useState(true);
    const [userInfo, setUserInfo] = useState({});
    const allReady = !loadingUserInfo;

    useEffect(() => {
        if (!allReady) {
            return;
        }
    }, [allReady, navigate]);

    useEffect(() => {
        const handleError = (error) => {
            setLoadingUserInfo(false);
        }

        const authToken = localStorage.getItem("auth_token");
        if (!authToken) {
            setLoadingUserInfo(false);
        } else {
            serverFetch(getUrl(`/users/current`), null, handleError).then((res) => {
                if (res) {
                    setUserInfo(res);
                }
                setLoadingUserInfo(false);
            })
        }
    }, []);

    return (
        <UserContext.Provider value={{
            userInfo,
            isSuperUser: !_.isNil(userInfo) && !_.isEmpty(userInfo) && userInfo.is_super_user,
            isLoggedIn: !_.isEmpty(userInfo),
        }}>
        {
            allReady && <Outlet />
        }
        </UserContext.Provider>
    );
}

export default OptionalAuthorization;

import '../../App.scss';
import React, {useState, useEffect, useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import { getUrl, serverPost, serverFetch } from '../../helpers/server';
import BaseContainerWithoutNav from '../../components/BaseContainerWithoutNav';
import AddOrEditCompanyModal from '../../components/modals/AddOrEditCompanyModal';
import Loader from '../../components/Loader';
import { useTranslation } from 'react-i18next';
import {getRoleDescription, UserContext} from "../../helpers/common";
const _ = require('lodash');

function UserHome() {
    const { t } = useTranslation('common');
    const { userInfo, isSuperUser } = useContext(UserContext);
    const navigate = useNavigate();
    const [showAddCompanyModal, setShowAddCompanyModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [companies, setCompanies] = useState([]);

    const fetchData = (skipCache=false) => {
        setLoading(true);
        serverFetch(getUrl('/companies'), { skipCache }).then((res) => {
            if (res) {
                const acceptedCompanies = _.filter(res, (r) => !r.pending);
                if (acceptedCompanies.length === 1 && res.length === 1) {
                    navigate(`/c/${acceptedCompanies[0].company.slug}/`);
                } else {
                    setCompanies(res);
                }
            }
            setLoading(false)
        });
    };

    useEffect(() => {
        fetchData();
    }, []);

    const onRegisterCompany = () => {
        setShowAddCompanyModal(true);
    }

    const onModalClose = (didUpdate) => {
        setShowAddCompanyModal(false);
        if (didUpdate) {
            fetchData(true);
        }
    }

    const acceptInvite = (event, company) => {
        event.preventDefault();
        event.stopPropagation();
        serverPost(getUrl(`/companies/${company.id}/invite/accept`), {}).then((res) => {
            if (res) {
                fetchData(true);
            }
        });
    }

    const navigateToCompany = (event, cuser) => {
        event.stopPropagation();
        event.preventDefault();
        if (cuser.pending) {
            return;
        }
        navigate(`/c/${cuser.company.slug}/`);
    }

    return (
        <BaseContainerWithoutNav>
            <Loader loading={loading}>
                <div className="flex justify-center">
                    <div className="w-screen max-w-2xl">
                        <div className="mb-4 px-3 py-2 sm:px-6">
                            <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
                                <div className="ml-4 mt-2">
                                    <h3 className="text-2xl font-semibold leading-6 text-gray-900">Companies</h3>
                                </div>
                                <div className="ml-4 mt-2 flex-shrink-0">
                                    {
                                        isSuperUser &&
                                            <button type="button"
                                                    onClick={onRegisterCompany}
                                                    className="relative inline-flex items-center rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                                                Register new company
                                            </button>
                                    }
                                </div>
                            </div>
                        </div>
                        <ul role="list" className="w-full divide-y divide-gray-200 bg-white rounded-md shadow-sm ring-1 ring-black ring-opacity-5">
                            {
                                _.map(companies, (row, i) =>
                                    <li key={i} className={"flex items-center justify-between gap-x-6 px-3 py-2 border-b-1 border-gray-500 hover:bg-primary-100 cursor-pointer"} onClick={(event) => navigateToCompany(event, row)}>
                                        <div className="min-w-0">
                                            <div className="flex items-start gap-x-3">
                                                <p className="text-sm font-semibold leading-6 text-gray-900">{ row.company.name }</p>
                                                {
                                                    row.pending ?
                                                        <p className="rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset text-orange-500 bg-orange-50 ring-orange-600/20">Pending</p>
                                                    : <p className="rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset text-green-700 bg-green-50 ring-green-600/20">Active</p>
                                                }
                                            </div>
                                            <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                                                <p className="whitespace-nowrap">{ getRoleDescription(_.find(userInfo.companies, (c) => String(c.id) === String(row.id))) }</p>
                                                <p className="truncate"></p>
                                            </div>
                                        </div>
                                        <div className="flex flex-none items-center gap-x-4">
                                            {
                                                row.pending ?
                                                    <button onClick={(event) => acceptInvite(event, row.company)}
                                                       className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block">
                                                        Accept Invite
                                                        <span className="sr-only">Accept Invitation</span>
                                                    </button>
                                                : <svg className="h-5 w-5 flex-none text-gray-400" viewBox="0 0 20 20"
                                                       fill="currentColor" aria-hidden="true">
                                                        <path fillRule="evenodd"
                                                              d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                                                              clipRule="evenodd"/>
                                                    </svg>
                                            }
                                        </div>
                                    </li>
                                )
                            }
                        </ul>
                    </div>
                </div>
            </Loader>
            <AddOrEditCompanyModal show={showAddCompanyModal} onClose={onModalClose} />
        </BaseContainerWithoutNav>
    );
}

export default UserHome;
import $ from "jquery";
import moment from 'moment';
import classnames from 'classnames';
import Label from '../components/Label';
import React, { createContext } from 'react';
import {Link} from "react-router-dom";
const _ = require("lodash");

export const UserContext = createContext();
export const BaseContext = createContext();
export const AppContext = createContext();
export const CustomerContext = createContext();
export const IntegrationContext = createContext();
export const PaymentLinkContext = createContext();
export const ContractContext = createContext();

export function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export function escapeSelector(selector) {
    return _.replace(selector, ".", "\\.");
}

export function currencyFormat(num, currency=null, includeSign=true, numDecimals=2) {
    if (_.isNil(num)) {
        return "";
    }
    let numValue = parseFloat(num.toFixed(numDecimals))
    return Intl.NumberFormat('en-US', {style: 'currency', currency: currency || "USD"}).format(numValue);
}

export function currencyFormatFromPrice(price, negate=false) {
    if (_.isNil(price.value_in_cents)) {
        return "";
    }
    let value = price.value_in_cents/100;
    let valueDescription = Intl.NumberFormat('en-US', {style: 'currency', currency: price.currency || "USD"}).format(value);
    if (negate) {
        valueDescription = `(${valueDescription})`;
    }
    return `${valueDescription}`;
}

export function getMonthName(t, month) {
    const monthNameMap = [
        "",
        t('month.january'),
        t('month.february'),
        t('month.march'),
        t('month.april'),
        t('month.may'),
        t('month.june'),
        t('month.july'),
        t('month.august'),
        t('month.september'),
        t('month.october'),
        t('month.november'),
        t('month.december'),
    ];
    return monthNameMap[month];
}

export function getShortMonthName(t, month) {
    const monthNameMap = [
        "",
        t('month.january_short'),
        t('month.february_short'),
        t('month.march_short'),
        t('month.april_short'),
        t('month.may_short'),
        t('month.june_short'),
        t('month.july_short'),
        t('month.august_short'),
        t('month.september_short'),
        t('month.october_short'),
        t('month.november_short'),
        t('month.december_short'),
    ];
    return monthNameMap[month];
}

export function timeOptions(interval=15) {
    const options = [];
    for (let i = 0; i < 24; i++) {
        const hour = (((i - 1) % 12) + 1);
        const hourString = String(i).padStart(2, '0');;
        for (let j = 0; j < 60/interval; j++) {
            const minute = String(interval * j).padStart(2, '0');;
            const idTime = hourString + ":" + minute + ":00";
            const labelTime = (hour > 0 ? hour : "12") + ":" + minute + (i >= 12 ? " PM":" AM");
            options.push({'value': idTime, 'label': labelTime});
        }
    }
    return options;
}

export function getAggregatorOptions(isUsageBased=false) {
    const options = [
        { value: "COUNT", label: "Count", isUsageBased: true },
        { value: "SUM", label: "Sum", isUsageBased: true },
        { value: "MAX", label: "Max", isUsageBased: true },
        { value: "AVG", label: "Average", isUsageBased: true },
        { value: "UNIQUE", label: "Unique Count", isUsageBased: true },
        { value: "OBJ_COUNT", label: "Count", isUsageBased: false },
        { value: "OBJ_SUM", label: "Sum", isUsageBased: false },
        { value: "OBJ_MAX", label: "Max", isUsageBased: false },
        { value: "OBJ_UNIQUE", label: "Unique Count", isUsageBased: false },
        { value: "CUSTOMER_FIXED", label: "Customer Fixed", isUsageBased: false },
        { value: "CUSTOMER_LAST_EVER", label: "Last Reported Value In History", isUsageBased: true },
        { value: "CUSTOMER_LAST_PERIOD", label: "Last Reported Value In Period", isUsageBased: true },
    ]
    return _.filter(options, (i) => i.isUsageBased === isUsageBased);
}

export function getLabelForAggregator(aggregator) {
    const allOptions = _.concat(getAggregatorOptions(true), getAggregatorOptions(false));
    const selectedOption = _.find(allOptions, (o) => o.value === aggregator);
    return selectedOption.label;
}

export function getDescriptionForMetric(metric, item) {
    const rule = metric.metering_rule;
    let itemName = "item";
    if (item) {
        itemName = item.name;
    }

    let filteringDescription = "";
    const filteringRule = rule.rule;
    if (filteringRule === null) {
        filteringDescription = `all ${itemName}(s)`
    } else {
        filteringDescription = `${itemName}(s) with restrictions`
    }

    return (
        <div>{getLabelForAggregator(rule.aggregator)}(<span className="body2">{rule.property || "*"}</span>) on {filteringDescription}</div>
    );
}

export function formatBillingFrequency(frequency) {
    const m = {
        "MONTHLY": "month",
        "YEARLY": "year",
        "ANNUALLY": "year",
        "QUARTERLY": "quarter",
        "HOUR": "hour",
        "DAY": "day",
        "WEEK": "week",
        "MONTH": "month",
        "QUARTER": "quarter",
        "YEAR": "year",
    };
    if (_.has(m, frequency)) {
        return m[frequency];
    } else {
        return frequency;
    }
}

export function getItemTypeOptions() {
    return [
        { value: "CUSTOM_USAGE", label: "Usage-based" },
        { value: "CUSTOM_OBJ", label: "Entity-based" }
    ]
}

export function getItemTypeDescription(type) {
    const options = getItemTypeOptions()
    const item = _.find(options, (op) => op.value === type);
    return item && item.label;
}

export function getCurrencyOptions() {
    return [
        { value: "USD", label: "USD" },
        { value: "CAD", label: "CAD" },
        { value: "EUR", label: "EUR" },
        { value: "AUD", label: "AUD" },
        { value: "SGD", label: "SGD" },
        { value: "INR", label: "INR" }
    ]
}

export function getCountryOptions() {
    return [
        { value: "US", label: "United States" },
        { value: "CA", label: "Canada" },
    ]
}

export function getLabelForCountry(code) {
    const options = getCountryOptions();
    const option = _.find(options, (op) => op.value === code)
    return option ? option.label : null;
}

export function hasAccessForCompany(companyId, feature, userInfo, mode="write") {
    if (_.isEmpty(userInfo)) {
        return false;
    }
    if (userInfo.is_super_user) {
        return true;
    }
    const companyUser = _.find(userInfo.companies, (c) => String(c.company_id) === String(companyId));
    if (!companyUser) {
        return false;
    }
    const accessInfo = companyUser.access[feature];
    if (mode === "write") {
        return accessInfo === "write";
    } else {
        return _.includes(["read", "write"], accessInfo);
    }
}

export function isPartialOrFullAdmin(userInfo) {
    return !_.isNil(userInfo.accessLevel) && userInfo.accessLevel !== undefined && userInfo.accessLevel > 0;
}

export function isNotAdmin(userInfo) {
    return !isPartialOrFullAdmin(userInfo);
}

export function validateURL(url, isRequired) {
    if (isRequired) {
        if (!url || _.isEmpty(url.trim())) {
            return false;
        }
    } else {
        if (!url || _.isEmpty(url.trim())) {
            return true;
        }
    }

    try {
        const urlObject = new URL(url);
        if (urlObject.protocol === "https:" || urlObject.protocol === "http:") {
            return true;
        }
        return false;
    } catch(e) {
        return false;
    }
}

export function validateEmail(email, isRequired) {
    if (isRequired) {
        if (!email || _.isEmpty(email.trim())) {
            return false;
        }
    } else {
        if (!email || _.isEmpty(email.trim())) {
            return true;
        }
    }
    const result = String(email.trim())
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    return result !== null;
}

export function dateRangeClassCheck(date, startDate, endDate) {
    var sdate = startDate;
    var edate = endDate;
    var fdate = moment(date).format("YYYY-MM-DD");
    var className = ""
    if (sdate === fdate) {
        className = "ui-datepicker-start-date";
    } else if (edate === fdate) {
        className = "ui-datepicker-end-date";
    } else if (moment(edate).isAfter(sdate) && moment(sdate).isBefore(fdate, 'day') && moment(edate).isAfter(fdate, 'day')) {
        className = "ui-datepicker-mid-date";
    }
    return [true, className, null];
}

export function downloadBlob(blob, filename) {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    // the filename you want
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
}

export function getRandomString(length) {
    var text = "";
    var charset = "abcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < length; i++)
        text += charset.charAt(Math.floor(Math.random() * charset.length));

    return text;
}

export function conciseTimeDisplay(mtime, withMeridiem = true) {
    if (mtime.minutes() !== 0) {
        return mtime.format(`h:mm${withMeridiem ? "a" : ""}`);
    } else {
        return mtime.format(`h${withMeridiem ? "a" : ""}`);
    }
}

export function getNameForProviderType(type) {
    const m = {
        "STRIPE": "Stripe",
        "HUBSPOT": "Hubspot",
        "BRAINTREE": "Braintree",
        "BAMBORA": "Bambora",
        "SQUARE": "Square",
        "ADYEN": "Adyen",
        "MONERIS": "Moneris",
        "CASH": "Cash",
        "CHEQUE": "Check",
        "WIRE": "Wire",
        "CREDIT_CARD_OFFLINE": "Credit Card Offline",
        "BILL_COM": "Bill.com"
    }
    if (_.has(m, type)) {
        return m[type];
    } else {
        return type;
    }
}

export function renderChildren(props) {
    if (typeof props.children === 'function') {
        return props.children();
    } else {
        return props.children;
    }
}

export function renderComponents(components) {
    if (typeof components === 'function') {
        return components();
    } else {
        return components;
    }
}

export function renderDescriptionForItemPricing(pricing, includeName=false, item=null) {
    let pricingDescriptions = [];
    let baseDescription = "";

    if (item && item.type === "ONETIME_ITEM") {
        pricingDescriptions = [`${currencyFormatFromPrice({ value_in_cents: pricing.fixed_price.price_per_unit, currency: pricing.base_price.currency })}`];
    } else {
        let itemName = "units";
        if (item) {
            if (item.type === "LICENSE_ITEM") {
                itemName = "License"
            } else if (item.type === "ONETIME_ITEM") {
                itemName = ""
            } else {
                itemName = item.name;
            }
        }

        let frequencyDescription = "";
        if (pricing.frequency !== "") {
            frequencyDescription = `/${formatBillingFrequency(pricing.frequency)}`;
        }

        if (pricing.type === "FIXED") {
            pricingDescriptions = [`${currencyFormatFromPrice({ value_in_cents: pricing.fixed_price.price_per_unit, currency: pricing.base_price.currency })}/${itemName}${frequencyDescription}`]
        } else if (pricing.type === "STEP") {
            pricingDescriptions = [`${currencyFormatFromPrice({ value_in_cents: pricing.step_price.price_per_step, currency: pricing.base_price.currency })}/${pricing.step_price.step_size} ${itemName}${frequencyDescription}`]
        } else if (pricing.type === "GRADIENT") {
            pricingDescriptions = [`Varied Pricing/${itemName}${frequencyDescription}`]
            _.each(pricing.gradient_price, (gp) => {
                let gpDescription = `${gp.start} - ${(gp.end === 2147483648 || gp.end === 9223372036854776000) ? "∞": gp.end} →`;
                if (gp.flat_price > 0) {
                    gpDescription = `${gpDescription} ${currencyFormatFromPrice({ value_in_cents: gp.flat_price, currency: pricing.base_price.currency })}${gp.price_per_unit > 0 ? " +":""}`
                }
                if (gp.price_per_unit > 0) {
                    gpDescription = `${gpDescription} ${currencyFormatFromPrice({ value_in_cents: gp.price_per_unit, currency: pricing.base_price.currency })}/unit`
                }
                pricingDescriptions.push(gpDescription);
            })
        } else if (pricing.type === "CUSTOM") {
            pricingDescriptions = [`Custom/period`]
        }

        let baseUnitsDescription = "";
        if (pricing.base_units) {
            baseUnitsDescription = `, ${pricing.base_units} ${itemName}(s) included`;
        }

        if (pricing.base_price.value_in_cents > 0 || pricing.base_units > 0) {
            baseDescription = `${_.isEmpty(pricingDescriptions) ? "": "+"}${ currencyFormatFromPrice(pricing.base_price) } (base price${baseUnitsDescription})`
        }
    }

    return (
        <div className="item_pricing d-flex flex-column">
            {
                includeName &&
                    <div className="flex-grow-0 button-text gray4">{ pricing.name }</div>
            }
            {
                _.map(pricingDescriptions, (pd, i) =>
                    <span key={i} className={classnames(i !== 0 ? "text-gray-500": "", "flex-grow-1 d-flex flex-column justify-content-center", i !== 0 && "caption")}>{ pd }</span>
                )
            }
            {
                baseDescription &&
                    <div className="caption flex-grow-1 text-gray-500">{ baseDescription }</div>
            }
        </div>
    )
}

export function renderAddress(address) {
    if (_.isNil(address) || _.isEmpty(address)) {
        return <span/>
    }

    if (_.isEmpty(address.address_line_1) && _.isEmpty(address.address_line_2)
        && _.isEmpty(address.city) && _.isEmpty(address.state) && _.isEmpty(address.zip)) {
        return <span/>
    }

    const line1 = address.address_line_1;
    const line2 = address.address_line_2;
    const line3 = _.filter([address.city, address.state, address.zip], (a) => a !== null && a !== "").join(", ");
    const line4 = getLabelForCountry(address.country);

    return (
        <div>
            {
                line1 &&
                    <div>{ line1 }</div>
            }
            {
                line2 &&
                    <div>{ line2 }</div>
            }
            <span>{ line3 }</span><br/>
            <span>{ line4 }</span><br/>
        </div>
    );
}

const getBrandName = (brand) => {
    const cardBrandNameMap = {
        visa: "Visa",
        amex: "American Express",
        mastercard: "Mastercard"
    }
    if (_.includes(_.keys(cardBrandNameMap), brand)) {
        return cardBrandNameMap[brand];
    } else {
        return brand;
    }
}

export function renderPaymentMethod(t, method) {
    return (
        <div className="d-flex flex-row align-items-center gap-3">
            <div className="profile-icon">

            </div>
            <div className="d-flex flex-column">
                <p className="title">{ getBrandName(method.brand) } ....{ method.last_4 }</p>
                <p className="body1 gray3">Expires on { getShortMonthName(t, method.exp_month) } { method.exp_year }</p>
            </div>
        </div>
    )
}

export function getPropsForMetric(metricKey) {
    const metricLabelMap = {
        "MRR": { title: "Monthly Recurring Revenue", yLabel: "MRR", type: "currency", summaryAggregator: "last",
            description: "This is calculated by adding up the monthly value of all of your active subscription customers, minus any non-expiring discounts."},
        "ARR": { title: "Annual Recurring Revenue", yLabel: "ARR", type: "currency", summaryAggregator: "last",
            description: "This is the current value of your business projected out over the next year. This is calculated by multiplying MRR by 12." },
        "NET_REVENUE": { title: "Net Revenue", yLabel: "Net Revenue", type: "currency", summaryAggregator: "sum",
            description: "This is your total revenue minus any fees. This is calculated by taking total revenue (recurring and non-recurring) minus any fees." },
        "TOTAL_REVENUE": { title: "Total Revenue", yLabel: "Total Revenue", type: "currency", summaryAggregator: "sum",
            description: "This is what flows into the business minus any discounts. This is calculated by taking gross volume (recurring and non-recurring) minus any refunds." },
        "FEES": { title: "Fees", yLabel: "Fees", type: "currency", summaryAggregator: "sum",
            description: "This includes the charge fees from your payment provider." },
        "MRR_GROWTH": { title: "MRR Growth", yLabel: "MRR Growth", type: "percent", summaryAggregator: "last",
            description: "This is calculated by taking ((Today's MRR / 30 days ago MRR) - 1) x 100."},
        "OTHER_REVENUE": { title: "Other Revenue", yLabel: "Other Revenue", type: "currency", summaryAggregator: "sum",
            description: "This is the total one-time non-recurring charges."},
        "ACTIVE_SUBS": { title: "Active Subscriptions", yLabel: "Subscriptions", type: "integer", summaryAggregator: "last",
            description: "This is the current number of active paying subscriptions.", showGrouping: true },
        "NEW_SUBS": { title: "New Subscriptions", yLabel: "Subscriptions", type: "integer", chartType: "bar", summaryAggregator: "sum",
            description: "This is the number of new paying subscriptions.", showGrouping: true},
        "UPGRADES": { title: "Upgrades", yLabel: "Upgrades", type: "integer", summaryAggregator: "sum",
            description: "This is the number of customers going from one paid plan to another more expensive paid plan."},
        "DOWNGRADES": { title: "Downgrades", yLabel: "Downgrades", type: "integer", summaryAggregator: "sum",
            description: "This is the number of customers going from one paid plan to another lower paid plan."},
        "FAILED_CHARGES": { title: "Failed Charges", yLabel: "Failed Charges", type: "integer", summaryAggregator: "sum",
            description: "This is the number of unsuccessful payment attempts by your payment provider." },
        "REFUNDS": { title: "Refunds", yLabel: "Refunds", type: "currency", summaryAggregator: "sum",
            description: "This is the total amount that is returned back to customers." },
        "DISCOUNTS_REDEEMED": { title: "Discounts Redeemed", yLabel: "Discounts", type: "currency", summaryAggregator: "sum",
            description: "This is the total amount given out as discounts to customers." },
        "ACTIVE_CUSTOMERS": { title: "Active Customers", yLabel: "Customers", type: "integer", summaryAggregator: "last",
            description:"This is the current number of customers with active paying subscriptions.", showGrouping: true },
        "NEW_CUSTOMERS": { title: "New Customers", yLabel: "Customers", type: "integer", chartType: "bar", summaryAggregator: "sum",
            description: "This is the number of new customers with active paying subscriptions." },
        "ARPU": { title: "Average Revenue Per User", yLabel: "ARPU", type: "currency", summaryAggregator: "last",
            description: "This is the average MRR per active customer. This is calculated by taking the MRR and dividing it by the number of active customers." },
        "ARPL": { title: "Average Revenue Per License", yLabel: "ARPL", type: "currency", summaryAggregator: "last",
            description: "This is the average MRR per license. This is calculated by taking the MRR and dividing it by the number of active licenses." },
        "HISTORICAL_ARPU": { title: "Total Revenue Per Customer", yLabel: "ARPU", type: "currency", summaryAggregator: "last",
            description: "This is the total historical revenue per customer (active now or historically). This is calculated by taking the total historical revenue and dividing it by the number of customers (active now or historically)." },
        "LTV": { title: "Lifetime Value", yLabel: "LTV", type: "currency", summaryAggregator: "last",
            description: "This is the expected value of a customer until they churn. This is calculated by taking the average MRR per active customer and dividing it by the user churn rate." },
        "ACTIVE_TRIALS": { title: "Active Trials", yLabel: "Subscriptions", type: "integer", summaryAggregator: "last",
            description:"This is the current number of active trial subscriptions.", showGrouping: true },
        "NEW_TRIALS": { title: "New Trials", yLabel: "Subscriptions", type: "integer", chartType: "bar", summaryAggregator: "sum",
            description: "This is the number of new trial subscriptions.", showGrouping: true },
        "CONVERTED_TRIALS": { title: "Converted Trials", yLabel: "Subscriptions", type: "integer", summaryAggregator: "sum",
            description: "This is the number of trial subscriptions that have successfully converted to an active subscription.", showGrouping: true },
        "CHURNED_CUSTOMERS": { title: "Churned Customers", yLabel: "Customers", type: "integer", summaryAggregator: "sum",
            description: "This is the number of customers that stopped their subscription." },
        "USER_CHURN": { title: "User Churn", yLabel: "Churn", type: "percent", summaryAggregator: "last",
            description: "This is the percentage of customers who left in the previous 30-day period relative to your total customer count 30 days ago." },
        "REVENUE_CHURN": { title: "Revenue Churn", yLabel: "Churn", type: "percent", summaryAggregator: "last",
            description: "This is the percentage of MRR that has been lost in the last 30 days relative to your total MRR 30 days ago." },
        "NET_REVENUE_CHURN": { title: "Net Revenue Churn", yLabel: "Churn", type: "percent", summaryAggregator: "sum",
            description: "This is total revenue churn minus expansion. This is calculated by taking (Churned MRR - expansion MRR) / starting MRR 30 days ago x 100." },
        "CHURNED_SUBS": { title: "Churned Subscriptions", yLabel: "Churned Subscriptions", type: "integer", summaryAggregator: "sum",
            description: "This is the number of canceled subscriptions.", showGrouping: true },
        "PAYMENTS": { title: "New Payments", yLabel: "Payments", type: "integer", chartType: "bar", summaryAggregator: "sum",
            description: "This is the number of payments being charged by your payment provider." },
        "NEW_INVOICES": { title: "New Invoices", yLabel: "Invoices", type: "integer", chartType: "bar", summaryAggregator: "sum",
            description: "This is the number of new invoices generated for customers." },
        "REACTIVATIONS": {title: "Reactivations", yLabel: "Reactivations", type: "integer", chartType: "bar", summaryAggregator: "sum",
            description: "This is number of churned customers that reactivated into a paying subscription." },
        "REVENUE_CONNECTED_ACCOUNTS": { title: "Total Connected Accounts Payments", yLabel: "Revenue", type: "currency", summaryAggregator: "sum",
            description: "This is total payments processed for your connected accounts." },
        "APPLICATION_FEES_CONNECTED_ACCOUNTS": { title: "Application Fees", yLabel: "Fees", type: "currency", summaryAggregator: "sum",
            description: "This is the application fees collected from connected account payments." },
        "LICENSES": { title: "Licenses", yLabel: "Licenses", type: "integer", summaryAggregator: "last",
            description: "This is the total number of licenses for active subscriptions", showGrouping: true },
    }

    let result = {
        yLabel: metricKey,
        xAxisLabelFormat: "MMM D",
        chartType: "line"
    }
    result = _.merge(result, metricLabelMap[metricKey])
    return result;
}

export function getLabelForSubscriptionLength(length) {
    if (length === "MONTH") {
        return "Month";
    } else if (length === "YEAR") {
        return "Year";
    } else if (length === "DAY") {
        return "Day";
    } else if (length === "HOUR") {
        return "Hour";
    } else if (length === "QUARTER") {
        return "Quarter";
    } else {
        return length;
    }
}

export function getLabelForBillingFrequency(frequency) {
    if (frequency === "MONTH") {
        return "monthly";
    } else if (frequency === "YEAR") {
        return "yearly";
    } else if (frequency === "DAY") {
        return "daily";
    } else if (frequency === "QUARTER") {
        return "quarterly";
    } else {
        return frequency;
    }
}

export function getLabelForTaxCategory(category) {
    if (category) {
        return `${category.name} (${category.product_tax_code})`;
    } else {
        return null;
    }
}

export function renderContractStatusLabel(contract) {
    if (contract.status === "PENDING_PAYMENT") {
        return (
            <Label.Warning>Pending Payment</Label.Warning>
        )
    } else if (contract.status === "EXECUTED") {
        return (
            <Label.Success>Executed</Label.Success>
        )
    } else if (contract.status === "DECLINED") {
        return (
            <Label.Danger>Declined</Label.Danger>
        )
    } else if (contract.status === "DRAFT") {
        return (
            <Label.Info>Draft</Label.Info>
        )
    } else if (contract.isExpired) {
        return (
            <Label.Danger>Expired</Label.Danger>
        )
    } else if (contract.status === "PENDING_SIGNATURES") {
        return (
            <Label.Danger>Pending Signatures</Label.Danger>
        )
    }
}

export function renderProductMetric(pmp, configItem) {
    if (pmp.metric.type === "ONETIME_METRIC") {
        if (configItem) {
            return (
                <span>{ configItem.num_licenses }</span>
            );
        } else {
            return (<span>0</span>);
        }
    } else if (pmp.metric.type === "LICENSE_METRIC") {
        if (configItem) {
            return (
                <span>{ configItem.num_licenses }</span>
            );
        } else {
            return (<span>0</span>);
        }
    } else {
        return (
            <>
                <span>{ pmp.metric.name }</span>
                <span className="caption">{ getDescriptionForMetric(pmp.metric) }</span>
                {
                    configItem && configItem.minimum_units > 0 &&
                        <span>Minimum: { configItem && configItem.minimum_units }</span>
                }
            </>
        )
    }
}

export function getLabelForAction(action) {
    const labelMap = {
        "contract.created": "Contract Created",
        "contract.updated": "Contract Updated",
        "contract.deleted": "Contract Deleted",
        "contract.signed": "Contract Signed",
        "contract.executed": "Contract Executed",

        "invoice.created": "Invoice Created",
        "invoice.updated": "Invoice Updated",
        "invoice.cancelled": "Invoice Cancelled",
        "invoice.payment.success": "Invoice Payment Succeeded",
        "invoice.payment.failed": "Invoice Payment Failed",
        "invoice.payment.reminder.sent": "Invoice Reminder Sent",
        "invoice.emailed": "Invoice Email Sent",

        "subscription.created": "Subscription Created",
        "subscription.updated": "Subscription Updated",
        "subscription.renewed": "Subscription Renewed",
        "subscription.cancelled": "Subscription Cancelled",
        "subscription.revoked": "Subscription Revoked",
        "subscription.extended": "Subscription Extended",
        "subscription.changed": "Subscription Plan Changed",

        "customer.created": "Customer Created",
        "customer.updated": "Customer Updated",
        "customer.deleted": "Customer Deleted",

        "pricing.created": "Pricing Created",
        "pricing.updated": "Pricing Updated",
        "pricing.deleted": "Pricing Deleted",

        "credit.created": "Credit Created",
        "credit.updated": "Credit Updated",
        "credit.deleted": "Credit Deleted",

        "discount.created": "Discount Created",
        "discount.updated": "Discount Updated",
        "discount.revoked": "Discount Revoked",
    }
    if (_.has(labelMap, action)) {
        return labelMap[action];
    } else {
        return action;
    }
}

export function getRoleDescription(row) {
    if (!row) {
        return <span></span>
    }
    if (row.role === "default_admin") {
        return <span>Full Admin</span>
    } else if (row.role === "default_sales_rw") {
        return <span>Sales Admin</span>
    } else {
        return <span></span>
    }
}

export function getAddressFromGooglePlace(place) {
    const addressComponentMap = {};
    _.each(place.address_components, (c) => {
        _.each(c.types, (t) => {
            if (t === "country" || t === "administrative_area_level_1") {
                addressComponentMap[t] = c.short_name;
            } else {
                addressComponentMap[t] = c.long_name;
            }
        })
    });
    const address = {
        formatted_address: place.formatted_address,
        place_id: place.place_id,
        country: addressComponentMap.country,
        zip: addressComponentMap.postal_code,
        state: addressComponentMap.administrative_area_level_1,
        city: addressComponentMap.locality || addressComponentMap.administrative_area_level_2,
        address_line_1: `${addressComponentMap.street_number || ""} ${addressComponentMap.route || ""}`.trim(),
        address_line_2: addressComponentMap.subpremise,
    }
    return address;
}

export function getFormattedAddressForAddress(address) {
    if (!address) {
        return null;
    } else if (!_.isEmpty(address.formatted)) {
        return address.formatted;
    } else {
        return _.filter([
            address.address_line_1,
            address.address_line_2,
            address.city,
            address.state,
            address.zip,
            address.country
        ], (a) => a !== null && a !== "").join(", ");
    }
}

export function getLabelForCouponType(type) {
    if (type === "DISCOUNT_PERCENT_COUPON") {
        return "Discount Percent";
    } else if (type === "DISCOUNT_AMOUNT_COUPON") {
        return "Discount Amount";
    } else if (type === "CREDIT_AMOUNT_COUPON") {
        return "Credit Amount";
    } else if (type === "CREDIT_UNITS_COUPON") {
        return "Credit Units";
    }
    return type;
}

export function getDescriptionForCoupon(coupon, products, productPricings, billableItems) {
    let couponDescription = coupon.name;
    if (coupon.type === "DISCOUNT_AMOUNT_COUPON") {
        couponDescription = `${ currencyFormatFromPrice(coupon.amount) }`;
    } else if (coupon.type === "DISCOUNT_PERCENT_COUPON") {
        const capDescription = coupon.cap.value_in_cents > 0 ? ` (Max ${currencyFormatFromPrice(coupon.cap)})` : "";
        couponDescription = `${ coupon.percent * 100 }%${capDescription}`;
    } else if (coupon.type === "CREDIT_AMOUNT_COUPON") {
        couponDescription = `${ currencyFormatFromPrice(coupon.amount) }`;
    } else if (coupon.type === "CREDIT_UNITS_COUPON") {
        const item = _.find(billableItems, (i) => i.id === coupon.item_id);
        const itemDescription = _.isNil(item) ? "" : ` (${item.name})`;
        couponDescription = `${coupon.units} Units${itemDescription}`;
    }
    let restrictionDescription = null;
    if (!_.isNil(coupon.product_id)) {
        const product = _.find(products, (p) => p.id === coupon.product_id);
        if (product) {
            restrictionDescription = `Applies to product: ${product.name}`;
        }
    }
    if (!_.isNil(coupon.product_pricing_id)) {
        const pricing = _.find(productPricings, (p) => p.id === coupon.product_pricing_id);
        if (pricing) {
            restrictionDescription = `Applies to pricing: ${pricing.name}`;
        }
    }
    return (<div>
        <span>{ couponDescription }</span><br/>
        {
            !_.isNil(restrictionDescription) &&
                <span className="caption">{ restrictionDescription }</span>
        }
    </div>)
}

export function getDescriptionForDiscount(discount, getCompanySpecificUrl, hideAdditionalDetails=false) {
    let label = discount.type;
    let additionalDescription = null;
    if (discount.item_pricing_id) {
        if (discount.item_pricing) {
            additionalDescription = <span className="italic">{discount.item_pricing.name || discount.item_pricing.description}</span>
        }
    } else if (discount.product_pricing_id) {
        if (discount.product_pricing) {
            additionalDescription = <span className="italic">{discount.product_pricing.name || discount.product_pricing.description}</span>
        }
    } else if (discount.product_id) {
        if (discount.product) {
            additionalDescription = <span className="italic">{discount.product.name}</span>
        }
    }
    let restrictionDescription = null;
    if (discount.subscription_id && additionalDescription) {
        restrictionDescription = <span>for {additionalDescription} in <Link to={getCompanySpecificUrl(`/subscription/${discount.subscription_id}`)}>{discount.subscription_id}</Link></span>
    } else if (discount.subscription_id) {
        restrictionDescription = <span>for <Link to={getCompanySpecificUrl(`/subscription/${discount.subscription_id}`)}>{discount.subscription_id}</Link></span>
    } else if (additionalDescription) {
        restrictionDescription = <span>for {additionalDescription}<Link to={getCompanySpecificUrl(`/subscription/${discount.subscription_id}`)}>{discount.subscription_id}</Link></span>
    }
    if (hideAdditionalDetails) {
        restrictionDescription = null;
    }

    if (discount.type === "DISCOUNT_AMOUNT") {
        return <>
            <span>Amount: { currencyFormatFromPrice(discount.amount) }</span><br/>
            {
                restrictionDescription &&
                <span>{restrictionDescription}</span>
            }
        </>
    } else if (discount.type === "DISCOUNT_PERCENT") {
        return (
            <>
                <span>Percentage: { discount.percent * 100 }%</span><br/>
                {
                    discount.cap.value_in_cents > 0 &&
                    <>
                        <span className="caption">Max: { currencyFormatFromPrice(discount.cap) }</span><br/>
                    </>
                }
                { restrictionDescription }
            </>
        )
    }
    return <span>{ label }</span>
}

export function getDescriptionForCreditState(state) {
    if (state === "ACTIVE") {
        return (
            <Label.Success>Active</Label.Success>
        )
    } else if (state === "CONSUMED") {
        return (
            <Label.Warning>Consumed</Label.Warning>
        )
    } else if (state === "REVOKED") {
        return (
            <Label.Danger>Revoked</Label.Danger>
        )
    } else if (state === "EXPIRED") {
        return (
            <Label.Danger>Expired</Label.Danger>
        )
    }
}

export function getColorOptions() {
    return [
        { "value" : "#6d8bbf", "label" : "Blue", "isDefault" : true },
        { "value" : "#5484ed", "label" : "Bold blue"  },
        { "value" : "#a4bdfc", "label" : "Blue" },
        { "value" : "#afeeee", "label" : "Pale Aquamarine" },
        { "value" : "#7fffd4", "label" : "Aquamarine" },
        { "value" : "#46d6db", "label" : "Turquoise" },
        { "value" : "#add6a7", "label" : "Dry Green" },
        { "value" : "#7bd148", "label" : "Green" },
        { "value" : "#51b749", "label" : "Bold green" },
        { "value" : "#f9e79f", "label" : "Chiffon" },
        { "value" : "#fbd75b", "label" : "Yellow" },
        { "value" : "#ffb878", "label" : "Orange" },
        { "value" : "#ffa500", "label" : "Dark Orange" },
        { "value" : "#ff887c", "label" : "Red" },
        { "value" : "#dc2127", "label" : "Bold red" },
        { "value" : "#e1e1e1", "label" : "Gray" },
        { "value" : "#6d5cae", "label" : "Blueberry" },
        { "value" : "#4b0082", "label" : "Indigo" },
        { "value" : "#48b0f7", "label" : "Peacock" },
        { "value" : "#0da899", "label" : "Basil" },
        { "value" : "#c9a843", "label" : "Sun" },
        { "value" : "#fdbeb9", "label" : "Flamingo" },
        { "value" : "#f7460f", "label" : "Tangerine" },
        { "value" : "#b03e3b", "label" : "Maroon" },
        { "value" : "#626c75", "label" : "Graphite" },
        { "value" : "#e8daef", "label" : "Light Purple" },
        { "value" : "#ff00ff", "label" : "Fushia" },
        { "value" : "#808000", "label" : "Olive" },
    ];
}

const getConfigJson = (type, days, action) => {
    return {
        "interval_reference_date_type": type,
        "interval_from_date": days * 86400,
        "action_type": action
    }
}

export function getConfigForPaymentRetrySchedule(type) {
    if (type === "basic") {
        return [
            getConfigJson("invoice.due_at", 1, "payment_retry"),
            getConfigJson("invoice.due_at", 6, "payment_retry"),
        ];
    } else if (type === "lenient") {
        return [
            getConfigJson("invoice.due_at", 1, "payment_retry"),
            getConfigJson("invoice.due_at", 7, "payment_retry"),
            getConfigJson("invoice.due_at", 14, "payment_retry"),
            getConfigJson("invoice.due_at", 21, "payment_retry"),
        ]
    } else if (type === "aggressive") {
        return [
            getConfigJson("invoice.due_at", 1, "payment_retry"),
            getConfigJson("invoice.due_at", 3, "payment_retry"),
            getConfigJson("invoice.due_at", 7, "payment_retry"),
            getConfigJson("invoice.due_at", 10, "payment_retry"),
            getConfigJson("invoice.due_at", 12, "payment_retry"),
            getConfigJson("invoice.due_at", 14, "cancel_subscription"),
        ]
    }
    return [];
}

export function getConfigForInvoiceReminderSchedule(type) {
    if (type === "basic") {
        return [
            getConfigJson("invoice.due_at", -3, "notification"),
            getConfigJson("invoice.due_at", -1, "notification"),
            getConfigJson("invoice.due_at", 0, "notification"),
            getConfigJson("invoice.due_at", 1, "notification"),
            getConfigJson("invoice.due_at", 3, "notification"),
        ];
    } else if (type === "lenient") {
        return [
            getConfigJson("invoice.due_at", -7, "notification"),
            getConfigJson("invoice.due_at", -1, "notification"),
            getConfigJson("invoice.due_at", 0, "notification"),
            getConfigJson("invoice.due_at", 7, "notification"),
            getConfigJson("invoice.due_at", 14, "notification"),
            getConfigJson("invoice.due_at", 21, "notification"),
        ]
    } else if (type === "aggressive") {
        return [
            getConfigJson("invoice.due_at", -7, "notification"),
            getConfigJson("invoice.due_at", -3, "notification"),
            getConfigJson("invoice.due_at", -1, "notification"),
            getConfigJson("invoice.due_at", 0, "notification"),
            getConfigJson("invoice.due_at", 1, "notification"),
            getConfigJson("invoice.due_at", 3, "notification"),
            getConfigJson("invoice.due_at", 7, "notification"),
            getConfigJson("invoice.due_at", 14, "notification"),
            getConfigJson("invoice.due_at", 20, "notification"),
            getConfigJson("invoice.due_at", 21, "cancel_subscription"),
        ]
    }
    return [];
}

export function getConfigForCardExpirySchedule(type) {
    if (type === "basic") {
        return [
            getConfigJson("expired.start_of_month", 0, "notification"),
            getConfigJson("expired.start_of_month", 2, "notification"),
            getConfigJson("expired.start_of_month", 6, "notification"),
        ];
    } else if (type === "lenient") {
        return [
            getConfigJson("expired.start_of_month", 0, "notification"),
            getConfigJson("expired.start_of_month", 1, "notification"),
            getConfigJson("expired.start_of_month", 7, "notification"),
            getConfigJson("expired.start_of_month", 14, "notification"),
        ]
    } else if (type === "aggressive") {
        return [
            getConfigJson("expired.start_of_month", 0, "notification"),
            getConfigJson("expired.start_of_month", 1, "notification"),
            getConfigJson("expired.start_of_month", 3, "notification"),
            getConfigJson("expired.start_of_month", 7, "notification"),
            getConfigJson("expired.start_of_month", 10, "notification"),
            getConfigJson("expired.start_of_month", 14, "notification"),
            getConfigJson("expired.start_of_month", 17, "notification"),
            getConfigJson("expired.start_of_month", 20, "notification"),
        ]
    }
    return [];
}

export function returnHTMLForVariable(fullString, variable, one, two) {
    if (_.isEmpty(fullString)) {
        return "";
    }
    let name = variable.trim();
    let extraClass = "";
    let extraContent = ``;
    if (variable === "Pricing") {
        extraClass = "maple-variable-pricing";
        name = "Pricing Table";
        extraContent = `<img src="/images/grid.svg"></img>`;
    } else if (variable === "SignatoryInitials") {
        extraClass = "maple-variable-initials";
        name = "Initials";
    } else if (variable === "SignatorySignature") {
        extraClass = "maple-variable-signature";
        name = "Sign Here";
        extraContent = `<br/><i class="fa fa-signature"></i>`;
    }
    return `<span contenteditable="false" class="fr-deletable maple-replace maple-variable ${extraClass}" data-key="${variable}" data-show-language-popup="false">${name}${extraContent}</span>`
    // return `<span class="maple-replace"><abbr contenteditable="false" class="maple-variable fr-deletable ${extraClass}" data-key="${variable}" data-show-language-popup="false">${name}${extraContent}</abbr></span>`
}

export function convertVariablesIntoHtml(data) {
    let convertedData = data;
    if (convertedData) {
        convertedData = data.replaceAll(/{{[^.]*.([A-Za-z0-9]*)[^.]*}}/g, returnHTMLForVariable);
    } else {
        return convertedData;
    }

    convertedData = convertedData.replaceAll("[initial|req|signer1]", returnHTMLForVariable("SignatoryInitials", "SignatoryInitials"))
    convertedData = convertedData.replaceAll("[sig|req|signer1]", returnHTMLForVariable("SignatorySignature", "SignatorySignature"))
    convertedData = convertedData.replaceAll("[date|req|signer1]", returnHTMLForVariable("SignatorySignatureDate", "SignatorySignatureDate"))
    return convertedData;
}

export function convertHtmlIntoVariables(data) {
    let wrappedData = `<div>${data}</div>`;
    let result = wrappedData;
    const jdata = $(wrappedData);
    jdata.find(".maple-replace").each(function(index) {
        let vvv = $(this).attr('data-key');
        result = result.replace($(this).prop('outerHTML'), "{{."+vvv+"}}")
    });
    result = result.replace('<p data-f-id="pbf" style="text-align: center; font-size: 14px; margin-top: 30px; opacity: 0.65; font-family: sans-serif;">Powered by <a href="https://www.froala.com/wysiwyg-editor?pb=1" title="Froala Editor">Froala Editor</a></p>', "")
    return result;
}

export function isPaymentMethodExpiring(method) {
    if ((moment().month() + 1) === method.exp_month &&
        moment().year() === method.exp_year) {
        return true;
    }
    return false;
}

export function isPaymentMethodExpired(method) {
    if (moment().year() > method.exp_year) {
        return true;
    }
    if (moment().year() === method.exp_year && (moment().month() + 1) > method.exp_month) {
        return true;
    }
    return false;
}


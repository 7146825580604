import '../../App.scss';
import '../../css/modals.scss';
import React, { useState, useEffect, useContext } from 'react';
import BaseSideModal from './BaseSideModal';
import SubmitButton from '../SubmitButton';
import Notification from '../Notification';
import { serverPost, serverPatch } from '../../helpers/server';
import { BaseContext } from '../../helpers/common';
import { useTranslation } from 'react-i18next';
const _ = require("lodash");

function BaseAddOrEditItemModal(props) {
    const { t } = useTranslation('common');
    const { getApiUrl, company } = useContext(BaseContext);
    const [isEditing, setIsEditing] = useState(false);

    const addItem = async (itemFields, defaultErrorHandler) => {
        const addFields = props.processAddFields({...itemFields, company_id: company.id});
        if (props.addItem) {
            props.addItem(addFields);
        } else {
            const addItemUrl = getApiUrl(props.itemBaseUrl);
            serverPost(addItemUrl, addFields, {}, defaultErrorHandler).then((res) => {
                if (res) {
                    Notification.Success(`${props.itemLabel} created`);
                    if (props.onAdded) {
                        props.onAdded(res);
                    }
                    props.onClose(true);
                }
            })
        }
    }

    const updateItem = (itemFields, defaultErrorHandler) => {
        const updateFields = props.processUpdateFields({...itemFields, company_id: company.id})
        if (props.updateItem) {
            props.updateItem(updateFields);
        } else {
            const updateItemUrl = getApiUrl(`${props.itemBaseUrl}/${props.itemToEdit.id}`);
            serverPatch(updateItemUrl, updateFields, {}, defaultErrorHandler).then((res) => {
                if (res) {
                    Notification.Success(`${props.itemLabel} updated`);
                    props.onClose(true);
                }
            })
        }
    }

    useEffect(() => {
        if (_.isNil(props.isEditing)) {
            setIsEditing(!_.isNil(props.itemToEdit));
        } else {
            setIsEditing(props.isEditing);
        }
    }, [props.itemToEdit, props.isEditing]);

    return (
        <BaseSideModal {...props}>
            <BaseSideModal.Form
                initialFormFields={props.itemToEdit || props.initialFormFields}
                onFieldChange={props.onFieldChange}
                onSubmit={isEditing ? updateItem : addItem}
                errorFields={props.errorFields}
                className={props.formClassName}
            >
                <BaseSideModal.Header title={isEditing ? t('common.modal.edit', {itemLabel: props.itemLabel}) : t('common.modal.add', {itemLabel: props.itemLabel})} />
                <BaseSideModal.Body>
                    { props.children }
                </BaseSideModal.Body>
                <BaseSideModal.Footer>
                    <SubmitButton variant="primary" disabled={props.submitDisabled}>{isEditing ? t('common.modal.update', {itemLabel: props.itemLabel}) : t('common.modal.add', {itemLabel: props.itemLabel})}</SubmitButton>
                </BaseSideModal.Footer>
            </BaseSideModal.Form>
        </BaseSideModal>
    );

}

export default BaseAddOrEditItemModal;

BaseAddOrEditItemModal.defaultProps = {
    processAddFields: (fields) => fields,
    processUpdateFields: (fields) => fields
};

import '../../App.scss';
import '../../css/modals.scss';
import BaseModal from './BaseModal';
import BaseForm from '../BaseForm';
import SubmitButton from '../SubmitButton';
import CopyableComponent from '../CopyableComponent';
import Columns from '../Columns';
import { useContext, useState, useEffect } from 'react';
import { BaseContext } from '../../helpers/common';
import { serverPost, notifyEvent } from '../../helpers/server';
import { Button, Row, Col, ButtonGroup, Alert, Table } from 'react-bootstrap';
const _ = require("lodash");
const sha1 = require('js-sha1');

function DeclineContractModal(props) {
    const { company, getApiUrl, getCompanySpecificUrl } = useContext(BaseContext);
    const [contract, setContract] = useState({});
    const [signatory, setSignatory] = useState(null);

    useEffect(() => {
        setContract(props.contract);
    }, [props.contract])

    useEffect(() => {
        setSignatory(props.signatory)
    }, [props.signatory])

    const declineContract = (data) => {
        const declineData = {
            email: signatory && signatory.email,
            reason: data.reason
        }
        serverPost(getApiUrl(`/proposals/${contract.id}/decline`), declineData).then((res) => {
            notifyEvent("contract");
        })
    }

    return (
        <BaseModal {...props}>
            <BaseForm onSubmit={declineContract}>
                <BaseModal.Header>
                    <BaseModal.Title>Decline Contract</BaseModal.Title>
                </BaseModal.Header>
                <BaseModal.Body>
                    <p className="body1">
                        Optional: You can provide a reason for declining the contract. We will let { company.name } know about it.
                    </p>
                    <Row>
                        <BaseForm.Input name="reason" type="textarea" label="Reason (Optional)" height="120px" placeholder="Not interested" />
                    </Row>
                </BaseModal.Body>
                <BaseModal.Footer>
                    <div className="d-flex flex-row align-items-end">
                        <SubmitButton variant="danger">Decline</SubmitButton>
                    </div>
                </BaseModal.Footer>
            </BaseForm>
        </BaseModal>
    );

}

export default DeclineContractModal;

import '../../../App.scss';
import React from 'react';
import { IntegrationContext } from '../../../helpers/common';
import { useEffect, useContext } from "react";
import { useOutletContext, useNavigate } from "react-router-dom";

function OauthCallback(props) {
    const navigate = useNavigate();
    const { integration, getIntegrationSpecificUrl } = useContext(IntegrationContext);
    const { next } = useOutletContext();

    useEffect(() => {
        if (integration) {
            if (integration.status === "ACTIVE") {
                window.location.href = getIntegrationSpecificUrl("/settings");
            }
        }
    }, []);

    return (
        <div/>
    );
}

export default OauthCallback;

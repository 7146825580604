import '../App.scss';
import React, {useState, useEffect, useContext} from 'react';
import { Table, Button } from 'react-bootstrap';
import BaseForm from './BaseForm';
import DeleteButton from './DeleteButton';
import MapleTable from './MapleTable';
import classnames from 'classnames';
import {serverPost} from "../helpers/server";
import {BaseContext, renderDescriptionForItemPricing} from "../helpers/common";
import Select from "react-select/async";
const _ = require("lodash");

function SelectEntitlements(props) {
    const { getApiUrl } = useContext(BaseContext);
    const [availableEntitlements, setAvailableEntitlements] = useState([]);
    const [originalEntitlements, setOriginalEntitlements] = useState([]);
    const [entitlements, setEntitlements] = useState([]);
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        const sortedEntitlements = _.sortBy(props.entitlements || [], (et) => {
            if (!_.isNil(et.inherited_from_type) && !et.overridden) {
                return "0" + et.entitlement_id;
            } else if (!_.isNil(et.inherited_from_type)) {
                return "1" + et.entitlement_id;
            } else {
                return "2" + et.entitlement_id;
            }
        })
        setEntitlements(sortedEntitlements);
        setOriginalEntitlements(sortedEntitlements);
    }, [props.entitlements]);

    useEffect(() => {
        if (_.isNil(props.isEditing)) {
            setIsEditing(false);
        } else {
            setIsEditing(props.isEditing)
        }
    }, [props.isEditing])

    useEffect(() => {
        serverPost(getApiUrl("/entitlements/find"), {}).then((res) => {
            setAvailableEntitlements(res);
        });
    }, [])

    const onSubmit = (data) => {
        const parsedData = _.filter(_.map(entitlements, (et, i) => {
            if (!_.isNil(et.inherited_from_type)) {
                if (et.overridden) {
                    return {
                        entitlement_id: et.entitlement_id,
                        value: data[String(i)].value
                    }
                } else {
                    return null;
                }
            } else {
                return {
                    entitlement_id: et.entitlement_id,
                    value: data[String(i)].value
                }
            }
        }), (v) => !!v);
        console.log("The parsed data is " + JSON.stringify(parsedData));
        if (props.onUpdateEntitlements) {
            props.onUpdateEntitlements(parsedData);
        }
    }

    const deleteRow = (i) => {
        const newItems = [...entitlements];
        newItems.splice(i, 1);
        if (_.isEmpty(newItems)) {
            setEntitlements([{}]);
        } else {
            setEntitlements(newItems);
        }
    }

    const overrideEntitlement = (event, index) => {
        event.stopPropagation();
        event.preventDefault();
        setEntitlements(prevItems => {
            const newItems = [...prevItems];
            newItems[index].overridden = true;
            return newItems;
        });
    }

    const revertEntitlement = (event, index) => {
        event.stopPropagation();
        event.preventDefault();
        setEntitlements(prevItems => {
            const newItems = [...prevItems];
            newItems[index].overridden = false;
            newItems[index].value = newItems[index].original_value;
            return newItems;
        });
    }

    const addNewRow = (option) => {
        const newItems = [...entitlements];
        newItems.push({ entitlement_id: option.id, name: option.name, type: option.type });
        setEntitlements(newItems);
    }

    const cancelEditing = () => {
        setEntitlements(originalEntitlements);
        if (props.onCancelEditing) {
            props.onCancelEditing();
        }
    }

    const renderField = (entitlement, i) => {
        if (entitlement.__new__) {
            return null;
        } else {
            if (entitlement.type === "BOOL") {
                return <BaseForm.Input type="select" colSpan="12" name={`${i}.value`} placeholder="Value" label="Value" hideLabel
                                       options={boolOptions} showSearch={false} formClassName="inline" required />
            } else if (entitlement.type === "NUMBER") {
                return <BaseForm.Input type="number" colSpan="12" name={`${i}.value`} placeholder="Value" label="Value"
                                       hideLabel formClassName="inline" required />
            } else if (entitlement.type === "TEXT") {
                return <BaseForm.Input type="text" colSpan="12" name={`${i}.value`} placeholder="Value" label="Value"
                                       hideLabel formClassName="inline" required />
            }
            return null;
        }
    }

    const renderValue = (item) => {
        if (item.type === "BOOL") {
            return <span className="italic">{boolMap[item.value]}</span>;
        } else {
            return <span>{ String(item.value) }</span>
        }
    }

    const boolMap = {
        false: "False",
        true: "True"
    }

    const boolOptions = [
        { value: true, label: "True" },
        { value: false, label: "False" }
    ]

    const availableEntitlementsOptions = _.map(availableEntitlements, (et) => {
        return {
            value: et.entitlement_id,
            label: et.name
        }
    })

    const loadAvailableEntitlements = (query, callback) => {
        callback(availableEntitlements);
    }

    const availableEntitlementLabel = (pricing) => {
        const ppLabel = pricing.name || pricing.description || "";
        return (
            <div>
                { !_.isEmpty(ppLabel) && <span>{ ppLabel }</span> }
                <div>
                    {
                        _.map(pricing.product_metric_pricings, (pmp, i) =>
                            <React.Fragment key={i}>
                                <span key={i}>{renderDescriptionForItemPricing(pmp.item_pricing, false, pmp.item)}</span>
                            </React.Fragment>
                        )
                    }
                </div>
            </div>
        )
    }

    if (isEditing) {
        return (
            <BaseForm initialFormFields={entitlements} onSubmit={onSubmit}>
                <MapleTable>
                    <MapleTable.Content>
                        <tbody className="divide-y divide-gray-200">
                        {
                            _.map(entitlements, (item, i) =>
                                <tr key={i} style={{ minHeight: "2px" }}>
                                    <td className="align-middle">
                                        {
                                            item.__new__ ?
                                                <BaseForm.Input type="select" name="new_entitlement"
                                                                options={availableEntitlementsOptions} showSearch={false}/>
                                                : <span className="align-middle">{item.name}</span>
                                        }
                                    </td>
                                    <td>
                                        {
                                            (!_.isNil(item.inherited_from_type) && item.overridden) ?
                                                renderField(item, i)
                                            : !_.isNil(item.inherited_from_type) ?
                                                <span>{renderValue(item)}</span>
                                            : renderField(item, i)
                                        }
                                    </td>
                                    <td className="no-stretch text-end">
                                        {
                                            item.overridden ?
                                                <Button size="sm" variant="text" onClick={(event) => revertEntitlement(event, i)}><i className={"fa fa-rotate-left"}/></Button>
                                            : !_.isNil(item.inherited_from_type) ?
                                                <span className="gray2">Inherited <Button size="sm" variant="text" onClick={(event) => overrideEntitlement(event, i)}><i className="fa fa-edit"/></Button></span>
                                            : _.isNil(item.inherited_from_type) &&
                                                <DeleteButton onDelete={() => deleteRow(i)}/>
                                        }
                                    </td>
                                </tr>
                            )
                        }
                            <tr>
                                <td colSpan="3">
                                    <div className="d-flex flex-row gap-3">
                                        <div className="flex-grow-1">
                                            <div className="form-group inline">
                                                <div className="form-input">
                                                    <Select
                                                        menuPlacement="auto"
                                                        components={{DropdownIndicator: null}}
                                                        className="select-container"
                                                        loadOptions={loadAvailableEntitlements}
                                                        defaultOptions
                                                        classNamePrefix="select2"
                                                        getOptionLabel={availableEntitlementLabel}
                                                        getOptionValue={(c) => c.id}
                                                        value={null}
                                                        placeholder="Add a entitlement..."
                                                        onChange={(value, meta) => {
                                                            addNewRow(value);
                                                        }}
                                                        isOptionDisabled={(option) => {
                                                            // return false;
                                                            return _.includes(_.map(entitlements, (et) => et.entitlement_id), option.id);
                                                        }}
                                                        isClearable={false}
                                                        noOptionsMessage={(inputValue) => {
                                                            return `Start typing...`
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-grow-0 d-flex flex-row gap-3">
                                            <Button variant="link" onClick={cancelEditing}>Cancel</Button>
                                            <Button type="submit">Save</Button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </MapleTable.Content>
                </MapleTable>
            </BaseForm>
        )
    } else if (_.isEmpty(entitlements)) {
        return (
            <span>No Entitlements</span>
        )
    } else {
        return (
            <div className={classnames("flex-grow-1", props.className)}>
                <MapleTable>
                    <MapleTable.Content>
                        <tbody className="divide-y divide-gray-200">
                        {
                            _.map(entitlements, (item, i) =>
                                <tr key={i}>
                                    <td className="font-medium">
                                        { item.name }
                                    </td>
                                    <td>{ renderValue(item) }</td>
                                    <td className="text-end no-stretch">
                                    {
                                        !_.isNil(item.inherited_from_type) && !item.overridden &&
                                            <span className="text-gray-400">Inherited</span>
                                    }
                                    {
                                        item.overridden &&
                                            <span>Overridden</span>
                                    }
                                    </td>
                                </tr>
                            )
                        }
                        </tbody>
                    </MapleTable.Content>
                </MapleTable>
            </div>
        );
    }
}

export default SelectEntitlements;

import '../../../App.scss';
import React, { useEffect, useState, useContext } from 'react';
import { serverFetch, serverPost, serverDelete } from '../../../helpers/server';
import { BaseContext } from '../../../helpers/common';
import MoreButton from '../../../components/MoreButton';
import BaseContainer from '../../../components/BaseContainer';
import MapleTable from '../../../components/MapleTable';
import ArchiveButton from '../../../components/ArchiveButton';
import Notification from '../../../components/Notification';
import Section from '../../../components/Section';
import ContentContainer from '../../../components/ContentContainer';
import { Button } from 'react-bootstrap';
import { useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classnames from "classnames";
const _ = require('lodash');

function ContractSettings() {
    const { t } = useTranslation('common');
    const navigate = useNavigate();
    const { getApiUrl, setPageTitle, getCompanySpecificUrl } = useContext(BaseContext);
    const [settings, setSettings] = useState({});
    const [templates, setTemplates] = useState([]);

    useEffect(() => {
        setPageTitle(`Contract Settings`);
    }, []);

    useEffect(() => {
        fetchData(true);
    }, []);

    const fetchData = (skipCache=false) => {
        serverFetch(getApiUrl("/settings"), { skipCache: skipCache }).then((res) => {
            setSettings(res);
        });

        serverPost(getApiUrl("/proposals/templates/find"), {}).then((res) => {
            setTemplates(res);
        });
    }

    const deleteTemplate = (template) => {
        serverDelete(getApiUrl(`/proposals/templates/${template.id}`)).then((res) => {
            Notification.Success("Template successfully deleted");
            fetchData(true);
        })
    }

    const onTemplateActionSelected = (template) => {
        const templateData = {
            name: `${template.name} - Copy`,
            content: template.content
        }
        serverPost(getApiUrl('/proposals/templates'), templateData).then((res) => {
            if (res) {
                fetchData();
            }
        })
    }

    const templateActionOptions = [
        { id: "duplicate", label: "Duplicate" }
    ]

    return (
        <BaseContainer>
            <ContentContainer>
                <Section title="Contract Templates"
                    actions={[{
                        variant: "primary",
                        icon: "fa-plus",
                        label: "Create Template",
                        link: getCompanySpecificUrl("/contract/template/create")
                    }]}>
                    <MapleTable>
                        <MapleTable.Content>
                            <thead>
                                <tr>
                                    <MapleTable.TH>Name</MapleTable.TH>
                                    <MapleTable.TH></MapleTable.TH>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                            {
                                _.map(templates, (row, i) =>
                                     <tr key={i}>
                                        <td>{ row.name }</td>
                                        <td className="text-end">
                                            <Link to={getCompanySpecificUrl(`/contract/template/edit/${row.id}`)}>
                                                <Button variant="text-primary" size="sm">Edit</Button>
                                            </Link>
                                            <ArchiveButton size="md" onDelete={() => deleteTemplate(row)}></ArchiveButton>
                                            <MoreButton items={templateActionOptions} onSelect={() => onTemplateActionSelected(row)} />
                                        </td>
                                     </tr>
                                )
                            }
                            </tbody>
                        </MapleTable.Content>
                    </MapleTable>
                </Section>
            </ContentContainer>
        </BaseContainer>
    );
}

export default ContractSettings;

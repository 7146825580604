import '../App.scss';
import '../css/forms.scss';
import '../js/forms.js';
import classnames from 'classnames';
import React, {useState, useEffect, useMemo} from 'react';
import {Button, Dropdown, Form, FormControl} from 'react-bootstrap';
const _ = require("lodash");

function MultiSelectDropdown(props) {
    const [items, setItems] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const idField = props.idField || 'id';
    const labelField = props.labelField || 'label';
    const prefix = useMemo(() => {
        return Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5);
    }, [])

    useEffect(() => {
        setItems(props.items || []);
    }, [props.items]);

    useEffect(() => {
        setSelectedItems(props.selectedItems || []);
    }, [props.selectedItems]);

    const onSelectAll = (event) => {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        const newSelectedItems = _.map(items, (p, i) => p[idField]);
        setSelectedItems(newSelectedItems);
        if (props.onItemsChange) {
            props.onItemsChange(newSelectedItems);
        }
    }

    const onSelectNone = (event) => {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        const newSelectedItems = [];
        setSelectedItems(newSelectedItems);
        if (props.onItemsChange) {
            props.onItemsChange(newSelectedItems);
        }
    }

    const updateSelectedItems = (event, value, isChecked) => {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        let newSelectedItems = [...selectedItems];
        if (isChecked) {
            newSelectedItems.push(value);
        } else {
            const index = newSelectedItems.indexOf(value);
            if (index > -1) {
                newSelectedItems.splice(index, 1);
            }
        }
        setSelectedItems(newSelectedItems);
        if (props.onItemsChange) {
            props.onItemsChange(newSelectedItems);
        }
        return false;
    }

    const CustomMenu = React.forwardRef(
        ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
            const [value, setValue] = useState('');

            return (
                <div ref={ref} style={style} className={className} aria-labelledby={labeledBy}>
                    {
                        props.showSearch &&
                        <FormControl autoFocus className="select-search" placeholder="Start typing"
                                     onChange={(e) => setValue(e.target.value)} value={value} />
                    }
                    <div className="selection-bar">
                        <span className="body2">Select:</span>
                        <Button variant="alink" className="skinny" onClick={(event) => onSelectAll(event)}>All</Button>
                        <Button variant="alink" className="skinny" onClick={(event) => onSelectNone(event)}>None</Button>
                    </div>
                    <ul className="list-unstyled">
                        {
                            React.Children.toArray(children).filter(
                                (child) =>
                                    !value || child.props.value.toLowerCase().includes(value.toLowerCase()),)
                        }
                    </ul>
                </div>
            );
        },
    );
    const showLabel = !(props.hideLabel || _.isNil(props.label));

    return (
        <Dropdown align={props.align || "start"}
                  className={classnames(props.className, (showLabel ? "inline": ""), "single")} disabled={props.disabled}>
            <Dropdown.Toggle variant="text" id={"dropdown-"+prefix} disabled={props.disabled}
                             data-bs-auto-close="outside"
                             className={classnames(props.hideCaret ? "no-caret": "")}>
                {
                    showLabel &&
                    <Form.Label>{props.label}</Form.Label>
                }
                {
                    props.toggleLabel ||
                        <div className="simple-label">{selectedItems.length} of {items.length}&nbsp;Selected</div>
                }
            </Dropdown.Toggle>
            <Dropdown.Menu as={CustomMenu} className="single" disabled={props.disabled}>
                {
                    items.map((item, key) => {
                        if (!item) {
                            return null;
                        } else if (item.divider) {
                            return <hr key={key}/>
                        } else {
                            return (
                                <Dropdown.Item key={key} eventKey={item[idField]} value={item[labelField]}
                                               active={false} className={item.className}
                                               disabled={item.disabled} onClick={(event) => updateSelectedItems(event, item[idField], !selectedItems.includes(item[idField]))}>
                                    <input type="checkbox" value={item[idField]} id={prefix + item[idField]} name="select[]" checked={selectedItems.includes(item[idField])} onChange={ (event) => updateSelectedItems(item[idField], event.target.checked) }/>
                                    {
                                        item.icon &&
                                        <>
                                            <i className={"fa " + item.icon} style={item.style}/>
                                            <span> </span>
                                        </>
                                    }
                                    <span className={classnames(item.className)}>{item[labelField]}</span>
                                    {
                                        item.disabled && item.disabledDescription &&
                                        <>
                                            <br/>
                                            <span className={classnames(item.className, "caption")}>{item.disabledDescription}</span>
                                        </>
                                    }
                                </Dropdown.Item>
                            );
                        }
                    })
                }
            </Dropdown.Menu>
        </Dropdown>
    );
}

export default MultiSelectDropdown;

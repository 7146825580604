import '../../App.scss';
import React, { useEffect, useState, useContext } from 'react';
import { useParams, Link } from 'react-router-dom';
import { serverFetch, serverDelete } from '../../helpers/server';
import {
    BaseContext,
    getDescriptionForCoupon,
    getLabelForCouponType
} from '../../helpers/common';
import { useTranslation } from 'react-i18next';
import BaseContainer from '../../components/BaseContainer';
import KeyValueDisplay from '../../components/KeyValueDisplay2';
import ContentContainer from '../../components/ContentContainer';
import Section from '../../components/Section';
import Loader from '../../components/Loader';
import SingleSelectDropdown from '../../components/SingleSelectDropdown';
import AddOrEditPromoCodeModal from '../../components/modals/AddOrEditPromoCodeModal';
import moment from 'moment';
import {Table} from "react-bootstrap";
import classnames from "classnames";
import DeleteButton from "../../components/DeleteButton";
import CopyableComponent from "../../components/CopyableComponent";
import MapleTable from "../../components/MapleTable";
const _ = require('lodash');

function Coupon(props) {
    const { t } = useTranslation('common');
    const { couponId } = useParams();
    const { getApiUrl, setPageTitle } = useContext(BaseContext);
    const [showAddOrEditPromoCodeModal, setShowAddOrEditPromoCodeModal] = useState(false);
    const [loadingCoupon, setLoadingCoupon] = useState(true);
    const [coupon, setCoupon] = useState({});
    const [details, setDetails] = useState({});

    useEffect(() => {
        setPageTitle(`Coupon - ${coupon.name}`);
    }, [coupon]);

    const fetchData = (skipCache=false) => {
        serverFetch(getApiUrl(`/coupons/${couponId}`), { skipCache }).then((res) => {
            if (res) {
                setCoupon(res);
                setLoadingCoupon(false);
            }
        });
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (_.isEmpty(coupon)) {
            return;
        }
        const data = {
            'ID': coupon.id,
            'Date': moment(coupon.created_at).format("DD MMM, YYYY h:mm:ssa"),
            'Type': getLabelForCouponType(coupon.type),
            'Details': getDescriptionForCoupon(coupon, coupon.products, [coupon.product_pricing], []),
            'Max Redemptions': coupon.max_redemptions,
            'Expiration Date': coupon.expiration_date && moment(coupon.expiration_date).format("DD MMM, YYYY h:mm:ssa"),
        };
        setDetails(data);
    }, [coupon]);

    const onModalClose = (didUpdate=false) => {
        setShowAddOrEditPromoCodeModal(false);
        fetchData(didUpdate);
    }

    const onActionSelected = (type) => {
        if (type === "refund") {
        }
    }

    const actionOptions = [
        { id: "refund", label: "Refund" }
    ];

    const renderActions = () => {
        return null;
        if (coupon.status !== "SUCCESS") {
            return null;
        }
        return (
            <SingleSelectDropdown items={actionOptions} onSelect={onActionSelected} align="end"
                menuOnly toggleLabel={<span><i className="fa fa-small fa-edit"/> Actions</span>} />
        )
    }

    const archivePromoCode = (promoCode) => {
        serverDelete(getApiUrl(`/coupons/${couponId}/promo/${promoCode.id}`)).then(res => {
            fetchData(true);
        })
    }

    return (
        <BaseContainer>
            <Loader loading={loadingCoupon}>
            {
                () =>
                    <>
                    <ContentContainer title={
                            <div className="d-flex flex-row gap-3">
                                <span>{ coupon.name }</span>
                            </div>
                        } customActions={ renderActions() }>
                        <br/>
                        <Section title="Details" actions={[]}>
                            <KeyValueDisplay items={details} />
                        </Section>
                        <Section title="Promo Codes" className="mt-4" actions={[{
                            variant: "primary",
                            icon: "fa-plus",
                            label: "Create Promo Code",
                            onClick: () => setShowAddOrEditPromoCodeModal(true)
                        }]}>
                            {
                                <MapleTable>
                                    <MapleTable.Content>
                                        <thead>
                                            <tr>
                                                <MapleTable.TH>Promotion Code</MapleTable.TH>
                                                <MapleTable.TH>ID</MapleTable.TH>
                                                <MapleTable.TH>Redemptions</MapleTable.TH>
                                                <MapleTable.TH>Expiration Date</MapleTable.TH>
                                                <MapleTable.TH>Creation Date</MapleTable.TH>
                                                <MapleTable.TH></MapleTable.TH>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200">
                                        {
                                            _.map(coupon.promo_codes, (row, i) =>
                                                <tr key={i} className={classnames(row.state !== "ACTIVE" && "de-highlighted")}>
                                                    <td>{row.code}</td>
                                                    <td>
                                                        <CopyableComponent value={row.code} />
                                                    </td>
                                                    <td>{row.num_redemptions}</td>
                                                    <td>
                                                        {
                                                            row.expiration_date ?
                                                                moment(row.expiration_date).format("DD MMM, YYYY h:mm:ssa")
                                                            : <span className="gray3">None</span>
                                                        }
                                                    </td>
                                                    <td>
                                                        { moment(row.created_at).format("D MMM, YYYY") }
                                                    </td>
                                                    <td className="text-end">
                                                        {
                                                            row.state === "ACTIVE" &&
                                                                <DeleteButton
                                                                    title="Archive Promo Code?"
                                                                    body="Are you sure you want to archive the promo code?"
                                                                    onDelete={() => archivePromoCode(row)}/>
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        </tbody>
                                    </MapleTable.Content>
                                </MapleTable>
                            }
                        </Section>
                    </ContentContainer>
                    </>
            }
            </Loader>
            <AddOrEditPromoCodeModal show={showAddOrEditPromoCodeModal} onClose={onModalClose} coupon={coupon} />
        </BaseContainer>
    );
}

export default Coupon;

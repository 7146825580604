import React from 'react';
import '../../App.scss';
import '../../css/table.scss';
import '../../css/forms.scss';
import { serverPost, getUrl } from '../../helpers/server';
import SuperContainer from '../../components/SuperContainer';
import BaseForm from '../../components/BaseForm';
import SubmitButton from '../../components/SubmitButton';
import ContentBox from '../../components/ContentBox';
import { Row } from 'react-bootstrap';

function Tasks() {

    const startPushIntegrationSync = (data) => {
        const newData = {
            company_id: data.company_id,
            integration_id: data.integration_id,
            sync_type: "PUSH"
        }
        serverPost(getUrl("/super_user/integration_sync"), newData).then((res) => {
            if (res) {
                // do something
                console.log("Push Sync Started");
            }
        });
    }

    const startPullIntegrationSync = (data) => {
        const newData = {
            company_id: data.company_id,
            integration_id: data.integration_id,
            sync_type: "PULL"
        }
        serverPost(getUrl("/super_user/integration_sync"), newData).then((res) => {
            if (res) {
                // do something
                console.log("Pull Sync Started");
            }
        });
    }

    const startUpdateIntegrationRefs = (data) => {
        const newData = {
            company_id: data.company_id,
            integration_id: data.integration_id,
            sync_type: "UPDATE"
        }
        serverPost(getUrl("/super_user/integration_sync"), newData).then((res) => {
            if (res) {
                // do something
                console.log("Update Refs Started");
            }
        });
    }


    const getPendingTasks = (data) => {
        serverPost(getUrl("/super_user/pending_tasks"), data).then((res) => {
            if (res) {
                // do something
                console.log("Pending tasks listed");
            }
        });
    }

    const purgePendingTasks = (data) => {
        serverPost(getUrl("/super_user/purge_pending_tasks"), data).then((res) => {
            if (res) {
                // do something
                console.log("Purged pending tasks");
            }
        });
    }

    const getDelayedTasks = (data) => {
        serverPost(getUrl("/super_user/delayed_tasks"), data).then((res) => {
            if (res) {
                // do something
                console.log("Delayed tasks listed");
            }
        });
    }

    const purgeDelayedTasks = (data) => {
        serverPost(getUrl("/super_user/purge_delayed_tasks"), data).then((res) => {
            if (res) {
                // do something
                console.log("Purged delayed tasks");
            }
        });
    }

    const runCleanupSubscription = (data) => {
        serverPost(getUrl("/super_user/cleanup_subscription"), data).then((res) => {
            if (res) {
                // do something
                console.log("Clean up subscription");
            }
        });
    }

    const runMRRSanityCheck = (data) => {
        data.always_email = true;
        serverPost(getUrl("/super_user/run_mrr_sanity_check"), data).then((res) => {
            if (res) {
                // do something
                console.log("MRR Sanity Checks");
            }
        });
    }

    const runStripeSyncSanityCheck = (data) => {
        data.always_email = true;
        serverPost(getUrl("/super_user/run_payment_provider_sync_sanity_check"), data).then((res) => {
            if (res) {
                // do something
                console.log("MRR Sanity Checks");
            }
        });
    }

    const runInvoiceDatesSanityCheck = (data) => {
        data.always_email = true;
        serverPost(getUrl("/super_user/run_invoice_dates_sanity_check"), data).then((res) => {
            if (res) {
                // do something
                console.log("MRR Sanity Checks");
            }
        });
    }

    return (
        <SuperContainer>
            <ContentBox>
                <ContentBox.Body>
                    <BaseForm onSubmit={startPushIntegrationSync}>
                        <h3>Start Push Integration Sync</h3>
                        <Row>
                            <BaseForm.Input colSpan="4" type="text" label="Company ID" name="company_id" required/>
                            <BaseForm.Input colSpan="4" type="text" label="Integration ID" name="integration_id" required/>
                        </Row>
                        <SubmitButton>Push</SubmitButton>
                    </BaseForm>
                </ContentBox.Body>
            </ContentBox>
            <ContentBox>
                <ContentBox.Body>
                    <BaseForm onSubmit={startPullIntegrationSync}>
                        <h3>Start Pull Integration Sync</h3>
                        <Row>
                            <BaseForm.Input colSpan="4" type="text" label="Company ID" name="company_id" required/>
                            <BaseForm.Input colSpan="4" type="text" label="Integration ID" name="integration_id" required/>
                        </Row>
                        <SubmitButton>Pull</SubmitButton>
                    </BaseForm>
                </ContentBox.Body>
            </ContentBox>
            <ContentBox>
                <ContentBox.Body>
                    <BaseForm onSubmit={startUpdateIntegrationRefs}>
                        <h3>Start Update Integration Refs</h3>
                        <Row>
                            <BaseForm.Input colSpan="4" type="text" label="Company ID" name="company_id" required/>
                            <BaseForm.Input colSpan="4" type="text" label="Integration ID" name="integration_id" required/>
                        </Row>
                        <SubmitButton>Update Refs</SubmitButton>
                    </BaseForm>
                </ContentBox.Body>
            </ContentBox>
            <ContentBox>
                <ContentBox.Body>
                    <BaseForm onSubmit={getPendingTasks}>
                        <h3>Pending Tasks</h3>
                        <Row>
                            <BaseForm.Input colSpan="4" type="text" label="Queue Name" name="queue" required/>
                        </Row>
                        <SubmitButton>List Tasks</SubmitButton>
                    </BaseForm>
                </ContentBox.Body>
            </ContentBox>
            <ContentBox>
                <ContentBox.Body>
                    <BaseForm onSubmit={purgePendingTasks}>
                        <h3>Purge Pending Tasks</h3>
                        <Row>
                            <BaseForm.Input colSpan="4" type="text" label="Queue Name" name="queue" required/>
                        </Row>
                        <SubmitButton>Purge Tasks</SubmitButton>
                    </BaseForm>
                </ContentBox.Body>
            </ContentBox>
            <ContentBox>
                <ContentBox.Body>
                    <BaseForm onSubmit={getDelayedTasks}>
                        <h3>List Scheduled Tasks</h3>
                        <SubmitButton>List Tasks</SubmitButton>
                    </BaseForm>
                </ContentBox.Body>
            </ContentBox>
            <ContentBox>
                <ContentBox.Body>
                    <BaseForm onSubmit={purgeDelayedTasks}>
                        <h3>Purge Scheduled Tasks</h3>
                        <SubmitButton>Purge Tasks</SubmitButton>
                    </BaseForm>
                </ContentBox.Body>
            </ContentBox>
            <ContentBox>
                <ContentBox.Body>
                    <BaseForm onSubmit={runCleanupSubscription}>
                        <h3>Run Subscription Cleanup</h3>
                        <Row>
                            <BaseForm.Input colSpan="4" type="text" label="Company ID" name="company_id" required/>
                            <BaseForm.Input colSpan="4" type="text" label="Subscription ID" name="subscription_id" required/>
                        </Row>
                        <SubmitButton>Run Cleanup</SubmitButton>
                    </BaseForm>
                </ContentBox.Body>
            </ContentBox>
            <ContentBox>
                <ContentBox.Body>
                    <BaseForm onSubmit={runMRRSanityCheck}>
                        <h3>Run MRR Sanity Check</h3>
                        <Row>
                            <BaseForm.Input colSpan="4" type="text" label="Company ID" name="company_id" required/>
                        </Row>
                        <SubmitButton>Run Check</SubmitButton>
                    </BaseForm>
                </ContentBox.Body>
            </ContentBox>
            <ContentBox>
                <ContentBox.Body>
                    <BaseForm onSubmit={runStripeSyncSanityCheck}>
                        <h3>Run Payment Provider Sync Sanity Check</h3>
                        <Row>
                            <BaseForm.Input colSpan="4" type="text" label="Company ID" name="company_id" required/>
                        </Row>
                        <SubmitButton>Run Check</SubmitButton>
                    </BaseForm>
                </ContentBox.Body>
            </ContentBox>
            <ContentBox>
                <ContentBox.Body>
                    <BaseForm onSubmit={runInvoiceDatesSanityCheck}>
                        <h3>Run Invoice Dates Sanity Check</h3>
                        <Row>
                            <BaseForm.Input colSpan="4" type="text" label="Company ID" name="company_id" required/>
                        </Row>
                        <SubmitButton>Run Check</SubmitButton>
                    </BaseForm>
                </ContentBox.Body>
            </ContentBox>
        </SuperContainer>
    );
}

export default Tasks;

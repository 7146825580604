import '../../App.scss';
import React, {useEffect, useContext, useMemo} from 'react';
import { BaseContext } from '../../helpers/common';
import Metric from '../../components/Metric';
import MetricSummary from '../../components/MetricSummary';
import BaseContainer from '../../components/BaseContainer';
import ContentContainer from '../../components/ContentContainer';
import Section from '../../components/Section';
import BaseForm from '../../components/BaseForm';
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
const _ = require('lodash');

function Metrics() {
    const { t } = useTranslation('common');
    const { getCompanySpecificUrl, setPageTitle } = useContext(BaseContext);
    let { key } = useParams();
    const navigate = useNavigate();
    const initialFields = useMemo(() => {
        return {
            key: key
        }
    }, [key])

    useEffect(() => {
        setPageTitle(`Metrics`);
    }, []);

    useEffect(() => {
        if (_.isNil(key)) {
            // In main metrics page, redirect to mrr page
            navigate(window.location.pathname + "/mrr", { replace: true });
        }
    }, [key])

    if (_.isNil(key)) {
        return null;
    }

    const items = [
        { label: t('metrics.header.revenue'), isHeader: true },
        { label: t('metrics.metric.mrr'), value: "mrr" },
        { label: t('metrics.metric.arr'), value: "arr" },
        { label: t('metrics.metric.total_revenue'), value: "total_revenue" },
        { label: t('metrics.metric.fees'), value: "fees" },
        { label: t('metrics.metric.net_revenue'), value: "net_revenue" },
        { label: t('metrics.metric.mrr_growth'), value: "mrr_growth" },
        { label: t('metrics.metric.other_revenue'), value: "other_revenue"},

        { label: t('metrics.header.subscriptions'), isHeader: true },
        { label: t('metrics.metric.active_subscriptions'), value: "active_subs" },
        { label: t('metrics.metric.new_subscriptions'), value: "new_subs" },
        { label: t('metrics.metric.upgrades'), value: "upgrades" },
        { label: t('metrics.metric.downgrades'), value: "downgrades" },
        { label: t('metrics.metric.failed_charges'), value: "failed_charges" },
        { label: t('metrics.metric.refunds'), value: "refunds" },
        { label: t('metrics.metric.discounts_redeemed'), value:"discounts_redeemed" },

        { label: t('metrics.header.licenses'), isHeader: true },
        { label: t('metrics.metric.licenses'), value: "licenses" },
        { label: t('metrics.metric.arpl'), value: "arpl" },

        { label: t('metrics.header.customer'), isHeader: true },
        { label: t('metrics.metric.active_customers'), value: "active_customers" },
        { label: t('metrics.metric.new_customers'), value: "new_customers" },
        { label: t('metrics.metric.arpu'), value: "arpu" },
        { label: t('metrics.metric.historical_arpu'), value: "historical_arpu" },
        { label: t('metrics.metric.ltv'), value: "ltv" },
        { label: t('metrics.metric.reactivations'), value: "reactivations" },

        { label: t('metrics.header.connected_accounts'), isHeader: true },
        { label: t('metrics.metric.revenue_connected_accounts'), value: "revenue_connected_accounts" },
        { label: t('metrics.metric.application_fees_connected_accounts'), value: "application_fees_connected_accounts" },

        { label: t('metrics.header.trials'), isHeader: true },
        { label: t('metrics.metric.new_trials'), value: "new_trials" },
        { label: t('metrics.metric.active_trials'), value: "active_trials" },
        { label: t('metrics.metric.converted_trials'), value: "converted_trials" },

        { label: t('metrics.header.churn'), isHeader: true },
        { label: t('metrics.metric.user_churn'), value: "user_churn" },
        { label: t('metrics.metric.revenue_churn'), value: "revenue_churn" },
        { label: t('metrics.metric.net_revenue_churn'), value: "net_revenue_churn" },
        { label: t('metrics.metric.churned_customers'), value: "churned_customers" },
        { label: t('metrics.metric.churned_subscriptions'), value: "churned_subs" }
    ];

    const onItemSelect = (name, value) => {
        if (name === "key") {
            navigate(getCompanySpecificUrl("/metrics/" + value));
        }
    }

    return (
        <BaseContainer>
            <div className="d-flex flex-row gap-4">
                <MetricsMenu activeKey={key} items={items} />
                <ContentContainer className="flex-grow-1">
                    <div className="d-md-none">
                        <BaseForm initialFormFields={initialFields} onFieldChange={onItemSelect}>
                            <div className="d-flex flex-row gap-3 align-items-center">
                                <span className="body2">Metric:</span>
                                <BaseForm.Input colSpan="4" type="select" name="key"
                                                formClassName="thin"
                                                label="Metric" showSearch={false} hideLabel
                                                options={_.filter(items, (i) => !i.isHeader)} />
                            </div>
                        </BaseForm>
                        <br/>
                    </div>
                    <Section>
                        <Metric
                            metricKey={_.toUpper(key)}
                            showBreakout={false}
                            defaultDateRange='This Month'
                            showGroupingOptions={true}
                        />
                    </Section>
                    <Section>
                        <MetricSummary metricKey={_.toUpper(key)} />
                    </Section>
                </ContentContainer>
            </div>
        </BaseContainer>
    );
}

function MetricsMenu(props) {
    const { t } = useTranslation('common');
    const { getCompanySpecificUrl } = useContext(BaseContext);

    return (
        <div className="metrics-menu d-none d-md-block">
            <div className="nav-items">
            {
                _.map(props.items, (item, i) => {
                    if (item.isHeader) {
                        return (<span key={i} className="nav-group-header body2">{ item.label }</span>);
                    } else {
                        return (<MenuItem key={i} label={item.label} link={getCompanySpecificUrl("/metrics/" + item.value)} active={item.value === props.activeKey} />);
                    }
                })
            }
            </div>
        </div>
    )
}

function MenuItem(props) {
    const navigate = useNavigate();

    const handleClick = (event) => {
        event.preventDefault();
        if (props.onClick) {
            props.onClick();
        } else if (props.link) {
            navigate(props.link);
        }
    }

    const activeClass = props.active ? "active": "";
    return (
        <div className={classnames("nav-item", activeClass)} onClick={(event) => handleClick(event)}>
            <div className="nav-title">
                {props.label}
            </div>
        </div>
    )
}

export default Metrics;

import '../App.scss';
import React from 'react';
import { renderChildren } from '../helpers/common';

function Label(props) {
    return (
        <></>
    );
}

function Info(props) {
    return (
        <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-700 ring-1 ring-inset ring-gray-600/20">
            { renderChildren(props) }
        </span>
    )
}
Label.Info = Info;

function Success(props) {
    return (
        <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
            { renderChildren(props) }
        </span>
    )
}
Label.Success = Success;

function Warning(props) {
    return (
        <span className="inline-flex items-center rounded-md bg-orange-50 px-2 py-1 text-xs font-medium text-orange-700 ring-1 ring-inset ring-orange-600/20">
            { renderChildren(props) }
        </span>
    )
}
Label.Warning = Warning;

function Danger(props) {
    return (
        <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/20 gap-1">
            { renderChildren(props) }
        </span>
    )
}
Label.Danger = Danger;

function Neutral(props) {
    return (
        <span className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-600/20">
            { renderChildren(props) }
        </span>
    )
}
Label.Neutral = Neutral;

export default Label;
import '../../App.scss';
import React, { useEffect, useState, useContext } from 'react';
import { useParams, Link } from 'react-router-dom';
import { serverFetch } from '../../helpers/server';
import {BaseContext, currencyFormatFromPrice, getNameForProviderType} from '../../helpers/common';
import { useTranslation } from 'react-i18next';
import BaseContainer from '../../components/BaseContainer';
import KeyValueDisplay from '../../components/KeyValueDisplay2';
import ContentContainer from '../../components/ContentContainer';
import Label from '../../components/Label';
import Loader from '../../components/Loader';
import SingleSelectDropdown from '../../components/SingleSelectDropdown';
import RefundPaymentModal from '../../components/modals/RefundPaymentModal';
import moment from 'moment';
const _ = require('lodash');

function Payment(props) {
    const { t } = useTranslation('common');
    const { paymentId } = useParams();
    const { getApiUrl, getCompanySpecificUrl, company, setPageTitle } = useContext(BaseContext);
    const [showRefundPaymentModal, setShowRefundPaymentModal] = useState(false);
    const [loadingPayment, setLoadingPayment] = useState(true);
    const [payment, setPayment] = useState({});
    const [details, setDetails] = useState({});

    useEffect(() => {
        setPageTitle(`Payment`);
    }, [payment]);

    const fetchData = (skipCache=false) => {
        serverFetch(getApiUrl(`/payments/transactions/${paymentId}`), { skipCache }).then((res) => {
            if (res) {
                setPayment(res);
                setLoadingPayment(false);
            }
        });
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (_.isEmpty(payment)) {
            return;
        }
        const data = {
            'Customer': <Link to={getCompanySpecificUrl(`/customer/${payment.customer.id}`)}>{payment.customer.org_name || payment.customer.name || payment.customer.email}</Link>,
            'ID': payment.id,
            'Status': renderPaymentStatus(),
            'Provider': getNameForProviderType(payment.provider_type),
            'Date': moment(payment.created_at).format("DD MMM, YYYY h:mm:ssa"),
            'Invoice': <Link to={getCompanySpecificUrl(`/invoice/${payment.invoice_uuid}`)}>{ payment.invoice_number }</Link>,
            'Total Amount': currencyFormatFromPrice(payment.total_amount),
        };
        if (payment.status === "SUCCESS") {
            data['Fees'] = currencyFormatFromPrice(payment.stripe_fee_amount)
            data['Net Amount'] = currencyFormatFromPrice(payment.net_amount)
        }
        if (payment.connected_account_transaction) {
            data['Application Fees'] = currencyFormatFromPrice(payment.application_fee_amount)
        }
        data['Is Connected Account Transaction'] = payment.connected_account_transaction ? "Yes": "No"
        data['Note'] = payment.note
        setDetails(data);
    }, [payment]);

    const onModalClose = (didUpdate=false) => {
        setShowRefundPaymentModal(false);
        fetchData(didUpdate);
    }

    const onActionSelected = (type) => {
        if (type === "refund") {
            setShowRefundPaymentModal(true);
        }
    }

    const actionOptions = [
        { id: "refund", label: "Refund" }
    ];

    const renderActions = () => {
        if (payment.status !== "SUCCESS") {
            return null;
        }
        if (!payment.is_refundable) {
            return null;
        }
        return (
            <SingleSelectDropdown items={actionOptions} onSelect={onActionSelected} align="end"
                menuOnly toggleLabel={<span><i className="fa fa-small fa-edit"/> Actions</span>} />
        )
    }

    const renderPaymentStatus = () => {
        if (payment.status === "SUCCESS") {
            return <Label.Success>Paid</Label.Success>
        } else if (_.includes(["PARTIALLY_REFUNDED", "REFUNDED", "CANCELLED", "PROCESSING"], payment.status)) {
            const mmap = {
                "PARTIALLY_REFUNDED": "Partially Refunded",
                "REFUNDED": "Refunded",
                "CANCELLED": "Cancelled",
                "PROCESSING": "Processing"
            }
            return <Label.Info>{ mmap[payment.status] }</Label.Info>
        } else if (payment.status === "FAILED") {
            return <Label.Danger>Failed</Label.Danger>
        }
        return <Label.Info>{ payment.status }</Label.Info>
    }

    return (
        <BaseContainer>
            <Loader loading={loadingPayment}>
            {
                () =>
                    <>
                    <ContentContainer title={
                            <div className="d-flex flex-row gap-3">
                                <span>Payment for {payment.customer && (payment.customer.name || payment.customer.email)}</span>
                            </div>
                        } customActions={ renderActions() }>
                        <br/>
                        <KeyValueDisplay
                            items={details}
                        />
                    </ContentContainer>
                    </>
            }
            </Loader>
            <RefundPaymentModal show={showRefundPaymentModal} onClose={onModalClose} payment={payment} />
        </BaseContainer>
    );
}

export default Payment;

import '../App.scss';
import React from 'react';
import { useNavigate } from "react-router-dom";
import { renderChildren } from "../helpers/common";
const _ = require('lodash');

function Link(props) {
    const navigate = useNavigate();

    const onClick = (event) => {
        event.preventDefault();
        event.stopPropagation();
        if (props.onClick) {
            props.onClick(event);
        } else if (props.href) {
            navigate(props.href)
        }
    }

    return (
        <a href={props.href || ""} onClick={onClick} className={props.className}>
            { renderChildren(props) }
        </a>
    );
}

export default Link;
import '../../App.scss';
import React, { useEffect, useState, useContext } from 'react';
import {Link, useSearchParams} from 'react-router-dom';
import { serverPost } from '../../helpers/server';
import { BaseContext, currencyFormatFromPrice } from '../../helpers/common';
import BaseContainer from '../../components/BaseContainer';
import ContentContainer from '../../components/ContentContainer';
import Section from '../../components/Section';
import Columns from '../../components/Columns';
import MapleTable from '../../components/MapleTable';
import Label from '../../components/Label';
import InfiniteScroll from "react-infinite-scroll-component";
import moment from 'moment';
import SortableTableHeader from "../../components/SortableTableHeader";
const _ = require('lodash');

function MRRLog(props) {
    const { company, getApiUrl, getCompanySpecificUrl, setPageTitle } = useContext(BaseContext);
    const [ searchParams ] = useSearchParams();
    let customerId = searchParams.get('c');

    const [mrrLogs, setMrrLogs] = useState([]);
    const [sort, setSort] = useState("timeDesc");
    const [hasMore, setHasMore] = useState(true);
    const [fromKey, setFromKey] = useState(null);
    const [meta, setMeta] = useState({});

    useEffect(() => {
        setPageTitle(`MRR Log`);
    }, []);

    useEffect(() => {
        onSearch(true);
    }, [sort]);

    useEffect(() => {
        onSearch(true);
    }, [searchParams]);

    const onFieldChange = (name, value) => {
        if (name === "sort") {
            setSort(value);
        }
    }

    const onSearch = (restart = true) => {
        const limit = 100;
        const params = {
            company_id: company.id,
            sort_key: sort || "timeDesc",
            pagination: {
                from_key: restart ? null: fromKey,
                limit: limit
            },
            query: {
                log_type: "CUSTOMER",
                ...props.extraQueryFilters,
                customer_id: customerId,
                hide_empty_activations: true
            },
            include_meta: restart
        }
        serverPost(getApiUrl("/reports/mrr_logs/find"), params).then((res) => {
            if (res) {
                const results = res.results || [];
                if (restart) {
                    setMrrLogs(results);
                    setMeta(res.meta);
                } else {
                    setMrrLogs(_.concat(mrrLogs, results));
                }
                setFromKey(res.pagination.from_key);
                setHasMore(results.length === limit);
            }
        });
    };

    const getDescriptionForReason = (log) => {
        const subscriptionReasonMap = {
            "NEW_ACTIVATION": "New Activation",
            "VOLUNTARY_CHURN": "Churn (Voluntary)",
            "GROWTH": "Expansion/Contraction",
            "UPGRADE": "Product Expansion",
            "DOWNGRADE": "Product Contraction",
        }
        const customerReasonMap = {
            "NEW_ACTIVATION": "New Customer Activation",
            "VOLUNTARY_CHURN": "Customer Churn",
            "GROWTH": "Expansion/Contraction",
            "UPGRADE": "Product Expansion",
            "DOWNGRADE": "Product Contraction",
        }
        if (log.log_type === "CUSTOMER") {
            return customerReasonMap[log.reason]
        } else {
            return subscriptionReasonMap[log.reason]
        }
    }

    return (
        <BaseContainer>
            <ContentContainer>
                <InfiniteScroll
                    dataLength={mrrLogs.length}
                    next={() => onSearch(false)}
                    hasMore={hasMore}
                    scrollableTarget="content-wrapper"
                >
                    <Section title="MRR Logs">
                    {
                        <MapleTable>
                            <MapleTable.Content>
                                <thead>
                                    <tr>
                                        <MapleTable.TH>Customer</MapleTable.TH>
                                        <SortableTableHeader
                                            onSortChange={setSort} sortKeyUp="timeAsc" sortKeyDown="timeDesc"
                                            currentSort={sort}>
                                            Time
                                        </SortableTableHeader>
                                        <MapleTable.TH>Reason</MapleTable.TH>
                                        <SortableTableHeader
                                            onSortChange={setSort} sortKeyUp="changeAsc" sortKeyDown="changeDesc"
                                            currentSort={sort} innerClassName="justify-end">
                                            Change
                                        </SortableTableHeader>
                                        <SortableTableHeader
                                            onSortChange={setSort} sortKeyUp="mrrAsc" sortKeyDown="mrrDesc"
                                            currentSort={sort} innerClassName="justify-end">
                                            Total
                                        </SortableTableHeader>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200">
                                {
                                    _.map(mrrLogs, (row, i) =>
                                         <tr key={i}>
                                            <td>
                                            {
                                                row.customer &&
                                                    <Link className="gray4" to={getCompanySpecificUrl(`/customer/${row.customer.id}`)}>
                                                        <Columns.CustomerName customer={row.customer} />
                                                    </Link>
                                            }
                                            </td>
                                            <td>
                                                <Link to={row.log_type === "CUSTOMER" ? getCompanySpecificUrl(`/customer/${row.customer_id}`): getCompanySpecificUrl(`/subscription/${row.subscription_id}`)}>
                                                    <span>{ moment(row.time).format("MMM D, YYYY h:mm:ssa") }</span><br/>
                                                    <span className="caption">{ row.subscription_id }</span>
                                                </Link>
                                            </td>
                                            <td>{ getDescriptionForReason(row) }</td>
                                            <td className="text-end whitespace-nowrap">
                                            {
                                                row.changed_amount && row.changed_amount.value_in_cents !== 0 &&
                                                    <>
                                                    {
                                                        row.changed_amount.value_in_cents > 0 ?
                                                            <Label.Success>{ currencyFormatFromPrice(row.changed_amount) }</Label.Success>
                                                        : <Label.Danger>{ currencyFormatFromPrice(row.changed_amount) }</Label.Danger>
                                                    }
                                                    </>
                                            }
                                            </td>
                                             <td className="text-end whitespace-nowrap">
                                                 {
                                                     row.total_amount && row.total_amount.value_in_cents !== 0 &&
                                                     <>
                                                         {
                                                             row.total_amount.value_in_cents > 0 ?
                                                                 <span className="body1">{ currencyFormatFromPrice(row.total_amount) }</span>
                                                                 : <span className="body1">-</span>
                                                         }
                                                     </>
                                                 }
                                             </td>
                                         </tr>
                                    )
                                }
                                {
                                    hasMore &&
                                        <tr>
                                            <td colSpan={4} className="text-center">
                                                <div className="spinner-border text-secondary"/>
                                            </td>
                                        </tr>
                                }
                                </tbody>
                            </MapleTable.Content>
                        </MapleTable>
                    }
                    </Section>
                </InfiniteScroll>
            </ContentContainer>
        </BaseContainer>
    );
}

export default MRRLog;

import '../../App.scss';
import '../../css/modals.scss';
import React from 'react';
import BaseForm from '../BaseForm';
import SubmitButton from '../SubmitButton';
import BaseSideModal from './BaseSideModal';
import { useState, useEffect, useContext, useMemo } from 'react';
import { BaseContext } from '../../helpers/common';
import {serverFetch, serverPatch} from '../../helpers/server';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
const _ = require('lodash');

function UpdateSubscriptionAutoChargeModal(props) {
    const { t } = useTranslation('common');
    const { getApiUrl } = useContext(BaseContext);
    const [subscription, setSubscription] = useState(null);
    const [settings, setSettings] = useState({});
    const [initialFields, setInitialFields] = useState({});

    useEffect(() => {
        serverFetch(getApiUrl("/settings")).then((res) => {
            setSettings(res);
        });
    }, []);

    useEffect(() => {
        if (props.subscription) {
            setSubscription(props.subscription)
            setInitialFields({
                auto_charges: String(props.subscription.auto_charges)
            })
        } else {
            setSubscription(null);
            setInitialFields({});
        }
    }, [props.subscription])

    const onSubmit = (data) => {
        const updateData = {
            auto_charges: String(data.auto_charges) === "true"
        }
        serverPatch(getApiUrl(`/subscriptions/${subscription.id}`), updateData).then((res) => {
            if (res) {
                props.onClose(true);
            }
        })
    }

    const paymentMechanismOptions = [
        { value: "true", label: "Automatically charge a payment method on file",
            disabled: _.isNil(settings.payment_config), disabledDescription: "* Payment Provider needs to be setup" },
        { value: "false", label: "Email invoice to customer manually" },
    ]

    if (!props.show) {
        return;
    }
    return (
        <BaseSideModal {...props}>
            <BaseSideModal.Form initialFormFields={initialFields} onSubmit={onSubmit} >
                <BaseSideModal.Header title={t('subscriptions.update_billing_mechanism')}/>
                <BaseSideModal.Body>
                    <BaseForm.Input type="select" name="auto_charges" label={t('subscriptions.payment_mechanism')} options={paymentMechanismOptions} showSearch={false}/>
                </BaseSideModal.Body>
                <BaseSideModal.Footer>
                    <SubmitButton>{t('common.update')}</SubmitButton>
                </BaseSideModal.Footer>
            </BaseSideModal.Form>
        </BaseSideModal>
    );
}

export default UpdateSubscriptionAutoChargeModal;

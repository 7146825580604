import '../../App.scss';
import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { serverPost, serverDelete } from '../../helpers/server';
import {
    BaseContext, currencyFormatFromPrice, renderContractStatusLabel
} from '../../helpers/common';
import Columns from '../../components/Columns';
import BaseContainer from '../../components/BaseContainer';
import ContentContainer from '../../components/ContentContainer';
import Section from '../../components/Section';
import DeleteButton from '../../components/DeleteButton';
import MapleTable from '../../components/MapleTable';
import InfiniteScroll from "react-infinite-scroll-component";
import moment from 'moment';
import SortableTableHeader from "../../components/SortableTableHeader";
import MapleTableHeaderWithActions from "../../components/MapleTableHeaderWithActions";
import { DocumentPlusIcon } from "@heroicons/react/24/outline";
import EmptyState from "../../components/EmptyState";
const _ = require('lodash');

function Contracts() {
    const navigate = useNavigate();
    const { company, getApiUrl, setPageTitle, getCompanySpecificUrl } = useContext(BaseContext);
    const [contracts, setContracts] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [fromKey, setFromKey] = useState(null);
    const [sort, setSort] = useState("createdAtDesc");
    const [loading, setLoading] = useState(true);
    const [initialFields, setInitialFields] = useState({ sort: "createdAtDesc" })
    const [query, setQuery] = useState(null)
    const [meta, setMeta] = useState(null)

    useEffect(() => {
        setPageTitle(`Contracts`);
    }, []);

    const onSearch = (restart = true) => {
        const limit = 50;
        const params = {
            company_id: company.id,
            sort_key: sort || "createdAtDesc",
            pagination: {
                from_key: restart ? null: fromKey,
                limit: limit
            },
            query: {
                search: query,
                type: "CONTRACT"
            },
            include_meta: restart
        }
        serverPost(getApiUrl("/proposals/find"), params).then((res) => {
            if (res) {
                const results = res.results || [];
                _.each(results, (r) => {
                    if (r.expiration_date) {
                        r.isExpired = moment(r.expiration_date).isBefore(moment());
                    }
                })
                if (restart) {
                    setContracts(results);
                } else {
                    setContracts(_.concat(contracts, results));
                }
                if (res.meta) {
                    setMeta(res.meta);
                }
                setLoading(false);
                setFromKey(res.pagination.from_key);
                setHasMore(results.length === limit);
            }
        });
    };

    useEffect(() => {
        onSearch(true);
    }, [sort, query]);

    const renderExpirationDate = (contract) => {
        if (_.isNil(contract.expiration_date)) {
            return null;
        }
        if (contract.status !== "PENDING_SIGNATURES") {
            return null;
        }

        let isExpired = moment(contract.expiration_date).isBefore(moment());
        if (isExpired) {
            return (
                <div className="caption danger-color">Expired</div>
            )
        } else {
            return (
                <div className="caption">Expires { moment(contract.expiration_date).fromNow() }</div>
            )
        }
    }

    const onParamsChange = (data) => {
        setQuery(data.search);
    }

    const deleteContract = (contract) => {
        serverDelete(getApiUrl(`/proposals/${contract.id}`)).then(res => {
            onSearch(true);
        })
    }

    const isEmpty = contracts.length === 0 && !hasMore && _.isEmpty(query);
    return (
        <BaseContainer>
            <ContentContainer>
                <InfiniteScroll
                    dataLength={contracts.length}
                    next={() => onSearch(false)}
                    hasMore={hasMore}
                    scrollableTarget="content-wrapper"
                >
                    <Section title="Contracts"
                             loading={loading}
                             actions={!isEmpty && !loading && [{
                                variant: "primary",
                                icon: "fa-plus",
                                label: "Create Contract",
                                link: getCompanySpecificUrl("/contract/create")
                             }]}>
                        {
                            isEmpty ?
                                <EmptyState
                                    icon={DocumentPlusIcon}
                                    title={"No contracts."}
                                    subtitle={"Get started by creating a new one."}
                                    buttonLabel={"New Contract"}
                                    onClick={() => navigate(getCompanySpecificUrl("/contract/create"))}
                                />
                            : <MapleTable>
                                    <MapleTableHeaderWithActions
                                        showSearch={true}
                                        searchPlaceholder="Search Contracts"
                                        showFilters={false}
                                        meta={meta}
                                        onParamsChange={onParamsChange}
                                    />
                                    <MapleTable.Content>
                                        <thead>
                                            <tr>
                                                <MapleTable.TH>Customer</MapleTable.TH>
                                                <MapleTable.TH className="d-none d-md-table-cell">Title</MapleTable.TH>
                                                <MapleTable.TH>Status</MapleTable.TH>
                                                <MapleTable.TH className="text-end">Contract Value</MapleTable.TH>
                                                <SortableTableHeader
                                                    onSortChange={setSort} sortKeyUp="createdAtAsc" sortKeyDown="createdAtDesc"
                                                    currentSort={sort} className="whitespace-nowrap flex-nowrap d-none d-xl-table-cell">
                                                    Created At
                                                </SortableTableHeader>
                                                <MapleTable.TH></MapleTable.TH>
                                                <MapleTable.TH className="no-stretch d-none d-md-table-cell">Owner</MapleTable.TH>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200">
                                        {
                                            _.map(contracts, (row, i) =>
                                                 <tr key={i} className="cursor-pointer" onClick={() => navigate(getCompanySpecificUrl(`/contract/view/${row.id}`))}>
                                                     <td>
                                                         <Columns.CustomerName customer={row.customer} />
                                                     </td>
                                                     <td className="d-none d-md-table-cell">{ row.title }</td>
                                                     <td className="break-words">{ renderContractStatusLabel(row) }</td>
                                                     <td className="text-end">
                                                         <span>{ currencyFormatFromPrice(row.tcv) }</span><br/>
                                                         <span className="caption italic">{ currencyFormatFromPrice(row.acv) }/yr</span><br/>
                                                     </td>
                                                     <td className="d-none d-xl-table-cell">
                                                         <span>{ moment(row.created_at).format("MMM D, YYYY") }</span>
                                                         { renderExpirationDate(row) }
                                                     </td>
                                                     <td className="px-0">
                                                     {
                                                         _.includes(["PENDING_SIGNATURES", "DRAFT"], row.status) &&
                                                             <DeleteButton size={"sm"} onDelete={() => deleteContract(row)} />
                                                     }
                                                     </td>
                                                     <td className="w-px whitespace-nowrap d-none d-md-table-cell">
                                                         <Columns.CustomerAvatar customer={row.owner.user} />
                                                     </td>
                                                 </tr>
                                            )
                                        }
                                        {
                                            hasMore &&
                                                <tr>
                                                    <td colSpan="10" className="text-center">
                                                        <div className="spinner-border text-secondary"/>
                                                    </td>
                                                </tr>
                                        }
                                        </tbody>
                                    </MapleTable.Content>
                                </MapleTable>
                        }
                    </Section>
                </InfiniteScroll>
            </ContentContainer>
        </BaseContainer>
    );
}

export default Contracts;

import '../../App.scss';
import React, { useEffect, useState, useContext, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { serverPost, serverFetch, serverPatch } from '../../helpers/server';
import { BaseContext, sleep } from '../../helpers/common';
import { useTranslation } from 'react-i18next';
import BaseContainer from '../../components/BaseContainer';
import ContentContainer from '../../components/ContentContainer';
import InvoiceComponent from '../../components/InvoiceComponent';
import MapleTable from '../../components/MapleTable';
import Loader from '../../components/Loader';
import Section from '../../components/Section';
import ContentBox from '../../components/ContentBox';
import Link from '../../components/Link';
import { Button, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
const _ = require('lodash');

function Invoice(props) {
    const { t } = useTranslation('common');
    const navigate = useNavigate();
    const { uuid } = useParams();
    const { company, getApiUrl, setPageTitle, getCompanySpecificUrl } = useContext(BaseContext);

    const [loadingInvoice, setLoadingInvoice] = useState(true);
    const [invoiceDetails, setInvoiceDetails] = useState({});
    const [settings, setSettings] = useState({});
    const [actions, setActions] = useState([]);

    useEffect(() => {
        if (invoiceDetails) {
            setPageTitle(`Invoice - ${invoiceDetails.number}`);
        } else {
            setPageTitle(`Invoice`);
        }
    }, [invoiceDetails]);

    const fetchData = (skipCache=false) => {
        serverFetch(getApiUrl(`/settings`)).then((res) => {
            setSettings(res)
        })

        serverFetch(getApiUrl(`/invoices/${uuid}`), { skipCache: skipCache }).then(async (res) => {
            setInvoiceDetails(res);
            setLoadingInvoice(false);
        })

        serverFetch(getApiUrl(`/invoices/${uuid}/actions`), { skipCache: skipCache }).then((res) => {
            setActions(res)
        })
    }

    const refresh = () => {
        fetchData(true);
    }

    useEffect(() => {
        fetchData();
    }, []);

    const renderLogAction = (log) => {
        if (_.startsWith(log.action, "invoice.payment.") && !_.isNil(log.payment_transaction_id)) {
            return <Link href={getCompanySpecificUrl(`/payment/${log.payment_transaction_id}`)}>View Payment</Link>
        }

        return null;
    }

    return (
        <BaseContainer>
            <ContentContainer>
                <Loader loading={loadingInvoice || _.isEmpty(settings)}>
                    {
                        (!_.isEmpty(invoiceDetails) && !_.isUndefined(invoiceDetails)) ?
                            <>
                            <InvoiceComponent wrapperClassName="full" invoice={invoiceDetails} settings={settings}
                                isAdmin={true} onUpdate={refresh} hideFooter={true} />
                                {
                                    !invoiceDetails.imported_from &&
                                    <Section title="Logs" className="mt-4">
                                        <span className="body1">All times shown in local timezone</span>
                                        <MapleTable>
                                            <MapleTable.Content>
                                                <tbody className="divide-y divide-gray-200">
                                                {
                                                    _.map(actions, (row, i) =>
                                                        <tr key={i}>
                                                            <td className="w-px whitespace-nowrap">{ moment(row.created_at).format("MMM D, YYYY h:mm:ssa") }</td>
                                                            <td>{ row.display_message }</td>
                                                            <td className="w-px whitespace-nowrap">
                                                                { renderLogAction(row) }
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                                </tbody>
                                            </MapleTable.Content>
                                        </MapleTable>
                                    </Section>
                                }
                            </>
                        : <ContentBox>
                            <ContentBox.Body>
                                <Row>
                                    <Col md="12" className="text-center">
                                        <h4>Oops, Invoice Not Found</h4>
                                        <br/>
                                    </Col>
                                    <Col md="12" className="text-center">
                                        <div className="body1">Not to worry, simply return to the dashboard and try the right link.</div>
                                        <br/><br/>
                                    </Col>
                                    <Col md="12" className="d-flex flex-column align-items-center">
                                        <div className="d-flex flex-row gap-3">
                                            <Button variant="primary" onClick={() => navigate(getCompanySpecificUrl("/"))}>Back to Dashboard</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </ContentBox.Body>
                        </ContentBox>
                    }
                </Loader>
            </ContentContainer>
        </BaseContainer>
    );
}

export default Invoice;

import '../../App.scss';
import '../../css/modals.scss';
import React, { createRef } from 'react';
import BaseForm from '../BaseForm';
import SubmitButton from '../SubmitButton';
import BaseSideModal from '../modals/BaseSideModal';
import { useState, useEffect, useContext } from 'react';
import { BaseContext } from '../../helpers/common';
import {serverFetch, serverPost} from '../../helpers/server';
import { Row, Col, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CustomerInput from "../CustomerInput";
import ProductPricingSelection from "../ProductPricingSelection";
const _ = require('lodash');

function AddOneTimeChargesToCustomerModal(props) {
    const { t } = useTranslation('common');
    const { company, getApiUrl } = useContext(BaseContext);
    const [initialFields, setInitialFields] = useState({aggregate: {}});
    const [type, setType] = useState("IMMEDIATE");
    const [error, setError] = useState(null);
    const [settings, setSettings] = useState({});
    const pricingsRef = createRef();

    useEffect(() => {
        setInitialFields(prevFields => {
            const newFields = {...prevFields};
            newFields.customer = props.customer;
            return newFields;
        });
    }, [props.customer]);

    useEffect(() => {
        serverFetch(getApiUrl("/settings")).then((res) => {
            setSettings(res);
        });
    }, []);

    const addItems = (onetimeFields) => {
        let pricingData = {};
        if (pricingsRef.current) {
            const validationResult = pricingsRef.current.validate(onetimeFields);
            if (!_.isNil(validationResult)) {
                setError(validationResult)
                return;
            }

            pricingData = pricingsRef.current.getPricingSelectionFields(onetimeFields);
        }
        setError(null);
        const onetimeData = {
            ...pricingData,
            company_id: company.id,
            customer_id: props.customer.id,
            type: onetimeFields.type,
            auto_charges: _.isNil(onetimeFields.auto_charges) ? false: onetimeFields.auto_charges === "true"
        }
        // console.log("The add items are " + JSON.stringify(onetimeData));
        serverPost(getApiUrl("/one_times"), onetimeData).then((res) => {
            if (res) {
                props.onClose(true);
            }
        });
    }

    const onFieldChange = (name, value) => {
        if (name === "type") {
            setType(value);
        }
    }

    const typeOptions = [
        { value: "IMMEDIATE", label: "Charge these immediately" },
        { value: "NEXT", label: "Add these charges to the next invoice" },
    ]

    const paymentMechanismOptions = [
        { value: "true", label: "Collect payment information and charge automatically.",
            disabled: _.isNil(settings.payment_config), disabledDescription: "* Payment Provider needs to be setup" },
        { value: "false", label: "Email invoice to customer manually" },
    ]

    return (
        <BaseSideModal {...props} size="lg">
            <BaseSideModal.Form initialFormFields={initialFields} onFieldChange={onFieldChange} onSubmit={addItems} >
                <BaseSideModal.Header title={"Add One Time Charges"} />
                <BaseSideModal.Body>
                    <Row>
                        <CustomerInput colSpan="6" defaultCustomer={props.customer} required />
                    </Row>
                    <Row>
                        <BaseForm.Input colSpan="6" name="type" label={"When should these be charged?"} type="select"
                                        options={typeOptions} showSearch={false} />
                        {
                            type === "IMMEDIATE" &&
                                <BaseForm.Input colSpan="6" name="auto_charges" label={t('subscriptions.payment_mechanism')} type="select"
                                                options={paymentMechanismOptions} showSearch={false} />
                        }
                    </Row>
                    <Alert>
                        <span className="caption">Note: Only one time charges are allowed to be added here. If you would like to add a recurring charge, please create a subscription instead.</span>
                    </Alert>
                    <ProductPricingSelection ref={pricingsRef}
                                             extraQueryFilters={{
                                                 type: "ONETIME_PRICING"
                                             }}/>
                    {
                        error &&
                            <div className="form-error-message">{ error }</div>
                    }
                </BaseSideModal.Body>
                <BaseSideModal.Footer>
                    <Row>
                        <Col md="12" className="text-end">
                            <SubmitButton variant="primary">Add Charges</SubmitButton>
                        </Col>
                    </Row>
                </BaseSideModal.Footer>
            </BaseSideModal.Form>
        </BaseSideModal>
    );
}

export default AddOneTimeChargesToCustomerModal;

import '../../App.scss';
import React, { useEffect, useState, useContext } from 'react';
import { serverPost } from '../../helpers/server';
import { BaseContext } from '../../helpers/common';
import Metric from '../../components/Metric';
import BaseContainer from '../../components/BaseContainer';
import DeleteButton from '../../components/DeleteButton';
import ContentContainer from '../../components/ContentContainer';
import Section from '../../components/Section';
import { Table, Button, Row, Col } from 'react-bootstrap';
const _ = require('lodash');

function Reports() {
    const { company, getApiUrl, setPageTitle } = useContext(BaseContext);
    const [reports, setReports] = useState([]);
    const [mrrData, setMRRData] = useState([]);
    const [arrData, setARRData] = useState([]);
    const [newSubscriptionsData, setNewSubscriptionsData] = useState([]);
    const [newCustomersData, setNewCustomersData] = useState([]);
    const [newInvoicesData, setNewInvoicesData] = useState([]);
    const [newPaymentsData, setNewPaymentsData] = useState([]);
    const [churnData, setChurnData] = useState([]);

    useEffect(() => {
        setPageTitle(`Reports`);
    }, []);

    useEffect(() => {
        serverPost(getApiUrl("/reports/find"), { company_id: company.id }).then((res) => {
            setReports(_.filter(res, r => _.isNil(r.standard_report_key)));
        });
    }, [company, getApiUrl]);

    useEffect(() => {
        serverPost(getApiUrl(`/reports/standard/mrr_report/mtd/run`)).then((res) => {
            setMRRData(res.result ? res.result.data : []);
        });

         serverPost(getApiUrl(`/reports/standard/arr_report/l4w/run`)).then((res) => {
             setARRData(res.result ? res.result.data : []);
         });

        serverPost(getApiUrl(`/reports/standard/subscriptions_report/l3m/run`)).then((res) => {
            setNewSubscriptionsData(res.result ? res.result.data : []);
        });

        serverPost(getApiUrl(`/reports/standard/customers_report/l12m/run`)).then((res) => {
            setNewCustomersData(res.result ? res.result.data : []);
        });

        serverPost(getApiUrl(`/reports/standard/invoices_report/qtd/run`)).then((res) => {
            setNewInvoicesData(res.result ? res.result.data : []);
        });

        serverPost(getApiUrl(`/reports/standard/payments_report/ytd/run`)).then((res) => {
            setNewPaymentsData(res.result ? res.result.data : []);
        });

        serverPost(getApiUrl(`/reports/standard/churn_report/l12m/run`)).then((res) => {
            setChurnData(res.result ? res.result.data : []);
        });
    }, [])

    const onRunReport = (report) => {
        console.log("Run this report " + JSON.stringify(report));
    }

    return (
        <BaseContainer>
            <ContentContainer>
                <Section title="Reports" actions={[{
                        variant: "btn btn-primary",
                        icon: "fa-plus",
                        label: "Create Report",
                    }]}>
                        <Table hover>
                            <thead>
                                <tr>
                                    <th>Report</th>
                                    <th>Product</th>
                                    <th></th>
                                    <th width="10%"></th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                _.map(reports, (row, i) =>
                                     <tr key={i} className="cursor-pointer">
                                        <td>{ row.name }</td>
                                        <td>{ row.name }</td>
                                        <td>{ row.type }</td>
                                        <td className="text-end">
                                            <Button variant="outline-primary" onClick={() => onRunReport(row)}>
                                                Run
                                            </Button>
                                            <DeleteButton size="sm"/>
                                        </td>
                                     </tr>
                                )
                            }
                            </tbody>
                        </Table>
                    </Section>
                    <Section title="Standard Reports">
                        <Row className="gy-3 gx-3">
                            <Col lg="6" md="12">
                                <Metric metricKey="ACTIVE_CUSTOMERS"
                                    chartType="line"
                                    label="Active Customers"
                                    yLabel="Customers"
                                    defaultDateRange='This Month'
                                />
                            </Col>
                            <Col lg="6" md="12">
                                <Metric metricKey="ACTIVE_SUBS"
                                    chartType="line"
                                    label="Active Subscriptions"
                                    yLabel="Subscriptions"
                                    defaultDateRange='This Month'
                                />
                            </Col>
                            <Col lg="6" md="12">
                                <Metric metricKey="INVOICES"
                                    chartType="line"
                                    label="Invoices"
                                    yLabel="Invoices"
                                    defaultDateRange='This Month'
                                />
                            </Col>
                            <Col lg="6" md="12">
                                <Metric metricKey="PAYMENTS"
                                    chartType="line"
                                    label="Payments"
                                    yLabel="Payments"
                                    defaultDateRange='This Month'
                                />
                            </Col>
                        </Row>
                </Section>
            </ContentContainer>
        </BaseContainer>
    );
}

export default Reports;

import '../../../App.scss';
import React from 'react';
import SubmitButton from '../../../components/SubmitButton';
import BaseForm from '../../../components/BaseForm';
import { getIntegrationInterfaceForKey } from '../../../helpers/integrations';
import { Container, Row, Col } from 'react-bootstrap';
import { serverPost, serverPatch } from '../../../helpers/server';
import { BaseContext, IntegrationContext } from '../../../helpers/common';
import { useEffect, useContext, useMemo } from "react";
import { useOutletContext, useParams } from "react-router-dom";

function API(props) {
    const { getApiUrl, getCompanySpecificUrl, setPageTitle } = useContext(BaseContext);
    const { integration } = useContext(IntegrationContext);
    let { key, id } = useParams();
    const { next } = useOutletContext();
    const { setShowNext } = useOutletContext();
    const integrationInterface = useMemo(() => {
        return getIntegrationInterfaceForKey(key);
    }, [key]);

    useEffect(() => {
        setPageTitle(`Integration Setup - API Key`);
    }, []);

    useEffect(() => {
        setShowNext(false);
    }, []);

    useEffect(() => {
        if (integration && integration.auth_type === "oauth2") {
            next();
        }
    }, [])

    const updateApiKey = (data) => {
        serverPatch(getApiUrl(`/integrations/${id}`), data).then((res) => {
            if (res) {
                next();
            }
        })
    }

    return (
        <div className="d-flex flex-column gap-3 align-items-center">
            <div className="d-flex flex-row gap-3 align-items-center">
                <div>
                    <img src={ integration.icon } alt="Integration Logo" className="integration-logo" />
                </div>
                <h2>{ integration.name } API Key</h2>
            </div>
            <br/>
            <Container className="integration-flow-content">
            <Row>
                <Col md={{ span: "6", offset: "3" }}>
                    { integrationInterface.getApiInstructions(updateApiKey) }
                </Col>
            </Row>
            </Container>
        </div>
    );
}

export default API;

import '../../App.scss';
import '../../css/contract.scss';
import React, { useEffect, useState, useContext, createRef } from 'react';
import { serverFetch, serverPost, notifyEvent } from '../../helpers/server';
import { BaseContext, ContractContext, getLabelForSubscriptionLength } from '../../helpers/common';
import ContentContainer from '../../components/ContentContainer';
import SubmitButton from '../../components/SubmitButton';
import StripeSetupPaymentMethod from '../../components/StripeSetupPaymentMethod';
import PricingBreakdown from '../../components/PricingBreakdown';
import BaseForm from '../../components/BaseForm';
import { Container, Row, Col } from 'react-bootstrap';
import { useSearchParams, useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import variables from "../../App.scss";
const _ = require('lodash');

function ContractPay() {
    const navigate = useNavigate();
    const { t } = useTranslation('common');
    const { uuid } = useParams();
    const [ searchParams ] = useSearchParams();
    let redirectStatus = searchParams.get('redirect_status');
    let setupIntent = searchParams.get('setup_intent');

    const { getApiUrl, setPageTitle, getCompanySpecificUrl, company } = useContext(BaseContext);
    const { contract } = useContext(ContractContext);
    const [pricingSummary, setPricingSummary] = useState(null);
    const [productNames, setProductNames] = useState([]);
    const [customerToken, setCustomerToken] = useState(null);
    const [askForCode, setAskForCode] = useState(false);
    const [errorFields, setErrorFields] = useState(null);
    const [settings, setSettings] = useState({});
    const [loadingPricing, setLoadingPricing] = useState(true);
    const [loadingSettings, setLoadingSettings] = useState(true);
    const setupRef = createRef();

    useEffect(() => {
        setPageTitle(`Contract - ${contract.title} - Payment`);
    }, [contract]);

    useEffect(() => {
        if (!_.isNil(uuid)) {
            fetchData();
        }

        const token = localStorage.getItem(`proposal_${uuid}_customer_token`);
        setCustomerToken(token);
    }, [uuid])

    useEffect(() => {
        if (redirectStatus === "succeeded") {
            payWithSetupIntent();
        }
    }, [redirectStatus, customerToken])

    useEffect(() => {
        if (pricingSummary) {
            let subsProductNames = [];
            if (pricingSummary.subscription_details.product_breakdown) {
                subsProductNames = _.map(pricingSummary.subscription_details.product_breakdown, (pb) => pb.product.name);
            }
            let oneTimeProductNames = [];
            if (pricingSummary.one_time_details.product_breakdown) {
                oneTimeProductNames = _.map(pricingSummary.one_time_details.product_breakdown, (pb) => pb.product.name);
            }
            setProductNames(_.uniq(_.concat(subsProductNames, oneTimeProductNames)));
        } else {
            setProductNames(null);
        }
    }, [pricingSummary])

    const payWithSetupIntent = () => {
        const payData = {
            setup_intent_id: setupIntent,
            email: contract.customer.email,
            name: contract.customer.name
        }
        const payOptions = {
            accessToken: customerToken
        }
        console.log("The pay options are " + JSON.stringify(payOptions));
        serverPost(getApiUrl(`/proposals/${uuid}/pay`), payData, payOptions).then((res) => {
            if (!_.isNil(res)) {
                navigate(getCompanySpecificUrl(`/contract/${uuid}/done`));
            }
            notifyEvent("contract");
        })
    }

    const fetchData = (skipCache=false) => {
        serverFetch(getApiUrl(`/settings`)).then((res) => {
            setSettings(res);
            setLoadingSettings(false);
        })

        serverPost(getApiUrl(`/proposals/${uuid}/pricing`), { skipCache: skipCache }).then((res) => {
            setPricingSummary(res);
            setLoadingPricing(false);
        })
    }

    const onSubmit = async (data) => {
        if (setupRef.current) {
            await setupRef.current.onSubmit(data);
        }
    }

    const onVerifyError = async (res) => {
        const errorMessage = await res.json();
        if (res.status === 400) {
            if (errorMessage.code_sent) {
                setAskForCode(true);
            } else {
                setErrorFields(errorMessage);
            }
        }
    };

    const verifyEmail = (data) => {
        const verifyData = {
            email: data.email,
            code: data.code
        }
        serverPost(getApiUrl(`/proposals/${contract.id}/verify_email`), verifyData, {}, onVerifyError).then((res) => {
            if (res) {
                setCustomerToken(res.token);
                localStorage.setItem(`proposal_${uuid}_customer_token`, res.token);
                fetchData(true);
            }
        })
    }

    const needCustomerVerification = contract.options && contract.options.requires_email_verification;

    const renderFooterDetails = () => {
        return (
            <div className="payment-details">
                <div className="flex flex-row mt-4 justify-center">
                    <a href="https://www.stripe.com" target="_blank">
                        <span className="gray3 caption">Payments powered by&nbsp;</span>
                        <svg className="inline-block InlineSVG Icon powered-by-icon Icon--md" focusable="false"
                             width="33" height="15" role="img" aria-labelledby="stripe-title"><title
                            id="stripe-title">Stripe</title><g fill-rule="evenodd"><path
                            d="M32.956 7.925c0-2.313-1.12-4.138-3.261-4.138-2.15 0-3.451 1.825-3.451 4.12 0 2.719 1.535 4.092 3.74 4.092 1.075 0 1.888-.244 2.502-.587V9.605c-.614.307-1.319.497-2.213.497-.876 0-1.653-.307-1.753-1.373h4.418c0-.118.018-.588.018-.804zm-4.463-.859c0-1.02.624-1.445 1.193-1.445.55 0 1.138.424 1.138 1.445h-2.33zM22.756 3.787c-.885 0-1.454.415-1.77.704l-.118-.56H18.88v10.535l2.259-.48.009-2.556c.325.235.804.57 1.6.57 1.616 0 3.089-1.302 3.089-4.166-.01-2.62-1.5-4.047-3.08-4.047zm-.542 6.225c-.533 0-.85-.19-1.066-.425l-.009-3.352c.235-.262.56-.443 1.075-.443.822 0 1.391.922 1.391 2.105 0 1.211-.56 2.115-1.39 2.115zM18.04 2.766V.932l-2.268.479v1.843zM15.772 3.94h2.268v7.905h-2.268zM13.342 4.609l-.144-.669h-1.952v7.906h2.259V6.488c.533-.696 1.436-.57 1.716-.47V3.94c-.289-.108-1.346-.307-1.879.669zM8.825 1.98l-2.205.47-.009 7.236c0 1.337 1.003 2.322 2.34 2.322.741 0 1.283-.135 1.581-.298V9.876c-.289.117-1.716.533-1.716-.804V5.865h1.716V3.94H8.816l.009-1.96zM2.718 6.235c0-.352.289-.488.767-.488.687 0 1.554.208 2.241.578V4.202a5.958 5.958 0 0 0-2.24-.415c-1.835 0-3.054.957-3.054 2.557 0 2.493 3.433 2.096 3.433 3.17 0 .416-.361.552-.867.552-.75 0-1.708-.307-2.467-.723v2.15c.84.362 1.69.515 2.467.515 1.879 0 3.17-.93 3.17-2.548-.008-2.692-3.45-2.213-3.45-3.225z"></path></g></svg>
                    </a>
                </div>
            </div>
        )
    }

    const renderContract = () => {
        let brandingLogo = null;
        let brandColor = variables.primaryColor;
        if (settings.branding) {
            brandingLogo = settings.branding.logo_url || null;
            brandColor = settings.branding.color || variables.primaryColor;
        }

        return (
            <div className="request-pay">
            {
                !loadingSettings && !loadingPricing && _.isNil(redirectStatus) ?
                    <>
                    <div className="left-half">
                        <div className="d-flex flex-row justify-content-end">
                            <div className="plan-details">
                                <div className="d-flex flex-row gap-3 align-items-center">
                                    {
                                        brandingLogo ?
                                            <div className="brand-logo">
                                                <img alt="Brand Logo" src={brandingLogo} />
                                            </div>
                                            : <i className="fa fa-cart-shopping" style={{ fontSize: "22px", color: brandColor }}/>
                                    }
                                    <h3 className="gray4">{ company.name }</h3>
                                </div>
                                <br/>
                                <span className="body2 gray4">{ contract.title }</span>
                                {
                                    !_.isEmpty(productNames) &&
                                        <div>
                                            <span className="body1 gray4">Subscribe to { productNames.join(", ") } for { contract.term.count } { getLabelForSubscriptionLength(contract.term.frequency) }</span>
                                        </div>
                                }
                                <br/>
                                {
                                    pricingSummary &&
                                        <PricingBreakdown
                                            discountDetails={pricingSummary.discount_details}
                                            creditDetails={pricingSummary.credit_details}
                                            subscriptionDetails={pricingSummary.subscription_details}
                                            oneTimeDetails={pricingSummary.one_time_details}
                                            total={pricingSummary.total}
                                            expanded={true}
                                            simplified={true}
                                            isPreview={true} />
                                }
                            </div>
                        </div>
                    </div>
                    <div className="right-half">
                        <div className="d-flex flex-row justify-content-start">
                            <div className="payment-details">
                            {
                                _.isNil(customerToken) && needCustomerVerification &&
                                    <div className="d-flex flex-column justify-content-center align-items-center gap-3" style={{ margin: "30px 0px" }}>
                                        <Container><Row><Col md={{ span:12 }}>
                                        <p>Welcome! Before we collect your payment details. We need to quickly confirm your identity before we get there.</p>
                                        <p>We will send you a code to verify your email.</p>
                                        <BaseForm initialFormFields={contract.customer} onSubmit={verifyEmail} errorFields={errorFields}>
                                            <BaseForm.Input type="text" name="email" label={t('common.email')} disabled/>
                                            {
                                                askForCode &&
                                                    <BaseForm.Input type="text" name="code" label={t('common.code')}
                                                        description="We have sent a verification code to your email. Please enter the code here."/>
                                            }
                                            <SubmitButton>{ askForCode ? "Verify": "Send Code" }</SubmitButton>
                                        </BaseForm>
                                        </Col></Row></Container>
                                    </div>
                            }
                            {
                                (!_.isNil(customerToken) || !needCustomerVerification) &&
                                    <BaseForm onSubmit={onSubmit}>
                                        <div className="body2">
                                            Payment Information
                                        </div>
                                        <div className="payment_input">
                                        {
                                            settings.payment_config && settings.payment_config.provider === "STRIPE" &&
                                                <StripeSetupPaymentMethod ref={setupRef}
                                                    redirectUrl={window.location.href} paymentConfig={settings.payment_config}
                                                    accessToken={customerToken} setupUrl={getApiUrl(`/proposals/${uuid}/setup_intent`)} />
                                        }
                                        </div>
                                        <br/>
                                        <Row>
                                            <Col md="12" className="text-end">
                                                <SubmitButton variant="primary" className="btn-complete" color={brandColor}>Subscribe</SubmitButton>
                                            </Col>
                                        </Row>
                                    </BaseForm>
                            }
                            </div>
                        </div>
                        <div className="d-flex flex-row justify-content-start">
                            { renderFooterDetails() }
                        </div>
                    </div>
                    </>
                : <div/>
            }
            </div>
        );
    }

    return (
        <ContentContainer className="full" bodyClassName="full">
            { renderContract() }
        </ContentContainer>
    );
}

export default ContractPay;

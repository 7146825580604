import '../App.scss';
import React from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import {renderChildren, renderComponents} from '../helpers/common';
import classnames from 'classnames';
import Loader from './Loader';
const _ = require("lodash");

function Section(props) {
    const navigate = useNavigate();

    const handleClick = (action) => {
        if (action.onClick) {
            action.onClick();
        } else if (action.link) {
            navigate(action.link);
        }
    }

    return (
        <div className={props.className}>
            {
                (props.title || !_.isEmpty(props.actions)) &&
                    <div className="sm:flex sm:items-center pb-2">
                        <div className="sm:flex-auto">
                            <div className="text-lg font-semibold leading-6 text-gray-900">{ props.title }</div>
                            {
                                props.subtitle &&
                                    <div className="mt-1 text-sm text-gray-700">
                                        { props.subtitle }
                                    </div>
                            }
                        </div>
                        <div className="ml-0 mr-0 sm:ml-16 sm:mt-0 sm:flex-none">
                            {
                                props.actions &&
                                <>
                                    {
                                        _.map(props.actions, (action, i) =>
                                            <Button key={i} variant={action.variant} onClick={() => handleClick(action)} disabled={action.disabled}>
                                                {
                                                    action.icon &&
                                                    <span><i className={classnames("fa", action.icon)}/>&nbsp;</span>
                                                }
                                                { action.label }
                                            </Button>
                                        )
                                    }
                                </>
                            }
                            { props.right && renderComponents(props.right) }
                        </div>
                    </div>
            }
            <div className={classnames("content-section-content", props.contentClassName)}>
                <Loader loading={props.loading}>
                    { renderChildren(props) }
                </Loader>
            </div>
        </div>
    );
}

export default Section;

Section.defaultProps = {
    loading: false
};

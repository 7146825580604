import '../../App.scss';
import '../../css/modals.scss';
import React from 'react';
import BaseForm from '../BaseForm';
import BaseAddOrEditItemModal from './BaseAddOrEditItemModal';
import DeleteButton from '../DeleteButton';
import { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import {
    BaseContext,
    getCurrencyOptions,
    renderDescriptionForItemPricing
} from '../../helpers/common';
import { serverPost } from '../../helpers/server';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Select from "react-select/async";
import AddOrEditBillableItemPricingModal from "./AddOrEditBillableItemPricingModal";
const _ = require('lodash');

function AddOrEditProductPricingModal(props) {
    const { t } = useTranslation('common');
    const { company, getApiUrl, getCompanySpecificUrl } = useContext(BaseContext);
    const [showAddOrEditBillableItemPricingModal, setShowAddOrEditBillableItemPricingModal] = useState(false);
    const [billableItems, setBillableItems] = useState([]);
    const [billableMetrics, setBillableMetrics] = useState([]);
    const [originalBillableItemPricings, setOriginalBillableItemPricings] = useState([]);
    const [billableItemPricings, setBillableItemPricings] = useState([]);
    const [loadingItemPricings, setLoadingItemPricings] = useState(true);
    const [onetimeItem, setOnetimeItem] = useState(null);
    const [onetimeMetric, setOnetimeMetric] = useState(null);
    const [licenseMetric, setLicenseMetric] = useState(null);
    const [isEditing, setIsEditing] = useState(false);

    const [recurring, setRecurring] = useState("SUBS_PRICING");
    const [selectedMetricPricings, setSelectedMetricPricings] = useState([]);
    const [metricTypes, setMetricTypes] = useState({});
    const [error, setError] = useState(null);

    useEffect(() => {
        setBillableItems(props.billableItems);
    }, [props.billableItems]);

    useEffect(() => {
        if (props.show) {
            serverPost(getApiUrl("/billable/items/find"), { companyId: company.id }).then((res) => {
                if (res) {
                    setOnetimeItem(_.find(res, (r) => r.type === "ONETIME_ITEM"));
                    setBillableItems(res);
                }
            });

            serverPost(getApiUrl("/billable/metrics/find"), { companyId: company.id }).then((res) => {
                if (res) {
                    setOnetimeMetric(_.find(res, (r) => r.type === "ONETIME_METRIC"))
                    setLicenseMetric(_.find(res, (r) => r.type === "LICENSE_METRIC"))
                    setBillableMetrics(_.filter(res, (r) => !r.standard));
                }
            });

            serverPost(getApiUrl("/item_pricings/find"), { companyId: company.id }).then((res) => {
                setOriginalBillableItemPricings(res || []);
            });
        } else {
            setSelectedMetricPricings([]);
        }
    }, [props.show, company, getApiUrl])

    useEffect(() => {
        setError(null);
    }, [selectedMetricPricings]);

    useEffect(() => {
        if (!billableItems) {
            return;
        }
        _.each(originalBillableItemPricings, (ip) => {
            ip.item = _.find(billableItems, (i) => i.id === ip.item_id);
        })
        setBillableItemPricings(originalBillableItemPricings);
        if (originalBillableItemPricings.length > 0) {
            setLoadingItemPricings(false);
        }
    }, [billableItems, originalBillableItemPricings])

    useEffect(() => {
        if (_.isNil(props.isEditing)) {
            setIsEditing(!_.isNil(props.itemToEdit));
        } else {
            setIsEditing(props.isEditing);
        }
    }, [props.itemToEdit])

    const onDeleteRow = (index) => {
        setSelectedMetricPricings(prevSelectedMetricPricings => {
            const newSelectedMetricPricings = [...prevSelectedMetricPricings];
            newSelectedMetricPricings.splice(index, 1)
            return newSelectedMetricPricings;
        });
    }

    const onFieldChange = (key, value) => {
        setError(null);
        if (key.endsWith("metric_id")) {
            setMetricTypes(prevTypes => {
                const newTypes = {...prevTypes};
                newTypes[key] = value
                return newTypes;
            })
        } else if (key === "type") {
            setRecurring(value);
        }
    }

    const handleError = async (error) => {
        const message = await error.json();
        setError(message.error)
    }

    const addItem = async (itemFields) => {
        setError(null);
        itemFields.product_id = props.product.id;
        if (itemFields.type === "ONETIME_PRICING") {
            const onetimeData = {
                item_id: onetimeItem.id,
                name: itemFields.name,
                description: itemFields.description,
                frequency: "MONTH",
                base_price: {
                    value_in_cents: 0,
                    currency: itemFields.currency
                },
                type: "FIXED",
                fixed_price: { price_per_unit: parseFloat(itemFields.onetime_price) * 100 }
            }
            const onetimePricing = await serverPost(getApiUrl("/item_pricings"), onetimeData);
            if (!onetimePricing) {
                setError("Unable to create pricing.")
                return;
            }

            const productPricingData = {
                product_id: props.product.id,
                name: itemFields.name,
                description: itemFields.description,
                type: itemFields.type,
                currency: itemFields.currency,
                product_metric_pricings: [
                    { item_id: onetimeItem.id, metric_id: onetimeMetric && onetimeMetric.id, item_pricing_id: onetimePricing.id }
                ]
            }
            serverPost(getApiUrl('/product_pricings'), productPricingData, {}, handleError).then((res) => {
                if (res) {
                    props.onClose();
                }
            })
        } else {
            const currencies = _.map(selectedMetricPricings, (mp) => {
                return mp.item_pricing.base_price.currency
            })
            const uniqueCurrencies = _.uniq(currencies);
            if (uniqueCurrencies.length > 1) {
                setError("All prices should be of the same currency");
                return
            }

            itemFields.product_metric_pricings = _.map(selectedMetricPricings, (mp, i) => {
                let metric_id = null;
                if (mp.item_pricing.item.type === "LICENSE_ITEM") {
                    metric_id = licenseMetric && licenseMetric.id;
                } else if (mp.item_pricing.item.type === "ONETIME_ITEM") {
                    metric_id = onetimeMetric && onetimeMetric.id;
                } else if (_.has(itemFields.product_metric_pricings, String(i))) {
                    metric_id = itemFields.product_metric_pricings[String(i)].metric_id;
                }
                return {
                    item_id: mp.item_pricing.item_id,
                    metric_id: metric_id,
                    item_pricing_id: mp.item_pricing.id,
                    currency: mp.item_pricing.base_price.currency
                }
            })
            const allGood = _.every(itemFields.product_metric_pricings, (pmp) => !_.isNil(pmp.metric_id))
            if (!allGood) {
                setError("Please select a valid metric for the price.");
                return
            }

            itemFields.currency = itemFields.product_metric_pricings[0].currency;
            serverPost(getApiUrl('/product_pricings'), itemFields, {}, handleError).then((res) => {
                if (res) {
                    props.onClose();
                }
            })
        }
    }

    const loadItemPrices = (query, callback) => {
        let filteredItemPricings = _.filter(billableItemPricings, (bip) => {
            if (bip.state === "ARCHIVED") {
                return false;
            }
            if (recurring === "SUBS_PRICING") {
                return bip.item && bip.item.type !== "ONETIME_ITEM";
            } else {
                return bip.item && bip.item.type === "ONETIME_ITEM";
            }
        });
        filteredItemPricings.unshift({ value: "new", label: <div><span className="text-sm font-semibold">Create New Price</span></div> })
        callback(filteredItemPricings);
    }

    const pricingLabel = (pricing) => {
        if (pricing.value === "new") {
            return pricing.label
        } else {
            return renderDescriptionForItemPricing(pricing, true, _.find(billableItems, (b) => b.id === pricing.item_id))
        }
    }

    const renderProductPricing = (productPricing) => {
        return (
            <>
                {
                    _.map(productPricing.product_metric_pricings, (pmp, i) =>
                        <Row className="metric-pricing-row" key={i}>
                            <Col md="12">
                                <div className="body1">{ renderDescriptionForItemPricing(pmp.item_pricing, false, pmp.item) }</div>
                            </Col>
                        </Row>
                    )
                }
            </>
        )
    }

    const getMetricOptionsForItemPricing = (itemPricing) => {
        return _.map(_.filter(billableMetrics, (m) => m.item_id === itemPricing.item_id), (bm) => {
            return { value: bm.id, label: bm.name }
        })
    }

    const recurringOptions = [
        { value: "SUBS_PRICING", label: "Recurring" },
        { value: "ONETIME_PRICING", label: "One-time" },
    ]

    const getDescriptionForType = (type) => {
        if (type === "SUBS_PRICING") {
            return "Recurring";
        } else if (type === "ONETIME_PRICING") {
            return "One-time";
        } else {
            return type;
        }
    }

    const onModalClose = () => {
        setShowAddOrEditBillableItemPricingModal(false);
    }

    const onItemPricingCreated = (itemPricing) => {
        itemPricing.item = _.find(billableItems, (i) => i.id === itemPricing.item_id);
        setSelectedMetricPricings(prevSelectedMetricPricings => {
            const newSelectedMetricPricings = [...prevSelectedMetricPricings];
            newSelectedMetricPricings.push({
                item_pricing: itemPricing
            });
            return newSelectedMetricPricings;
        })
    }

    return (
        <>
            <BaseAddOrEditItemModal
                size="lg"
                {...props}
                show={props.show && !showAddOrEditBillableItemPricingModal}
                addItem={addItem}
                onFieldChange={onFieldChange}
                itemLabel={t('billable_product_pricing.pricing')}
                itemBaseUrl="/product_pricings"
            >
                <Row>
                    <BaseForm.Input colSpan="6" name="name" label={t('common.name')} type="text" required />
                    <BaseForm.Input colSpan="6" name="description" label={t('common.description')} type="text" />
                    <BaseForm.Input colSpan="12" name="external_name" label={"Public Name (Optional)"} type="text"
                                    description="This will appear on the invoices for your customer."/>
                    {
                        !isEditing &&
                            <BaseForm.Input colSpan="6" name="type" label={t('billable_product_pricing.type')} type="select"
                                            options={recurringOptions} showSearch={false} disabled={isEditing}/>
                    }
                </Row>
                <br/>
                <Row>
                    <Col md="12">
                        <p className="body2">Price Details</p>
                    </Col>
                </Row>
                {
                    isEditing &&
                        <>
                            <p><span className={"body1"}>Type:</span> { getDescriptionForType(props.itemToEdit.type) }</p>
                            { renderProductPricing(props.itemToEdit) }
                            <span className="body1 italic warning-color">Note: You can't edit the pricing details once created. If you don't want this pricing to be available any more, you can archive it and create a new product pricing.</span>
                        </>
                }
                {
                    !isEditing && recurring === "ONETIME_PRICING" &&
                        <Row>
                            <br/>
                            <BaseForm.Input colSpan="4" name="onetime_price" label={t('billable_item_pricing.price')} type="number" step="0.01" />
                            <BaseForm.Input colSpan="4" name="currency" label={t('common.currency')} type="select" options={getCurrencyOptions()} showSearch={false} />
                        </Row>
                }
                {
                    !isEditing && recurring === "SUBS_PRICING" && !loadingItemPricings &&
                        <>
                            {
                                _.map(selectedMetricPricings, (mp, i) =>
                                    <Row key={i} className="metric-pricing-row">
                                        <Col lg="6" className="d-flex align-self-center">
                                        <span className="body1">
                                        {
                                            renderDescriptionForItemPricing(mp.item_pricing, true, mp.item_pricing.item)
                                        }
                                        </span>
                                        </Col>
                                        <Col lg="5" className="align-self-center">
                                            {
                                                mp.item_pricing.item.type === "ONETIME_ITEM" &&
                                                <>
                                                    <span className="body2">Metric</span><br/>
                                                    <span className="body1">One Time</span>
                                                </>
                                            }
                                            {
                                                mp.item_pricing.item.type === "LICENSE_ITEM" &&
                                                <>
                                                    <span className="body2">Metric</span><br/>
                                                    <span className="body1">Number of Licenses</span>
                                                </>
                                            }
                                            {
                                                mp.item_pricing.item.type !== "ONETIME_ITEM" && mp.item_pricing.item.type !== "LICENSE_ITEM" &&
                                                <Row>
                                                    {
                                                        _.isEmpty(getMetricOptionsForItemPricing(mp.item_pricing)) ?
                                                            <span className="d-flex flex-row gap-2 align-items-center">
                                                            <i className="fa fa-triangle-exclamation warning-color fa-1-5x"/>
                                                            <span
                                                                className="body1">No metrics present for this item.&nbsp;
                                                                <Link to={getCompanySpecificUrl('/billable_metrics')}>Create one</Link>
                                                            </span>
                                                        </span>
                                                            : <BaseForm.Input
                                                                type="select"
                                                                colSpan={metricTypes[`${i}.metric_id`] === "FIXED" ? "6" : "12"}
                                                                label="Metric" name={`product_metric_pricings.${i}.metric_id`}
                                                                showSearch={false}
                                                                options={getMetricOptionsForItemPricing(mp.item_pricing)}/>
                                                    }
                                                    {
                                                        metricTypes[`product_metric_pricings.${i}.metric_id`] === "FIXED" &&
                                                        <BaseForm.Input type="number" colSpan="6" label="Quantity"
                                                                        name={`product_metric_pricings.${i}.count`}
                                                                        required/>
                                                    }
                                                </Row>
                                            }
                                        </Col>
                                        <Col lg="1"
                                             className="d-flex flex-column align-items-center justify-content-center">
                                            <DeleteButton onDelete={() => onDeleteRow(i)}/>
                                        </Col>
                                    </Row>
                                )
                            }
                            <br/>
                            <Row>
                                <Col lg="6">
                                    <div className="form-group">
                                        <span className="body2">Add a price</span>
                                        <div className="form-input">
                                            <Select
                                                menuPlacement="auto"
                                                components={{ DropdownIndicator: null }}
                                                isMulti={false}
                                                className="select-container"
                                                loadOptions={loadItemPrices}
                                                defaultOptions
                                                classNamePrefix="select2"
                                                getOptionLabel={pricingLabel}
                                                getOptionValue={(c) => c.id}
                                                value={null}
                                                placeholder="Add a price..."
                                                onChange={(value, meta) => {
                                                    if (value.value === "new") {
                                                        setShowAddOrEditBillableItemPricingModal(true);
                                                    } else {
                                                        setSelectedMetricPricings(prevSelectedMetricPricings => {
                                                            const newSelectedMetricPricings = [...prevSelectedMetricPricings];
                                                            newSelectedMetricPricings.push({
                                                                item_pricing: value
                                                            });
                                                            return newSelectedMetricPricings;
                                                        })
                                                    }
                                                }}
                                                isClearable={false}
                                                noOptionsMessage={(inputValue) => { return `Start typing...` }}
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </>
                }
                {
                    error &&
                        <p className="form-error-message">{error}</p>
                }
            </BaseAddOrEditItemModal>
            <AddOrEditBillableItemPricingModal
                show={showAddOrEditBillableItemPricingModal}
                formClassName={"ml-4"}
                onClose={onModalClose}
                billableItems={billableItems}
                onAdded={onItemPricingCreated}
                onCreateBillableItem={props.onCreateBillableItem}
            />
        </>
    );
}

export default AddOrEditProductPricingModal;

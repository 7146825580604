import '../../App.scss';
import '../../css/contract.scss';
import React, { useEffect, useState, useContext } from 'react';
import { serverFetch } from '../../helpers/server';
import {BaseContext, PaymentLinkContext} from '../../helpers/common';
import ContentContainer from '../../components/ContentContainer';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import variables from "../../App.scss";
const _ = require('lodash');

function PaymentLinkDone() {
    const { t } = useTranslation('common');
    const { uuid } = useParams();

    const { getApiUrl, setPageTitle } = useContext(BaseContext);
    const { paymentLink } = useContext(PaymentLinkContext);
    const [settings, setSettings] = useState({});

    useEffect(() => {
        setPageTitle(`Success`);
    }, [paymentLink]);

    useEffect(() => {
        localStorage.removeItem(`proposal_${uuid}_customer_token`);
        serverFetch(getApiUrl(`/settings`)).then((res) => {
            setSettings(res)
        })
    }, [])

    let brandColor = variables.primaryColor;
    if (settings.branding) {
        brandColor = settings.branding.color || variables.primaryColor;
    }
    return (
        <ContentContainer className="full" bodyClassName="content-body-full">
            <div className="contract-wrapper">
                <div className="d-flex flex-column flex-grow-1 justify-content-center align-items-center gap-3">
                    <i className="fa fa-thin fa-cart-shopping fa-4x" style={{color: brandColor}}/>
                    <h3 className="gray4">All set! Thank you for your purchase.</h3>
                </div>
            </div>
        </ContentContainer>
    );
}

export default PaymentLinkDone;

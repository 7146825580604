import $ from "jquery";
import moment from 'moment';
import React, { createContext } from 'react';
import Label from '../components/Label';
;const _ = require("lodash");


export function getStartDateForSubscription(subscription) {
    const length = subscription.term.frequency;
    if (length === "DAY" || length === "HOUR") {
        return moment(subscription.start_date).format("MMM D hh:mma");
    } else {
        return moment(subscription.start_date).format("MMM D, YYYY");
    }
}

export function getRenewalDateForSubscription(subscription) {
    const length = subscription.term.frequency;
    if (length === "DAY" || length === "HOUR") {
        return moment(subscription.renewal_date).format("MMM D hh:mma");
    } else {
        return moment(subscription.renewal_date).format("MMM D, YYYY");
    }
}

export function getSubscriptionStatus(subscription) {
    let showPauseInformation = !_.isNil(subscription.pause_config);
    let statusDetails = null;
    if (subscription.end_date) {
        let dateFormat = "MMM D";
        if (moment(subscription.end_date).year() !== moment().year()) {
            dateFormat = "MMM D, YYYY";
        }
        if (moment().isAfter(subscription.end_date)) {
            if (!_.isNil(subscription.change_config)) {
                statusDetails = <Label.Info>Changed</Label.Info>
                showPauseInformation = false;
            } else {
                statusDetails = <Label.Info>Cancelled</Label.Info>
                showPauseInformation = false;
            }
        } else if (subscription.trial) {
            statusDetails = <Label.Neutral>Trials till {moment(subscription.end_date).format(dateFormat)}</Label.Neutral>
        } else if (!_.isNil(subscription.change_config)) {
            statusDetails = <Label.Warning>Changes on {moment(subscription.end_date).format(dateFormat)}</Label.Warning>
        } else {
            statusDetails = <Label.Danger><i className="fa fa-clock"/>Cancels on { moment(subscription.end_date).format(dateFormat) }</Label.Danger>
        }
    } else if (subscription.status === "UNPAID") {
        statusDetails = <Label.Danger>Unpaid</Label.Danger>
    } else if (subscription.status === "ACTIVE") {
        statusDetails = <Label.Success>Active</Label.Success>
    } else {
        statusDetails = <Label.Info>{ subscription.status }</Label.Info>
    }
    return <div className="flex flex-row gap-1 flex-wrap">
        { statusDetails }
        {
            showPauseInformation &&
                <Label.Info>
                    <i className="fa fa-pause"/>&nbsp;Paused
                </Label.Info>
        }
    </div>
}

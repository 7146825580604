import '../../App.scss';
import React, { useEffect, useState, useContext, useCallback } from 'react';
import { serverPost } from '../../helpers/server';
import { BaseContext } from '../../helpers/common';
import BaseContainer from '../../components/BaseContainer';
import ContentContainer from '../../components/ContentContainer';
import PaymentListSection from '../../components/PaymentListSection';

function Payments() {
    const { company, getApiUrl, setPageTitle } = useContext(BaseContext);
    const [payments, setPayments] = useState([]);

    useEffect(() => {
        setPageTitle(`Payments`);
    }, []);

    return (
        <BaseContainer>
            <ContentContainer>
                <PaymentListSection includeCustomer={true} showTableActions={true}/>
            </ContentContainer>
        </BaseContainer>
    );
}

export default Payments;

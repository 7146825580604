import '../App.scss';
import React, {useState, useEffect, useContext, forwardRef, useImperativeHandle, useMemo} from 'react';
import { BaseContext, UserContext, currencyFormatFromPrice, getLabelForSubscriptionLength } from '../helpers/common';
import { getStartDateForSubscription, getRenewalDateForSubscription, getSubscriptionStatus } from '../helpers/subscriptions';
import { serverPost } from '../helpers/server';
import { useNavigate } from 'react-router-dom';
import Columns from './Columns';
import MapleTable from './MapleTable';
import InfiniteScroll from "react-infinite-scroll-component";
import { useTranslation } from 'react-i18next';
import Section from './Section';
import moment from 'moment';
import MapleTableHeaderWithActions from "./MapleTableHeaderWithActions";
import SortableTableHeader from "./SortableTableHeader";
import { RectangleGroupIcon} from "@heroicons/react/20/solid";
import EmptyState from "./EmptyState";
const _ = require('lodash');

const SubscriptionListSection = forwardRef((props, ref)  => {
    useImperativeHandle(ref, () => ({
        refresh() {
            onSearch(true);
        },
    }));

    const { t } = useTranslation('common')
    const navigate = useNavigate();
    const { userInfo } = useContext(UserContext);
    const { company, getApiUrl, getCompanySpecificUrl, hasAccess } = useContext(BaseContext);
    const [includeCustomer, setIncludeCustomer] = useState(true);
    const [showTableActions, setShowTableActions] = useState(_.isNil(props.showTableActions) ? true: props.showTableActions);
    const [loading, setLoading] = useState(true);
    const [filters, setFilters] = useState([]);
    const [hideTableIfEmpty, setHideTableIfEmpty] = useState(false);

    const [subscriptions, setSubscriptions] = useState([]);
    const [sort, setSort] = useState("createdAtDesc");
    const [hasMore, setHasMore] = useState(true);
    const [fromKey, setFromKey] = useState(null);
    const [meta, setMeta] = useState({});

    const defaultSelectedFilters = useMemo(() => {
        return showTableActions ? {"statuses":["ACTIVE"],"auto_charges":null}: {}
    }, [showTableActions]);
    const cachedSelectedFilters = useMemo(() => {
        const cachedString = localStorage.getItem(company.id + "_filter_cache_subscriptions");
        if (cachedString && showTableActions) {
            return JSON.parse(cachedString);
        } else {
            return null;
        }
    }, [showTableActions])
    const [selectedFilters, setSelectedFilters] = useState(cachedSelectedFilters || defaultSelectedFilters);

    const hasReadPermissions = hasAccess("subscriptions", userInfo, "read");
    const hasWritePermissions = hasAccess("subscriptions", userInfo, "write") && (!props.customer || !props.customer.managed_externally);

    useEffect(() => {
        if (!_.isNil(props.includeCustomer)) {
            setIncludeCustomer(props.includeCustomer);
        }
    }, [props.includeCustomer])

    useEffect(() => {
        if (!_.isNil(props.showTableActions)) {
            setShowTableActions(props.showTableActions);
        }
    }, [props.showTableActions])

    useEffect(() => {
        if (!_.isNil(props.hideTableIfEmpty)) {
            setHideTableIfEmpty(props.hideTableIfEmpty);
        }
    }, [props.hideTableIfEmpty])

    useEffect(() => {
        const paymentMechanismOptions = [
            { value: null, label: "All" },
            { value: true, label: "Automatic" },
            { value: false, label: "Manual invoice" },
        ]

        const statusOptions = [
            { value: "ACTIVE", label: "Active" },
            { value: "CANCELLED", label: "Cancelled" },
            { value: "UNPAID", label: "Unpaid" }
        ]

        setFilters([
            { title: "Status", type: "multi-select", name: "statuses", options: statusOptions},
            { title: "Payment Method", type: "select", name: "auto_charges", options: paymentMechanismOptions }
        ])
    }, [])

    const onSearch = (restart = true) => {
        const limit = 50;
        const params = {
            company_id: company.id,
            sort_key: sort || "createdAtDesc",
            pagination: {
                from_key: restart ? null: fromKey,
                limit: limit
            },
            query: {
                ...props.extraQueryFilters,
                ...selectedFilters,
                auto_charges: !_.isNil(selectedFilters.auto_charges) ? selectedFilters.auto_charges === "true": null,
            },
            include_meta: restart
        }
        serverPost(getApiUrl("/subscriptions/find"), params).then((res) => {
            setLoading(false);
            if (res) {
                const results = res.results || [];
                if (restart) {
                    setSubscriptions(results);
                    setMeta(res.meta);
                } else {
                    setSubscriptions(_.concat(subscriptions, results));
                }
                setFromKey(res.pagination.from_key);
                setHasMore(results.length === limit);
            }
        });
    };

    useEffect(() => {
        onSearch(true);
    }, [sort, selectedFilters]);

    const onParamsChange = (params) => {
        if (!_.isEqual({...selectedFilters, sort}, params)) {
            const newFilters = {...params};
            setSelectedFilters(newFilters);
            localStorage.setItem(company.id + "_filter_cache_subscriptions", JSON.stringify(newFilters));
        }
    }

    const getSubscriptionCreationUrl = () => {
        let url = getCompanySpecificUrl("/subscription/create");
        if (props.customer) {
            let urlParser = new URL(window.location.origin + url);
            urlParser.searchParams.set('customer', props.customer.id);
            url = urlParser.pathname + urlParser.search;
        }
        return url;
    }

    if (!hasReadPermissions) {
        return null;
    }

    const showEmptyState = subscriptions.length === 0 && !hasMore && props.showEmptyState && _.isEmpty(selectedFilters);
    return (
        <InfiniteScroll
            dataLength={subscriptions.length}
            next={() => onSearch(false)}
            hasMore={hasMore}
            scrollableTarget="content-wrapper"
        >
            <Section title="Subscriptions" loading={loading} actions={hasWritePermissions && !showEmptyState && !loading && [{
                    variant: "primary",
                    icon: "fa-plus",
                    label: "Create Subscription",
                    link: getSubscriptionCreationUrl()
                }]}>
                {
                    showEmptyState ?
                        <EmptyState
                            icon={RectangleGroupIcon}
                            title={"No subscriptions."}
                            subtitle={"Get started by creating a new one."}
                            buttonLabel={"New Subscription"}
                            hasWritePermissions={hasWritePermissions}
                            onClick={() => navigate(getSubscriptionCreationUrl())}
                        />
                    : <MapleTable>
                            {
                                showTableActions &&
                                    <MapleTableHeaderWithActions
                                        showSearch={false}
                                        searchPlaceholder="Search Subscriptions"
                                        showFilters={true}
                                        filters={filters}
                                        meta={meta}
                                        defaultSelectedFilters={defaultSelectedFilters}
                                        cachedSelectedFilters={cachedSelectedFilters}
                                        onParamsChange={onParamsChange}
                                    />
                            }
                            <MapleTable.Content>
                                <thead>
                                    <tr>
                                    {
                                        includeCustomer &&
                                        <MapleTable.TH>Customer</MapleTable.TH>
                                    }
                                        <MapleTable.TH className="d-none d-md-table-cell">Term</MapleTable.TH>
                                        <SortableTableHeader
                                            onSortChange={setSort} sortKeyUp="mrrAsc" sortKeyDown="mrrDesc"
                                            currentSort={sort} innerClassName="justify-end">
                                            MRR
                                        </SortableTableHeader>
                                        <MapleTable.TH>Status</MapleTable.TH>
                                        <SortableTableHeader
                                            onSortChange={setSort} sortKeyUp="startDateAsc" sortKeyDown="startDateDesc"
                                            currentSort={sort} className="no-stretch d-none d-lg-table-cell">
                                            Since
                                        </SortableTableHeader>
                                        <MapleTable.TH className="d-none d-md-table-cell whitespace-nowrap">Renewing on</MapleTable.TH>
                                        <SortableTableHeader
                                            onSortChange={setSort} sortKeyUp="nextInvoiceAsc" sortKeyDown="nextInvoiceDesc"
                                            currentSort={sort} className="d-none d-md-table-cell">
                                            Next Invoice
                                        </SortableTableHeader>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200">
                                {
                                    _.map(subscriptions, (row, i) =>
                                         <tr key={i} className="cursor-pointer" onClick={() => navigate(getCompanySpecificUrl(`/subscription/${row.id}`))}>
                                        {
                                            includeCustomer &&
                                                <td><Columns.CustomerName customer={row.customer} /></td>
                                        }
                                            <td className="d-none d-md-table-cell">{ row.term.count} { getLabelForSubscriptionLength(row.term.frequency) }(s)</td>
                                            <td className="text-end">{ currencyFormatFromPrice(row.mrr) }</td>
                                            <td>{ getSubscriptionStatus(row) }</td>
                                            <td className="d-none d-lg-table-cell">{ getStartDateForSubscription(row) }</td>
                                            <td className="d-none d-md-table-cell">
                                            {
                                                !row.end_date ?
                                                    getRenewalDateForSubscription(row)
                                                : <span></span>
                                            }
                                            </td>
                                            <td className="d-none d-md-table-cell">
                                            {
                                                row.next_invoice_date &&
                                                    <span>{ moment(row.next_invoice_date).format("MMM D, YYYY hh:mma") }</span>
                                            }
                                            </td>
                                         </tr>
                                    )
                                }
                                {
                                    hasMore &&
                                        <tr>
                                            <td colSpan={10} className="text-center">
                                                <div className="spinner-border text-secondary"/>
                                            </td>
                                        </tr>
                                }
                                </tbody>
                            </MapleTable.Content>
                        </MapleTable>
                }
            </Section>
        </InfiniteScroll>
    );
})

export default SubscriptionListSection;

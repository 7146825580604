import '../App.scss';
import React, { useEffect, useContext } from 'react';
import { UserContext } from "../helpers/common";
import { Outlet, useNavigate } from "react-router-dom";
const _ = require('lodash');

function RequireAuthorization(props) {
    const { userInfo, isLoggedIn } = useContext(UserContext);
    const navigate = useNavigate();
    const authorized = isLoggedIn && !_.isEmpty(userInfo);

    useEffect(() => {
        if (!authorized) {
            navigate('/');
        }
    }, [authorized]);

    return (
        <>
        {
            authorized && <Outlet />
        }
        </>
    );
}

export default RequireAuthorization;

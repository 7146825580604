import '../App.scss';
import React, {useState, useEffect, useContext, forwardRef, useImperativeHandle, useMemo} from 'react';
import {
    BaseContext,
    UserContext,
    currencyFormatFromPrice,
    getCurrencyOptions,
    getNameForProviderType
} from '../helpers/common';
import { serverPost } from '../helpers/server';
import { useNavigate, Link } from 'react-router-dom';
import Columns from './Columns';
import MapleTable from './MapleTable';
import InfiniteScroll from "react-infinite-scroll-component";
import { useTranslation } from 'react-i18next';
import Section from './Section';
import Label from './Label';
import moment from 'moment';
import MapleTableHeaderWithActions from "./MapleTableHeaderWithActions";
import SortableTableHeader from "./SortableTableHeader";
import BaseOverlayTrigger from "./BaseOverlayTrigger";
const _ = require('lodash');

const InvoiceListSection = forwardRef((props, ref)  => {
    useImperativeHandle(ref, () => ({
        refresh() {
            onSearch(true);
        },
    }));

    const { t } = useTranslation('common')
    const navigate = useNavigate();
    const { userInfo } = useContext(UserContext);
    const { company, getApiUrl, getCompanySpecificUrl, hasAccess } = useContext(BaseContext);
    const [invoices, setInvoices] = useState([]);
    const [includeCustomer, setIncludeCustomer] = useState(true);
    const [hideTableIfEmpty, setHideTableIfEmpty] = useState(false);
    const [showTableActions, setShowTableActions] = useState(_.isNil(props.showTableActions) ? true: props.showTableActions);
    const [showStatusImageColumn, setShowStatusImageColumn] = useState(false);
    const [loading, setLoading] = useState(true);

    const [sort, setSort] = useState("createdAtDesc");
    const [hasMore, setHasMore] = useState(true);
    const [fromKey, setFromKey] = useState(null);
    const [filters, setFilters] = useState([])
    const [meta, setMeta] = useState({});

    const defaultSelectedFilters = useMemo(() => {
        return {}
    }, [showTableActions]);
    const cachedSelectedFilters = useMemo(() => {
        const cachedString = localStorage.getItem(company.id + "_filter_cache_invoices");
        if (cachedString && showTableActions) {
            return JSON.parse(cachedString);
        } else {
            return null;
        }
    }, [showTableActions])
    const [selectedFilters, setSelectedFilters] = useState(cachedSelectedFilters || defaultSelectedFilters);

    const hasReadPermissions = hasAccess("invoices", userInfo, "read");

    useEffect(() => {
        if (!_.isNil(props.includeCustomer)) {
            setIncludeCustomer(props.includeCustomer);
        }
    }, [props.includeCustomer])

    useEffect(() => {
        if (!_.isNil(props.showTableActions)) {
            setShowTableActions(props.showTableActions);
        }
    }, [props.showTableActions])

    useEffect(() => {
        if (!_.isNil(props.hideTableIfEmpty)) {
            setHideTableIfEmpty(props.hideTableIfEmpty);
        }
    }, [props.hideTableIfEmpty])

    useEffect(() => {
        const paymentMechanismOptions = [
            { value: null, label: "All" },
            { value: true, label: "Automatically charge" },
            { value: false, label: "Email invoice" },
        ]

        const statusOptions = [
            { value: null, label: "All" },
            { value: "PAID", label: "Paid" },
            { value: "PENDING", label: "Pending" },
            { value: "VOID", label: "Void" },
            { value: "DRAFT", label: "Draft"},
        ]

        const currencyOptions = getCurrencyOptions();
        currencyOptions.unshift({ value: null, label: "All" })

        setFilters([
            { title: "Status", type: "select", name: "status", options: statusOptions},
            { title: "Currency", type: "select", name: "currency", options: currencyOptions },
            { title: "Payment Method", type: "select", name: "auto_charges", options: paymentMechanismOptions }
        ])
    }, [])

    const onSearch = (restart = true) => {
        const limit = 50;
        const params = {
            company_id: company.id,
            sort_key: sort || "createdAtDesc",
            pagination: {
                from_key: restart ? null: fromKey,
                limit: limit
            },
            query: {
                ...props.extraQueryFilters,
                ...selectedFilters
            },
            include_meta: restart
        }
        serverPost(getApiUrl("/invoices/find"), params).then((res) => {
            setLoading(false);
            if (res) {
                const results = res.results || [];
                if (restart) {
                    setInvoices(processResults(results));
                    setMeta(res.meta);
                } else {
                    setInvoices(_.concat(invoices, processResults(results)));
                }
                setFromKey(res.pagination.from_key);
                setHasMore(results.length === limit);
            }
        });
    };

    useEffect(() => {
        const importedInvoices = _.filter(invoices, (i) =>  !_.isNil(i.imported_from))
        setShowStatusImageColumn(!_.isEmpty(importedInvoices));
    }, [invoices])

    useEffect(() => {
        onSearch(true);
    }, [sort, selectedFilters]);

    const processResults = (results) => {
        _.each(results, (r) => {
            if (r.status === "PENDING") {
                r.isOverdue = moment(r.due_date).isBefore(moment());
            }
        })
        return results;
    }

    const renderStatusLabel = (invoice) => {
	    if (invoice.status === "DRAFT") {
	        return (<Label.Info>Draft</Label.Info>);
	    } else if (invoice.status === "PENDING") {
            if (moment(invoice.due_date).isBefore(moment())) {
                return (<Label.Danger>Overdue</Label.Danger>);
            } else {
                return (<Label.Info>Pending</Label.Info>);
            }
	    } else if (invoice.status === "PAID") {
	        return (<Label.Success>Paid</Label.Success>);
	    } else if (invoice.status === "VOID") {
	        return (<Label.Info>Void</Label.Info>);
	    }
        return (
            <Label.Info>{invoice.status}</Label.Info>
        )
    }

    const renderStatusImage = (invoice) => {
        let imageUrl = null
        if (invoice.imported_from === "HUBSPOT") {
            imageUrl = "/icons/hubspot.png"
        } else if (invoice.imported_from === "STRIPE") {
            imageUrl = "/icons/stripe.png"
        }

        const providerName = getNameForProviderType(invoice.imported_from);

        if (imageUrl) {
            return <BaseOverlayTrigger content={`Invoice has been imported from ${providerName}`}>
                <div className="status-image">
                    <img src={imageUrl} />
                </div>
            </BaseOverlayTrigger>
        }
    }

    const onParamsChange = (params) => {
        setSelectedFilters(params);
        localStorage.setItem(company.id + "_filter_cache_invoices", JSON.stringify(params));
    }

    if (!hasReadPermissions) {
        return null;
    }

    return (
        <InfiniteScroll
            dataLength={invoices.length}
            next={() => onSearch(false)}
            hasMore={hasMore}
            scrollableTarget="content-wrapper"
        >
            <Section title={t('common.invoices')} loading={loading} actions={props.actions || []}>
                {
                    <MapleTable>
                        {
                            showTableActions &&
                                <MapleTableHeaderWithActions
                                    showSearch={false}
                                    searchPlaceholder="Search Invoices"
                                    showFilters={true}
                                    filters={filters}
                                    meta={meta}
                                    defaultSelectedFilter={defaultSelectedFilters}
                                    cachedSelectedFilters={cachedSelectedFilters}
                                    onParamsChange={onParamsChange}
                                />
                        }
                        <MapleTable.Content>
                            <thead>
                                <tr>
                                {
                                    includeCustomer &&
                                        <MapleTable.TH className="">Customer</MapleTable.TH>
                                }
                                    <MapleTable.TH className="text-nowrap">Number</MapleTable.TH>
                                    <SortableTableHeader
                                        onSortChange={setSort} sortKeyUp="totalAmountAsc" sortKeyDown="totalAmountDesc"
                                        currentSort={sort} innerClassName="justify-end" className="d-none d-md-table-cell">
                                        Amount
                                    </SortableTableHeader>
                                    <SortableTableHeader
                                        onSortChange={setSort} sortKeyUp="invoiceDateAsc" sortKeyDown="invoiceDateDesc"
                                        currentSort={sort} className="d-none d-md-table-cell">
                                        Invoice Date
                                    </SortableTableHeader>
                                    <MapleTable.TH>Status</MapleTable.TH>
                                    <SortableTableHeader
                                        onSortChange={setSort} sortKeyUp="dueDateAsc" sortKeyDown="dueDateDesc"
                                        currentSort={sort} className="d-none d-md-table-cell">
                                        Due
                                    </SortableTableHeader>
                                    { showStatusImageColumn && <MapleTable.TH className="d-none d-md-table-cell"></MapleTable.TH> }
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                            {
                                _.map(invoices, (row, i) =>
                                    <tr key={i} className="cursor-pointer" onClick={() => navigate(getCompanySpecificUrl(`/invoice/${row.uuid}`))}>
                                        {
                                            includeCustomer &&
                                                <td className=""><Columns.CustomerName customer={row.customer}/></td>
                                        }
                                        <td>
                                            { row.number }
                                            <span className="d-block d-md-none text-gray-500">{ currencyFormatFromPrice(row.total) }</span>
                                        </td>
                                        <td className="text-end d-none d-md-table-cell">{ currencyFormatFromPrice(row.total) }</td>
                                        <td className="d-none d-md-table-cell">{ moment(row.invoice_date).format("MMM D, YYYY h:mm:ssa") }</td>
                                        <td>
                                        { renderStatusLabel(row) }
                                        </td>
                                        <td className="d-none d-md-table-cell">
                                            <span>
                                                { moment(row.due_date).format("MMM D, YYYY h:mm:ssa") }
                                            </span>
                                            {
                                                row.isOverdue &&
                                                    <>
                                                        <span className="danger-color caption">
                                                            <br/>
                                                            Overdue by { moment().diff(moment(row.due_date), "days") } days
                                                        </span>
                                                    </>
                                            }
                                        </td>
                                        {
                                            showStatusImageColumn &&
                                                <td className="d-none d-md-table-cell">{ renderStatusImage(row) }</td>
                                        }
                                    </tr>
                                )
                            }
                            {
                                hasMore &&
                                    <tr>
                                        <td colSpan={10} className="text-center">
                                            <div className="spinner-border text-secondary"/>
                                        </td>
                                    </tr>
                            }
                            </tbody>
                        </MapleTable.Content>
                    </MapleTable>
                }
            </Section>
        </InfiniteScroll>
    );
})

export default InvoiceListSection;

import '../App.scss';
import '../css/contract.scss';
import React, { useEffect, useState, useContext } from 'react';
import { serverFetch, subscribeToEvent } from '../helpers/server';
import { BaseContext, ContractContext } from '../helpers/common';
import Loader from '../components/Loader';
import { useParams, Outlet, useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const _ = require('lodash');

function ContractComponent() {
    const { t } = useTranslation('common');
    const { uuid } = useParams();
    const outletContext = useOutletContext();

    const { getApiUrl } = useContext(BaseContext);
    const [contract, setContract] = useState(null);
    const [contractLoading, setContractLoading] = useState(true);
    const [settings, setSettings] = useState({});
    const [settingsLoading, setSettingsLoading] = useState(true);

    useEffect(() => {
        if (!_.isNil(uuid)) {
            fetchData();
        }
        subscribeToEvent("contract", (data) => { fetchData(true) });
    }, [uuid])

    const fetchData = (skipCache=false) => {
        console.log("Fetching contract " + skipCache);
        serverFetch(getApiUrl(`/proposals/${uuid}`), { suppressUnauthenticated: true, skipCache: skipCache }).then((res) => {
            setContractLoading(false);
            if (res) {
                setContract(res);
            } else {
                setContract(null);
            }
        })

        serverFetch(getApiUrl(`/settings`), { suppressUnauthenticated: true, skipCache: skipCache }).then((res) => {
            setSettingsLoading(false);
            if (res) {
                setSettings(res);
            } else {
                setSettings(null);
            }
        })
    }

    const renderNotFound = () => {
        return (
            <div className="contract-wrapper">
                <div className="d-flex flex-column flex-grow-1 justify-content-center align-items-center gap-3">
                    <i className="fa fa-thin fa-file-contract danger-color fa-8x"/>
                    <h3 className="gray4">Contract not found</h3>
                    <div className="gray3 body1 text-center" style={{ maxWidth: "400px" }}>Oops, we can't find the contract you are looking for. Please reach out to the person who send you the contract.</div>
                </div>
            </div>
        )
    }

    const renderDeclined = () => {
        return (
            <div className="contract-wrapper">
                <div className="d-flex flex-column flex-grow-1 justify-content-center align-items-center gap-3">
                    <i className="fa fa-thin fa-file-contract danger-color fa-8x"/>
                    <h3 className="gray4">Contract Declined</h3>
                    <div className="gray3 body1 text-center" style={{ maxWidth: "400px" }}>Oops, this contract is not
                        active anymore. Please reach out to the person who send you the contract.</div>
                </div>
            </div>
        )
    }

    return (
        <ContractContext.Provider value={{
            contract,
            settings
        }}>
            <Loader loading={contractLoading || settingsLoading}>
                {
                    () => {
                        if (_.isNil(contract)) {
                            return renderNotFound();
                        } else if (contract.status === "DECLINED") {
                            return renderDeclined();
                        } else {
                            return (<Outlet context={outletContext} />);
                        }
                    }
                }
            </Loader>
        </ContractContext.Provider>
    )
}

export default ContractComponent;

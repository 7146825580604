import { clearUserInfo } from './storage';
const _ = require("lodash");

var cache = require('js-cache');
var requestCache = new cache();
var requestQueue = {};
const logInfo = false;

export function handleCategoryChange() {
    requestCache.clear();
}

function getSubpathFromUrl(url) {
    let urlParser = new URL(url);
    let pathname = urlParser.pathname;
    const [emp, companies, companyId, ...rest] = pathname.split('/');
    return rest ? `/${rest.join("/")}` : rest;
}

export function getUrl(subUrl, params=null) {
    const baseServerUrl = process.env.REACT_APP_BASE_SERVER_URL || "http://localhost";
    let url = baseServerUrl + subUrl;
    if (params) {
        let urlParser = new URL(url);
        _.each(params, (value, key) => {
            if (Array.isArray(value)) {
                _.each(value, (v) => {
                    urlParser.searchParams.append(`${key}[]`, v);
                })
            } else {
                urlParser.searchParams.set(key, value);
            }
            url = urlParser.toString();
        })
    }
    return url;
}

export function baseGetApiUrl(companyId, subUrl, params=null) {
    return getUrl(`/companies/${companyId}${subUrl}`, params);
}

export async function serverFetch(url, options = {}, onError = null) {
    logInfo && console.log("Fetching " + url + ", " + JSON.stringify(options));

    options = options || {};
    if (options.skipCache !== true) {
        const cachedValue = requestCache.get(url);
        if (cachedValue) {
            logInfo && console.log("Returning cached value");
            return Promise.resolve(cachedValue);
        }
    }

    if (!_.isNil(options.accessToken)) {
        options['headers'] = options['headers'] || {};
        options['headers']['Authorization'] = 'Bearer ' + options.accessToken;
    } else if (localStorage.getItem("auth_token") != null) {
        options['headers'] = options['headers'] || {};
        options['headers']['Authorization'] = 'Bearer ' + localStorage.getItem("auth_token");
    }
    const random = Math.floor(Math.random() * 1000);
    const queueKey = url + JSON.stringify(options);
    logInfo && console.log(random + "The queue key is " + url);
    let resultPromise = null;
    if (queueKey in requestQueue) {
        logInfo && console.log(random + "Already in queue. Waiting");
        resultPromise = requestQueue[queueKey];
    } else {
        logInfo && console.log(random + "Not in queue. Creating item");
        resultPromise = fetch(url, options)
            .then(async (res) => {
                if (res.ok) {
                    if (!options.noJson) {
                        return res.json();
                    } else {
                        return res;
                    }
                } else {
                    throw res;
                }
            })
            .catch(async (res) => {
                if (onError) {
                    onError(res);
                }
                if (!options.suppressUnauthenticated && res.status === 401) {
                    console.log("Seems to be logged out. Go to the main page.");
                    // Unauthenticated. Redirect to main page.
                    clearUserInfo();
                    window.location = `/`;
                }
            });
        requestQueue[queueKey] = resultPromise;
    }
    const result = await resultPromise;
    delete requestQueue[queueKey];
    logInfo && console.log(random + "Resolved");
    if (result && !result.error) {
        logInfo && console.log("Storing in cache");
        requestCache.set(url, result, 60000);
    }

    logInfo && console.log("Done Fetching " + url);
    return result;
}

export async function serverPost(url, data, options = {}, onError = null) {
    return internalServerPost("POST", url, data, options, onError);
}

export async function serverPatch(url, data, options = {}, onError = null) {
    const result = internalServerPost("PATCH", url, data, options, onError);
    let subPath = getSubpathFromUrl(url);
//    let subPath = url;
//    console.log("The sub path is " + subPath);
    if (subPath === "/settings" || subPath === "") {
        notifyEvent('settings');
    }

    return result;
}

export async function serverPut(url, data, options = {}, onError = null) {
    options = options || {};
    options['method'] = "PUT";
    options['body'] = data;
    return await fetch(url, options)
        .then(async (res) => {
            if (res.ok) {
                return res.blob();
            } else {
                throw res;
            }
        })
}

export async function serverDelete(url) {
    return internalServerPost("DELETE", url, null, {}, null);
}

export async function internalServerPost(method, url, data, options = {}, onError = null) {
    options = options || {};
    options['method'] = method;
    options['headers'] = options['headers'] || {};
    options['headers']['Content-Type'] = 'application/json'
    if (!_.isNil(options.accessToken)) {
        options['headers']['Authorization'] = 'Bearer ' + options.accessToken;
    } else if (localStorage.getItem("auth_token") != null) {
        options['headers']['Authorization'] = 'Bearer ' + localStorage.getItem("auth_token");
    }
    if (data !== null) {
        options['body'] = JSON.stringify(data);
    }
    const postResult = await fetch(url, options)
        .then(async (res) => {
            if (res.ok) {
                if (!options.noJson) {
                    return res.json();
                } else {
                    return res.blob();
                }
            } else {
                throw res;
            }
        })
        .catch(async (res) => {
            if (onError) {
                onError(res);
            }
            if (!options.suppressUnauthenticated && res.status === 401) {
                // Unauthenticated. Redirect to main page.
                clearUserInfo();
                const redirectTo = `/`
                if (window.location.pathname !== redirectTo) {
//                    window.location = redirectTo;
                }
            }
        });
    requestCache.del(url);
    return postResult;
}

var subscriptions = new cache();
export function subscribeToEvent(event, callback) {
    let listeners = subscriptions.get(event);
    if (!listeners) {
        listeners = [callback];
    } else {
        listeners.push(callback);
    }
    subscriptions.set(event, listeners);
}

export function notifyEvent(event, data) {
    const listeners = subscriptions.get(event);
    if (listeners) {
        _.each(listeners, (l) => {
            l(data);
        })
    }
}

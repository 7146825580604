import '../App.scss';
import React, { useState, useEffect, useContext, forwardRef, useImperativeHandle, useMemo } from 'react';
import BaseForm from './BaseForm';
import DeleteButton from './DeleteButton';
import { serverPost } from '../helpers/server';
import { getCurrencyOptions, BaseContext } from '../helpers/common';
import { Row, Table, Button, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const _ = require('lodash');

const ItemPricingFields = forwardRef((props, ref)  => {
    useImperativeHandle(ref, () => ({
        processFields(data) {
            return processFields(data);
        },
        onFieldChange(name, value) {
            onFieldChange(name, value);
        },
        isDisabled() {
            return _.isEmpty(billableItems) && baseType === "no";
        },
    }));

    const { t } = useTranslation('common');
    const { getApiUrl } = useContext(BaseContext);

    const [baseType, setBaseType] = useState("yes");
    const [type, setType] = useState(null);
    const [licenseItem, setLicenseItem] = useState(null);
    const [billableItems, setBillableItems] = useState([]);
    const [numberOfGradientRows, setNumberOfGradientRows] = useState(2);

    const defaultFields = useMemo(() => {
        return {
            frequency: "MONTH",
            currency: "USD",
            base_units: 0,
            base_price: 0,
            fixed_price: {
                price_per_unit: 0
            },
            step_price: {
                price_per_step: 0,
                step_size: 1
            },
            gradient_price: [
                { start: 0, end: 2, price_per_unit: 0, flat_price: 0 },
                { start: 3, end: 2147483648, price_per_unit: 0, flat_price: 0 },
            ]
        }
    }, []);

    useEffect(() => {
        if (_.isNil(props.itemToEdit)) {
            props.setInitialFields({...defaultFields, ...props.initialFormFields});
        } else {
            const item = {...props.itemToEdit};
            const isBaseType = item.base_price.value_in_cents === 0 && item.type === "FIXED";
            setBaseType(isBaseType ? "yes": "no");
            const initialData = item;
            initialData.company = null;
            initialData.base_type = isBaseType ? "yes": "no";
            if (isBaseType) {
                initialData.base_price = parseFloat(item.fixed_price.price_per_unit) / 100;
            } else {
                initialData.base_price = parseFloat(initialData.base_price.value_in_cents) / 100;
                if (item.type === "FIXED") {
                    initialData.fixed_price.price_per_unit = parseFloat(initialData.fixed_price.price_per_unit) / 100;
                } else if (item.type === "STEP") {
                    initialData.step_price.step_size = parseFloat(initialData.step_price.step_size);
                    initialData.step_price.price_per_step = parseFloat(initialData.step_price.price_per_step) / 100;
                }
            }
            props.setInitialFields(initialData)
        }
    }, [props.itemToEdit]);

    useEffect(() => {
        serverPost(getApiUrl("/billable/items/find")).then((res) => {
            setBillableItems(_.filter(res, (r) => !r.standard));
            setLicenseItem(_.find(res, (r) => r.type === "LICENSE_ITEM" && r.standard));
        });
    }, []);

    const periodOptions = [
        { value: "DAY", label: "Daily" },
        { value: "WEEK", label: "Weekly" },
        { value: "MONTH", label: "Monthly" },
        { value: "QUARTER", label: "Quarterly" },
        { value: "YEAR", label: "Yearly" }
    ]

    const baseTypeOptions = [
        { value: "yes", label: "Standard Pricing" },
        { value: "no", label: "Variable Pricing" },
    ]

    const typeOptions = [
        { value: "FIXED", label: "Fixed" },
        { value: "STEP", label: "Step" },
        { value: "GRADIENT", label: "Gradient" },
    ]
//        { value: "CUSTOM", label: "Custom" },

    const billableItemOptions = _.map(billableItems, (bi) => {
        return { value: bi.id, label: bi.name }
    })
    if (licenseItem) {
        billableItemOptions.unshift({ value: licenseItem.id, label: "License" })
    }

    const onCreateBillableItem = (event) => {
        event.preventDefault();
        if (props.onCreateBillableItem) {
            props.onCreateBillableItem();
        }
    }

    const onAddGradientRow = () => {
        const oldCount = numberOfGradientRows;
        setNumberOfGradientRows(numberOfGradientRows + 1);
        props.setInitialFields(prevFields => {
            const newFields = {...prevFields}
//            newFields.gradient_price = [...prevFields.gradient_price]
            newFields.gradient_price.splice(numberOfGradientRows - 1, 0, { start: 3, end: 100, price_per_unit: 0, flat_price: 0 })
            newFields.gradient_price[oldCount - 1].start = newFields.gradient_price[oldCount].start
            newFields.gradient_price[oldCount - 1].end = parseInt(newFields.gradient_price[oldCount].start) + 2
            newFields.gradient_price[oldCount].start = parseInt(newFields.gradient_price[oldCount - 1].end) + 1
            return newFields;
        });
    }

    const onDeleteGradientRow = (i) => {
        setNumberOfGradientRows(numberOfGradientRows - 1);
        props.setInitialFields(prevFields => {
            const newFields = {...prevFields}
//            newFields.gradient_price = [...prevFields.gradient_price]
            newFields.gradient_price[i+1].start = newFields.gradient_price[i].start
            newFields.gradient_price.splice(i, 1)
            return newFields;
        });
    }

    const onFieldChange = (key, value) => {
        if (key === "type") {
            setType(value);
        } else if (key === "name") {
            props.setInitialFields(prevFields => {
                prevFields.name = value;
                return prevFields;
            });
        } else if (key === "base_type") {
            setBaseType(value);
        } else if (key.endsWith("end")) {
            const index = parseInt(key.split(".")[1])
            props.setInitialFields(prevFields => {
                const newFields = {...prevFields}
                newFields.gradient_price = [...prevFields.gradient_price]
                newFields.gradient_price[index + 1].start = parseInt(value) + 1;
                return newFields;
            });
        } else if (key.endsWith("start")) {
            const index = parseInt(key.split(".")[1])
            props.setInitialFields(prevFields => {
                const newFields = {...prevFields}
                newFields.gradient_price = [...prevFields.gradient_price]
                newFields.gradient_price[index - 1].end = parseInt(value) - 1;
                return newFields;
            });
        }
    }

    const processFields = (fields) => {
        const newFields = {...fields};
        const basePrice = newFields.base_price
        if (fields.base_type === "yes") {
            newFields.type = "FIXED";
            newFields.base_price = { value_in_cents: 0, currency: newFields.currency };
            newFields.base_units = newFields.base_units ? parseInt(newFields.base_units) : 0;
            newFields.item_id = licenseItem && licenseItem.id;
            newFields.fixed_price = { price_per_unit: parseFloat(basePrice) * 100 }
        } else {
            newFields.item_id = newFields.item_id;
            newFields.base_price = { value_in_cents: parseFloat(basePrice) * 100, currency: newFields.currency };
            newFields.base_units = newFields.base_units ? parseInt(newFields.base_units) : 0;
            if (newFields.fixed_price) {
                newFields.fixed_price.price_per_unit = parseFloat(newFields.fixed_price.price_per_unit) * 100;
            } else if (newFields.step_price) {
                newFields.step_price.step_size = parseFloat(newFields.step_price.step_size);
                newFields.step_price.price_per_step = parseFloat(newFields.step_price.price_per_step)  * 100;
            } else if (newFields.gradient_price) {
                _.each(newFields.gradient_price, (p, i) => {
                    newFields.gradient_price[i].start = newFields.gradient_price[i].start ? parseInt(newFields.gradient_price[i].start) : 0
                    newFields.gradient_price[i].end = newFields.gradient_price[i].end ? parseInt(newFields.gradient_price[i].end): 2147483648
                    newFields.gradient_price[i].price_per_unit = parseFloat(newFields.gradient_price[i].price_per_unit) * 100
                    newFields.gradient_price[i].flat_price = parseFloat(newFields.gradient_price[i].flat_price) * 100
                })

                newFields.gradient_price = _.values(newFields.gradient_price);
            }
        }
        return newFields;
    }

    return (
        <>
            <Row>
                <Col md="6">
                    <div className="body2">Billing Period</div>
                    <span className="body1">What's the billing period for this pricing?</span>
                </Col>
                <Col md="6">
                    <div className="body2">Currency</div>
                    <span className="body1">What's the billing period for this pricing?</span>
                </Col>
                <BaseForm.Input colSpan="6" name="frequency" label={t('billable_item_pricing.frequency')} type="select" options={periodOptions} showSearch={false} hideLabel />
                <BaseForm.Input colSpan="6" name="currency" label={t('common.currency')} type="select" options={getCurrencyOptions()} showSearch={false} hideLabel />
            </Row>
            <Row>
                <Col md="6">
                    <div className="body2">Pricing details</div>
                    <BaseForm.Input colSpan="12" name="base_type" label={t('billable_item_pricing.base_type')} type="select" options={baseTypeOptions} showSearch={false} hideLabel />
                </Col>
                {
                    baseType === "yes" &&
                        <BaseForm.Input colSpan="6" name="base_price" label={t('billable_item_pricing.base_price')} type="number" required />
                }
            </Row>
            {
                baseType === "no" &&
                    <>
                    {
                        _.isEmpty(billableItems) ?
                            <Row>
                                <div className="body1 error">In order to create a pricing with variable counting, we need to
                                create a billable item first. <Link onClick={onCreateBillableItem}>Get started here.</Link></div>
                            </Row>
                        : <>
                            <Row>
                                <div className="body2">Variable pricing details</div>
                                <BaseForm.Input colSpan="6" name="item_id" label={t('billable_item.item')} type="select"
                                    description="What item are you counting?"
                                    options={billableItemOptions} showSearch={false} hideLabel />
                            </Row>
                            <Row>
                                <BaseForm.Input colSpan="6" name="base_price" label={t('billable_item_pricing.base_price')}
                                    description="Base price for the plan (in dollars)."
                                    type="number" step="0.01" min="0" hideLabel required/>
                                <BaseForm.Input colSpan="6" name="base_units" label={t('billable_item_pricing.base_units')}
                                    description="Select the no. of units included in the plan."
                                    type="number" step="1" min="0" hideLabel required/>
                                <span className="body1">Select the pricing scheme to be used when calculating price based on usage.</span>
                                <BaseForm.Input colSpan="6" name="type" label={t('billable_item_pricing.pricing_type')} type="select" options={typeOptions} showSearch={false} hideLabel />
                            </Row>
                            {
                                type === "FIXED" &&
                                    <>
                                        <span className="body1">{t('billable_item_pricing.fixed_price.price_per_unit')}</span>
                                        <Row>
                                            <BaseForm.Input colSpan="6" name="fixed_price.price_per_unit" label={t('billable_item_pricing.fixed_price.price_per_unit')} type="number" validations={{ required: true, min: 0 }} step="0.01" min="0" leftContent="$" hideLabel />
                                        </Row>
                                    </>
                            }
                            {
                                type === "STEP" &&
                                    <>
                                        <span className="body1">{t('billable_item_pricing.step_price.price_per_step')}</span>
                                        <Row>
                                            <BaseForm.Input type="number" colSpan="4" name="step_price.price_per_step"
                                                label={t('billable_item_pricing.step_price.price_per_step')}
                                                validations={{ required: true, min: 0 }} step="0.01" min="0" hideLabel />
                                            <Col md="1" className="d-flex align-items-center body1">
                                                <BaseForm.Label>per</BaseForm.Label>
                                            </Col>
                                            <BaseForm.Input type="number" colSpan="3" name="step_price.step_size"
                                                label={t('billable_item_pricing.step_price.step_size')}
                                                validations={{ required: true, min: 1 }} step="1" min="1" hideLabel />
                                            <Col md="2" className="d-flex align-items-center body1">
                                                <BaseForm.Label>unit(s)</BaseForm.Label>
                                            </Col>
                                        </Row>
                                    </>
                            }
                            {
                                type === "GRADIENT" &&
                                    <>
                                        <span className="caption">Price per unit and flat price are both dollar units.</span>
                                        <Table borderless>
                                            <thead>
                                                <tr className="thin thin-horizontal">
                                                    <th></th>
                                                    <th>First</th>
                                                    <th>Last</th>
                                                    <th>Price Per Unit</th>
                                                    <th>Flat Price</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                _.map(_.range(numberOfGradientRows), (r, i) =>
                                                    <tr key={i} className="thin thin-horizontal">
                                                        <td>
                                                        {
                                                            i === 0 ?
                                                                <span>For first</span>
                                                            : (
                                                                i === numberOfGradientRows - 1 ?
                                                                    <span>For last</span>
                                                                : <span>For next</span>
                                                            )
                                                        }
                                                        </td>
                                                        <td>
                                                        {
                                                            i === 0 ?
                                                                <p>0</p>
                                                            : <BaseForm.Number name={`gradient_price.${r}.start`} step="1" disabled={i === 0}/>
                                                        }
                                                        </td>
                                                        <td>
                                                        {
                                                            i === (numberOfGradientRows - 1) ?
                                                                <p>&#8734;</p>
                                                            : <BaseForm.Input type="number" name={`gradient_price.${r}.end`} step="1" disabled={i === (numberOfGradientRows - 1)}/>
                                                        }
                                                        </td>
                                                        <td><BaseForm.NumberGroup name={`gradient_price.${r}.price_per_unit`} step="0.01"/></td>
                                                        <td><BaseForm.NumberGroup name={`gradient_price.${r}.flat_price`} step="0.01"/></td>
                                                        <td>
                                                        {
                                                            i !== (numberOfGradientRows - 1) &&
                                                                <DeleteButton size="sm" onDelete={() => onDeleteGradientRow(i)}/>
                                                        }
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                                <tr>
                                                    <td colSpan="2"><Button variant="alink" className="skinny" onClick={onAddGradientRow}><i className="fa fa-plus"/> Add New</Button></td>
                                                    <td colSpan="4"></td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </>
                            }
                        </>
                    }
                    </>
            }
        </>
    );
})

const getDefaultFields = (itemToEdit) => {

}

export default ItemPricingFields;

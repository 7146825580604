import '../../App.scss';
import '../../css/modals.scss';
import React, {useState, useEffect, useContext} from 'react';
import BaseForm from '../BaseForm';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import BaseSideModal from "./BaseSideModal";
import SubmitButton from "../SubmitButton";
import {serverPost} from "../../helpers/server";
import {BaseContext} from "../../helpers/common";
import moment from "moment/moment";

function UpdateTrialEndDateModal(props) {
    const { t } = useTranslation('common');
    const { getApiUrl } = useContext(BaseContext);
    const [subscription, setSubscription] = useState(null);

    useEffect(() => {
        setSubscription(props.subscription);
    }, [props.subscription])

    const onSubmit = async (data) => {
        const result = await serverPost(getApiUrl(`/subscriptions/${subscription.id}/extend`), data);
        if (result) {
            props.onClose(true);
        }
    }

    return (
        <BaseSideModal {...props}>
            <BaseSideModal.Form initialFormFields={subscription} onSubmit={onSubmit}>
                <BaseSideModal.Header title="Update Trial End Date"/>
                <BaseSideModal.Body>
                    <Row>
                        <BaseForm.Input colSpan="12" name="end_date" label={'End Date'} type="date" minDate={moment()}
                                        required />
                    </Row>
                </BaseSideModal.Body>
                <BaseSideModal.Footer>
                    <SubmitButton>Update</SubmitButton>
                </BaseSideModal.Footer>
            </BaseSideModal.Form>
        </BaseSideModal>
    );
}

export default UpdateTrialEndDateModal;

import '../../App.scss';
import '../../css/modals.scss';
import React from 'react';
import BaseForm from '../BaseForm';
import SubmitButton from '../SubmitButton';
import BaseSideModal from './BaseSideModal';
import { useState, useEffect, useContext, useMemo } from 'react';
import { BaseContext } from '../../helpers/common';
import { serverPost} from '../../helpers/server';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
const _ = require('lodash');

function UpdateSubscriptionMetricUsageModal(props) {
    const { t } = useTranslation('common');
    const { company, getApiUrl } = useContext(BaseContext);
    const [subscription, setSubscription] = useState(null);
    const [metric, setMetric] = useState(null);
    const [initialFields, setInitialFields] = useState({});

    useEffect(() => {
        setSubscription(props.subscription);
    }, [props.subscription]);

    useEffect(() => {
        setMetric(props.metric);
        if (props.usage) {
            setInitialFields({
                value: props.usage.value
            })
        }
    }, [props.metric, props.usage]);

    const onSubmit = (data) => {
        const key = metric.metering_rule.property || "id";
        let properties = {};
        properties[key] = data.value;
        const updateData = {
            events: [{
                company_id: subscription.company.id,
                customer_id: subscription.customer.id,
                item_id: metric.item_id,
                timestamp: moment(),
                transaction_id: String(moment().valueOf()),
                properties: properties,
            }]
        }
        serverPost(getApiUrl(`/events/ingest`), updateData).then(res => {
            // if (res) {
                props.onClose(true);
            // }
        })
    }

    if (!props.show) {
        return;
    }
    return (
        <BaseSideModal {...props}>
            <BaseSideModal.Form initialFormFields={initialFields} onSubmit={onSubmit} >
                <BaseSideModal.Header title={"Update Usage"}/>
                <BaseSideModal.Body>
                    <BaseForm.Input type="number" name="value" label="New Value"/>
                </BaseSideModal.Body>
                <BaseSideModal.Footer>
                    <SubmitButton>{t('common.update')}</SubmitButton>
                </BaseSideModal.Footer>
            </BaseSideModal.Form>
        </BaseSideModal>
    );
}

export default UpdateSubscriptionMetricUsageModal;

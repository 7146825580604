import '../../App.scss';
import '../../css/modals.scss';
import React, {useMemo} from 'react';
import BaseForm from '../BaseForm';
import BaseModal from './BaseTailwindModal';
import SubmitButton from '../SubmitButton';
import { getUrl, serverPost } from '../../helpers/server';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {getCurrencyOptions} from "../../helpers/common";

function AddOrEditCompanyModal(props) {
    const { t } = useTranslation('common');
    const initialFields = useMemo(() => {
        return {
            'preferred_currency': "USD"
        }
    }, []);

    const onSubmit = (data) => {
        data.email = data.email.toLowerCase();
        data.slug = data.slug.toLowerCase();
        serverPost(getUrl('/companies'), data).then((res) => {
            if (res) {
                props.onClose(true);
            }
        });
    }

    const countryOptions = [
        { value: "US", label: "USA" },
        { value: "CA", label: "Canada" },
    ];

    return (
        <BaseModal {...props}>
            <BaseModal.Header title={t('companies.register_company_title')} />
            <BaseForm initialFormFields={initialFields} onSubmit={onSubmit}>
                <BaseModal.Body>
                    <Row>
                        <BaseForm.Input colSpan="12" name="name" label={t('common.name')} type="text" required />
                        <BaseForm.Input colSpan="12" name="email" label={t('common.email')} type="text" validations={{ required: true, validEmail: true }} />
                        <BaseForm.Input colSpan="12" name="slug" label={t('common.slug')} type="text" validations={{ required: true, slugCheck: { getUrl: getUrl } }} />
                        <BaseForm.Input colSpan="12" name="address.country" label={t('common.country')} type="select" options={countryOptions} showSearch={false} />
                        <BaseForm.Input colSpan="12" name="preferred_currency" type="select" label={"Preferred Currency"}
                                        description={"Preferred currency will be used for metric calculations. It does not affect the currency in which the actual invoices will be processed."}
                                        options={getCurrencyOptions()}/>
                    </Row>
                </BaseModal.Body>
                <BaseModal.Footer>
                    <Row>
                        <Col md="12" className="text-end">
                            <SubmitButton variant="primary">{t('companies.register_company.register')}</SubmitButton>
                        </Col>
                    </Row>
                </BaseModal.Footer>
            </BaseForm>
        </BaseModal>
    );

}

export default AddOrEditCompanyModal;

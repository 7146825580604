import '../../App.scss';
import '../../css/modals.scss';
import React from 'react';
import BaseForm from '../BaseForm';
import { getItemTypeOptions } from '../../helpers/common';
import BaseAddOrEditItemModal from './BaseAddOrEditItemModal';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function AddOrEditBillableItemModal(props) {
    const { t } = useTranslation('common');

    return (
        <BaseAddOrEditItemModal
            {...props}
            itemLabel={t('billable_item.item')}
            itemBaseUrl="/billable/items"
        >
            <Row>
                <BaseForm.Input colSpan="12" name="name" label={t('common.name')} type="text" required />
                <BaseForm.Input colSpan="12" name="description" label={t('common.description')} type="textarea" />
                <BaseForm.Input colSpan="12" name="type" label={t('billable_item.item_type')} type="select" options={getItemTypeOptions()} showSearch={false} />
            </Row>
        </BaseAddOrEditItemModal>
    );
}

export default AddOrEditBillableItemModal;

import '../App.scss';
import React, {useContext, useEffect, useState} from 'react';
import { serverPost } from '../helpers/server';
import { BaseContext } from '../helpers/common';
import BaseForm from './BaseForm';
import { useTranslation } from 'react-i18next';
const _ = require('lodash');

function CustomerInput(props) {
    const { t } = useTranslation('common');
    const [hasCRMIntegrations, setHasCRMIntegrations] = useState(false);
    const [allowLeads, setAllowLeads] = useState(false);
    const { company, getApiUrl } = useContext(BaseContext);

    useEffect(() => {
        serverPost(getApiUrl("/integrations/installed"), { query: {status: "ACTIVE", features_enabled: ["CRM"]} }).then((res) => {
            setHasCRMIntegrations(res.results.length > 0);
        });
    }, [])

    useEffect(() => {
        setAllowLeads(props.allowLeads && hasCRMIntegrations);
    }, [props.allowLeads, hasCRMIntegrations])

    const loadCustomers = async (query) => {
        const customerPromise = serverPost(getApiUrl('/customers/autocomplete'), { search_query: query, sort_key:"aToZ", pagination:{limit:20} });
        let leadResult = null;
        if (allowLeads) {
            const leadsPromise = serverPost(getApiUrl('/customers/autocomplete/integrations'), { search_query: query, sort_key:"aToZ", pagination:{limit:20} });
            leadResult = await leadsPromise;
        }
        const customerResult = await customerPromise;
        let results = [];
        let preexistingIdentifiers = [];
        if (customerResult && customerResult.results) {
            results = _.concat(results, customerResult.results);
            preexistingIdentifiers = _.map(results, r => r.identifier);
        }
        if (leadResult && leadResult.results) {
            const filteredResults = _.filter(leadResult.results, (r) => !_.includes(preexistingIdentifiers, `${r.integration_id}-${r.import_id}`))
            results = _.concat(results, filteredResults);
        }
        return results;
    }

    const customerLabel = (customer) => {
        let label = customer.email;
        if (customer.name) {
            if (label) {
                label = `${customer.name} (${label})`
            } else {
                label = customer.name
            }
        }
        return (
            <div className="flex flex-row gap-2 items-center">
                <div className="flex flex-col grow">
                    {
                        customer.org_name &&
                            <span className="body2" style={{ marginTop: "-4px" }}>{ customer.org_name }</span>
                    }
                    <span style={{ marginTop: customer.org_name ? "-4px": "0px" }}>{ label }</span>
                </div>
                <div className="h-4 shrink-0">
                    {
                        customer.integration_icon &&
                            <img src={ customer.integration_icon } alt="Integration Logo" className="h-4"/>
                    }
                </div>
            </div>
        )
    }

    const getOptionValue = (customer) => {
        if (customer.import_id) {
            return `${customer.integration_id}-${customer.import_id}`;
        } else {
            return customer.id;
        }
    }

    const isOptionDisabled = (option) => {
        return option.managed_externally;
    }

    return (
        <BaseForm.Input colSpan={props.colSpan || "6"} name="customer" label={t('common.customer')} type="selector"
                        loadOptions={loadCustomers} getOptionLabel={customerLabel} getOptionValue={getOptionValue}
                        defaultOptions={props.defaultCustomer ? [props.defaultCustomer]: null} required={props.required}
                        disabled={!_.isNil(props.defaultCustomer)} isOptionDisabled={isOptionDisabled} />
    )
}

export default CustomerInput;

import '../../App.scss';
import '../../css/modals.scss';
import { Modal, Alert } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { renderChildren } from '../../helpers/common';

const BaseModal = (props) => {
    const [show, setShow] = useState(false);

    const handleClose = (event) => {
        if (event) {
            event.stopPropagation()
        }
        setShow(false);
        if (props.onClose) {
            props.onClose(false);
        }
    }

    useEffect(() => {
        setShow(props.show);
    }, [props.show]);

    return (
        <Modal show={show} onHide={handleClose} size={props.size || "md"}>
        { show && renderChildren(props) }
        </Modal>
    );
}

const Header = (props) => {
    return (
        <Modal.Header closeButton>
            { props.children }
        </Modal.Header>
    );
}
BaseModal.Header = Header;

const Body = (props) => {
    return (
        <Modal.Body className={props.className}>
            <div className="notification-modal ahide noprint hide">
                <Alert variant="warning">
                    Warning message
                </Alert>
            </div>
            { props.children }
        </Modal.Body>
    );
}
BaseModal.Body = Body;

const Title = (props) => {
    return (
        <Modal.Title>
            <h4>{ props.children }</h4>
        </Modal.Title>
    );
}
BaseModal.Title = Title;

const Footer = (props) => {
    return (
        <Modal.Footer>
            { props.children }
        </Modal.Footer>
    );
}
BaseModal.Footer = Footer;

export default BaseModal;

import '../App.scss';
import React, { useState, useEffect, useContext } from 'react';
import { serverFetch, serverPost } from '../helpers/server';
import {
    BaseContext,
    getDescriptionForDiscount,
    renderDescriptionForItemPricing,
    renderProductMetric
} from '../helpers/common';
import MapleTable from "./MapleTable";
import Link from "./Link";
import UpdateSubscriptionMetricUsageModal from "./modals/UpdateSubscriptionMetricUsageModal";
import moment from 'moment';
const _ = require('lodash');

function BundlePricingTable(props) {
    const { getApiUrl, getCompanySpecificUrl } = useContext(BaseContext);
    const [showUpdateUsageModal, setShowUpdateUsageModal] = useState(false);
    const [metricToUpdateUsage, setMetricToUpdateUsage] = useState(null);
    const [usageToUpdate, setUsageToUpdate] = useState(null);
    const [productPricingIds, setProductPricingIds] = useState([]);
    const [configMap, setConfigMap] = useState({});
    const [discountMap, setDiscountMap] = useState([]);
    const [productPricingsMap, setProductPricingsMap] = useState({});
    const [oneTimeBillables, setOneTimeBillables] = useState([]);
    const [showUsage, setShowUsage] = useState(false);
    const [subscription, setSubscription] = useState(null);
    const [metricUsageMap, setMetricUsageMap] = useState({});

    useEffect(() => {
        setShowUsage(props.showUsage || false);
    }, [props.showUsage]);

    useEffect(() => {
        setSubscription(props.subscription || false);
    }, [props.subscription]);

    useEffect(() => {
        if (!_.isNil(props.product_pricing_ids)) {
            setProductPricingIds(props.product_pricing_ids);
        } else if (!_.isUndefined(props.bundlePricing)) {
            setProductPricingIds(_.map(props.bundlePricing.bundle_product_pricings, (bpp) => bpp.product_pricing_id));
        }
        setConfigMap(_.keyBy(props.configItems, 'product_metric_pricing_id'));
    }, [props.bundlePricing, props.product_pricing_ids, props.configItems])

    useEffect(() => {
        if (props.discounts && props.discounts.length > 0) {
            setDiscountMap(_.groupBy(props.discounts, 'item_pricing_id'));
        } else {
            setDiscountMap(null);
        }
    }, [props.discounts]);

    useEffect(() => {
        setOneTimeBillables(props.oneTimeBillables);
    }, [props.oneTimeBillables])

    useEffect(() => {
        if (_.isEmpty(productPricingIds) && _.isEmpty(oneTimeBillables)) {
            return;
        }
        const allProductPricingIds = [...productPricingIds,..._.map(oneTimeBillables, (otb) => otb.product_pricing_id)];
        serverPost(getApiUrl(`/product_pricings/batch`), { ids: allProductPricingIds }).then((res) => {
            if (res) {
                setProductPricingsMap(_.keyBy(res, 'id'))
            }
        });
    }, [productPricingIds, oneTimeBillables, getApiUrl]);

    useEffect(() => {
        if (!showUsage || _.isEmpty(productPricingsMap) || _.isNil(subscription) || _.isEmpty(subscription)) {
            return;
        }
        fetchMetricUsages();
    }, [productPricingsMap, showUsage])

    const fetchMetricUsages = () => {
        const metricIds = [];
        _.each(_.values(productPricingsMap), (pp) => {
            _.each(pp.product_metric_pricings, (pmp) => {
                metricIds.push(pmp.metric_id);
            });
        })
        _.each(metricIds, (mid) => {
            serverPost(getApiUrl(`/subscriptions/${subscription.id}/usage`), { metric_id: mid }).then((res) => {
                setMetricUsageMap(prevMap => {
                    const newMap = {...prevMap};
                    newMap[res.metric.id] = _.find(res.usage, (u) => moment(u.period.start_date).isBefore(moment()) && moment(u.period.end_date).isAfter(moment()));
                    return newMap;
                })
            });
        })
    }

    const updateMetricUsage = (metric, usage) => {
        setMetricToUpdateUsage(metric);
        setUsageToUpdate(usage);
        setShowUpdateUsageModal(true);
    }

    const onModalClose = (didUpdate) => {
        setShowUpdateUsageModal(false);
        if (didUpdate) {
            fetchMetricUsages();
            if (props.onUsageUpdated) {
                props.onUsageUpdated(true);
            }
        }
    }

    const renderProductPricing = (pricing, i) => {
        const oneTimeIds = _.map(oneTimeBillables, (otb) => otb.product_pricing_id);
        if (!_.includes(productPricingIds, pricing.id) && !_.includes(oneTimeIds, pricing.id)) {
            return;
        }
        return (
            <React.Fragment key={i}>
                {
                    _.map(pricing.product_metric_pricings, (pmp, j) =>
                        <tr key={j} className="">
                            <td>
                                <span className="body1">
                                    {j === 0 &&
                                        <>
                                            <span
                                                className="body2">{pricing.product.name}</span><span> - {pricing.name}</span>
                                        </>
                                    }
                                </span>
                            </td>
                            <td>{ renderProductMetric(pmp, _.has(configMap, pmp.id) ? configMap[pmp.id]: null) }</td>
                            <td>
                                <span>{ renderDescriptionForItemPricing(pmp.item_pricing, false, pmp.item) }</span>
                            </td>
                            {
                                discountMap &&
                                <td>{discountMap[pmp.item_pricing_id] ? getDescriptionForDiscount(discountMap[pmp.item_pricing_id][0], getCompanySpecificUrl, props.hideDiscountAdditionalDetails) : "-"}</td>
                            }
                            {
                                showUsage &&
                                    <td>
                                        <span>{ metricUsageMap[pmp.metric_id] && metricUsageMap[pmp.metric_id].value }</span><br/>
                                        {
                                            pmp.metric.metering_rule.aggregator === "CUSTOMER_LAST_PERIOD" &&
                                                <Link onClick={() => updateMetricUsage(pmp.metric, metricUsageMap[pmp.metric_id])}>Update</Link>
                                        }
                                    </td>
                            }
                        </tr>
                    )
                }
            </React.Fragment>
        )
    }

    return (
        <>
            <MapleTable>
                <MapleTable.Content>
                    <thead>
                        <tr>
                            <MapleTable.TH>Product</MapleTable.TH>
                            <MapleTable.TH>Metric/Quantity</MapleTable.TH>
                            <MapleTable.TH>Pricing</MapleTable.TH>
                            {
                                discountMap &&
                                <MapleTable.TH>Discount</MapleTable.TH>
                            }
                            {
                                showUsage &&
                                <MapleTable.TH>Usage</MapleTable.TH>
                            }
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                    {
                        !_.isEmpty(productPricingsMap) && _.map(productPricingsMap, (pricing, i) =>
                            renderProductPricing(pricing, i)
                        )
                    }
                    </tbody>
                </MapleTable.Content>
            </MapleTable>
            <UpdateSubscriptionMetricUsageModal
                show={showUpdateUsageModal}
                onClose={onModalClose}
                usage={usageToUpdate}
                metric={metricToUpdateUsage}
                subscription={subscription}/>
        </>
    );
}

export default BundlePricingTable;

import '../../../App.scss';
import React, { useEffect, useState, useContext, useMemo } from 'react';
import {serverPost, serverPatch, serverFetch, serverDelete} from '../../../helpers/server';
import {BaseContext, renderChildren} from '../../../helpers/common';
import BaseContainer from '../../../components/BaseContainer';
import BaseForm from '../../../components/BaseForm';
import ContentContainer from '../../../components/ContentContainer';
import ContentBox from '../../../components/ContentBox';
import Section from '../../../components/Section';
import MapleTable from '../../../components/MapleTable';
import Label from '../../../components/Label';
import { Row, Col, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import ConfirmationButton from "../../../components/ConfirmationButton";
const _ = require('lodash');

function PaymentProviders() {
    const { t } = useTranslation('common');
    const { company, getApiUrl, setPageTitle } = useContext(BaseContext);
    const [filteredPaymentProviders, setFilteredPaymentProviders] = useState([]);
    const [paymentProviders, setPaymentProviders] = useState([]);
    const [settings, setSettings] = useState({});

    useEffect(() => {
        setPageTitle(`Payment Providers`);
    }, []);

    const availablePaymentProviders = useMemo(() => {
        return [
            { name: "Stripe", description: "Stripe is a payment services provider that lets merchants accept credit and debit cards or other payments.", icon: "/icons/payment_providers/stripe.png", isAvailable: true, authorize_url: "/stripe/authorize_url" },
        ]
    }, [])

    const comingSoonPaymentProviders = useMemo(() => {
        return [
            { name: "Braintree", description: "Braintree is a global commerce tools people need to build businesses, accept payments, and enable commerce for their users.", icon: "/icons/payment_providers/braintree.png", isAvailable: false, authorize_url: "/stripe/authorize_url" },
            { name: "Bambora", description: "Worldline is a global leader in secure payments and trusted transactions.", icon: "/icons/payment_providers/bambora.png", isAvailable: false, authorize_url: "/stripe/authorize_url" },
            { name: "Moneris", description: "Moneris empowers business owners to keep doing business their way while we handle the payment processing our way. The safe way. The always-connected way.", icon: "/icons/payment_providers/moneris.png", isAvailable: false, authorize_url: "/stripe/authorize_url" },
        ]
    }, [])

    const fetchData = (skipCache=false) => {
        serverPost(getApiUrl('/payments/providers/find'), {company_id: company.id}).then((res) => {
            setPaymentProviders(res);
        })

        serverFetch(getApiUrl(`/settings`)).then((res) => {
            setSettings(res)
        })
    }

    useEffect(() => {
        fetchData();
    }, [])

    useEffect(() => {
        setFilteredPaymentProviders(_.filter(paymentProviders, (pp) => pp.live_mode === settings.payment_provider_live_mode));
    }, [settings, paymentProviders])

    const onAddPaymentProvider = (provider) => {
        serverPost(getApiUrl(provider.authorize_url), { company_id: company.id }).then((res) => {
            if (res) {
                window.location = res.url ;
            }
        });
    }

    const onFieldChange = (name, value) => {
        if (name === "payment_provider_live_mode") {
            const settingsData = {
                payment_provider_live_mode: value
            }
            serverPatch(getApiUrl("/settings"), settingsData).then((res) => {
                setSettings(res);
            })
        }
    }

    const getNameForProviderType = (type) => {
        const m = {
            "STRIPE": "Stripe",
            "BRAINTREE": "Braintree",
            "BAMBORA": "Bambora",
            "SQUARE": "Square",
            "ADYEN": "Adyen",
            "MONERIS": "Moneris",
        }
        return m[type];
    }

    const deletePaymentProvider = (provider) => {
        serverDelete(getApiUrl(`/payments/providers/${provider.id}`)).then(res => {
            fetchData(true);
        })
    }

    return (
        <BaseContainer>
            <ContentContainer>
                <Section
                    title={t('payment_providers.title')}
                    right={(
                        <BaseForm initialFormFields={settings} onFieldChange={onFieldChange}>
                            <BaseForm.Input name="payment_provider_live_mode" type="switch"
                                            label="Live Mode" value="Live Mode"
                                            formInputClassName="transparent" />
                        </BaseForm>
                    )}
                >

                    <MapleTable>
                        <MapleTable.Content>
                            <thead>
                                <tr>
                                    <MapleTable.TH>Provider</MapleTable.TH>
                                    <MapleTable.TH>Live Mode</MapleTable.TH>
                                    <MapleTable.TH>Status</MapleTable.TH>
                                    <MapleTable.TH></MapleTable.TH>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                            {
                                _.map(filteredPaymentProviders, (row, i) =>
                                     <tr key={i}>
                                         <td>
                                             <div>{ getNameForProviderType(row.type) }</div>
                                             <div className="text-gray-500">{ row.account_id }</div>
                                         </td>
                                         <td>{ String(row.live_mode) }</td>
                                         <td><Label.Success>Active</Label.Success></td>
                                         <td className="w-px whitespace-nowrap">
                                             <ConfirmationButton
                                                 size="sm" onConfirm={() => deletePaymentProvider(row)}
                                                 variant="text-danger" title="Disconnect Payment Provider?"
                                                 body={
                                                     <>
                                                         <p>Please review carefully before disconnecting the payment provider. This action can't be undone</p>
                                                         <br/>
                                                         <p>Any pending invoices, payment links and so on will not be payable through this payment provider.</p>
                                                     </>
                                                 }
                                                 confirmationText={`DELETE ${ getNameForProviderType(row.type) }`}>
                                                 <i className="fa fa-trash"/>
                                             </ConfirmationButton>
                                         </td>
                                     </tr>
                                )
                            }
                            </tbody>
                        </MapleTable.Content>
                    </MapleTable>
                </Section>
                <h5>Available payment provider integrations</h5>
                <br/>
                <Row>
                {
                    _.map(availablePaymentProviders, (provider, i) =>
                        <Col key={i} md="4">
                            <ContentBox className={classnames("", provider.isAvailable ? "": "disabled")}>
                                <ContentBox.Body className="flex flex-col gap-2 !p-3 m-1">
                                    <div className="flex flex-row gap-2 items-center">
                                        <img src={ provider.icon } alt="Provider Logo" className="h-7 rounded-md" />
                                        <span className="grow text-base font-semibold">{ provider.name }</span>
                                    </div>
                                    <div className="h-24">
                                        { provider.description }
                                    </div>
                                    <div className="flex flex-col items-end">
                                        <Button
                                            variant="primary"
                                            disabled={!provider.isAvailable || filteredPaymentProviders.length > 0}
                                            onClick={() => onAddPaymentProvider(provider)}
                                        >Connect</Button>
                                    </div>
                                </ContentBox.Body>
                            </ContentBox>
                        </Col>
                    )
                }
                </Row>
                <br/>
                <h5 className="hide1">Coming Soon</h5>
                <br/>
                <Row className="hide1">
                {
                    _.map(comingSoonPaymentProviders, (provider, i) =>
                        <Col key={i} md="4">
                            <ContentBox className={classnames("", provider.isAvailable ? "": "disabled")}>
                                <ContentBox.Body className="flex flex-col gap-2 !p-3 m-1">
                                    <div className="flex flex-row gap-2 items-center">
                                        <img src={ provider.icon } alt="Provider Logo" className="h-7 rounded-md" />
                                        <span className="grow text-base font-semibold">{ provider.name }</span>
                                    </div>
                                    <div className="h-24">
                                        { provider.description }
                                    </div>
                                    <div className="flex flex-col items-end">
                                        <Button
                                            variant="primary"
                                            disabled={!provider.isAvailable || filteredPaymentProviders.length > 0}
                                            onClick={() => onAddPaymentProvider(provider)}
                                        >Coming Soon</Button>
                                    </div>
                                </ContentBox.Body>
                            </ContentBox>
                        </Col>
                    )
                }
                </Row>
            </ContentContainer>
        </BaseContainer>
    );
}

export default PaymentProviders;

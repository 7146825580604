import '../../App.scss';
import '../../css/modals.scss';
import BaseModal from './BaseModal';
import BaseForm from '../BaseForm';
import SubmitButton from '../SubmitButton';
import CopyableComponent from '../CopyableComponent';
import Columns from '../Columns';
import { useContext, useState, useEffect } from 'react';
import { BaseContext } from '../../helpers/common';
import { serverPost } from '../../helpers/server';
import { Button, Row, Col, ButtonGroup, Alert, Table } from 'react-bootstrap';
const _ = require("lodash");
const sha1 = require('js-sha1');

function ShareContractLinkModal(props) {
    const { getApiUrl, getCompanySpecificUrl } = useContext(BaseContext);
    const [initialFields, setInitialFields] = useState({});
    const [contract, setContract] = useState({});

    useEffect(() => {
        setInitialFields({
            email: props.contract && props.contract.customer && props.contract.customer.email
        })
        setContract(props.contract)
    }, [props.contract])

    const getUrl = (signatory) => {
        const relativeUrl = getCompanySpecificUrl(`/contract/${contract.id}/${sha1(signatory.email.toLowerCase()).substring(0, 8)}/accept`);
        const baseUrl = process.env.REACT_APP_BASE_WEB_URL || "https://app.maplebilling.com";
        return `${baseUrl}${relativeUrl}`
    }

    return (
        <BaseModal size="lg" {...props}>
            <BaseModal.Header>
                <BaseModal.Title>Send Contract Links</BaseModal.Title>
            </BaseModal.Header>
            <BaseModal.Body>
                <Row>
                    <p className="body2">Individual Links</p>
                    <p className="body1">
                        Links are unique for each recipient. Make sure that the intended recipients/signers are the only ones accessing the document through their link.
                    </p>
                    <Table borderless>
                        <tbody>
                            {
                                _.map(contract.signatories, (signatory, i) =>
                                    <tr key={i}>
                                        <td className="no-stretch"><Columns.CustomerName customer={ signatory }/></td>
                                        <td>
                                            <CopyableComponent
                                                value={<a className="">{getUrl(signatory)}</a>}
                                                copyableValue={getUrl(signatory)}
                                            />
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </Table>
                </Row>
            </BaseModal.Body>
            <BaseModal.Footer>
                <div className="d-flex flex-row align-items-end">
                    <Button variant="primary" onClick={() => props.onClose(false)}>Done</Button>
                </div>
            </BaseModal.Footer>
        </BaseModal>
    );

}

export default ShareContractLinkModal;

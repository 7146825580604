import '../App.scss';
import '../css/invoice.scss';
import React, { useEffect, useState, useContext, createRef } from 'react';
import { serverFetch, serverPatch, notifyEvent } from '../helpers/server';
import { BaseContext, IntegrationContext } from '../helpers/common';
import Loader from './Loader';
import ContentBox from './ContentBox';
import BaseForm from './BaseForm';
import { Table, Button } from 'react-bootstrap';
const _ = require('lodash');

function IntegrationFieldMapComponent(props) {
    const { getApiUrl } = useContext(BaseContext);
    const { integration } = useContext(IntegrationContext);
    const formRef = createRef();

    const [isEditing, setIsEditing] = useState(false);
    const [entityFields, setEntityFields] = useState([]);
    const [loadingEntityFields, setLoadingEntityFields] = useState(true);
    const [remoteAttributes, setRemoteAttributes] = useState([]);
    const [loadingRemoteAttributes, setLoadingRemoteAttributes] = useState(true);

    const fetchData = (skipCache=false) => {
        console.log("Skipping cache " + skipCache);
        serverFetch(getApiUrl(`/integrations/${integration.id}/get_entity_fields`), { skipCache: skipCache }).then(res => {
            if (res) {
                setEntityFields(res);
            }
            setLoadingEntityFields(false);
        });

        serverFetch(getApiUrl(`/integrations/${integration.id}/get_remote_attributes`), { skipCache: skipCache }).then(res => {
            if (res) {
                setRemoteAttributes(res);
            }
            setLoadingRemoteAttributes(false);
        });
    }

    useEffect(() => {
        fetchData();
    }, [])

    const onEdit = () => {
        setIsEditing(true);
    }

    const onSave = () => {
        const formData = formRef.current.getFormData();
        serverPatch(getApiUrl(`/integrations/${integration.id}`), formData).then(res => {
            setIsEditing(false);
            notifyEvent('integration_settings');
            fetchData(true);
        });
    }

    const remoteAttributeOptions = _.map(remoteAttributes, (a) => {
        return {
            value: a.key,
            label: a.name + " (" + a.key + ")"
        };
    })
    remoteAttributeOptions.unshift({ value: null, label: "Ignore" })

    return (
        <ContentBox>
            <ContentBox.Body>
                <Loader loading={loadingEntityFields || loadingRemoteAttributes}>
                {
                    () =>
                        <div>
                            <div className="d-flex flex-row gap-3">
                                <h5>Field Mapping</h5>
                                {
                                    !_.isEmpty(remoteAttributes) &&
                                        <div className="flex-grow-1 text-end">
                                        {
                                            isEditing ?
                                                <Button variant="primary" onClick={onSave}><i className="fa fa-save"/> Save</Button>
                                            : <Button variant="text-primary" onClick={onEdit}><i className="fa fa-edit"/> Edit</Button>
                                        }
                                        </div>
                                }
                            </div>
                            {
                                _.isEmpty(remoteAttributes) ?
                                    <div>
                                        <br/>
                                        <span>In order to map the field names between Maple and { integration.name }, we need the list of fields in { integration.name }.
                                        We are currently fetching this information. Please hang tight while we complete this.</span>
                                    </div>
                                : <BaseForm ref={formRef} initialFormFields={integration}>
                                    <Table borderless>
                                        <thead>
                                            <tr>
                                                <th>Maple Field</th>
                                                <th><i className="fa fa-arrow-right" /></th>
                                                <th>{ integration.name } Field</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            _.map(entityFields, (ef, i) =>
                                                <tr className="thin" key={i}>
                                                    <td>{ ef.name }</td>
                                                    <td><i className="fa fa-arrow-right" /></td>
                                                    <td>
                                                    {
                                                        isEditing ?
                                                            <BaseForm.Input type="select" name={`field_mapping.${ef.key}`}
                                                                options={remoteAttributeOptions} showSearch={true}/>
                                                        : <span>{integration.field_mapping[ef.key]}</span>
                                                    }
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        </tbody>
                                    </Table>
                                </BaseForm>
                            }
                        </div>
                }
                </Loader>
            </ContentBox.Body>
        </ContentBox>
    );
}

export default IntegrationFieldMapComponent;

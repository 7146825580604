import '../../App.scss';
import React, { useEffect, useState, useContext, useCallback } from 'react';
import { serverPost } from '../../helpers/server';
import { BaseContext, getItemTypeDescription } from '../../helpers/common';
import { useTranslation } from 'react-i18next';
import BaseContainer from '../../components/BaseContainer';
import AddOrEditBillableItemModal from '../../components/modals/AddOrEditBillableItemModal';
import ContentContainer from '../../components/ContentContainer';
import Section from '../../components/Section';
import MapleTable from '../../components/MapleTable';
import CopyableComponent from '../../components/CopyableComponent';
import { Button } from 'react-bootstrap';
import EmptyState from "../../components/EmptyState";
const _ = require('lodash');

function BillableItems() {
    const { t } = useTranslation('common');
    const { company, getApiUrl, setPageTitle } = useContext(BaseContext);
    const [billableItems, setBillableItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [billableItemToEdit, setBillableItemToEdit] = useState(null);
    const [showAddOrEditBillableItemModal, setShowAddOrEditBillableItemModal] = useState(false);

    useEffect(() => {
        setPageTitle(`Billable Items`);
    }, []);

    const fetchData = (skipCache=false) => {
        setLoading(true);
        serverPost(getApiUrl("/billable/items/find"), { company_id: company.id }, { skipCache }).then((res) => {
            setLoading(false);
            setBillableItems(_.filter(res, (r) => !r.standard));
        });
    }

    useEffect(() => {
        fetchData();
    }, []);

    const onModalClose = (didUpdate) => {
        setShowAddOrEditBillableItemModal(false);
        if (didUpdate) {
            fetchData(true);
        }
    }

    const onAddBillableItem = () => {
        setShowAddOrEditBillableItemModal(true);
        setBillableItemToEdit(null);
    }

    const onEditBillableItem = (item) => {
        setShowAddOrEditBillableItemModal(true);
        setBillableItemToEdit(item);
    }

    return (
        <BaseContainer>
            <ContentContainer>
                <Section title="Billable Items" loading={loading}
                         subtitle={"A list of all billable items. These form the base for creating any billable metrics and prices associated with them."}
                         actions={!_.isEmpty(billableItems) &&[
                            {variant: "primary", icon: "fa-plus", label: "Create Item", onClick: onAddBillableItem}
                         ]}>

                    { !_.isEmpty(billableItems) &&
                    <MapleTable>
                        <MapleTable.Content>
                            <thead>
                                <tr>
                                    <MapleTable.TH>{ t('common.name') }</MapleTable.TH>
                                    <MapleTable.TH>{ t('billable_item.item_type') }</MapleTable.TH>
                                    <MapleTable.TH className="max-sm:hidden"></MapleTable.TH>
                                    <MapleTable.TH></MapleTable.TH>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                            {
                                _.map(billableItems, (item, i) =>
                                    <tr key={i}>
                                        <td>
                                            <div className="font-medium text-gray-700">{item.name}</div>
                                            <div className="mt-0 text-gray-500">{item.description}</div>
                                        </td>
                                        <td>
                                            { getItemTypeDescription(item.type) }
                                        </td>
                                        <td className="max-sm:hidden">
                                            <CopyableComponent value={ item.id }/>
                                        </td>
                                        <td className="text-end">
                                        {
                                            !item.standard &&
                                                <Button variant="text-primary" size="sm" onClick={() => onEditBillableItem(item)}>Edit</Button>
                                        }
                                        </td>
                                    </tr>
                                )
                            }
                            </tbody>
                        </MapleTable.Content>
                    </MapleTable>
                    }
                    {
                        _.isEmpty(billableItems) &&
                            <EmptyState
                                title={"No billable items."}
                                subtitle={"Get started by creating a new one."}
                                buttonLabel={"New Item"}
                                onClick={onAddBillableItem}
                            />
                    }
                </Section>
            </ContentContainer>
            <AddOrEditBillableItemModal show={showAddOrEditBillableItemModal} onClose={onModalClose} itemToEdit={billableItemToEdit} />
        </BaseContainer>
    );
}

export default BillableItems;

import '../../../App.scss';
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {serverPatch, serverDelete, serverPost, notifyEvent} from '../../../helpers/server';
import {BaseContext, IntegrationContext} from '../../../helpers/common';
import {getFeatureDescriptions, isFieldMappingEnabled, isSyncEnabled} from '../../../helpers/integrations';
import ContentContainer from '../../../components/ContentContainer';
import IntegrationFieldMapComponent from '../../../components/IntegrationFieldMapComponent';
import {Container, Button, Row, Col, Table} from 'react-bootstrap';
import DeleteButton from '../../../components/DeleteButton';
import ContentBox from '../../../components/ContentBox';
import Label from '../../../components/Label';
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment/moment";
import {Switch} from "@headlessui/react";
import classnames from "classnames";
const _ = require('lodash');

function Settings() {
    const { getApiUrl, getCompanySpecificUrl, setPageTitle } = useContext(BaseContext);
    const { integration, integrationInterface } = useContext(IntegrationContext);
    const [isEditing, setIsEditing] = useState(false);
    const navigate = useNavigate();

    const [sort, setSort] = useState("createdAtDesc");
    const [hasMore, setHasMore] = useState(true);
    const [fromKey, setFromKey] = useState(null);
    const [syncs, setSyncs] = useState([]);

    useEffect(() => {
        setPageTitle(`Integrations - Settings`);
    }, []);

    useEffect(() => {
        onSearch(true);
    }, []);

    const onSearch = (restart = true) => {
        if (!isSyncEnabled(integration)) {
            return;
        }
        const limit = 50;
        const params = {
            sort_key: sort,
            pagination: {
                from_key: restart ? null: fromKey,
                limit: limit
            },
            query: {}
        }
        serverPost(getApiUrl(`/integrations/${integration.id}/syncs/find`), params).then((res) => {
            if (res) {
                const results = res.results || [];
                if (restart) {
                    setSyncs(results);
                } else {
                    setSyncs(_.concat(syncs, results));
                }
                setFromKey(res.pagination.from_key);
                setHasMore(results.length === limit);
            }
        });
    };

    const removeIntegration = (data) => {
        serverDelete(getApiUrl(`/integrations/${integration.id}`)).then((res) => {
            navigate(getCompanySpecificUrl("/integrations"));
        })
    }

    const updateApiKey = (data) => {
        serverPatch(getApiUrl(`/integrations/${integration.id}`), data).then((res) => {
            if (res) {
                setIsEditing(false);
                Notification.Success("Successfully updated");
            }
        })
    }

    const renderStatus = (sync) => {
        if (sync.status === "IN_PROGRESS") {
            return (<Label.Info>In Progress</Label.Info>);
        } else if (sync.status === "SUCCESS") {
            return (<Label.Success>Succeeded</Label.Success>);
        } else if (sync.status === "FAIL") {
            return (<Label.Danger>Failed</Label.Danger>);
        }
    }

    const onFeatureEnabledChange = (name, value) => {
        let newEnabledFeatures = integration.enabled_features;
        if (value) {
            newEnabledFeatures = _.union(integration.enabled_features, [name]);
        } else {
            newEnabledFeatures = _.filter(integration.enabled_features, f => f != name);
        }
        serverPatch(getApiUrl(`/integrations/${integration.id}`), { features_enabled: newEnabledFeatures }).then(res => {
            notifyEvent('integration_settings');
        })
    }

    const featureDescriptions = getFeatureDescriptions(integration);
    return (
        <ContentContainer>
            <br/>
            {
                <ContentBox>
                    <ContentBox.Body>
                        <div className="text-base font-semibold mb-4">Features</div>
                        {
                            _.map(featureDescriptions, (feature, g) =>
                                <div key={g} className="flex flex-row items-center">
                                    <div className="flex flex-col gap-1 grow">
                                        <span className="text-gray-900 text-sm font-semibold">{ feature.title }</span>
                                        <span className="text-gray-500 text-sm">{ feature.description }</span>
                                    </div>
                                    <div>
                                        <Switch.Group as="div" className="flex items-center">
                                            <Switch
                                                checked={_.includes(integration.enabled_features, feature.key)}
                                                onChange={() => onFeatureEnabledChange(feature.key, !_.includes(integration.enabled_features, feature.key))}
                                                className={classnames(
                                                    _.includes(integration.enabled_features, feature.key) ? 'bg-indigo-600' : 'bg-gray-200',
                                                    'relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-0'
                                                )}
                                            >
                                                <span
                                                    aria-hidden="true"
                                                    className={classnames(
                                                        _.includes(integration.enabled_features, feature.key) ? 'translate-x-5' : 'translate-x-0',
                                                        'pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                                    )}
                                                />
                                            </Switch>
                                            <Switch.Label as="span" className="ml-1 text-sm">
                                                <span className="font-normal text-gray-500"></span>
                                            </Switch.Label>
                                        </Switch.Group>
                                    </div>
                                </div>
                            )
                        }
                    </ContentBox.Body>
                </ContentBox>
            }
            {
                integration.type === "api" &&
                    <ContentBox>
                        <ContentBox.Body>
                            <div className="d-flex flex-row gap-3">
                                <div className="flex-grow-1">
                                    <h5>API Authorization</h5>
                                </div>
                                <div className="flex-shrink-0 d-flex align-items-center">
                                {
                                    isEditing ?
                                        <Button variant="text-danger" onClick={() => setIsEditing(false)}>Cancel</Button>
                                    : <Button variant="text-primary" onClick={() => setIsEditing(true)}><i className="fa fa-edit" /> Edit</Button>
                                }
                                </div>
                            </div>
                            {
                                isEditing ?
                                    <Container className="integration-flow-content">
                                        <Row>
                                            <Col md="8">
                                                { integrationInterface.getApiInstructions(updateApiKey, integration) }
                                            </Col>
                                        </Row>
                                    </Container>
                                : <Container className="integration-flow-content">
                                    <Row>
                                        <Col md="8">
                                            <span className="body2">API Key</span><br/>
                                            <span className="body1">{ integration.apikey_authentication && integration.apikey_authentication.access_token }</span>
                                            <br/><br/>
                                        </Col>
                                    </Row>
                                </Container>
                            }
                        </ContentBox.Body>
                    </ContentBox>
            }
            {
                isFieldMappingEnabled(integration) && <IntegrationFieldMapComponent />
            }
            {
                isSyncEnabled(integration) &&
                    <ContentBox>
                        <ContentBox.Body>
                            <div className="d-flex flex-row gap-3 align-items-center">
                                <h5 className="flex-grow-1">Syncs</h5>
                                <Button variant="primary">Start Sync</Button>
                            </div>
                            <InfiniteScroll
                                dataLength={syncs.length}
                                next={() => onSearch(false)}
                                hasMore={hasMore}
                                scrollableTarget="content-wrapper"
                            >
                                <Table borderless>
                                    <thead>
                                    <tr>
                                        <th>Start Time</th>
                                        <th>Status</th>
                                        <th>Progress</th>
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        _.map(syncs, (sync, i) =>
                                            <tr key={i}>
                                                <td>{ moment(sync.created_at).format("MMM D, YYYY h:mm:ssa") }</td>
                                                <td>{ renderStatus(sync) }</td>
                                                <td>{ sync.progress*100 }%</td>
                                            </tr>
                                        )
                                    }
                                    </tbody>
                                </Table>
                            </InfiniteScroll>
                        </ContentBox.Body>
                    </ContentBox>
            }
            <ContentBox>
                <ContentBox.Body>
                    <div className="d-flex flex-row gap-3">
                        <div className="flex-grow-1">
                            <h5>Remove Integration</h5>
                            <br/>
                            <div className="body1">Please be careful when removing an integration as this action can not be undone. You will lose all of the settings, field mappings and syncs will stop going forward.</div>
                        </div>
                        <div className="flex-shrink-0 d-flex align-items-center">
                            <DeleteButton onDelete={removeIntegration}>Remove Integration</DeleteButton>
                        </div>
                    </div>
                </ContentBox.Body>
            </ContentBox>
        </ContentContainer>
    );
}

export default Settings;

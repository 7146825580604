import '../../App.scss';
import React, { useEffect, useRef, useState, useContext, useMemo, createRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { serverFetch, serverPost } from '../../helpers/server';
import { BaseContext, UserContext } from '../../helpers/common';
import SubmitButton from '../../components/SubmitButton';
import BaseContainer from '../../components/BaseContainer';
import ContentContainer from '../../components/ContentContainer';
import ContentBox from '../../components/ContentBox';
import BaseForm from '../../components/BaseForm';
import ProductPricingSelection from '../../components/ProductPricingSelection';
import Loader from '../../components/Loader';
import { Row, Col, Breadcrumb } from 'react-bootstrap';
import { useParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import CustomerInput from "../../components/CustomerInput";
const _ = require('lodash');

function ContractCreate() {
    const navigate = useNavigate();
    const { t } = useTranslation('common');
    const { uuid } = useParams();

    const { company, getApiUrl, setPageTitle, getCompanySpecificUrl } = useContext(BaseContext);
    const { userInfo } = useContext(UserContext);
    const [contract, setContract] = useState({});
    const [teamMembers, setTeamMembers] = useState([]);
    const [settings, setSettings] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [loading, setLoading] = useState(true);
    const [productPricings, setProductPricings] = useState([]);
    const [error, setError] = useState(null);
    const pricingsRef = createRef();
    const [sort, setSort] = useState("createdAtDesc");
    const [isEditing, setIsEditing] = useState(false);
    const [initialFields, setInitialFields] = useState({});
    const [startDateType, setStartDateType] = useState("SIGN_DATE");
    const formRef = createRef();
    const signatoryNameUpdatedRef = useRef(false);
    const signatoryEmailUpdatedRef = useRef(false);
    const titleUpdatedRef = useRef(false);
    const defaultFields = useMemo(() => {
        return {
            term: {
                frequency: "YEAR",
                count: 1
            }
        }
    }, []);

    useEffect(() => {
        setPageTitle(`Contracts`);
    }, []);

    useEffect(() => {
        serverFetch(getApiUrl("/settings")).then((res) => {
            setSettings(res);
            if (_.isNil(res.payment_config)) {
                setInitialFields(prevFields => {
                    const newFields = {...prevFields};
                    newFields.auto_charges = "false";
                    return newFields;
                });
            }
        });
    }, []);

    useEffect(() => {
        const limit = 100
        const params = {
            company_id: company.id,
            sort_key: sort,
            pagination: {
                limit: limit
            },
        }
        serverPost(getApiUrl("/product_pricings/find"), params).then((res) => {
            if (res) {
                const results = res.results || [];
                setProductPricings(results);
            }
        });

        serverPost(getApiUrl("/proposals/templates/find"), {}).then((res) => {
            setLoading(false);
            setTemplates(res);
        });

        serverFetch(getApiUrl("/users")).then((res) => {
            const nonPendingMembers = _.filter(res, (r) => !r.pending);
            setTeamMembers(nonPendingMembers);
            if (userInfo) {
                const currentUser = _.find(nonPendingMembers, (m) => String(m.user.id) === String(userInfo.id));
                if (currentUser) {
                    setInitialFields(prevFields => {
                        const newFields = {...prevFields};
                        newFields.owner = currentUser.id;
                        return newFields;
                    })
                }
            }
        });
    }, [])

    useEffect(() => {
        if (!_.isNil(uuid)) {
            serverFetch(getApiUrl(`/proposals/${uuid}`)).then((res) => {
                setContract(res);
                setInitialFields({
                    ...res,
                    ...defaultFields,
                    owner: res.owner.id || null
                });
            })
            setIsEditing(true);
        } else {
            setInitialFields(defaultFields);
            setIsEditing(false);
        }
    }, [uuid])

    const createContract = async (data) => {
        let pricingData = {};
        if (pricingsRef.current) {
            const validationResult = pricingsRef.current.validate(data);
            if (!_.isNil(validationResult)) {
                setError(validationResult)
                return;
            }

            pricingData = pricingsRef.current.getPricingSelectionFields(data);
        }
        setError(null);
        let isToday = false;
        let startDate = null;
        if (data.start_date_type === "SPECIFIC_DATE") {
            isToday = moment(0, "HH").diff(data.start_date, "days") == 0;
            startDate = isToday ? moment(): moment(data.start_date)
        }
        const contractData = {
            ...pricingData,
            company_id: data.company_id,
            status: "DRAFT",
            auto_charges: data.auto_charges === "true",
            title: data.title,
            config_items: _.map(data.config, (v, k) => { return {...v, num_licenses: parseInt(v.num_licenses || "0"), minimum_units: parseInt(v.minimum_units || "0"), product_metric_pricing_id: k} }),
            signatories: [data.signatory],
            owner_id: data.owner,
            term: {
                frequency: data.term.frequency,
                count: parseInt(data.term.count)
            },
            start_date: startDate && startDate.format(),
            expiration_date: data.expiration_date || null,
            template_id: data.template_id,
            type: "CONTRACT"
        }
        if (data.customer.import_id) {
            contractData['integration_customer'] = {
                import_id: data.customer.import_id,
                integration_id: data.customer.integration_id
            }
        } else {
            contractData['customer_id'] = data.customer.id;
        }

        const contractResult = await serverPost(getApiUrl('/proposals'), contractData)
        if (contractResult) {
            navigate(getCompanySpecificUrl(`/contract/view/${contractResult.id}`), { replace: true })
        }
    }

    const onFieldChange = (name, value) => {
        if (name === "customer") {
            if (formRef.current) {
                const formData = formRef.current.getFormData();
                let dataToUpdate = {
                    ...formData,
                    signatory: formData.signatory,
                    title: formData.title,
                    customer: value
                };
                if (!signatoryNameUpdatedRef.current) {
                    dataToUpdate.signatory.name = value.name;
                }
                if (!signatoryEmailUpdatedRef.current) {
                    dataToUpdate.signatory.email = value.email;
                }
                if (!titleUpdatedRef.current) {
                    dataToUpdate.title = `${value.org_name || value.name} - ${company.name} Contract`;
                }
                setInitialFields(prevFields => {
                    return {...prevFields, ...dataToUpdate};
                })
            }
        } else if (name === "signatory.name") {
            signatoryNameUpdatedRef.current = true;
        } else if (name === "signatory.email") {
            signatoryEmailUpdatedRef.current = true;
        } else if (name === "title") {
            titleUpdatedRef.current = true;
        } else if (name === "start_date_type") {
            setStartDateType(value);
        }
    }

    const teamMemberOptions = _.map(teamMembers, (member) => {
        return {
            value: member.id,
            label: member.user.name || member.user.email
        }
    })
    teamMemberOptions.unshift({ value: null, label: "No one" })

    const templateOptions = _.map(templates, (template) => {
        return {
            value: template.id,
            label: template.name
        }
    })

    const paymentMechanismOptions = [
        { value: "true", label: "Collect payment information and charge automatically.",
            disabled: _.isNil(settings.payment_config), disabledDescription: "* Payment Provider needs to be setup" },
        { value: "false", label: "Email invoice to customer" },
    ]

    const planLengthOptions = [
        { value: "DAY", label: "Day" },
        { value: "WEEK", label: "Week" },
        { value: "MONTH", label: "Month" },
        { value: "QUARTER", label: "Quarter" },
        { value: "YEAR", label: "Year" },
    ]

    const startDateTypeOptions = [
        { value: "SIGN_DATE", label: 'When contract is signed' },
        { value: "SPECIFIC_DATE", label: 'On a specific date' },
    ]

    const renderContractCreation = () => {
        return (
            <ContentBox>
                <ContentBox.Body>
                    <BaseForm ref={formRef} initialFormFields={initialFields} onSubmit={createContract} onFieldChange={onFieldChange}>
                        <Row className="align-items-center">
                            <Col md="8">
                                <h4>{ isEditing ? "Edit": "Create" } Contract</h4>
                            </Col>
                            <BaseForm.Input colSpan="4" name="owner" label={"Owner"} type="select" options={teamMemberOptions}/>
                        </Row>
                        <Row>
                            <CustomerInput colSpan="5" allowLeads required />
                        </Row>
                        <br/>
                        <Row>
                            <div className="body2">Signatory</div>
                            <div className="body1">Enter details about who is going to be signing the contract on behalf of the customer.</div>
                            <BaseForm.Input colSpan="5" name="signatory.name" label={t('common.name')} type="text" required />
                            <BaseForm.Input colSpan="5" name="signatory.email" label={t('common.email')} type="text" transformations={["lowercase"]} validations={{ required: true, validEmail: true }}/>
                        </Row>
                        <br/>
                        <hr/>
                        <Row>
                            <div className="body2">Details</div>
                            <div className="body1">Enter details about the contract.</div>
                            <BaseForm.Input colSpan="5" name="title" label="Title" type="text" required />
                            <BaseForm.Input colSpan="5" name="template_id" label={"Template"} type="select" options={templateOptions} showSearch={false}/>
                            <BaseForm.Input colSpan="5" name="expiration_date" label={"Expiration Date"} type="date" includeTime={true} minDate={moment()} />
                            <BaseForm.Input colSpan="5" name="auto_charges" label={t('subscriptions.payment_mechanism')} type="select"
                                options={paymentMechanismOptions} showSearch={false}/>
                        </Row>
                        <br/>
                        <hr/>
                        <Row>
                            <div className="body2">Plan Details</div>
                            <div className="body1">Enter details about who is going to be signing the contract on behalf of the customer.</div>
                            <BaseForm.Input colSpan="5" name="start_date_type" label={t('subscriptions.start_date')} type="select"
                                            options={startDateTypeOptions} showSearch={false}/>
                            {
                                startDateType === "SPECIFIC_DATE" &&
                                    <BaseForm.Input colSpan="5" name="start_date" label={'Specific Date'} type="date"
                                                    required minDate={moment()} />
                            }
                        </Row>
                        <Row>
                            <Col lg="5">
                                <BaseForm.InputGroup label="Term">
                                    <BaseForm.Number name="term.count" min="1" required label="Count" hideLabel/>
                                    <BaseForm.Divider />
                                    <BaseForm.SingleSelect name="term.frequency" options={planLengthOptions} showSearch={false}/>
                                </BaseForm.InputGroup>
                            </Col>
                        </Row>
                        <br/>
                        <ProductPricingSelection ref={pricingsRef} productPricings={productPricings}
                                                 allowDiscounts={true} requireRecurring />
                        {
                            error &&
                                <div className="form-error-message">{ error }</div>
                        }
                        <Row>
                            <Col md="12">
                                <br/>
                                <SubmitButton>{ isEditing ? "Update": "Create" } Contract</SubmitButton>
                            </Col>
                        </Row>
                    </BaseForm>
                </ContentBox.Body>
            </ContentBox>
        );
    }

    const renderEmpty = () => {
        return (
            <ContentBox>
                <ContentBox.Body>
                    <div className="d-flex flex-column">
                        <div className="align-items-center justify-content-center text-center" style={{ marginTop: "30px" }}>
                            <Row>
                                <Col md={{ span: 8, offset: 2 }}>
                                    <div className="body2">Hi there!</div>
                                    <br/>
                                    <p>Before we create a contract, we will need to create at least one contract template.
                                    You can get <Link to={getCompanySpecificUrl("/settings/contracts")}>started here</Link></p>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </ContentBox.Body>
            </ContentBox>
        )
    }

    return (
        <BaseContainer>
            <ContentContainer>
                <Breadcrumb>
                    <Breadcrumb.Item linkProps={{ to: getCompanySpecificUrl(`/contracts`) }} linkAs={Link}>Contracts</Breadcrumb.Item>
                    <Breadcrumb.Item active>{ isEditing ? (contract.customer && contract.customer.name): "New" }</Breadcrumb.Item>
                </Breadcrumb>
                <Loader loading={loading}>
                {
                    () => {
                        if (_.isEmpty(templates)) {
                            return renderEmpty();
                        } else {
                            return renderContractCreation();
                        }
                    }
                }
                </Loader>
            </ContentContainer>
        </BaseContainer>
    )
}

export default ContractCreate;

import '../../App.scss';
import React, { useEffect, useState, useContext, useCallback } from 'react';
import { useParams, Link } from 'react-router-dom';
import { serverFetch, serverPatch } from '../../helpers/server';
import {BaseContext, renderDescriptionForItemPricing, getLabelForTaxCategory, UserContext} from '../../helpers/common';
import { useTranslation } from 'react-i18next';
import BaseContainer from '../../components/BaseContainer';
import KeyValueDisplay from '../../components/KeyValueDisplay';
import KeyValueDisplay2 from '../../components/KeyValueDisplay2';
import AddOrEditProductPricingModal from '../../components/modals/AddOrEditProductPricingModal';
import ArchiveButton from '../../components/ArchiveButton';
import ContentContainer from '../../components/ContentContainer';
import Section from '../../components/Section';
import ChangeProductModal from "../../components/modals/ChangeProductModal";
import SelectEntitlements from "../../components/SelectEntitlements";
const _ = require('lodash');

function ProductPricing(props) {
    const { t } = useTranslation('common');
    const { pricingId } = useParams();
    const { isSuperUser } = useContext(UserContext);
    const { company, getApiUrl, setPageTitle, getCompanySpecificUrl } = useContext(BaseContext);
    const [pricing, setPricing] = useState({});
    const [details, setDetails] = useState({});
    const [isEditingMetadata, setIsEditingMetadata] = useState(false);
    const [isEditingEntitlements, setIsEditingEntitlements] = useState(false);
    const [showAddOrEditProductPricingModal, setShowAddOrEditProductPricingModal] = useState(false);
    const [showChangeProductModal, setShowChangeProductModal] = useState(false);
    const allowEntitlements = isSuperUser;

    useEffect(() => {
        setPageTitle(`Pricing - ${pricing.name}`);
    }, [pricing]);

    const fetchData = (skipCache=false) => {
        serverFetch(getApiUrl(`/product_pricings/${pricingId}`), { skipCache }).then((res) => {
            if (res) {
                setPricing(res);
            }
        });
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        setDetails({
            'Product': !_.isEmpty(pricing) && <Link to={getCompanySpecificUrl(`/product/${pricing.product_id}`)}>{pricing.product.name}</Link>,
            'Name': pricing.name,
            'Public Name': pricing.external_name,
            'Description': pricing.description
        });
    }, [pricing]);

    const onModalClose = () => {
        setShowAddOrEditProductPricingModal(false);
        setShowChangeProductModal(false);
        fetchData(true);
    }

    const editPricing = () => {
        setShowAddOrEditProductPricingModal(true);
    }

    const onArchiveProductPricing = (pricing) => {
        serverPatch(getApiUrl(`/product_pricings/${pricing.id}`), { state: "ARCHIVED" }).then((res) => {
            if (res) {
                fetchData(true);
            }
        })
    }

    const editMetadata = () => {
        setIsEditingMetadata(true);
    }

    const saveMetadata = (newMetadata) => {
        const processedData = {
            metadata: newMetadata
        };

        serverPatch(getApiUrl(`/product_pricings/${pricingId}`), processedData).then((res) => {
            if (res) {
                fetchData(true);
            }
            setIsEditingMetadata(false);
        });
    }

    const editEntitlements = () => {
        setIsEditingEntitlements(true);
    }

    const saveEntitlements = (newEntitlements) => {
        const processedData = {
            entitlements: newEntitlements
        };

        serverPatch(getApiUrl(`/product_pricings/${pricingId}`), processedData).then((res) => {
            if (res) {
                fetchData(true);
            }
            setIsEditingEntitlements(false);
        });
    }

    const isOneTimeProductPricing = (productPricing) => {
        const types = _.uniq(_.map(productPricing.product_metric_pricings, (pmp) => pmp.item.type));
        return types.length === 1 && types[0] === "ONETIME_ITEM";
    }

    const renderProductPricing = (productPricing) => {
        return (
            <>
            {
                _.map(productPricing.product_metric_pricings, (pmp, i) =>
                     <div key={i}>
                        { renderDescriptionForItemPricing(pmp.item_pricing, false, pmp.item) }
                    </div>
                )
            }
            </>
        )
    }

    return (
        <BaseContainer>
            <ContentContainer title={pricing.name} actions={[
                {variant: "text-primary", icon: "fa-edit", label: t('common.edit'), onClick: editPricing}
            ]} customActions={<ArchiveButton size="md" onDelete={() => onArchiveProductPricing(pricing)}></ArchiveButton>}>
                <Section title="Details" actions={[]}>
                    <KeyValueDisplay2 items={details} />
                </Section>
                {
                    allowEntitlements &&
                        <Section title="Entitlements" className="mt-4" actions={!isEditingEntitlements && [
                            {variant: "text-primary", icon: "fa-edit", label: "Edit", onClick: editEntitlements}
                        ]}>
                            <div className="max-w-3xl">
                                <SelectEntitlements
                                    entitlements={pricing.computed_entitlements}
                                    isEditing={isEditingEntitlements}
                                    onUpdateEntitlements={saveEntitlements}
                                    onCancelEditing={() => setIsEditingEntitlements(false)}
                                />
                            </div>
                        </Section>
                }
                <Section title="Metadata" className="mt-4" actions={[
                    {variant: "text-primary", icon: "fa-edit", label: "Edit", onClick: editMetadata}
                ]}>
                    {
                        (!isEditingMetadata && (_.isNil(pricing.metadata) || _.isEmpty(pricing.metadata))) ?
                            <p>No metadata</p>
                            : <KeyValueDisplay items={pricing.metadata} isEditing={isEditingMetadata}
                                               onUpdateKeyValues={saveMetadata}
                                               onCancelEditing={() => setIsEditingMetadata(false)}/>
                    }
                </Section>
            </ContentContainer>
            <AddOrEditProductPricingModal show={showAddOrEditProductPricingModal} onClose={onModalClose} itemToEdit={pricing} />
            <ChangeProductModal show={showChangeProductModal} onClose={onModalClose} pricing={pricing} />
        </BaseContainer>
    );
}

export default ProductPricing;

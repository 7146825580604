import '../App.scss';
import React from 'react';
import classnames from 'classnames';
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import {useNavigate} from "react-router-dom";
import { renderChildren } from "../helpers/common";

const _ = require('lodash');

function DropdownMenu(props) {
    const navigate = useNavigate();

    const handleClick = (event, action) => {
        event.stopPropagation();
        event.preventDefault();
        if (action.onClick) {
            action.onClick();
        } else if (action.link) {
            navigate(action.link);
        }
    }

    return (
        <Menu as="div" className="inline-block text-left">
            <div className="flex flex-row">
                <Menu.Button className="flex items-center text-gray-400 hover:text-gray-600 focus:outline-none outline-none">
                    { renderChildren(props) }
                    <span className="sr-only">Open options</span>
                    <EllipsisVerticalIcon className="h-7 w-7" aria-hidden="true" />
                </Menu.Button>
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute right-5 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                        {
                            _.map(props.items, (item, i) => {
                                if (!item) {
                                    return null;
                                } else {
                                    return (
                                        <Menu.Item key={i}>
                                            {({active}) => (
                                                <a
                                                    href={item.link || "#"}
                                                    className={classnames(
                                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                        'block px-4 py-2 text-sm'
                                                    )}
                                                    onClick={(event) => handleClick(event, item)}
                                                >
                                                    { item.icon && <><i className={"fa " + item.icon}/>&nbsp;</> }
                                                    {item.label}
                                                </a>
                                            )}
                                        </Menu.Item>
                                    );
                                }
                            })
                        }
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}

export default DropdownMenu;

import '../App.scss';
import React, { useState, useEffect, createRef } from "react";
import BaseForm from "./BaseForm";
import {Button} from "react-bootstrap";
import { Switch } from '@headlessui/react'
import classnames from 'classnames';
const _ = require('lodash');

const MapleTableHeaderWithActions = (props) => {
    const [initialFields, setInitialFields] = useState({});
    const [filters, setFilters] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [selectedFilterCount, setSelectedFilterCount] = useState(0)
    const [showFilterOptions, setShowFilterOptions] = useState(false)
    const formRef = createRef();

    useEffect(() => {
        setFilters((props.filters))
    }, [props.filters])

    useEffect(() => {
        const defaultFilters = {...props.defaultSelectedFilters};
        delete defaultFilters["search"];
        delete defaultFilters["sort"];
        let newFilters = defaultFilters;
        if (props.cachedSelectedFilters) {
            newFilters = props.cachedSelectedFilters;
        }
        setSelectedFilters(newFilters);
        setInitialFields(prevFields => {
            return {...prevFields, ...defaultFilters};
        });
    }, [props.defaultSelectedFilters, props.cachedSelectedFilters]);

    useEffect(() => {
        let count = 0;
        _.filter(selectedFilters, (value, key) => {
            if (!_.isNil(value) && !_.isUndefined(value)) {
                count += 1;
            }
        })
        setSelectedFilterCount(count);
    }, [selectedFilters])

    const onFieldChange = (name, value) => {
        if (name === "search") {
            onParamsChange(name, value);
        } else if (name === "sort") {
            onParamsChange(name, value);
        } else {
            setSelectedFilters(prevFilters => {
                const newFilters = {...prevFilters};
                newFilters[name] = value;
                return newFilters;
            })
        }
    }

    const onCheckboxChange = (name, value, checked) => {
        let newValue = selectedFilters[name];
        if (checked) {
            newValue.push(value);
        } else {
            newValue = _.filter(newValue, (v) => v !== value);
        }
        onFieldChange(name, newValue);
    }

    const onToggleFilterOptions = (event) => {
        event.stopPropagation();
        event.preventDefault();
        setShowFilterOptions(!showFilterOptions);
    }

    const onSubmit = (data) => {
        document.body.click();
        if (props.onParamsChange) {
            props.onParamsChange(selectedFilters);
        }

        setShowFilterOptions(false);
    }

    const onParamsChange = (name, value) => {
        const formData = formRef.current.getFormData();
        formData[name] = value;
        if (props.onParamsChange) {
            props.onParamsChange(formData);
        }
    }

    const onResetFilters = () => {
        const formData = formRef.current.getFormData();
        setSelectedFilters({...props.defaultSelectedFilters});

        const basicData = {
            sort: formData.sort,
            search: formData.search
        }
        let resetData = {};
        _.each(filters, (f) => {
            resetData[f.name] = null;
        })
        resetData = {...resetData, ...props.defaultSelectedFilters};
        formRef.current.onResetFields(resetData);
        setInitialFields(basicData);
        if (props.onParamsChange) {
            props.onParamsChange(resetData);
        }
        setShowFilterOptions(false);
    }

    const renderFilter = (filter) => {
        if (filter.type === "switch") {
            let value = selectedFilters[filter.name];
            return <div>
                <Switch.Group as="div" className="flex items-center">
                    <Switch
                        checked={value}
                        onChange={() => onFieldChange(filter.name, !value)}
                        className={classnames(
                            value ? 'bg-indigo-600' : 'bg-gray-200',
                            'relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-0'
                        )}
                    >
                        <span
                            aria-hidden="true"
                            className={classnames(
                                value ? 'translate-x-5' : 'translate-x-0',
                                'pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                            )}
                        />
                    </Switch>
                    <Switch.Label as="span" className="ml-1 text-sm">
                        <span className="font-normal text-gray-500">{filter.label}</span>
                    </Switch.Label>
                </Switch.Group>
            </div>
        } else if (filter.type === "select") {
            let value = selectedFilters[filter.name];
            if (_.isUndefined(value)) {
                value = null;
            }
            return <div className="space-y-0">
                {
                    _.map(filter.options, (option, i) =>
                        <div key={i} className="flex items-center">
                            <input
                                id={String(option.value)}
                                name={filter.name}
                                type="radio"
                                defaultChecked={option.value === value}
                                onChange={(event) => onFieldChange(filter.name, option.value)}
                                className="h-3 w-3 border-gray-300 text-gray-500 focus:ring-indigo-600"
                            />
                            <label htmlFor={String(option.value)} className="ml-2 block text-sm font-normal leading-6 text-gray-500">
                                { option.label }
                            </label>
                        </div>
                    )
                }
            </div>
        } else if (filter.type === "multi-select") {
            let value = selectedFilters[filter.name];
            if (_.isUndefined(value)) {
                value = null;
            }
            return <div className="space-y-0">
                {
                    _.map(filter.options, (option, i) =>
                        <div key={i} className="flex items-center">
                            <input
                                id={option.value}
                                name={option.value}
                                type="checkbox"
                                defaultChecked={_.includes(value, option.value)}
                                onChange={(event) => onCheckboxChange(filter.name, option.value, event.target.checked)}
                                className="h-3 w-3 border-gray-300 text-gray-500 focus:ring-indigo-600"
                            />
                            <label htmlFor={option.value} className="ml-2 block text-sm font-normal leading-6 text-gray-500">
                                { option.label }
                            </label>
                        </div>
                    )
                }
            </div>
        }
    }

    return (
        <>
            <BaseForm ref={formRef} initialFormFields={initialFields} onFieldChange={onFieldChange} onSubmit={onSubmit}>
                <div className="flex flex-row gap-2">
                    {
                        props.showSearch &&
                            <div className="grow max-w-md">
                                <div className="w-full">
                                    <BaseForm.TextGroup name="search" icon="search-icon" placeholder={props.searchPlaceholder || "Search Items"} formClassName="inline" />
                                </div>
                            </div>
                    }
                    {
                        props.showFilters && !_.isEmpty(filters) &&
                            <div className="cursor-pointer p-1.5 rounded-md bg-white border-1 border-slate-300" onClick={onToggleFilterOptions}>
                                <div className="flex flex-row gap-1">
                                    <div className="filter-icon d-inline-block"/>
                                    <span className="table-filter-count text-slate-900">Filters</span>
                                    {
                                        selectedFilterCount > 0 &&
                                        <span className="table-filter-count text-slate-900">({ selectedFilterCount })</span>
                                    }
                                </div>
                            </div>
                    }
                    <div className={"grow flex flex-row gap-2 items-center justify-end"}>
                        {
                            props.meta &&
                                <div className="body1 gray3">{ props.meta && props.meta.total } results</div>
                        }
                    </div>
                </div>
                {
                    showFilterOptions &&
                        <div className="flex flex-row gap-2 justify-end my-1">
                            {
                                props.showFilters && !_.isEmpty(filters) &&
                                    <div className="flex flex-col gap-3 w-full items-start p-3 bg-white rounded-md shadow-sm ring-1 ring-black ring-opacity-5">
                                        <div className="flex">
                                            <div className="table-filter-menu-header">
                                                <span className="font-medium text-base">Filters</span>
                                            </div>
                                        </div>
                                        <div className="flex flex-row flex-wrap gap-4">
                                            {
                                                _.map(props.filters, (item, i) =>
                                                    <div className={i === 0 ? "pr-0": "px-0"} key={i}>
                                                        <div className="body2 gray4 flex-grow-1">{ item.title }</div>
                                                        { renderFilter(item) }
                                                    </div>
                                                )
                                            }
                                        </div>
                                        <div className="flex flex-row items-center gap-2 w-full">
                                            <Button variant="primary" type="submit">Apply</Button>
                                            <Button variant="outline-primary" onClick={onToggleFilterOptions}>Cancel</Button>
                                            <div className="grow flex justify-end">
                                                <Button variant="text" size="sm" className="text-slate-300 grow-0" onClick={() => onResetFilters()}>Reset Filters</Button>
                                            </div>
                                        </div>
                                    </div>
                            }
                        </div>
                }
            </BaseForm>
        </>
    );
}

/*
    <div className={"table-filter-menu-item-body"}>
        <BaseForm.Input type={item.type} name={item.name} options={item.options} label={item.label} showSearch={false} />
    </div>
 */

export default MapleTableHeaderWithActions;

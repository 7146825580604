import '../../App.scss';
import React, { useEffect, useState, useContext, useMemo, createRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { serverFetch, serverPost, serverPatch } from '../../helpers/server';
import { BaseContext } from '../../helpers/common';
import SubmitButton from '../../components/SubmitButton';
import BaseContainer from '../../components/BaseContainer';
import ContentContainer from '../../components/ContentContainer';
import ContentBox from '../../components/ContentBox';
import BaseForm from '../../components/BaseForm';
import ProductPricingSelection from '../../components/ProductPricingSelection';
import { Row, Col, Breadcrumb } from 'react-bootstrap';
import { useParams, Link, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
const _ = require('lodash');

function PaymentLinkCreateOrUpdate() {
    const navigate = useNavigate();
    const { t } = useTranslation('common');
    const { uuid } = useParams();
    const [ searchParams ] = useSearchParams();
    let defaultPricingId = searchParams.get('pricing');

    const { company, getApiUrl, setPageTitle, getCompanySpecificUrl } = useContext(BaseContext);
    const [paymentLink, setPaymentLink] = useState({});
    const [productPricings, setProductPricings] = useState([]);
    const [selectedProductPricingIds, setSelectedProductPricingIds] = useState([]);
    const [sort, setSort] = useState("createdAtDesc");
    const [error, setError] = useState(null);
    const pricingsRef = createRef();

    const [isEditing, setIsEditing] = useState(false);
    const defaultInitialFields = useMemo(() => {
        return {
            term: {
                count: 1,
                frequency: "YEAR"
            }
        }
    })
    const [initialFields, setInitialFields] = useState(defaultInitialFields);

    useEffect(() => {
        setPageTitle(`Payment Link - Create`);
    }, []);

    useEffect(() => {
        const limit = 100
        const params = {
            company_id: company.id,
            sort_key: sort,
            pagination: {
                limit: limit
            },
        }
        serverPost(getApiUrl("/product_pricings/find"), params).then((res) => {
            if (res) {
                const results = res.results || [];
                setProductPricings(results);
            }
            if (defaultPricingId) {
                setSelectedProductPricingIds([defaultPricingId]);
            }
        });
    }, [])

    useEffect(() => {
        if (!_.isNil(uuid)) {
            serverFetch(getApiUrl(`/proposals/${uuid}`)).then((res) => {
                setPaymentLink(res);
                setInitialFields({
                    ...res,
                    config: _.keyBy(res.config_items, 'product_metric_pricing_id')
                });
                const ppIds = _.map(res.bundle_pricing.bundle_product_pricings, (pp) => pp.product_pricing_id);
                setSelectedProductPricingIds(ppIds);
            })
            setIsEditing(true);
        } else {
            setInitialFields(defaultInitialFields)
            setIsEditing(false);
        }
    }, [uuid])

    const createPaymentLink = async (data) => {
        let pricingData = {};
        if (pricingsRef.current) {
            const validationResult = pricingsRef.current.validate(data);
            if (!_.isNil(validationResult)) {
                setError(validationResult)
                return;
            }

            pricingData = pricingsRef.current.getPricingSelectionFields(data);
        }
        setError(null);
        const isToday = moment(0, "HH").diff(data.start_date, "days") == 0;
        const startDate = isToday ? moment(): moment(data.start_date)
        const paymentLinkData = {
            ...pricingData,
            options: data.options,
            status: "PENDING_PAYMENT",
            term: {
                frequency: data.term.frequency,
                count: parseInt(data.term.count)
            },
            start_date: startDate.format(),
            type: "CHECKOUT"
        }

        if (isEditing) {
            const paymentLinkUpdateResult = await serverPatch(getApiUrl(`/proposals/${uuid}`), paymentLinkData)
            if (paymentLinkUpdateResult) {
                navigate(getCompanySpecificUrl(`/payment_links`))
            }
        } else {
            const paymentLinkResult = await serverPost(getApiUrl('/proposals'), paymentLinkData)
            if (paymentLinkResult) {
                navigate(getCompanySpecificUrl(`/payment_links`))
            }
        }
    }

    const onFieldChange = (name, value) => {
        // console.log("Field change " + name + ", " + value);
    }

    const planLengthOptions = [
        { value: "DAY", label: "Day" },
        { value: "WEEK", label: "Week" },
        { value: "MONTH", label: "Month" },
        { value: "QUARTER", label: "Quarter" },
        { value: "YEAR", label: "Year" },
    ]

    console.log("The initial fields are " + JSON.stringify(initialFields.config_items));
    return (
        <BaseContainer>
            <ContentContainer>
                <Breadcrumb>
                    <Breadcrumb.Item linkProps={{ to: getCompanySpecificUrl(`/payment_links`) }} linkAs={Link}>Payment Links</Breadcrumb.Item>
                    <Breadcrumb.Item active>{ isEditing ? paymentLink.id: "New" }</Breadcrumb.Item>
                </Breadcrumb>
                <ContentBox>
                    <ContentBox.Body>
                        <BaseForm initialFormFields={initialFields} onSubmit={createPaymentLink} onFieldChange={onFieldChange}>
                            <Row className="align-items-center">
                                <Col md="8">
                                    <h4>{ isEditing ? "Edit": "Create" } Payment Link</h4>
                                </Col>
                            </Row>
                            <hr/>
                            <Row>
                                <div className="body2">Plan Details</div>
                                <div className="body1">Enter details about who is going to be signing the contract on behalf of the customer.</div>
                                <Col sm="4">
                                    <BaseForm.InputGroup label="Term">
                                        <BaseForm.Number name="term.count" min="1" required label="Count" hideLabel/>
                                        <BaseForm.Divider />
                                        <BaseForm.SingleSelect name="term.frequency" options={planLengthOptions} showSearch={false}/>
                                    </BaseForm.InputGroup>
                                </Col>
                            </Row>
                            <br/>
                            <Row>
                                <div className="body2">Advanced Options</div>
                                <Col md="4">
                                    <BaseForm.Input type="switch" name="options.allow_promo_code" label="Allow Promo Codes" formClassName="thin"/>
                                </Col>
                            </Row>
                            <br/>
                            <ProductPricingSelection ref={pricingsRef} productPricings={productPricings}
                                                     preselectedProductPricingIds={selectedProductPricingIds}/>
                            {
                                error &&
                                    <div className="form-error-message">{ error }</div>
                            }
                            <Row>
                                <Col md="12">
                                    <br/>
                                    <SubmitButton>{ isEditing ? "Update": "Create" } Payment Link</SubmitButton>
                                </Col>
                            </Row>
                        </BaseForm>
                    </ContentBox.Body>
                </ContentBox>
            </ContentContainer>
        </BaseContainer>
    );
}

export default PaymentLinkCreateOrUpdate;

import '../App.scss';
import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { getValueFromEvent } from '../helpers/input';
import SingleSelectDropdown from './SingleSelectDropdown';
import BaseForm from './BaseForm';
import DeleteButton from './DeleteButton';
import classnames from 'classnames';
const _ = require("lodash");

function Rule(props) {
    const [value, setValue] = useState(null);
    const [propertyFields, setPropertyFields] = useState([]);

    const defaultValue = null;
    const defaultComparator = {
        comparator: {
            type: "EQUAL",
            left_item: {
                item_type: "PROPERTY",
                property: ""
            },
            right_item: {
                item_type: "VALUE",
                value: ""
            }
        }
    }

    useEffect(() => {
        const newValue = !_.isEmpty(props.value) ? props.value : defaultValue;
        if (!_.isEqual(value, newValue)) {
            setValue(newValue);
        }
    }, [props.value])

    useEffect(() => {
        setPropertyFields(props.propertyFields);
    }, [props.propertyFields])

    useEffect(() => {
        props.onChange(value);
    }, [value]);

    const comparatorOptions = [
        { id: "OR", label: "OR" },
        { id: "AND", label: "AND" },
    ]

    const isLeafComparatorType = (type) => {
        const leafComparators = ["AND", "OR", "XOR", "NOR"];
        const isLeafComparator = !_.includes(leafComparators, type);
        return isLeafComparator;
    }

    const getTypeForKey = (key) => {
        const pField = _.find(propertyFields, (pf) => pf.Key === key);
        if (pField) {
            return pField.Type;
        } else {
            return null;
        }
    }

    const getValueForKey = (key) => {
        const parts = key.split(".");
        let v = value.comparator;
        for (let i = 1; i < parts.length; i++) {
            if (parts[i] === "left") {
                v = v.left_item;
            } else if (parts[i] === "right") {
                v = v.right_item;
            } else if (parts[i] === "c") {
                v = v.comparator;
            }

            if (i === parts.length - 1) {
                if (parts[i] === "property") {
                    return v.property;
                } else if (parts[i] === "value") {
                    return v.value;
                } else if (parts[i] === "type") {
                    return v.type;
                }
            }
        }
        return null;
    }

    const updateValueForKey = (key, val) => {
        const parts = key.split(".");
        setValue(prevValue => {
            const nextValue = {...prevValue};
            let v = nextValue.comparator;
            for (let i = 1; i < parts.length; i++) {
                if (parts[i] === "left") {
                    v = v.left_item;
                } else if (parts[i] === "right") {
                    v = v.right_item;
                } else if (parts[i] === "c") {
                    v = v.comparator;
                }

                if (i === parts.length - 1) {
                    if (parts[i] === "property") {
                        v.property = val;
                    } else if (parts[i] === "value") {
                        v.value = val;
                    } else if (parts[i] === "type") {
                        v.type = val;
                    }
                }
            }
            return nextValue;
        })
    }

    const onComparatorSelected = (key, value) => {
        const parts = key.split(".");
        setValue(prevValue => {
            const nextValue = {...prevValue};
            let v = nextValue;
            for (let i = 0; i < parts.length - 1; i++) {
                if (parts[i] === "main") {
                    v = v.comparator;
                } else if (parts[i] === "left") {
                    v = v.left_item;
                } else if (parts[i] === "right") {
                    v = v.right_item;
                } else if (parts[i] === "c") {
                    v = v.comparator;
                }
            }

            if (parts[parts.length - 1] === "c" || parts[parts.length - 1] === "main") {
                const existingComparator = { ...v.comparator };
                v.comparator = {
                    type: value,
                    left_item: {
                        item_type: "COMPARATOR",
                        comparator: existingComparator,
                    },
                    right_item: {
                        item_type: "COMPARATOR",
                        comparator: {
                            type: "EQUAL",
                            left_item: {
                                item_type: "PROPERTY",
                                property: ""
                            },
                            right_item: {
                                item_type: "VALUE",
                                value: ""
                            }
                        }
                    }
                };
            }
            return nextValue;
        })
    }

    const onComparatorDelete = (key) => {
        const parts = key.split(".");
        setValue((prevValue) => {
            let nextValue = {...prevValue};
            let v = nextValue;
            for (let i = 0; i < parts.length - 3; i++) {
                if (parts[i] === "main") {
                    v = v.comparator;
                } else if (parts[i] === "left") {
                    v = v.left_item;
                } else if (parts[i] === "right") {
                    v = v.right_item;
                } else if (parts[i] === "c") {
                    v = v.comparator;
                }
            }

            if (parts[parts.length - 1] === "main") {
                // Deleting the only item so go back to a default empty one
                nextValue = defaultValue;
            } else if (parts[parts.length - 1] === "c") {
                if (parts[parts.length - 2] === "left") {
                    v.comparator = v.comparator.right_item.comparator;
                } else if (parts[parts.length - 2] === "right") {
                    v.comparator = v.comparator.left_item.comparator;
                }
            }
            return nextValue;
        })
    }

    const getOperationsForType = (type) => {
        if (type === "Text") {
            return [
                { value: "EQUAL", label: "eq" }
            ]
        } else if (type === "Number") {
            return [
                { value: "EQUAL", label: "eq" },
                { value: "GREATER_THAN", label: ">" },
                { value: "GREATER_THAN_EQUAL", label: ">=" },
                { value: "LESS_THAN", label: "<" },
                { value: "LESS_THAN_EQUAL", label: "<=" },
            ]
        }
        return [
            { value: "EQUAL", label: "=" },
            { value: "GREATER_THAN", label: ">" },
            { value: "GREATER_THAN_EQUAL", label: ">=" },
            { value: "LESS_THAN", label: "<" },
            { value: "LESS_THAN_EQUAL", label: "<=" },
        ]
    }

    const renderProperty = (p, key) => {
        return (
            <BaseForm.InputGroup>
                <Form.Control
                    value={getValueForKey(key)}
                    onChange={(event) => {
                        updateValueForKey(key, getValueFromEvent(event));
                    }}>
                    { props.children }
                </Form.Control>
            </BaseForm.InputGroup>
        )
    }

    const renderValue = (p, key) => {
        return (
            <BaseForm.InputGroup className="rule-value">
                <Form.Control
                    value={getValueForKey(key)}
                    onChange={(event) => {
                        updateValueForKey(key, getValueFromEvent(event));
                    }}>
                    { props.children }
                </Form.Control>
            </BaseForm.InputGroup>
        )
    }

    const renderLeafNode = (n, key) => {
        if (n.item_type === "PROPERTY") {
            return renderProperty(n, key + ".property");
        } else if (n.item_type === "VALUE") {
            return renderValue(n, key + ".value");
        } else {
            return (
                <div>Not expected to be here.</div>
            )
        }
    }

    const renderLeafComparator = (v, key, showActions=true, isColored=false) => {
        let propertyKey = null;
        if (v.left_item.item_type === "PROPERTY") {
            propertyKey = v.left_item.property;
        } else if (v.right_item.item_type === "PROPERTY") {
            propertyKey = v.right_item.property;
        }
        let type = getTypeForKey(propertyKey);
        return (
            <Row className="rule-leaf-comparator">
                <Col md="4">
                    { renderLeafNode(v.left_item, key + ".left") }
                </Col>
                <Col md="3">
                    <BaseForm.InputGroup className={classnames("form-select")}>
                        <SingleSelectDropdown items={getOperationsForType(type)} selectedId={getValueForKey(key + ".type")}
                            showAll={false} idField="value" showSearch={false}
                            onSelect={(value) => {
                                updateValueForKey(key + ".type", value)
                            }}
                            disabled={props.disabled}/>
                    </BaseForm.InputGroup>
                </Col>
                <Col md="4">
                    { renderLeafNode(v.right_item, key + ".right") }
                </Col>
                <Col md="1" className="d-flex flex-row justify-content-center">
                    <DeleteButton variant="text-danger" onDelete={() => onComparatorDelete(key)}></DeleteButton>
                </Col>
            </Row>
        )
    }

    const renderComparator = (c, key, showActions=true, isColored=false) => {
        const isLeafComparator = isLeafComparatorType(c.type);
        if (isLeafComparator) {
            return (
                <>
                { renderLeafComparator(c, key, showActions, isColored) }
                {
                    showActions &&
                        <Row>
                            <Col md="1">
                                <div className="dropdown">
                                    <SingleSelectDropdown className={"dropdown"} toggleLabel="Add" items={comparatorOptions} onSelect={(value) => onComparatorSelected(key, value)} menuOnly/>
                                </div>
                            </Col>
                        </Row>
                }
                </>
            )
        } else {
            if (c.type === "AND") {
                return renderAND(c, key, showActions, isColored);
            } else if (c.type === "OR") {
                return renderOR(c, key, showActions, isColored);
            }
        }
    }

    const renderAND = (v, key, showActions=true, isColored=false) => {
        return (
            <div className="rule-and">
                <div className="rule-left">{ renderComparator(v.left_item.comparator, key + ".left.c", false, isColored) }</div>
                <div className="body2">AND</div>
                <div className="rule-right">{ renderComparator(v.right_item.comparator, key + ".right.c", false, isColored) }</div>
                <div>
                {
                    showActions &&
                        <Row>
                            <Col md="1">
                                <div className="dropdown">
                                    <SingleSelectDropdown className="dropdown" label="Add" items={comparatorOptions} onSelect={(value) => onComparatorSelected(key, value)} align="end" menuOnly/>
                                </div>
                            </Col>
                        </Row>
                }
                </div>
            </div>
        )
    }

    const renderOR = (v, key, showActions=true, isColored=false) => {
        return (
            <div className="rule-or">
                <div className={classnames("rule-left", isColored ? "alt": "")}>{ renderComparator(v.left_item.comparator, key + ".left.c", true, !isColored) }</div>
                <div className="body2">OR</div>
                <div className={classnames("rule-right", isColored ? "alt": "")}>{ renderComparator(v.right_item.comparator, key + ".right.c", true, !isColored) }</div>
                {
                    showActions &&
                        <Row>
                            <Col md="1">
                                <div className="dropdown">
                                    <SingleSelectDropdown className="dropdown" toggleLabel="Add" items={comparatorOptions} onSelect={(value) => onComparatorSelected(key, value)} align="end" menuOnly/>
                                </div>
                            </Col>
                        </Row>
                }
            </div>
        )
    }

    const onAddFilter = () => {
        setValue({...defaultComparator});
    }

    return (
        <div className={classnames("rule", props.isColored ? "alt": "")}>
            {
                !_.isNil(value) ?
                    <>
                        { value.comparator && renderComparator(value.comparator, "main", true, !props.isColored) }
                    </>
                : <>
                    <Button onClick={onAddFilter}>Add filter</Button>
                </>
            }
        </div>
    );
}

export default Rule;

import '../../../App.scss';
import React, { useContext, useEffect } from 'react';
import { serverFetch } from '../../../helpers/server';
import { BaseContext, IntegrationContext } from '../../../helpers/common';
import { Button, Container, Row, Col } from 'react-bootstrap';
import { useOutletContext } from 'react-router-dom';
const _ = require('lodash');

function Welcome(props) {
    const { setShowNext, setShowProgress, next, navigateTo, setVisibility } = useOutletContext();
    const { getApiUrl, setPageTitle } = useContext(BaseContext);
    const { integration, integrationInterface } = useContext(IntegrationContext);

    useEffect(() => {
        setPageTitle(`Integrations - Welcome`);
    }, []);

    useEffect(() => {
        setShowProgress(false);
        setShowNext(false);
    }, []);

    const onInstall = async () => {
        if (integration.auth_type === undefined || integration.auth_type === "oauth2") {
            setVisibility("api", false);
            serverFetch(getApiUrl(`/integrations/${integration.id}/oauth_url`)).then(res => {
                if (res) {
                    window.location.href = res;
                }
            })
        } else if (integration.auth_type === "api") {
            setVisibility("oauth_callback", false);
            setVisibility("api", true);
            next();
        }
    }

    return (
        <div className="d-flex flex-column gap-3 align-items-center">
            <div className="d-flex flex-row gap-3 align-items-center">
                <div>
                    <img src={ integration.icon } alt="Integration Logo" className="integration-logo"/>
                </div>
                <h2>{ integration.name }</h2>
            </div>
            <Container className="integration-flow-content">
                <Row>
                    <Col md={{ span: "6", offset: "3" }}>
                    { integrationInterface.getWelcomePageContent() }
                    <div className="d-flex justify-content-center">
                        <Button variant="primary" onClick={onInstall}>Install</Button>
                    </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Welcome;

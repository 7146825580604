import '../App.scss';
import React, { useState, useEffect } from 'react';
import { Table, Image } from 'react-bootstrap';
import { currencyFormatFromPrice, getLabelForBillingFrequency } from '../helpers/common';
import moment from 'moment';
import classnames from 'classnames';
const _ = require('lodash');

function PricingBreakdown(props) {
    const [subscriptionDetails, setSubscriptionDetails] = useState([]);
    const [oneTimeDetails, setOneTimeDetails] = useState([]);
    const [creditDetails, setCreditDetails] = useState([]);
    const [discountDetails, setDiscountDetails] = useState([]);
    const [taxDetails, setTaxDetails] = useState([]);
    const [dataOpenMap, setDataOpenMap] = useState({});

    useEffect(() => {
        setSubscriptionDetails(props.subscriptionDetails);
    }, [props.subscriptionDetails])

    useEffect(() => {
        setOneTimeDetails(props.oneTimeDetails);
    }, [props.oneTimeDetails])

    useEffect(() => {
        setCreditDetails(props.creditDetails);
    }, [props.creditDetails])

    useEffect(() => {
        setDiscountDetails(props.discountDetails);
    }, [props.discountDetails])

    useEffect(() => {
        if (props.taxDetails) {
            const taxGroups = _.groupBy(props.taxDetails.line_items, "tax.name");
            const processedLineItems = _.map(taxGroups, (items, tg) => {
                return {
                    tax: items[0].tax,
                    aggregate_price: {
                        value_in_cents: _.sumBy(items, (item) => item.aggregate_price.value_in_cents)
                    }
                }
            })
            setTaxDetails({
                aggregate_price: props.taxDetails.aggregate_price,
                line_items: processedLineItems
            });
        } else {
            setTaxDetails([]);
        }
    }, [props.taxDetails])

    const openRow = (i) => {
        setDataOpenMap(prevData => {
            const newData = {...prevData};
            if (_.has(newData, String(i))) {
                newData[String(i)] = !newData[String(i)];
            } else {
                newData[String(i)] = true;
            }
            return newData
        });
    }

    const renderMetricPriceLineItem = (lineItem, currency) => {
        let pricingDescription = "";
        if (lineItem.type === "FIXED_UNITS") {
            pricingDescription = `${ currencyFormatFromPrice({ value_in_cents: lineItem.fixed_price.price_per_unit, currency: currency }) }`
        } else if (lineItem.type === "GRADIENT_UNITS") {
            pricingDescription = `${ currencyFormatFromPrice({ value_in_cents: lineItem.gradient_price.price_per_unit, currency: currency }) }/unit (+${ currencyFormatFromPrice({ value_in_cents: lineItem.gradient_price.flat_price, currency: currency }) })`
        }
        // { false && currencyFormat(lineItem.step_price.price_per_step) } per { false && lineItem.step_price.step_size } units
        return (
            <>
            { lineItem.aggregate.toLocaleString("en-US") } additional units @ { pricingDescription }
            </>
        )
    }

    const renderMetricName = (product, productPricing, mb) => {
        const productName = product.name;
        let metricName = null;
        let frequencyName = null;
        let externalProductPricingName = null;
        if (props.simplified) {
            frequencyName = getLabelForBillingFrequency(mb.item_pricing.frequency);
        }
        if (productPricing.external_name) {
            externalProductPricingName = productPricing.external_name;
        }
        if (mb.metric.type !== "LICENSE_METRIC") {
            metricName = mb.metric.name;
        }
        return (
            <>
                <span>{product.name}</span><br/>
                {
                    externalProductPricingName &&
                        <><span className="gray3">{ externalProductPricingName }</span><br/></>
                }
                {
                    frequencyName &&
                        <><span className="gray3">Billed {frequencyName}</span><br/></>
                }
                {
                    metricName &&
                        <span className="gray3">{metricName}</span>
                }
            </>
        );
    }

    const renderMetricBreakdown = (product, productPricing, mb, i) => {
        const periodDateFormat = "D MMM, YYYY";
        const showFullDetails = !mb.is_post_charge || mb.pre_charged_units.aggregate < mb.total_units.aggregate;
        return (
            <React.Fragment key={i}>
                <tr className={classnames(props.simplified ? "": "invoice-collapsible-row", "spacey")} onClick={() => openRow(`mb-${i}`)}>
                    <td className={classnames(props.simplified ? "thin-horizontal": "", "no-stretch")} style={{ paddingLeft: "3px", paddingRight: "3px" }}>
                        <div className="d-flex flex-row gap-2">
                            <div className={classnames("align-self-center", dataOpenMap[`mb-${i}`] ? "chevron-down-icon": "chevron-right-icon", props.expanded ? "hide": "")}/>
                            {
                                product.image_url ?
                                    <div className="small-product-logo align-self-center">
                                        <Image src={product.image_url} />
                                    </div>
                                : <i className={classnames("fa fa-product-hunt fa-primary", props.simplified ? "": "hide")} style={{ fontSize: "35px" }}/>
                            }
                        </div>
                    </td>
                    <td>
                        <span className="body1">{ renderMetricName(product, productPricing, mb) }</span>
                    </td>
                    <td className={classnames("no-stretch", props.isPreview && "thin-horizontal" )}>
                    {
                        !props.isPreview &&
                            <span><span style={{ whiteSpace: "nowrap" }}>{ moment(mb.period.start_date).format(periodDateFormat) }</span> - <span style={{ whiteSpace: "nowrap" }}>{ moment(mb.period.end_date).format(periodDateFormat) }</span></span>
                    }
                    </td>
                    <td className="text-end no-stretch">{ mb.total_units.aggregate.toLocaleString("en-US") }</td>
                    <td className="text-end no-stretch">{ currencyFormatFromPrice(mb.total_price.aggregate_price) }</td>
                </tr>
                <tr className={classnames("invoice-collapsible-row-data", props.simplified ? "hide": "", (dataOpenMap[`mb-${i}`] || props.expanded) ? "show": "hide")}>
                    <td colSpan="5" className="thin-horizontal">
                        <Table borderless>
                            <tbody>
                                <tr className="thin">
                                    <td className="text-end">Total units</td>
                                    <td className="no-stretch">{ mb.total_units.aggregate.toLocaleString("en-US") }</td>
                                    <td className="no-stretch"></td>
                                </tr>
                                {
                                    mb.is_post_charge && mb.pre_charged_units.aggregate > 0 &&
                                        <tr className="thin">
                                            <td className="text-end">Pre charged units </td>
                                            <td className="no-stretch">{ mb.pre_charged_units.aggregate.toLocaleString("en-US") }</td>
                                            <td className="no-stretch"></td>
                                        </tr>
                                }
                                {
                                    showFullDetails && mb.base_units_applied.aggregate > 0 &&
                                    <tr className="thin">
                                        <td className="text-end">Base units </td>
                                        <td className="no-stretch">{ mb.base_units_applied.aggregate.toLocaleString("en-US") }</td>
                                        <td className="no-stretch"></td>
                                    </tr>
                                }
                                {
                                    showFullDetails && mb.carry_over_units_applied.aggregate > 0 &&
                                        <tr className="thin">
                                            <td className="text-end">Carry over units</td>
                                            <td className="no-stretch">{ mb.carry_over_units_applied.aggregate.toLocaleString("en-US") }</td>
                                            <td className="no-stretch"></td>
                                        </tr>
                                }
                                {
                                    mb.credit_units_applied.aggregate !== 0 &&
                                        _.map(mb.credit_units_applied.line_items, (clt, k) =>
                                            <tr className="thin" key={"c"+k}>
                                                <td className="text-end">{ clt.credit.name }</td>
                                                <td className="no-stretch">{ clt.aggregate.toLocaleString("en-US") }</td>
                                                <td className="no-stretch"></td>
                                            </tr>
                                        )
                                }
                                {
                                    showFullDetails && mb.base_price.aggregate_price.value_in_cents > 0 &&
                                        <tr className="thin">
                                            <td colSpan="2" className="no-stretch text-end">Base Price</td>
                                            <td className="no-stretch text-end">{ currencyFormatFromPrice(mb.base_price.aggregate_price) }</td>
                                        </tr>
                                }
                                {
                                    showFullDetails && _.map(mb.metric_price.line_items, (lineItem, j) => {
                                        if (lineItem.aggregate > 0) {
                                            return (
                                                <tr className="thin" key={"m"+j}>
                                                    <td colSpan="2" className="no-stretch text-end">{ renderMetricPriceLineItem(lineItem, mb.item_pricing.base_price.currency) }</td>
                                                    <td className="no-stretch text-end">
                                                        {
                                                            lineItem.aggregate_price.value_in_cents > 0 && lineItem.aggregate_price.currency !== "" &&
                                                            <>{ currencyFormatFromPrice(lineItem.aggregate_price) }</>
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    })
                                }
                                {
                                    showFullDetails && (mb.discount_percent.aggregate_price.value_in_cents > 0 || mb.discount_amount.aggregate_price.value_in_cents > 0) &&
                                        <tr className="thin">
                                            <td colSpan="2" className="no-stretch text-end"><span className="body2">Subtotal</span></td>
                                            <td className="no-stretch text-end"><span className="body2">{ currencyFormatFromPrice(mb.metric_price.aggregate_price) }</span></td>
                                        </tr>
                                }
                                {
                                    showFullDetails && mb.discount_percent.aggregate_price.value_in_cents > 0 &&
                                        _.map(mb.discount_percent.line_items, (lineItem, d) =>
                                            <tr className="thin" key={"dp"+d}>
                                                <td colSpan="2" className="no-stretch text-end">{renderDiscountDescription(lineItem.discount)}</td>
                                                <td className="no-stretch text-end">{ currencyFormatFromPrice(lineItem.aggregate_price, true) }</td>
                                            </tr>
                                        )
                                }
                                {
                                    showFullDetails && mb.discount_amount.aggregate_price.value_in_cents > 0 &&
                                        _.map(mb.discount_amount.line_items, (lineItem, d) =>
                                            <tr className="thin" key={"da"+d}>
                                                <td colSpan="2" className="no-stretch text-end">{renderDiscountDescription(lineItem.discount)}</td>
                                                <td className="no-stretch text-end">{ currencyFormatFromPrice(lineItem.aggregate_price, true) }</td>
                                            </tr>
                                        )
                                }
                                {
                                    showFullDetails && mb.pre_charged_amount.aggregate_price.value_in_cents > 0 &&
                                        <tr className="thin">
                                            <td colSpan="2" className="no-stretch text-end"><span>Pre charged</span></td>
                                            <td className="no-stretch text-end"><span>{ currencyFormatFromPrice(mb.pre_charged_amount.aggregate_price, true) }</span></td>
                                        </tr>
                                }
                                <tr className="thin">
                                    <td colSpan="2" className="no-stretch text-end"><span className="body2">Total</span></td>
                                    <td className="no-stretch text-end"><span className="body2">{ currencyFormatFromPrice(mb.total_price.aggregate_price) }</span></td>
                                </tr>
                            </tbody>
                        </Table>
                    </td>
                </tr>
            </React.Fragment>
        )
    }

    const renderProductBreakdown = (product_breakdown, key) => {
        return (
            <React.Fragment key={key}>
            {
                _.map(product_breakdown.metrics_breakdown, (mb, j) =>
                    renderMetricBreakdown(product_breakdown.product, product_breakdown.product_pricing, mb, key + "" + j)
                )
            }
            {
                product_breakdown.discount_percent.aggregate_price.value_in_cents > 0 &&
                    _.map(product_breakdown.discount_percent.line_items, (lineItem, i) =>
                        <tr key={i} className="thin">
                            <td colSpan="4" className="text-end">Discount ({product_breakdown.product.name} - { lineItem.discount.name })</td>
                            <td className="no-stretch text-end">{ currencyFormatFromPrice(lineItem.aggregate_price, true) }</td>
                        </tr>
                    )
            }
            {
                product_breakdown.discount_amount.aggregate_price.value_in_cents > 0 &&
                    _.map(product_breakdown.discount_amount.line_items, (lineItem, i) =>
                        <tr key={i} className="thin">
                            <td colSpan="4" className="text-end">Discount ({product_breakdown.product.name} - { lineItem.discount.name })</td>
                            <td className="no-stretch text-end">{ currencyFormatFromPrice(lineItem.aggregate_price, true) }</td>
                        </tr>
                    )
            }
            </React.Fragment>
        )
    }

    const renderOneTimeCharge = (olt, o) => {
        const one_time_price = olt.one_time_price[0];
        const item = one_time_price.line_items[0];
        const product = olt.product;
        const hasDiscounts = (_.sum(_.map(olt.discount_percent, (d) => d.aggregate_price.value_in_cents)) + _.sum(_.map(olt.discount_amount, (d) => d.aggregate_price.value_in_cents))) > 0;
        return (
            <React.Fragment key={"o"+o}>
                <tr className={classnames(props.simplified ? "": "invoice-collapsible-row", "spacey")} onClick={() => openRow(`ob-${o}`)}>
                    <td className={classnames(props.simplified ? "thin-horizontal": "", "no-stretch")} style={{ paddingLeft: "3px", paddingRight: "3px" }}>
                        <div className="d-flex flex-row gap-2">
                            <div className={classnames("align-self-center", dataOpenMap[`mb-${0}`] ? "chevron-down-icon": "chevron-right-icon", props.expanded ? "hide": "")}/>
                            {
                                product.image_url ?
                                    <div className="small-product-logo align-self-center">
                                        <Image src={product.image_url} />
                                    </div>
                                    : <i className={classnames("fa fa-product-hunt fa-primary", props.simplified ? "": "hide")} style={{ fontSize: "35px" }}/>
                            }
                        </div>
                    </td>
                    <td>
                        <span className="body1">{ olt.product.name }</span>
                        {
                            olt.product_pricing.external_name &&
                                <><br/><span className="gray3">{ olt.product_pricing.external_name }</span></>
                        }
                    </td>
                    <td></td>
                    <td className="text-end">{ item.aggregate.toLocaleString("en-US") }</td>
                    <td className="text-end no-stretch">{ currencyFormatFromPrice(one_time_price.aggregate_price) }</td>
                </tr>
                <tr className={classnames("invoice-collapsible-row-data", props.simplified ? "hide": "", (dataOpenMap[`ob-${o}`] || props.expanded) ? "show": "hide")}>
                    <td colSpan="5" className="thin-horizontal">
                        <Table borderless>
                            <tbody>
                                <tr className="thin">
                                    <td className="text-end">Total units</td>
                                    <td className="no-stretch">{ item.aggregate.toLocaleString("en-US") }</td>
                                </tr>
                                {
                                    _.map(olt.one_time_price[0].line_items, (lineItem, li) =>
                                        <tr className="thin" key={"oli"+li}>
                                            <td colSpan="2" className="no-stretch text-end">{ lineItem.aggregate.toLocaleString("en-US") } units  @ { currencyFormatFromPrice({ value_in_cents: lineItem.fixed_price.price_per_unit, currency: olt.one_time_price[0].currency }) }</td>
                                            <td className="no-stretch text-end">
                                                { currencyFormatFromPrice(lineItem.aggregate_price) }
                                            </td>
                                        </tr>
                                    )
                                }
                                {
                                    hasDiscounts &&
                                        <tr className="thin">
                                            <td colSpan="2" className="no-stretch text-end"><span className="body2">Subtotal</span></td>
                                            <td className="no-stretch text-end">
                                                <span className="body2">
                                                    { currencyFormatFromPrice(olt.one_time_price[0].line_items[0].aggregate_price) }
                                                </span>
                                            </td>
                                        </tr>
                                }
                                {
                                    olt.discount_percent &&
                                        _.map(olt.discount_percent, (dp, d) =>
                                            _.map(dp.line_items, (lineItem, li) =>
                                                <tr className="thin" key={"odp"+d}>
                                                    <td colSpan="2" className="no-stretch text-end">{renderDiscountDescription(lineItem.discount)}</td>
                                                    <td className="no-stretch text-end">{ currencyFormatFromPrice(lineItem.aggregate_price, true) }</td>
                                                </tr>
                                            )
                                        )
                                }
                                {
                                    olt.discount_amount &&
                                        _.map(olt.discount_amount, (da, d) =>
                                            _.map(da.discount_amount, (lineItem, d) =>
                                                <tr className="thin" key={"oda"+d}>
                                                    <td colSpan="2" className="no-stretch text-end">{renderDiscountDescription(lineItem.discount)}</td>
                                                    <td className="no-stretch text-end">{ currencyFormatFromPrice(lineItem.aggregate_price, true) }</td>
                                                </tr>
                                            )
                                        )
                                }
                                <tr className="thin">
                                    <td colSpan="2" className="no-stretch text-end"><span className="body2">Total</span></td>
                                    <td className="no-stretch text-end"><span className="body2">{ currencyFormatFromPrice(one_time_price.aggregate_price) }</span></td>
                                </tr>
                            </tbody>
                        </Table>
                    </td>
                </tr>
            </React.Fragment>
        )
    }

    const renderCancellationBreakdown = (cancellation_breakdown) => {
        const filteredCancellations = _.filter(cancellation_breakdown || [], (cb) => cb.total_price.aggregate_price.value_in_cents > 0);
        return _.map(filteredCancellations, (cb, i) => {
            return (
                <tr>
                    <td></td>
                    <td>
                        { cb.item_pricing.name !== "" ?
                            <span className="body1">Cancellation Refund ({ cb.item_pricing.name })</span>
                            :<span className="body1">Cancellation Refund</span>
                        }
                    </td>
                    <td></td>
                    <td></td>
                    <td className="text-end no-stretch">{ currencyFormatFromPrice(cb.total_price.aggregate_price, true)}</td>
                </tr>
            )
        })
    }

    const renderAdjustmentDetails = (adjustmentDetails) => {
        return _.map(adjustmentDetails.line_items, (lineItem, i) => {
            const price = {
                value_in_cents: -1*lineItem.aggregate_price.value_in_cents,
                currency: -1*lineItem.aggregate_price.currency
            };
            return (
                <tr key={i}>
                    <td className="no-stretch"></td>
                    <td>
                        { lineItem.credit.name }
                    </td>
                    <td></td>
                    <td></td>
                    <td className="text-end no-stretch">{ currencyFormatFromPrice(price, false)}</td>
                </tr>
            )
        })
    }

    const hasDiscounts = () => {
        return hasCustomerDiscounts() || hasSubscriptionPercentDiscounts() || hasSubscriptionAmountDiscounts();
    }

    const hasCustomerDiscounts = () => {
        if (_.isEmpty(discountDetails)) {
            return false;
        }
        const aggregate = discountDetails.aggregate_price.value_in_cents;
        if (aggregate > 0) {
            return true;
        }
        return false;
    }

    const hasSubscriptionAmountDiscounts = () => {
        if (_.isEmpty(subscriptionDetails)) {
            return false;
        }
        if (!_.isNil(subscriptionDetails.discount_amount) && subscriptionDetails.discount_amount.aggregate_price.value_in_cents > 0) {
            return true;
        }
        return false;
    }

    const hasSubscriptionPercentDiscounts = () => {
        if (_.isEmpty(subscriptionDetails)) {
            return false;
        }
        if (!_.isNil(subscriptionDetails.discount_percent) && subscriptionDetails.discount_percent.aggregate_price.value_in_cents > 0) {
            return true;
        }
        return false;
    }

    const hasAdjustments = () => {
        if (_.isEmpty(subscriptionDetails)) {
            return false;
        }
        if (!_.isNil(subscriptionDetails.credit_amount) && !_.isEmpty(subscriptionDetails.credit_amount)) {
            return true;
        }
        return false;
    }

    const hasCredits = () => {
        if (_.isEmpty(creditDetails)) {
            return false;
        }
        const aggregate = creditDetails.aggregate_price.value_in_cents;
        if (aggregate !== 0) {
            return true;
        }
        return false;
    }

    const hasTaxes = () => {
        if (_.isEmpty(taxDetails)) {
            return false;
        }
        const aggregate = taxDetails.aggregate_price.value_in_cents;
        if (aggregate > 0) {
            return true;
        }
        return false;
    }

    const renderInnerLineItemList = (items, getFirst, getSecond) => {
        return (
            <Table borderless className="thin">
                <tbody>
                {
                    _.map(items, (lineItem, i) =>
                        renderInnerLineItem(i, getFirst(lineItem), getSecond(lineItem))
                    )
                }
                </tbody>
            </Table>
        )
    }

    const renderInnerLineItem = (key, first, second) => {
        return (
            <tr key={key} className="thin">
                <td colSpan="4" className="text-end">{ first }</td>
                <td className="no-stretch text-end">{ second }</td>
            </tr>
        )
    }

    const renderLaterSection = (title, details, getFirst, getSecond, negate) => {
        return (
            <>
                {
                    _.map(details.line_items, (lineItem, i) =>
                        renderInnerLineItem(i, getFirst(lineItem), getSecond(lineItem))
                    )
                }
                <tr className="hide">
                    <td colSpan="4" className="text-end body2">{ title }</td>
                    <td className="no-stretch text-end body2">{ currencyFormatFromPrice(details.aggregate_price, negate) }</td>
                </tr>
            </>
        )
    }

    const renderDiscountDescription = (discount) => {
        let description = "";
        if (discount.type === "DISCOUNT_AMOUNT") {
            description = currencyFormatFromPrice(discount.amount);
        } else if (discount.type === "DISCOUNT_PERCENT") {
            description = `${discount.percent * 100 }%`;
        }
        return `Discount (${ discount.name }, ${description})`;
    }

    const onChangeIncludeTaxes = (event) => {
        event.stopPropagation();
        event.preventDefault();
        if (props.onChangeIncludeTaxes) {
            props.onChangeIncludeTaxes(true);
        }
    }

    const subtotalBeforeDiscount = (_.isEmpty(oneTimeDetails) && _.isEmpty(subscriptionDetails)) ? {}: {
        value_in_cents: oneTimeDetails.one_time_price.aggregate_price.value_in_cents + subscriptionDetails.subscription_price.aggregate_price.value_in_cents,
        currency: props.total.currency
    };

    return (
        <>
            <Table hover={!props.simplified} borderless={props.simplified} className="no-background">
                <thead className={classnames(props.simplified ? "hide": "")}>
                    <tr>
                        <th className="alt"></th>
                        <th className="alt">Description</th>
                        <th className="alt no-stretch"></th>
                        <th className="alt text-end no-stretch">Quantity</th>
                        <th className="alt text-end no-stretch">Amount</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        subscriptionDetails && _.map(subscriptionDetails.product_breakdown, (pb, i) => renderProductBreakdown(pb, i))
                    }
                    {
                        oneTimeDetails && !_.isEmpty(oneTimeDetails.product_breakdown) &&
                            _.map(oneTimeDetails.product_breakdown, (olt, m) => renderOneTimeCharge(olt, m))
                    }
                    {
                        subscriptionDetails && subscriptionDetails.cancellation_breakdown && renderCancellationBreakdown(subscriptionDetails.cancellation_breakdown)
                    }
                    {
                        hasAdjustments() && renderAdjustmentDetails(subscriptionDetails.credit_amount)
                    }
                    {
                        props.simplified &&
                            <tr className="thin" style={{ borderBottom: "1px solid lightgray" }}/>
                    }
                </tbody>
            </Table>
            <Table borderless className="no-background">
                <tbody>
                    {
                        hasDiscounts() &&
                            <tr className={classnames(props.simplified ? "spacey": "thin")}>
                                <td colSpan="4" className="text-end"></td>
                                <td className="no-stretch text-end">
                                    <span className="body2">
                                        { currencyFormatFromPrice(subtotalBeforeDiscount) }
                                    </span>
                                </td>
                            </tr>
                    }
                    {
                        hasSubscriptionPercentDiscounts() &&
                            renderLaterSection(
                                "Total Discounts2",
                                subscriptionDetails.discount_percent,
                                (item) => renderDiscountDescription(item.discount),
                                (item) => currencyFormatFromPrice(item.aggregate_price, true),
                                true
                            )
                    }
                    {
                        hasSubscriptionAmountDiscounts() &&
                            renderLaterSection(
                                "Total Discounts2",
                                subscriptionDetails.discount_amount,
                                (item) => renderDiscountDescription(item.discount),
                                (item) => currencyFormatFromPrice(item.aggregate_price, true),
                                true
                            )
                    }
                    {
                        hasCustomerDiscounts() &&
                            renderLaterSection(
                                "Total Discounts",
                                discountDetails,
                                (item) => renderDiscountDescription(item.discount),
                                (item) => currencyFormatFromPrice(item.aggregate_price, true),
                                true
                            )
                    }
                    {
                        !props.isPreview &&
                            <>
                                <tr className={props.simplified ? "spacey": "thin"}>
                                    <td colSpan="4" className="text-end"><span className="body2">Subtotal</span></td>
                                    <td className="no-stretch text-end"><span className="body2">{ currencyFormatFromPrice(props.subTotal) }</span></td>
                                </tr>
                                {
                                    props.showTaxAsEstimate &&
                                        <tr className={props.simplified ? "": "thin"}>
                                            <td colSpan="4" className="text-end body2"><span className="body2">Tax</span></td>
                                            <td className="no-stretch text-end caption gray3">
                                                <span>*Calculated when finalized.</span><br/>
                                                <span><a href="" onClick={onChangeIncludeTaxes}>Calculate</a></span>
                                            </td>
                                        </tr>
                                }
                                {
                                    !props.showTaxAsEstimate && hasTaxes() &&
                                        renderLaterSection(
                                            "Total Tax",
                                            taxDetails,
                                            (item) => `${ item.tax.name } (${ item.tax.rate *100 }%)`,
                                            (item) => currencyFormatFromPrice(item.aggregate_price),
                                            false
                                        )
                                }
                            </>
                    }
                    {
                        hasCredits() &&
                            renderLaterSection(
                                "Total Credits",
                                creditDetails,
                                (item) => `Credit (${ item.credit.name })`,
                                (item) => currencyFormatFromPrice(item.aggregate_price),
                                true
                            )
                    }
                    <tr className={props.simplified ? "": "thin"}>
                        <td colSpan="4" className="text-end body2"><strong>Total</strong></td>
                        <td className="no-stretch text-end body2"><strong>{ currencyFormatFromPrice(props.total) }</strong></td>
                    </tr>
                    {
                        !props.isPreview && props.paid.value_in_cents > 0 &&
                            <tr className={props.simplified ? "": "thin"}>
                                <td colSpan="4" className="text-end body2"><strong>Total Payments</strong></td>
                                <td className="no-stretch text-end body2"><strong>{ currencyFormatFromPrice(props.paid) }</strong></td>
                            </tr>
                    }
                    {
                        !props.isPreview && !_.isNil(props.refunds) && !_.isEmpty(props.refunds) &&
                            <>
                                {
                                    _.map(props.refunds, (refund, r) =>
                                        <tr className={props.simplified ? "": "thin"}>
                                            <td colSpan="4" className="text-end body2"><strong>Refund</strong></td>
                                            <td className="no-stretch text-end body2"><strong>{ currencyFormatFromPrice(refund.total_amount) }</strong></td>
                                        </tr>
                                    )
                                }
                            </>
                    }
                    {
                        !props.isPreview &&
                            <tr className={props.simplified ? "": "thin"}>
                                <td colSpan="4" className="text-end body2"><strong>Amount Due</strong></td>
                                <td className="no-stretch text-end body2"><strong>{ currencyFormatFromPrice(props.due) }</strong></td>
                            </tr>
                    }
                </tbody>
            </Table>
        </>
    );
}

export default PricingBreakdown;

import '../../../App.scss';
import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { serverPost } from '../../../helpers/server';
import { BaseContext } from '../../../helpers/common';
import { useTranslation } from 'react-i18next';
import BaseContainer from '../../../components/BaseContainer';
import ContentContainer from '../../../components/ContentContainer';
import ContentBox from '../../../components/ContentBox';
import { Button, Row, Col } from 'react-bootstrap';
import {getFeatureDescription, getFeatureDescriptions} from "../../../helpers/integrations";
import classnames from 'classnames';
const _ = require('lodash');

function Integrations() {
    const navigate = useNavigate();
    const { t } = useTranslation('common');
    const { company, getApiUrl, getCompanySpecificUrl, setPageTitle } = useContext(BaseContext);
    const [installedIntegrations, setInstalledIntegrations] = useState([]);
    const [partiallyInstalledIntegrations, setPartiallyInstalledIntegrations] = useState([]);
    const [availableIntegrations, setAvailableIntegrations] = useState([]);

    useEffect(() => {
        setPageTitle(`Integrations`);
    }, []);

    useEffect(() => {
        serverPost(getApiUrl("/integrations/available"), { company_id: company.id }).then((res) => {
            setAvailableIntegrations(res.results);
        });

        serverPost(getApiUrl("/integrations/installed"), { company_id: company.id }).then((res) => {
            setPartiallyInstalledIntegrations(_.filter(res.results, (r) => r.status !== "ACTIVE"));
            setInstalledIntegrations(_.filter(res.results, (r) => r.status === "ACTIVE"));
        });
    }, [company, getApiUrl])

    const onInstallIntegration = async (integration) => {
        let targetIntegration = integration;
        if (integration.status === "AVAILABLE") {
            // Haven't started the installation yet
            const installData = {
                key: integration.key,
                live_mode: false
            }
            const installResult = await serverPost(getApiUrl('/integrations/install'), installData);
            if (installResult) {
                targetIntegration = installResult;
            }
        } else {
            // Already installed or installation started
        }

        navigate(getCompanySpecificUrl(`/integrations/${targetIntegration.id}/setup`));
    }

    return (
        <BaseContainer>
            <ContentContainer>
                <h5>{t('integrations.installed_apps.title')}</h5>
                <br/>
                <Row>
                {
                    _.isEmpty(installedIntegrations) &&
                        <span>No integrations installed. Please start by installing one below.</span>
                }
                {
                    _.map(installedIntegrations, (integration, i) => {
                        const descriptions = getFeatureDescriptions(integration);
                        return (<Col key={i} md="4">
                            <ContentBox className="integration gap-2">
                                <div className="integration-header">
                                    <img src={ integration.icon } alt="Integration Logo"/>
                                    <span>{ integration.name }</span>
                                </div>
                                <div className="flex flex-row gap-2">
                                    {
                                        _.map(descriptions, (f, k) =>
                                            <span
                                                className={classnames("inline-flex items-center rounded-md px-2 py-1 text-xs font-medium", f.pillClassName)}>{f.shortTitle}</span>
                                        )
                                    }
                                </div>
                                <div className="integration-body">
                                    { integration.description }
                                </div>
                                <div className="integration-footer d-flex flex-column align-items-end">
                                    <Link to={getCompanySpecificUrl(`/integrations/${integration.id}/settings`)}><Button variant="primary">Manage</Button></Link>
                                </div>
                            </ContentBox>
                        </Col>)
                    })
                }
                </Row>
                <br/>
                {
                    !_.isEmpty(partiallyInstalledIntegrations) &&
                        <>
                            <h5>{t('integrations.partially_installed_apps.title')}</h5>
                            <br/>
                            <Row>
                            {
                                _.map(partiallyInstalledIntegrations, (integration, i) => {
                                    const descriptions = getFeatureDescriptions(integration);
                                    return (<Col key={i} md="4">
                                        <ContentBox className="integration gap-2">
                                            <div className="integration-header">
                                                <img src={ integration.icon } alt="Integration Logo"/>
                                                <span>{ integration.name }</span>
                                            </div>
                                            <div className="flex flex-row gap-2">
                                                {
                                                    _.map(descriptions, (f, k) =>
                                                        <span
                                                            className={classnames("inline-flex items-center rounded-md px-2 py-1 text-xs font-medium", f.pillClassName)}>{f.shortTitle}</span>
                                                    )
                                                }
                                            </div>
                                            <div className="integration-body">
                                                { integration.description }
                                            </div>
                                            <div className="integration-footer d-flex flex-column align-items-end">
                                                <Link to={getCompanySpecificUrl(`/integrations/${integration.id}/setup`)}><Button variant="primary" >Finish Setup</Button></Link>
                                            </div>
                                        </ContentBox>
                                    </Col>)
                                })
                            }
                            </Row>
                            <br/>
                        </>
                }
                <h5>{t('integrations.available_apps.title')}</h5>
                <br/>
                <Row>
                {
                    _.map(availableIntegrations, (integration, i) => {
                        const descriptions = getFeatureDescriptions(integration);
                        return (<Col key={i} md="4">
                            <ContentBox className="integration gap-2">
                                <div className="integration-header">
                                    <img src={integration.icon} alt="Integration Logo"/>
                                    <span>{integration.name}</span>
                                </div>
                                <div className="flex flex-row gap-2">
                                    {
                                        _.map(descriptions, (f, k) =>
                                            <span
                                                className={classnames("inline-flex items-center rounded-md px-2 py-1 text-xs font-medium", f.pillClassName)}>{f.shortTitle}</span>
                                        )
                                    }
                                </div>
                                <div className="integration-body">
                                    {integration.description}
                                </div>
                                <div className="integration-footer d-flex flex-column align-items-end">
                                    <Button variant="primary"
                                            onClick={() => onInstallIntegration(integration)}>Install</Button>
                                </div>
                            </ContentBox>
                        </Col>)
                    })
                }
                </Row>
            </ContentContainer>
        </BaseContainer>
    );
}

export default Integrations;

import '../../App.scss';
import '../../css/modals.scss';
import React, { useState, useEffect, useContext } from 'react';
import BaseModal from './BaseModal';
import BaseForm from '../BaseForm';
import SubmitButton from '../SubmitButton';
import { Row, Col } from 'react-bootstrap';
import { serverFetch } from '../../helpers/server';
import { BaseContext, currencyFormatFromPrice } from '../../helpers/common';
import { useTranslation } from 'react-i18next';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
const _ = require('lodash');

function StripeInvoicePaymentModal(props) {
    const { t } = useTranslation('common');
    const { getApiUrl } = useContext(BaseContext);
    const [settings, setSettings] = useState({});
    const [invoice, setInvoice] = useState(null);
    const [paymentIntent, setPaymentIntent] = useState(null);

    useEffect(() => {
        if (props.show) {
            setInvoice(props.invoice);
        } else {
            setInvoice(null);
        }
    }, [props.show, props.invoice])

    useEffect(() => {
        serverFetch(getApiUrl("/settings")).then((res) => {
            setSettings(res);
        });
    }, []);

    useEffect(() => {
        if (invoice) {
            serverFetch(getApiUrl(`/invoices/${invoice.uuid}/payment_intent`)).then((res) => {
                if (res) {
                    setPaymentIntent(res);
                }
            })
        }
    }, [invoice, getApiUrl])

    const publishableKey = settings.payment_provider_live_mode ?
        process.env.REACT_APP_STRIPE_PROD_PUBLISHABLE_KEY
        : process.env.REACT_APP_STRIPE_TEST_PUBLISHABLE_KEY;
    const stripePromise = loadStripe(publishableKey, {
        stripeAccount: props.paymentConfig && props.paymentConfig.account_id
    });
    const options = paymentIntent && {
        clientSecret: paymentIntent.intent_secret,
        appearance: {/*...*/},
    };

    return (
        <BaseModal {...props}>
            <BaseModal.Header>
                <BaseModal.Title>{t('invoice.payment.title')}</BaseModal.Title>
            </BaseModal.Header>
            {
                _.isNil(paymentIntent) ?
                    <BaseModal.Body>
                        <div className="spinner-border text-secondary"/>
                    </BaseModal.Body>
                :   <Elements stripe={stripePromise} options={options}>
                        <CheckoutForm invoice={invoice} />
                    </Elements>
            }
        </BaseModal>
    );

}

function CheckoutForm(props) {
    const stripe = useStripe();
    const elements = useElements();
    const [errorMessage, setErrorMessage] = useState(null);
    const [redirectUrl, setRedirectUrl] = useState(null);

    useEffect(() => {
        if (_.isNil(props.invoice)) {
            setRedirectUrl(null);
            return;
        }
        const company = props.invoice.company;
        const domain = window.location.origin;
        const url = `${domain}/c/${company.id}/invoice/view?uuid=${props.invoice.uuid}`
        setRedirectUrl(url);
    }, [props.invoice])

    const onSubmit = async (data) => {
        if (!stripe || !elements) {
            return;
        }

        const {error} = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: redirectUrl,
            },
        });

        if (error) {
            setErrorMessage(error.message);
        } else {

        }
    };

    return (
        <>
            <BaseForm onSubmit={onSubmit}>
                <BaseModal.Body>
                    <div className="body1">
                        Processing payment for invoice ({ props.invoice.number }) for the amount { currencyFormatFromPrice(props.invoice.due) }
                    </div>
                    <br/>
                    <PaymentElement />
                    {errorMessage && <div className="form-error-message">{errorMessage}</div>}
                </BaseModal.Body>
                <BaseModal.Footer>
                    <Row>
                        <Col md="12" className="text-end">
                            <SubmitButton variant="primary">Process Payment</SubmitButton>
                        </Col>
                    </Row>
                </BaseModal.Footer>
            </BaseForm>
        </>
    )
}

export default StripeInvoicePaymentModal;

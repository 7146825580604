import '../../App.scss';
import variables from '../../App.scss';
import React, { useEffect, useState, useContext } from 'react';
import { useNavigate} from 'react-router-dom';
import {serverFetch, serverPost} from '../../helpers/server';
import {
    BaseContext, currencyFormatFromPrice
} from '../../helpers/common';
import BaseContainer from '../../components/BaseContainer';
import ContentContainer from '../../components/ContentContainer';
import Section from '../../components/Section';
import { Row, Col } from 'react-bootstrap';
import classnames from 'classnames';
import moment from 'moment';
import BaseForm from "../../components/BaseForm";
const _ = require('lodash');

function ContractsMetrics() {
    const navigate = useNavigate();
    const { company, getApiUrl, setPageTitle } = useContext(BaseContext);
    const [metricsMap, setMetricsMap] = useState({})
    const [teamMembers, setTeamMembers] = useState([])
    const [ownerId, setOwnerId] = useState(null);
    const [period, setPeriod] = useState(null);

    useEffect(() => {
        setPageTitle(`Contracts - Dashboard`);
    }, []);

    useEffect(() => {
        if (!_.isNil(period)) {
            retrieveMetrics("")
        }
    }, [ownerId, period])

     useEffect(() => {
        serverFetch(getApiUrl("/users")).then((res) => {
            const nonPendingMembers = _.filter(res, (r) => !r.pending);
            setTeamMembers(nonPendingMembers);
        });
    }, [])

    const onFieldChange = (name, value) => {
        if (name === "owner") {
            setOwnerId(value);
        } else if (name === "period") {
            setPeriod(value);
        }
    }

    const retrieveMetrics = () => {
        const data = {
            period: periodMap[period],
            owner_id: ownerId
        }
        serverPost(getApiUrl(`/proposals/metrics_summary`), data).then((res) => {
            if (res) {
                setMetricsMap(res.breakout)
            }
        });
    }

    const states = [
        "CONTACT_DRAFT", "CONTRACT_CREATED", "CONTRACT_PENDING_SIGNATURES", "CONTRACT_PENDING_PAYMENT",
        "CONTRACT_EXECUTED", "CONTRACT_DECLINED", "CONTRACT_EXPIRED"
    ]

    const stateMap = {
        "CONTACT_DRAFT": {
            title: 'Draft',
            color: variables.whiteColor,
            key: "DRAFT"
        },
        "CONTRACT_CREATED": {
            title: "Created",
            color: variables.aegeanColor,
            textColor: variables.whiteColor,
            key: "CREATED"
        },
        "CONTRACT_PENDING_SIGNATURES": {
            title: "Pending Signature",
            color: variables.tigerColor,
            textColor: variables.whiteColor,
            key: "PENDING_SIGNATURES"
        },
        "CONTRACT_PENDING_PAYMENT": {
            title: "Pending Payment",
            color: variables.warningColor,
            textColor: variables.whiteColor,
            key: "PENDING_PAYMENT"
        },
        "CONTRACT_EXECUTED": {
            title: "Executed",
            color: variables.positiveColor,
            textColor: variables.whiteColor,
            key: "EXECUTED"
        },
        "CONTRACT_DECLINED": {
            title: "Declined",
            color: variables.dangerColor,
            textColor: variables.whiteColor,
            key: "DECLINED"
        },
        "CONTRACT_EXPIRED": {
            title: "Expired",
            color: variables.gray2Color,
            textColor: variables.whiteColor,
            key: "EXPIRED"
        }
    }

    const getCount = (key) => {
        if (metricsMap[key]) {
            return metricsMap[key].count
        } else {
            return 0
        }
    }

    const getValue = (key) => {
        if (metricsMap[key]) {
            return metricsMap[key].total_tcv
        } else {
            return {
                value_in_cents: 0,
                currency: company.preferred_currency
            }
        }
    }

    const teamMemberOptions = _.map(teamMembers, (member) => {
        return {
            value: member.id,
            label: member.user.name || member.user.email
        }
    })
    teamMemberOptions.unshift({ value: null, label: "Everyone" })

    const periodMap = {
        '1_WEEK': {
            start_date: moment().subtract(1, 'week'),
            end_date: moment()
        },
        '1_MONTH': {
            start_date: moment().subtract(1, 'month'),
            end_date: moment()
        },
        'MONTH_TO_DATE': {
            start_date: moment().startOf('month'),
            end_date: moment()
        },
        '3_MONTHS': {
            start_date: moment().subtract(3, 'month'),
            end_date: moment()
        },
        'QUARTER_TO_DATE': {
            start_date: moment().startOf('quarter'),
            end_date: moment()
        },
        '1_YEAR': {
            start_date: moment().subtract(1, 'year'),
            end_date: moment()
        },
        'YEAR_TO_DATE': {
            start_date: moment().startOf('year'),
            end_date: moment()
        },
    }
    const periodOptions = [
        { value: "1_WEEK", label: "1 Week" },
        { value: "1_MONTH", label: "1 Month" },
        { value: "MONTH_TO_DATE", label: "Month to date" },
        { value: "3_MONTHS", label: "3 Months" },
        { value: "QUARTER_TO_DATE", label: "Quarter to date" },
        { value: "1_YEAR", label: "1 Year" },
        { value: "YEAR_TO_DATE", label: "Year to date" }
    ]

    return (
        <BaseContainer>
            <ContentContainer>
                <Section title="Contract Metrics">
                    <BaseForm onFieldChange={onFieldChange}>
                        <Row>
                            <Col md="6"/>
                            <BaseForm.Input colSpan="3" name="owner" type="select" options={teamMemberOptions}/>
                            <BaseForm.Input colSpan="3" name="period" type="select" options={periodOptions} showSearch={false}/>
                        </Row>
                    </BaseForm>
                    <Row>
                        {
                            _.map(states, (st, i) =>
                                <Col md="4" key={i}>
                                    <div className={classnames("integration")}
                                         style={{ backgroundColor: stateMap[st].color, color: stateMap[st].textColor }}>
                                        <div className="integration-header">
                                            <div>
                                                <div className="d-flex flex-row gap-3 align-items-center body2">
                                                    { stateMap[st].title }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="integration-body">
                                            <span className="body2" style={{ fontSize: "40px" }}>{ getCount(stateMap[st].key) }</span>
                                        </div>
                                        <div className="integration-footer d-flex flex-row align-items-center">
                                            <div className="body1">
                                                { currencyFormatFromPrice(getValue(stateMap[st].key)) }
                                            </div>
                                            <div className="flex-grow-1"/>
                                            <span className="flex-grow-0"></span>
                                        </div>
                                    </div>
                                </Col>
                            )
                        }
                    </Row>
                </Section>
            </ContentContainer>
        </BaseContainer>
    );
}

export default ContractsMetrics;

import '../App.scss';
import React, {useEffect, useState, forwardRef, useImperativeHandle, useContext} from 'react';
import BaseForm from './BaseForm';
import DeleteButton from './DeleteButton';
import SingleSelectDropdown from './SingleSelectDropdown';
import { Table, Row, Button, Col } from 'react-bootstrap';
import {
    renderDescriptionForItemPricing, getDescriptionForCoupon,
    getLabelForCouponType, BaseContext
} from '../helpers/common';
import { useTranslation } from 'react-i18next';
import {serverPost} from "../helpers/server";
import Select from "react-select/async";
const _ = require('lodash');

const ProductPricingSelection = forwardRef((props, ref)  => {
    useImperativeHandle(ref, () => ({
        validate(allFields) {
            return validate(allFields);
        },
        getPricingSelectionFields(allFields) {
            return getPricingSelectionFields(allFields);
        }
    }));

    const { getApiUrl, company } = useContext(BaseContext);
    const { t } = useTranslation('common');
    const [coupons, setCoupons] = useState([]);
    const [selectedCoupons, setSelectedCoupons] = useState([]);
    const [allProductPricings, setAllProductPricings] = useState([]);
    const [selectedProductPricings, setSelectedProductPricings] = useState([]);
    const [editable, setEditable] = useState(true);
    const [allowDiscounts, setAllowDiscounts] = useState(true);
    const [requireRecurring, setRequireRecurring] = useState(false);

    useEffect(() => {
        setAllProductPricings(props.productPricings);
    }, [props.productPricings])

    useEffect(() => {
        setCoupons(props.coupons);
    }, [props.coupons])

    useEffect(() => {
        setAllowDiscounts(props.allowDiscounts);
    }, [props.allowDiscounts])

    useEffect(() => {
        if (_.isNil(props.editable)) {
            setEditable(true);
        } else {
            setEditable(props.editable);
        }
    }, [props.editable])

    useEffect(() => {
        setRequireRecurring(props.requireRecurring || false);
    }, [props.requireRecurring])

    useEffect(() => {
        const selectedIds = props.preselectedProductPricingIds || [];
        const selectPricings = []
        _.each(selectedIds, (id) => {
            const pp = _.find(allProductPricings, (p) => p.id === id);
            if (pp) {
                selectPricings.push(pp);
            }
        })
        setSelectedProductPricings(selectPricings);
    }, [allProductPricings, props.preselectedProductPricingIds])

    const validate = (allFields) => {
        if (selectedProductPricings.length === 0) {
            return "Please select at least one product price."
        }
        const uniqueCurrencies = _.uniq(_.map(selectedProductPricings, (pp) => pp.currency));
        if (uniqueCurrencies.length > 1) {
            return "Multiple currencies are not permitted together."
        }
        if (requireRecurring) {
            const recurringProductPricings = _.filter(selectedProductPricings, (pp) => pp.type !== "ONETIME_PRICING");
            if (recurringProductPricings.length === 0) {
                return "Please select at least one recurring price.";
            }
        }
        return null;
    }

    const getPricingSelectionFields = (allFields) => {
        let discounts = [];
        _.each(allFields.discounts, (value, key) => {
            if (parseFloat(value.percentage) > 0) {
                discounts.push({
                    name: "Discount",
                    type: "DISCOUNT_PERCENT",
                    customer_id: allFields.customer.id,
                    percent: parseFloat(value.percentage)/100,
                    state:"ACTIVE",
                    item_pricing_id: key
                })
            }
        })
        return {
            currency: selectedProductPricings[0].currency,
            config_items: _.map(allFields.config, (v, k) => { return {...v, num_licenses: parseInt(v.num_licenses || "0"), minimum_units: parseInt(v.minimum_units || "0"), product_metric_pricing_id: k} }),
            product_pricing_ids: _.map(_.filter(selectedProductPricings, (a) => a.type !== "ONETIME_PRICING"), (p) => p.id),
            onetime_items: _.map(_.filter(selectedProductPricings, (a) => a.type === "ONETIME_PRICING"), (p) => {
                const pmp_id = p.product_metric_pricings[0].id;
                return {
                    product_pricing_id: p.id,
                    aggregate: parseInt(allFields.config[String(pmp_id)].num_licenses)
                }
            }),
            discounts: discounts
        }
    }

    const onProductProductPricingDelete = (value) => {
        setSelectedProductPricings(prevSelectedProductPricings => {
            return _.filter(prevSelectedProductPricings, (p) => p.id !== value);
        })
    }

    const onCouponSelect = (value) => {
        if (value === null) {
            return;
        }
        const cc = _.find(coupons, (c) => String(c.id) === String(value));
        console.log("Coupon selected " + value + ", " + JSON.stringify(cc));
        setSelectedCoupons(prevCoupons => {
            const newCoupons = [...prevCoupons];
            newCoupons.push(cc);
            return newCoupons;
        })
    }

    const onCouponDelete = (value) => {
        setSelectedCoupons(prevCoupons => {
            return _.filter(prevCoupons, (c) => c.id !== value);
        })
    }

    const couponOptions = _.map(coupons, (coupon) => {
        return {
            id: coupon.id,
            label: coupon.name
        };
    })

    const loadPrices = (query, callback) => {
        const limit = 100
        const params = {
            company_id: company.id,
            sort_key: "createdAtDesc",
            search_query: query,
            query: props.extraQueryFilters && {
                ...props.extraQueryFilters
            },
            pagination: {
                limit: limit
            },
        }
        serverPost(getApiUrl("/product_pricings/autocomplete"), params).then(async (res) => {
            if (res) {
                const allResults = res.results || [];
                const groupedPrices = _.groupBy(allResults, (p) => p.product.name);
                const results = _.map(groupedPrices, (values, key) => {
                    return {
                        label: key,
                        options: values
                    };
                })
                callback(results);
            }
        });
    }

    const pricingLabel = (pricing) => {
        const ppLabel = pricing.name || pricing.description || "";
        return (
            <div>
                { !_.isEmpty(ppLabel) && <span>{ ppLabel }</span> }
                <div>
                    {
                        _.map(pricing.product_metric_pricings, (pmp, i) =>
                            <React.Fragment key={i}>
                                <span key={i}>{renderDescriptionForItemPricing(pmp.item_pricing, false, pmp.item)}</span>
                            </React.Fragment>
                        )
                    }
                </div>
            </div>
        )
    }

    const groupLabel = (data) => (
        <div className="d-flex flex-row gap-3" style={{ paddingTop: "4px", paddingBottom: "4px" }}>
            <span className="flex-grow-1 body1 gray4" style={{ textTransform: "initial" }}>{data.label}</span>
            <span className="flex-grow-0 body1 gray4" style={{ textTransform: "initial", whiteSpace: "nowrap"}}>{data.options.length} Prices</span>
        </div>
    );

    const selectedProductPricingIds = _.map(selectedProductPricings, (pp) => pp.id);
    return (
        <div>
            <p><strong>{ t('subscriptions.product_pricings') }</strong></p>
            {
                _.map(selectedProductPricings, (pp, i) => {
                    const pmps = pp.product_metric_pricings;
                    return (
                        <React.Fragment key={i}>
                            <Row>
                                <Col md="12">
                                    <span className="body2">{ pp.product.name }</span>
                                    <DeleteButton onDelete={() => onProductProductPricingDelete(pp.id)} />
                                </Col>
                            </Row>
                            {
                                _.map(pmps, (pmp, j) =>
                                    <Row key={j} className="metric-pricing-row">
                                        <Col md="5">
                                            { renderDescriptionForItemPricing(pmp.item_pricing, true, pmp.item) }
                                        </Col>
                                        {
                                            pmp.metric.type === "LICENSE_METRIC" &&
                                                <BaseForm.Input colSpan={allowDiscounts ? "5": "7"} name={`config.${pmp.id}.num_licenses`} label={t('common.quantity')} type="number" min="0" required />
                                        }
                                        {
                                            pmp.metric.type === "ONETIME_METRIC" &&
                                                <BaseForm.Input colSpan={allowDiscounts ? "5": "7"} name={`config.${pmp.id}.num_licenses`} label="One time" type="number" min="0" required />
                                        }
                                        {
                                            pmp.metric.type === "CUSTOM_METRIC" &&
                                                <Col md={allowDiscounts ? "3": "4"} className="d-flex flex-column gap-2">
                                                    <div className="body2">Metric</div>
                                                    { pmp.metric.name }
                                                </Col>
                                        }
                                        {
                                            pmp.metric.type === "CUSTOM_METRIC" &&
                                                <BaseForm.Input colSpan={allowDiscounts ? "2": "3"} name={`config.${pmp.id}.minimum_units`} label="Minimum (optional)" type="number" min="0" />
                                        }
                                        {
                                            allowDiscounts &&
                                                <BaseForm.Input colSpan="2" name={`discounts.${pmp.item_pricing.id}.percentage`} label="Discount %" type="number" min="0" step="0.01" max="100" />
                                        }
                                    </Row>
                                )
                            }
                        </React.Fragment>
                    )
                })
            }
            {
                !_.isEmpty(selectedProductPricings) &&
                    <><br/></>
            }
            {
                editable &&
                    <Row>
                    <Col lg="6">
                        <div className="form-group">
                            <span className="body2">Add a price</span>
                            <div className="form-input">
                                <Select
                                    menuPlacement="auto"
                                    components={{ DropdownIndicator: null }}
                                    isMulti={false}
                                    className="select-container"
                                    loadOptions={loadPrices}
                                    defaultOptions
                                    classNamePrefix="select2"
                                    getOptionLabel={pricingLabel}
                                    getOptionValue={(c) => c.id}
                                    value={null}
                                    placeholder="Add a price..."
                                    onChange={(value, meta) => {
                                        setSelectedProductPricings(prevSelectedProductPricings => {
                                            const newSelectedProductPricings = [...prevSelectedProductPricings];
                                            newSelectedProductPricings.push(value);
                                            return newSelectedProductPricings;
                                        })
                                    }}
                                    isOptionDisabled={(option) => {
                                        return _.includes(selectedProductPricingIds, option.id);
                                    }}
                                    isClearable={false}
                                    noOptionsMessage={(inputValue) => { return `Start typing...` }}
                                    formatGroupLabel={groupLabel}
                                />
                            </div>
                        </div>
                    </Col>
                    </Row>
            }
            <br/><br/>
            {
                allowDiscounts && false &&
                    <>
                        <p><strong>Coupons</strong></p>
                        <Table>
                            <tbody>
                            {
                                _.map(selectedCoupons, (coupon, i) =>
                                     <tr key={i}>
                                        <td><span className="body2">{ coupon.name }</span></td>
                                        <td>
                                            <span>{ getLabelForCouponType(coupon.type) }</span><br/>
                                            <span>{ getDescriptionForCoupon(coupon) }</span>
                                        </td>
                                        <td className="no-stretch">
                                            <Button variant="text-danger" size="sm" onClick={() => onCouponDelete(coupon.id)} ><i className="fa fa-trash"/></Button>
                                        </td>
                                     </tr>
                                )
                            }
                            </tbody>
                        </Table>
                        <SingleSelectDropdown
                            items={couponOptions}
                            onSelect={onCouponSelect}
                            label="Select Coupon"
                            menuOnly={true}
                        />
                    </>
            }
        </div>
    )
})

export default ProductPricingSelection;

import '../../App.scss';
import '../../css/contract.scss';
import React, { useEffect, useState, useContext, createRef } from 'react';
import { serverFetch, serverPost } from '../../helpers/server';
import { BaseContext } from '../../helpers/common';
import ContentContainer from '../../components/ContentContainer';
import SubmitButton from '../../components/SubmitButton';
import StripeSetupPaymentMethod from '../../components/StripeSetupPaymentMethod';
import PricingBreakdown from '../../components/PricingBreakdown';
import BaseForm from '../../components/BaseForm';
import { Row, Col } from 'react-bootstrap';
import { useSearchParams, useParams, Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const _ = require('lodash');

function PaymentLinkPay() {
    const navigate = useNavigate();
    const { t } = useTranslation('common');
    const { type, uuid } = useParams();
    const [ searchParams ] = useSearchParams();
    let redirectStatus = searchParams.get('redirect_status');
    let setupIntent = searchParams.get('setup_intent');

    const { company, getApiUrl, setPageTitle, getCompanySpecificUrl } = useContext(BaseContext);
    const [loadingPaymentLink, setLoadingPaymentLink] = useState(true);
    const [paymentLink, setPaymentLink] = useState({});
    const [pricingSummary, setPricingSummary] = useState(null);
    const [customerToken, setCustomerToken] = useState(null);
    const [settings, setSettings] = useState({});
    const setupRef = createRef();

    useEffect(() => {
        setPageTitle(`Buy`);
    }, [paymentLink]);

    useEffect(() => {
        if (!_.isNil(uuid)) {
            fetchData();
        }

        const token = localStorage.getItem(`proposal_${uuid}_customer_token`);
        setCustomerToken(token);
    }, [uuid])

    useEffect(() => {
        serverFetch(getApiUrl(`/settings`)).then((res) => {
            setSettings(res)
        })
    }, [])

    useEffect(() => {
        if (redirectStatus === "succeeded" && !loadingPaymentLink) {
            payWithSetupIntent();
        }
    }, [redirectStatus, loadingPaymentLink])

    const payWithSetupIntent = () => {
        const payData = {
            setup_intent_id: setupIntent
        }
        const payOptions = {
            accessToken: customerToken
        }
        serverPost(getApiUrl(`/proposals/${uuid}/pay`), payData, payOptions).then((res) => {
            if (!_.isNil(res)) {
                navigate(getCompanySpecificUrl(`/buy/${uuid}/done`));
            }
        })
    }

    const fetchData = (skipCache=false) => {
        serverFetch(getApiUrl(`/proposals/${uuid}`), { skipCache: skipCache }).then((res) => {
            setPaymentLink(res);
            setLoadingPaymentLink(false);
        })

        serverPost(getApiUrl(`/proposals/${uuid}/pricing`), {}).then((res) => {
            setPricingSummary(res);
        })
    }

    const onSubmit = async (data) => {
        if (setupRef.current) {
            await setupRef.current.onSubmit(data);
        }
    }

    return (
        <ContentContainer className="full" bodyClassName="content-body-full">
            <div className="request-pay">
            {
                _.isNil(redirectStatus) ?
                    <>
                    <div className="left-half">
                        <div className="d-flex flex-row justify-content-end">
                            <div className="plan-details">
                                <h3 className="gray4">{ company.name }</h3>
                                <br/>
                                {
                                    pricingSummary &&
                                        <PricingBreakdown
                                            discountDetails={pricingSummary.discount_details}
                                            creditDetails={pricingSummary.credit_details}
                                            subscriptionDetails={pricingSummary.subscription_details}
                                            oneTimeDetails={pricingSummary.one_time_details}
                                            total={pricingSummary.total}
                                            expanded={true}
                                            isPreview={true} />
                                }
                            </div>
                        </div>
                        <div className="d-flex flex-row justify-content-end">
                            <div className="footer-details">
                                <Link to="/" target="_blank"><span className="caption">Powered by Maple</span></Link>
                            </div>
                        </div>
                    </div>
                    <div className="right-half">
                        <div className="d-flex flex-row justify-content-start">
                            <div className="payment-details">
                                <BaseForm onSubmit={onSubmit}>
                                    <div className="body2">
                                        Pay with card
                                    </div>
                                    <div className="payment_input">
                                    {
                                        settings.payment_config && settings.payment_config.provider === "STRIPE" &&
                                            <StripeSetupPaymentMethod ref={setupRef}
                                                redirectUrl={window.location.href} paymentConfig={settings.payment_config}
                                                accessToken={customerToken}  setupUrl={getApiUrl(`/proposals/${uuid}/setup_intent`)} />
                                    }
                                    </div>
                                    <br/>
                                    <Row>
                                        <Col md="12" className="text-end">
                                            <SubmitButton variant="primary">Pay and Subscribe</SubmitButton>
                                        </Col>
                                    </Row>
                                </BaseForm>
                            </div>
                        </div>
                    </div>
                    </>
                : <div className="d-flex flex-row align-items-center flex-grow-1 justify-content-center">
                    <div className="spinner-border text-secondary"/>
                </div>
            }
            </div>
        </ContentContainer>
    );
}

export default PaymentLinkPay;

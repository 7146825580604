import '../App.scss';
import React from 'react';
import Header from './Header';
import classnames from 'classnames';
import { Container } from 'react-bootstrap';

function BaseContainerWithoutNav(props) {
    return (
        <div className="page-wrapper">
            <div className="right-wrapper">
                <Header />
                <Container fluid className={classnames("content-wrapper", props.containerClassName)}>
                    { props.children }
                </Container>
            </div>
        </div>
    );
}

export default BaseContainerWithoutNav;

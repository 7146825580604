import '../../App.scss';
import React, {useEffect, useState, useContext, createRef} from 'react';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import { serverFetch, serverPost } from '../../helpers/server';
import {BaseContext, UserContext} from '../../helpers/common';
import { useTranslation } from 'react-i18next';
import BaseContainer from '../../components/BaseContainer';
import BaseForm from '../../components/BaseForm';
import ContentContainer from '../../components/ContentContainer';
import { Row, Col, Alert } from 'react-bootstrap';
import moment from 'moment';
import ProductPricingSelection from "../../components/ProductPricingSelection";
import SubmitButton from "../../components/SubmitButton";
import Loader from "../../components/Loader";
import ContentBox from "../../components/ContentBox";
import CustomerInput from "../../components/CustomerInput";
const _ = require('lodash');

function SubscriptionCreate(props) {
    const navigate = useNavigate();
    const { t } = useTranslation('common');
    const { isSuperUser } = useContext(UserContext);
    const { getApiUrl, getCompanySpecificUrl, company, setPageTitle } = useContext(BaseContext);
    const [ searchParams ] = useSearchParams();
    let defaultCustomerId = searchParams.get('customer');

    const [productPricings, setProductPricings] = useState([]);
    const [loading, setLoading] = useState(true);
    const [coupons, setCoupons] = useState([]);
    const [settings, setSettings] = useState({});
    const [error, setError] = useState(null);
    const [isInPast, setIsInPast] = useState(false);
    const [defaultCustomer, setDefaultCustomer] = useState(null);
    const [initialFields, setInitialFields] = useState({
        start_date: moment(),
        term: {
            count: 1,
            frequency: "YEAR"
        }
    });
    const pricingsRef = createRef();

    useEffect(() => {
        setPageTitle(`Subscription - Create`);
    }, []);

    useEffect(() => {
        if (defaultCustomerId) {
            serverFetch(getApiUrl(`/customers/${defaultCustomerId}`), { skipCache: true }).then((res) => {
                setDefaultCustomer(res);
                setInitialFields(prevFields => {
                    const newFields = {...prevFields};
                    newFields.customer = res;
                    return newFields;
                });
            });
        }
    }, [defaultCustomerId])

    useEffect(() => {
        setInitialFields(prevFields => {
            const newFields = {...prevFields};
            newFields.start_date = moment();
            return newFields;
        });

        const limit = 100
        const params = {
            company_id: company.id,
            sort_key: "createdAtDesc",
            pagination: {
                limit: limit
            },
        }
        serverPost(getApiUrl("/product_pricings/find"), params).then(async (res) => {
            if (res) {
                const results = res.results || [];
                setProductPricings(results);
            }
            setLoading(false);
        });

        serverPost(getApiUrl("/coupons/find"), { companyId: company.id }).then((res) => {
            setCoupons(res);
        });
    }, [company, getApiUrl])

    useEffect(() => {
        serverFetch(getApiUrl("/settings")).then((res) => {
            setSettings(res);
            if (_.isNil(res.payment_config)) {
                setInitialFields(prevFields => {
                    const newFields = {...prevFields};
                    newFields.auto_charges = "false";
                    return newFields;
                });
            }
        });
    }, []);

    const onFieldChange = (name, value) => {
        if (name === "start_date") {
            setIsInPast(moment(value).utc().isBefore(moment().utc().startOf('day')));
        }
    }

    const addSubscription = async (subscriptionFields) => {
        let pricingData = {};
        if (pricingsRef.current) {
            const validationResult = pricingsRef.current.validate(subscriptionFields);
            if (!_.isNil(validationResult)) {
                setError(validationResult)
                return;
            }

            pricingData = pricingsRef.current.getPricingSelectionFields(subscriptionFields);
        }
        setError(null);
        const startOfToday = moment().startOf('day');
        const startOfStartDate = moment(subscriptionFields.start_date).startOf('day');
        const isToday = startOfToday.isSame(startOfStartDate)
        const startDate = isToday ? moment(): moment(subscriptionFields.start_date)
        const data = {
            ...pricingData,
            company_id: subscriptionFields.company_id,
            auto_renews: _.isEmpty(subscriptionFields.end_date),
            auto_charges: subscriptionFields.auto_charges === "true",
            customer_id: props.customer ? props.customer.id : subscriptionFields.customer.id,
            term: {
                frequency: subscriptionFields.term.frequency,
                count: parseInt(subscriptionFields.term.count)
            },
            start_date: startDate.format(),
            end_date: _.isEmpty(subscriptionFields.end_date) ? null : subscriptionFields.end_date,
            renewal_date: _.isEmpty(subscriptionFields.renewal_date) ? null : subscriptionFields.renewal_date,
        }
        const subscriptionResult = await serverPost(getApiUrl('/subscriptions'), data);
        if (subscriptionResult) {
            navigate(getCompanySpecificUrl(`/subscription/${subscriptionResult.id}`), { replace: true })
        }
    }

    const planLengthOptions = [
        { value: "DAY", label: "Day" },
        { value: "WEEK", label: "Week" },
        { value: "MONTH", label: "Month" },
        { value: "QUARTER", label: "Quarter" },
        { value: "YEAR", label: "Year" },
    ]

    const paymentMechanismOptions = [
        { value: "true", label: "Automatically charge a payment method on file",
            disabled: _.isNil(settings.payment_config), disabledDescription: "* Payment Provider needs to be setup" },
        { value: "false", label: "Email invoice to customer manually" },
    ]

    return (
        <BaseContainer>
            <ContentContainer>
                <ContentBox>
                    <ContentBox.Body>
                        <Loader loading={loading || (defaultCustomerId && !defaultCustomer)}>
                        {
                            _.isEmpty(productPricings) &&
                            <div className="d-flex flex-column">
                                <div className="align-items-center justify-content-center text-center" style={{ marginTop: "30px" }}>
                                    <Row>
                                        <Col md={{ span: 8, offset: 2 }}>
                                            <div className="body2">Hi there!</div>
                                            <br/>
                                            <p>Before we create a subscription, we will need to create at least one pricing for a
                                                product. You can get <Link to={getCompanySpecificUrl("/products")}>started here</Link></p>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        }
                        {
                            !_.isEmpty(productPricings) &&
                            <div>
                                <h4>Create Subscription</h4>
                                <br/>
                                <BaseForm initialFormFields={initialFields} onFieldChange={onFieldChange} onSubmit={addSubscription}>
                                <Row>
                                    <CustomerInput colSpan="6" defaultCustomer={defaultCustomer} allowLeads required />
                                    <BaseForm.Input colSpan="6" name="start_date" label={t('subscriptions.start_date') + " (local timezone)"} type="date"
                                                    includeTime={true} required />
                                    <BaseForm.Input colSpan="6" name="end_date" label={t('subscriptions.end_date') + " (optional, local timezone)"} type="date" minDate={moment()} />
                                    <BaseForm.Input colSpan="6" name="renewal_date" label={t('subscriptions.renewal_date') + " (optional, local timezone)"} type="date" minDate={moment()} />
                                    <BaseForm.Input colSpan="6" name="auto_charges" label={t('subscriptions.payment_mechanism')} type="select"
                                                    options={paymentMechanismOptions} showSearch={false} />
                                </Row>
                                <Row>
                                    <Col sm="6">
                                        <BaseForm.InputGroup label="Term">
                                            <BaseForm.Number name="term.count" min="1" />
                                            <BaseForm.Divider />
                                            <BaseForm.SingleSelect name="term.frequency" options={planLengthOptions} showSearch={false}/>
                                        </BaseForm.InputGroup>
                                    </Col>
                                </Row>
                                <br/>
                                <ProductPricingSelection ref={pricingsRef} productPricings={productPricings} coupons={coupons}
                                                         allowDiscounts={true} requireRecurring/>
                                {
                                    error &&
                                    <div className="form-error-message">{ error }</div>
                                }
                                    {
                                    isInPast &&
                                    <Row style={{ marginTop: "20px" }}>
                                        <Col>
                                            <Alert variant="warning">Your subscription starts in the past. No invoices will be created for the past. The next invoice will be created as scheduled at the next invoice date.</Alert>
                                        </Col>
                                    </Row>
                                }
                                    <SubmitButton>Create</SubmitButton>
                                </BaseForm>
                            </div>
                        }
                        </Loader>
                    </ContentBox.Body>
                </ContentBox>
            </ContentContainer>
        </BaseContainer>
    );
}

export default SubscriptionCreate;

import '../../App.scss';
import React, { useEffect, useState, useContext, useCallback } from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import { serverPost, serverFetch, serverPatch } from '../../helpers/server';
import {BaseContext, renderDescriptionForItemPricing, getLabelForTaxCategory, UserContext} from '../../helpers/common';
import { useTranslation } from 'react-i18next';
import BaseContainer from '../../components/BaseContainer';
import KeyValueDisplay from '../../components/KeyValueDisplay';
import KeyValueDisplay2 from '../../components/KeyValueDisplay2';
import AddOrEditProductModal from '../../components/modals/AddOrEditProductModal';
import AddOrEditProductPricingModal from '../../components/modals/AddOrEditProductPricingModal';
import ArchiveButton from '../../components/ArchiveButton';
import ContentContainer from '../../components/ContentContainer';
import BaseOverlayTrigger from '../../components/BaseOverlayTrigger';
import Section from '../../components/Section';
import SectionNav from '../../components/SectionNav';
import Link from '../../components/Link';
import { Button, Row, Col } from 'react-bootstrap';
import CopyableComponent from "../../components/CopyableComponent";
import ChangeProductModal from "../../components/modals/ChangeProductModal";
import SelectEntitlements from "../../components/SelectEntitlements";
import MapleTable from "../../components/MapleTable";
const _ = require('lodash');

function Product(props) {
    const { t } = useTranslation('common');
    const navigate = useNavigate();
    const { productId } = useParams();
    const { isSuperUser } = useContext(UserContext);
    const { company, getApiUrl, setPageTitle, getCompanySpecificUrl } = useContext(BaseContext);
    const [product, setProduct] = useState({});
    const [pricings, setPricings] = useState([]);
    const [details, setDetails] = useState({});
    const [isEditingMetadata, setIsEditingMetadata] = useState(false);
    const [isEditingEntitlements, setIsEditingEntitlements] = useState(false);
    const [showAddOrEditProductModal, setShowAddOrEditProductModal] = useState(false);
    const [showAddOrEditProductPricingModal, setShowAddOrEditProductPricingModal] = useState(false);
    const [showChangeProductModal, setShowChangeProductModal] = useState(false);
    const [pricingToEdit, setPricingToEdit] = useState(null);
    const [filteredPricings, setFilteredPricings] = useState([]);
    const [hasArchivedPricings, setHasArchivedPricings] = useState(false);
    const [activeNav, setActiveNav] = useState("ACTIVE");
    const [sort, setSort] = useState("createdAtDesc");
    const allowEntitlements = isSuperUser;

    useEffect(() => {
        setPageTitle(`Product - ${product.name}`);
    }, [product]);

    const fetchData = (skipCache=false) => {
        serverFetch(getApiUrl(`/products/${productId}`), { skipCache }).then((res) => {
            if (res) {
                setProduct(res);
            }
        });

        const limit = 100
        const params = {
            company_id: company.id,
            sort_key: sort,
            pagination: {
                limit: limit
            },
            query: {
                product_id: productId
            }
        }
        serverPost(getApiUrl("/product_pricings/find"), params,{ skipCache }).then((res) => {
            if (res) {
                const results = res.results || [];
                setPricings(results);
            }
        });
    }

    useEffect(() => {
        setHasArchivedPricings(!_.isEmpty(_.filter(pricings, p => p.state === "ARCHIVED")));
        setFilteredPricings(_.filter(pricings, p => p.state === activeNav));
    }, [pricings, activeNav])

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        setDetails({
            'Name': product.name,
            'Description': product.description,
            'Tax Code': getLabelForTaxCategory(product.tax_category),
        });
    }, [product]);

    const onModalClose = () => {
        setShowAddOrEditProductModal(false);
        setShowAddOrEditProductPricingModal(false);
        setShowChangeProductModal(false);
        fetchData(true);
    }

    const editProduct = (product) => {
        setShowAddOrEditProductModal(true);
    }

    const addNewPricing = () => {
        setShowAddOrEditProductPricingModal(true);
        setPricingToEdit(null);
    }

    const onEditPricing = (pricing) => {
        setShowAddOrEditProductPricingModal(true);
        setPricingToEdit(pricing);
    }

    const onChangeProduct = (pricing) => {
        setShowChangeProductModal(true);
        setPricingToEdit(pricing);
    }

    const onArchiveProductPricing = (pricing) => {
        serverPatch(getApiUrl(`/product_pricings/${pricing.id}`), { state: "ARCHIVED" }).then((res) => {
            if (res) {
                fetchData(true);
            }
        })
    }

    const editMetadata = () => {
        setIsEditingMetadata(true);
    }

    const saveMetadata = (newMetadata) => {
        const processedData = {
            metadata: newMetadata
        };

        serverPatch(getApiUrl(`/products/${productId}`), processedData).then((res) => {
            if (res) {
                fetchData(true);
            }
            setIsEditingMetadata(false);
        });
    }

    const editEntitlements = () => {
        setIsEditingEntitlements(true);
    }

    const saveEntitlements = (newEntitlements) => {
        const processedData = {
            entitlements: newEntitlements
        };

        serverPatch(getApiUrl(`/products/${productId}`), processedData).then((res) => {
            if (res) {
                fetchData(true);
            }
            setIsEditingEntitlements(false);
        });
    }

    const onNavClick = (tabId) => {
        setActiveNav(tabId);
    }

    const isOneTimeProductPricing = (productPricing) => {
        const types = _.uniq(_.map(productPricing.product_metric_pricings, (pmp) => pmp.item.type));
        return types.length === 1 && types[0] === "ONETIME_ITEM";
    }

    const renderProductPricing = (productPricing) => {
        return (
            <>
            {
                _.map(productPricing.product_metric_pricings, (pmp, i) =>
                     <div key={i}>
                        { renderDescriptionForItemPricing(pmp.item_pricing, false, pmp.item) }
                    </div>
                )
            }
            </>
        )
    }

    const navigateToSubscriptionListForPricing = (event, pricing) => {
        event.preventDefault();
        event.stopPropagation();
        navigate(getCompanySpecificUrl(`/subscriptions?pp=${ pricing.id }`));
    }

    const tabItems = [{
        'label': 'Active',
        'id': 'ACTIVE',
        active: activeNav === "ACTIVE"
    }, {
        'label': 'Archived',
        'id': 'ARCHIVED',
        active: activeNav === "ARCHIVED"
    }]

    return (
        <BaseContainer>
            <ContentContainer title={product.name} logo={product.image_url} actions={[
                {variant: "text-primary", icon: "fa-edit", label: t('common.edit'), onClick: editProduct}
            ]}>
                <Section title="Details" actions={[]}>
                    <KeyValueDisplay2 items={details} />
                </Section>
                {
                    allowEntitlements &&
                        <Section title="Entitlements" className="mt-4" actions={!isEditingEntitlements && [
                            {variant: "text-primary", icon: "fa-edit", label: "Edit", onClick: editEntitlements}
                        ]}>
                            <div className="max-w-3xl">
                                <SelectEntitlements
                                    entitlements={product.entitlements}
                                    isEditing={isEditingEntitlements}
                                    onUpdateEntitlements={saveEntitlements}
                                    onCancelEditing={() => setIsEditingEntitlements(false)}
                                />
                            </div>
                        </Section>
                }
                <Section title="Metadata" className="mt-4" actions={[
                    {variant: "text-primary", icon: "fa-edit", label: "Edit", onClick: editMetadata}
                ]}>
                    {
                        (!isEditingMetadata && (_.isNil(product.metadata) || _.isEmpty(product.metadata))) ?
                            <p>No metadata</p>
                            : <KeyValueDisplay items={product.metadata} isEditing={isEditingMetadata}
                                               onUpdateKeyValues={saveMetadata}
                                               onCancelEditing={() => setIsEditingMetadata(false)}/>
                    }
                </Section>
                <Section title="Pricings" className="mt-4" actions={[
                    {variant: "primary", icon: "fa-plus", label: "Add New Pricing", onClick: addNewPricing}
                ]}>
                    {
                        hasArchivedPricings &&
                            <>
                                <SectionNav items={tabItems} onClick={onNavClick} />
                                <br/>
                            </>
                    }
                    <MapleTable>
                        <MapleTable.Content>
                        <thead>
                            <tr>
                                <MapleTable.TH>Name</MapleTable.TH>
                                <MapleTable.TH></MapleTable.TH>
                                <MapleTable.TH>Price</MapleTable.TH>
                                <MapleTable.TH>Subscriptions</MapleTable.TH>
                                <MapleTable.TH></MapleTable.TH>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                        {
                            _.map(filteredPricings, (pricing, i) =>
                                <tr key={i} className="cursor-pointer" onClick={() => navigate(getCompanySpecificUrl(`/pricing/${pricing.id}`))}>
                                    <td>
                                        <p>{ pricing.name }</p>
                                        <p>{ pricing.description }</p>
                                    </td>
                                    <td><CopyableComponent value={ pricing.id }/></td>
                                    <td>
                                        { renderProductPricing(pricing) }
                                    </td>
                                    <td>
                                    {
                                        isOneTimeProductPricing(pricing) ?
                                            <span className="gray2">N/A</span>
                                        : <Link onClick={(event) => navigateToSubscriptionListForPricing(event, pricing)}>{ pricing.num_subs } active</Link>
                                    }
                                    </td>
                                    <td className="text-end w-px whitespace-nowrap">
                                    {
                                        pricing.state !== "ARCHIVED" &&
                                            <div className="flex flex-row gap-1">
                                                <Link href={getCompanySpecificUrl(`/payment_link/create?pricing=${pricing.id}`)}>
                                                    <BaseOverlayTrigger content={<span>Create Payment Link</span>}>
                                                        <Button variant="text-primary" size="sm"><i className="fa fa-cart-shopping"/></Button>
                                                    </BaseOverlayTrigger>
                                                </Link>
                                                <Button size="sm" variant="text-primary" onClick={() => onEditPricing(pricing)}><i className="fa fa-edit"/></Button>
                                                {
                                                    isSuperUser &&
                                                        <Button size="sm" variant="text-primary" onClick={() => onChangeProduct(pricing)}><i className="fa fa-rotate"/></Button>
                                                }
                                                <ArchiveButton size="sm" onDelete={() => onArchiveProductPricing(pricing)}></ArchiveButton>
                                            </div>
                                    }
                                    </td>
                                </tr>
                            )
                        }
                        </tbody>
                        </MapleTable.Content>
                    </MapleTable>
                </Section>
            </ContentContainer>
            <AddOrEditProductModal show={showAddOrEditProductModal} onClose={onModalClose} itemToEdit={product} />
            <AddOrEditProductPricingModal show={showAddOrEditProductPricingModal} onClose={onModalClose} itemToEdit={pricingToEdit} product={product} />
            <ChangeProductModal show={showChangeProductModal} onClose={onModalClose} pricing={pricingToEdit} />
        </BaseContainer>
    );
}

export default Product;

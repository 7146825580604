import '../../App.scss';
import React, { useEffect, useContext, createRef } from 'react';
import { useSearchParams } from "react-router-dom";
import { BaseContext } from '../../helpers/common';
import BaseContainer from '../../components/BaseContainer';
import ContentContainer from '../../components/ContentContainer';
import SubscriptionListSection from '../../components/SubscriptionListSection';

function Subscriptions() {
    const { setPageTitle } = useContext(BaseContext);
    const subscriptionListRef = createRef();
    const [ searchParams ] = useSearchParams();
    let product_pricing_id = searchParams.get('pp');

    useEffect(() => {
        setPageTitle(`Subscriptions`);
    }, []);

    return (
        <BaseContainer>
            <ContentContainer>
                <SubscriptionListSection
                    showEmptyState={true}
                    ref={subscriptionListRef}
                    extraQueryFilters={{
                        product_pricing_id: product_pricing_id || null
                    }}
                />
            </ContentContainer>
        </BaseContainer>
    );
}

export default Subscriptions;

import '../App.scss';
import { serverFetch, subscribeToEvent } from '../helpers/server'
import {BaseContext, IntegrationContext} from '../helpers/common'
import React, { useContext } from 'react';
import SectionNav from './SectionNav';
import Section from './Section';
import BaseContainer from './BaseContainer';
import { Outlet, useOutletContext, useParams } from "react-router-dom";

function IntegrationContainer(props) {
    const { getCompanySpecificUrl } = useContext(BaseContext);
    const { integration, getIntegrationSpecificUrl } = useContext(IntegrationContext);
    const outletContext = useOutletContext();
    let { key, id } = useParams();

    const tabItems = [{
        'label': 'Home',
        'href': getIntegrationSpecificUrl("/dashboard"),
        'id': 'home',
    }]

    const rightTabItems = [{
        'label': 'Settings',
        'href': getIntegrationSpecificUrl("/settings"),
        'id': 'settings',
    }];

    return (
        <BaseContainer>
            <Section title={integration.name}>
                <SectionNav size="sm" items={tabItems} rightItems={rightTabItems} shouldAutoMatch={true} />
                <Outlet context={outletContext} />
            </Section>
        </BaseContainer>
    );
}

export default IntegrationContainer;

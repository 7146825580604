import '../../App.scss';
import '../../css/modals.scss';
import React from 'react';
import BaseForm from '../BaseForm';
import BaseAddOrEditItemModal from './BaseAddOrEditItemModal';
import { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
const _ = require('lodash');

function AddOrEditBillableMetricModal(props) {
    const { t } = useTranslation('common');
    const [billableItems, setBillableItems] = useState([]);
    const [initialFormFields, setInitialFormFields] = useState(null);
    const [aggregator, setAggregator] = useState("COUNT");
    const [itemType, setItemType] = useState(null);

    useEffect(() => {
        setBillableItems(props.billableItems);
    }, [props.billableItems]);

    useEffect(() => {
        if (props.show && props.metricToCreate) {
            setInitialFormFields({
                "metering_rule": {
                    "rule": props.metricToCreate
                }
            })
            setItemType("CUSTOM_OBJ");
        } else {
            setInitialFormFields(null);
        }
    }, [props.show, props.metricToCreate]);

    useEffect(() => {
        if (props.show) {
            if (props.itemToEdit) {
                setAggregator(props.itemToEdit.metering_rule.aggregator);
                const item = _.find(billableItems, (i) => String(i.id) === String(props.itemToEdit.item_id));
                setItemType(item.type);
            }
        }
    }, [props.show, props.itemToEdit])

    const billableItemOptions = _.map(billableItems, (bi) => {
        return { value: bi.id, label: bi.name }
    })

    const cleanupFields = (fields) => {
        const newFields = {
            ...fields,
            item_id: fields.item_id
        };
        // TODO(aswin): Remove this once the backend doesn't require this.
        if (newFields.metering_rule && _.isNil(newFields.metering_rule.property)) {
            newFields.metering_rule.property = "id";
        }
        newFields.metering_rule.group_keys = _.map(fields.metering_rule.group_keys, (k) => k.value);
        return newFields;
    }

    const usageAggregatorOptions = [
        { value: "COUNT", label: "Count" },
        { value: "SUM", label: "Sum" },
        { value: "MAX", label: "Max" },
        { value: "AVG", label: "Average" },
        { value: "UNIQUE", label: "Unique Count" },
        { value: "CUSTOMER_LAST_PERIOD", label: "Last Reported In Period" },
    ];
    const propertyAggregatorOptions = [
        { value: "OBJ_COUNT", label: "Count" },
        { value: "OBJ_SUM", label: "Sum" },
        { value: "OBJ_MAX", label: "Max" },
        { value: "OBJ_UNIQUE", label: "Unique Count" },
    ];

    const onFieldChange = (name, value) => {
        if (name === "metering_rule.aggregator") {
            setAggregator(value);
        } else if (name === "item_id") {
            const item = _.find(billableItems, (i) => String(i.id) === String(value));
            setItemType(item.type);
            let options = [];
            if (item.type === "CUSTOM_OBJ") {
                options = _.map(propertyAggregatorOptions, (a) => a.value)
            } else {
                options = _.map(usageAggregatorOptions, (a) => a.value)
            }
            if (!_.includes(options, aggregator)) {
                setAggregator(options[0]);
                setInitialFormFields(prevFields => {
                    if (prevFields) {
                        prevFields.metering_rule.aggregator = options[0];
                        return prevFields;
                    } else {
                        const newFields = {
                            metering_rule: {
                                aggregator: options[0]
                            }
                        };
                        return newFields;
                    }
                });
            }
        }
    }

    const aggregatorOptions = itemType === "CUSTOM_OBJ" ? propertyAggregatorOptions: usageAggregatorOptions;
    return (
        <BaseAddOrEditItemModal
            {...props}
            size="lg"
            initialFormFields={initialFormFields}
            processAddFields={cleanupFields}
            processUpdateFields={cleanupFields}
            onFieldChange={onFieldChange}
            itemLabel={t('billable_metric.metric')}
            itemBaseUrl="/billable/metrics"
        >
            <Row>
                <BaseForm.Input colSpan="12" name="name" label={t('common.name')} type="text" required />
                <BaseForm.Input colSpan="12" name="description" label={t('common.description')} type="textarea" />
            </Row>
            <br/>
            <Row>
                <Col md="12">
                    <div className="body2">Metric details</div>
                    <div className="body1">Select details about the metric that you want to track.</div>
                </Col>
                <BaseForm.Input colSpan="4" name="item_id" label={t('billable_metric.item')} type="select" options={billableItemOptions} showSearch={false} />
                <BaseForm.Input colSpan="4" name="metering_rule.aggregator" label={t('billable_metric.aggregator')} type="select" options={aggregatorOptions} showSearch={false} />
                {
                    aggregator !== "COUNT" && aggregator !== "OBJ_COUNT" && aggregator !== "CUSTOMER_LAST_PERIOD" &&
                        <BaseForm.Input colSpan="4" name="metering_rule.property" label={t('billable_metric.property')} type="text" required />
                }
            </Row>
            <br/>
            <Row>
                <Col md="12">
                    <div className="body2">Grouping details</div>
                    <div className="body1">If you want to group your metric value based on certain properties, you can do so here. You can leave it blank if you do not want any grouping.</div>
                </Col>
                <BaseForm.Input colSpan="8" name="metering_rule.group_keys" label={t('billable_metric.group_by')} type="selector"
                    isMulti={true} canCreate={true} formatCreateLabel={(inputValue) => `Group by ${inputValue}`}/>
            </Row>
            <br/>
            <Row>
                <Col md="12">
                    <div className="body2">Filtering details</div>
                    <div className="body1">If you want to calculate your metric value on only a subset of usages, you can add a filter for the usage records.</div>
                </Col>
                <BaseForm.Input colSpan="12" name="metering_rule.rule" label="Rule" type="rule" isColored={true} hideLabel />
            </Row>
        </BaseAddOrEditItemModal>
    );
}

export default AddOrEditBillableMetricModal;

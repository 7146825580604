import '../App.scss';
import React from 'react';
import {renderChildren} from "../helpers/common";
import classnames from 'classnames';

function ContentBox(props) {
    return (
        <div className={classnames("rounded-md shadow-sm bg-white ring-1 ring-black ring-opacity-5 mb-3", props.className)}>
            { renderChildren(props) }
        </div>
    );
}

const Body = (props) => {
    return (
        <div className={classnames("p-4", props.className)}>
            { renderChildren(props) }
        </div>
    )
}
ContentBox.Body = Body;

export default ContentBox;

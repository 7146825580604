import '../../App.scss';
import '../../css/modals.scss';
import React from 'react';
import BaseForm from '../BaseForm';
import SubmitButton from '../SubmitButton';
import BaseSideModal from './BaseSideModal';
import { useState, useEffect, useContext, useMemo } from 'react';
import { BaseContext } from '../../helpers/common';
import { serverPost } from '../../helpers/server';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

function PausePaymentsModal(props) {
    const { t } = useTranslation('common');
    const { getApiUrl } = useContext(BaseContext);
    const [subscription, setSubscription] = useState(null);

    const [timingType, setTimingType] = useState(null);
    const [refundType, setRefundType] = useState(null);
    const initialFields = useMemo(() => {
        return {
            custom_end_date: moment().endOf('day')
        }
    });

    useEffect(() => {
        setSubscription(props.subscription)
    }, [props.subscription])

    const cancelSubscription = (data) => {
        const pauseData = {
            behavior: data.behavior,
            resume_date: null
        }
        if (data.duration === "CUSTOM") {
            pauseData.resume_date = data.resume_date;
        }
        console.log("Pause subscription " + JSON.stringify(pauseData));
        serverPost(getApiUrl(`/subscriptions/${subscription.id}/pause`), pauseData).then((res) => {
            if (res) {
                props.onClose(true);
            }
        })
    }

    const onFieldChange = (name, value) => {
        if (name === "duration") {
            setTimingType(value);
        } else if (name === "refund") {
            setRefundType(value);
        }
    }

    const timingOptions = [
        { value: "FOREVER", label: "Forever" },
        { value: "CUSTOM", label: "Till specific date" },
    ];

    const invoiceOptions = [
        { value: "DRAFT", label: "Keep as draft" },
        { value: "VOID", label: "Void invoices" },
    ];

    return (
        <BaseSideModal {...props}>
            <BaseSideModal.Form initialFormFields={initialFields} onFieldChange={onFieldChange} onSubmit={cancelSubscription} >
                <BaseSideModal.Header title={t('subscriptions.pause_collection')}/>
                <BaseSideModal.Body>
                    <BaseForm.Input type="select" name="duration" label="Duration" options={timingOptions} showSearch={false}/>
                    {
                        timingType === "CUSTOM" &&
                            <>
                            <BaseForm.Input type="date" name="resume_date" label="Pause Till" minDate={moment()} includeTime={true} required />
                            <span className="caption">Date/Time is based on your local timezone</span>
                            <br/><br/>
                            </>
                    }
                    <BaseForm.Input type="select" name="behavior" label="Invoices" options={invoiceOptions}
                        showSearch={false} description="What should we do with the invoices created while the payments are paused?"/>
                </BaseSideModal.Body>
                <BaseSideModal.Footer>
                    <SubmitButton>{t('subscriptions.pause.pause')}</SubmitButton>
                </BaseSideModal.Footer>
            </BaseSideModal.Form>
        </BaseSideModal>
    );
}

export default PausePaymentsModal;

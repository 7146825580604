import '../../App.scss';
import '../../css/modals.scss';
import React from 'react';
import BaseForm from '../BaseForm';
import SubmitButton from '../SubmitButton';
import BaseSideModal from './BaseSideModal';
import { useState, useEffect, useContext, useMemo } from 'react';
import { BaseContext } from '../../helpers/common';
import {serverFetch, serverPatch, serverPost} from '../../helpers/server';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
const _ = require('lodash');

function ChangeProductModal(props) {
    const { t } = useTranslation('common');
    const { getApiUrl } = useContext(BaseContext);
    const [pricing, setPricing] = useState(null);
    const [products, setProducts] = useState([]);
    const [initialFields, setInitialFields] = useState({});

    useEffect(() => {
        serverPost(getApiUrl("/products/find")).then((res) => {
            setProducts(res);
        });
    }, []);

    useEffect(() => {
        setPricing(props.pricing);
    }, [props.pricing]);

    const onSubmit = (data) => {
        console.log("The data is " + JSON.stringify(data));
        serverPatch(getApiUrl(`/product_pricings/${pricing.id}/move`), data).then((res) => {
            if (res) {
                props.onClose(true);
            }
        })
    }

    const fileteredProducts = pricing ? _.filter(products, (p) => p.id != pricing.product_id): products;
    const productOptions = _.map(fileteredProducts, (p) => {
        return { value: p.id, label: p.name || p.description }
    })

    if (!props.show) {
        return;
    }
    return (
        <BaseSideModal {...props}>
            <BaseSideModal.Form initialFormFields={initialFields} onSubmit={onSubmit} >
                <BaseSideModal.Header title={"Change Product"}/>
                <BaseSideModal.Body>
                    <BaseForm.Input type="select" name="product_id" label="Product" options={productOptions} showSearch={false}/>
                </BaseSideModal.Body>
                <BaseSideModal.Footer>
                    <SubmitButton>{t('common.update')}</SubmitButton>
                </BaseSideModal.Footer>
            </BaseSideModal.Form>
        </BaseSideModal>
    );
}

export default ChangeProductModal;

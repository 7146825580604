import '../../../App.scss';
import React, {useEffect, useState, useContext, useMemo} from 'react';
import { serverFetch, serverPatch } from '../../../helpers/server';
import {
    BaseContext,
    getConfigForPaymentRetrySchedule,
    getConfigForInvoiceReminderSchedule,
    getConfigForCardExpirySchedule,
    sleep
} from '../../../helpers/common';
import AddOrEditInvoiceReminderModal from '../../../components/modals/AddOrEditInvoiceReminderModal';
import BaseContainer from '../../../components/BaseContainer';
import BaseForm from '../../../components/BaseForm';
import SubmitButton from '../../../components/SubmitButton';
import DeleteButton from '../../../components/DeleteButton';
import ContentContainer from '../../../components/ContentContainer';
import Notification from '../../../components/Notification';
import ContentBox from '../../../components/ContentBox';
import {Row, Col, Button, Table} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import EditScheduleModal from "../../../components/modals/EditScheduleModal";
import Section from "../../../components/Section";
const _ = require('lodash');

function InvoiceSettings() {
    const { t } = useTranslation('common');
    const { getApiUrl, setPageTitle } = useContext(BaseContext);
    const [settings, setSettings] = useState({});
    const [showEditAutoChargeRetryModal, setShowEditAutoChargeRetryModal] = useState(false);
    const [showEditNonAutoChargeScheduleModal, setShowEditNonAutoChargeScheduleModal] = useState(false);
    const [showEditCardExpiryScheduleModal, setShowEditCardExpiryScheduleModal] = useState(false);
    const [showInvoiceReminderModal, setShowInvoiceReminderModal] = useState(false);
    const [reminderIndexToEdit, setReminderIndexToEdit] = useState(null);
    const [autoChargeInvoicePaymentRetryEnabled, setAutoChargeInvoicePaymentRetryEnabled] = useState(false);
    const [nonAutoChargeInvoiceReminderEnabled, setNonAutoChargeInvoiceReminderEnabled] = useState(false);
    const [cardExpiryReminderEnabled, setCardExpiryReminderEnabled] = useState(false);
    const [autoChargeInvoicePaymentRetryEditing, setAutoChargeInvoicePaymentRetryEditing] = useState(false);
    const [nonAutoChargeInvoiceReminderEditing, setNonAutoChargeInvoiceReminderEditing] = useState(false);
    const [cardExpiryReminderEditing, setCardExpiryReminderEditing] = useState(false);
    const [reminderItems, setReminderItems] = useState([]);
    const [showAdditionalFieldsForm, setShowAdditionalFieldsForm] = useState(false);

    const initialFields = useMemo(() => {
        return {
            ...settings,
            ...settings.invoice_email_config
        }
    }, [settings])

    useEffect(() => {
        setPageTitle(`Invoice Settings`);
    }, []);

    useEffect(() => {
        fetchData(true);
    }, []);

    const onModalClose = () => {
        setShowInvoiceReminderModal(false);
        setShowEditAutoChargeRetryModal(false);
        setShowEditNonAutoChargeScheduleModal(false);
        setShowEditCardExpiryScheduleModal(false);
    }

    const fetchData = (skipCache=false) => {
        serverFetch(getApiUrl("/settings"), { skipCache: skipCache }).then((res) => {
            if (res) {
                processSettings(res);
            }
        });
    }

    const processSettings = (res) => {
        setSettings(res);
        setAutoChargeInvoicePaymentRetryEnabled(res.auto_charge_invoice_payment_retry_enabled)
        setNonAutoChargeInvoiceReminderEnabled(res.non_auto_charge_invoice_reminder_enabled)
        setCardExpiryReminderEnabled(res.card_expiry_reminder_enabled)
        if (res.invoice_reminder_config) {
            setReminderItems(res.invoice_reminder_config.items);
        } else {
            setReminderItems([]);
        }
    }

    const updateSettings = async (data) => {
        if (data.invoice_logo_url === "") {
            data.invoice_logo_url = null;
        }
        const result = await serverPatch(getApiUrl("/settings"), data);
        if (result) {
            processSettings(result);
            // fetchData(true);
            setShowAdditionalFieldsForm(false);
            Notification.Success("Successfully updated");
        }
    }

    const onEditReminder = (i, reminder) => {
        setReminderIndexToEdit(i);
        setShowInvoiceReminderModal(true);
    }

    const onAddNewReminder = () => {
        setReminderIndexToEdit(-1);
        setShowInvoiceReminderModal(true);
    }

    const addReminder = (data) => {
        setReminderItems(prevItems => {
            const newItems = [...prevItems];
            newItems.push(data);
            return newItems;
        })
    }

    const updateReminder = (index, data) => {
        setReminderItems(prevItems => {
            const newItems = [...prevItems];
            newItems[index] = data;
            return newItems;
        })
    }

    const deleteReminder = (index) => {
        setReminderItems(prevItems => {
            const newItems = [...prevItems];
            newItems.splice(index, 1);
            return newItems;
        })
    }

    const updateInvoiceReminderSettings = async (data) => {
        if (data.invoice_reminders_enabled) {
            data.invoice_reminder_config.items = reminderItems;
        }
        console.log("Updating the invoice reminder settings " + JSON.stringify(data));
        data.invoice_reminder_enabled = data.invoice_reminders_enabled;
        await updateSettings(data);
        setAutoChargeInvoicePaymentRetryEditing(false);
    }

    const onFieldChange = async (name, value) => {
        if (name === "auto_charge_invoice_payment_retry_enabled") {
            setAutoChargeInvoicePaymentRetryEnabled(value);
            const data = {
                auto_charge_invoice_payment_retry_enabled: value
            }
            if (value && (!settings.auto_charge_invoice_payment_retry_config || settings.auto_charge_invoice_payment_retry_config.grouping_name === "")) {
                data["auto_charge_invoice_payment_retry_config"] = { grouping_name: "lenient" }
            }
            await updateSettings(data);
            if (value) {
                setAutoChargeInvoicePaymentRetryEditing(true);
            }
        } else if (name === "non_auto_charge_invoice_reminder_enabled") {
            setNonAutoChargeInvoiceReminderEnabled(value);
            const data = {
                non_auto_charge_invoice_reminder_enabled: value
            }
            if (value && (!settings.non_auto_charge_invoice_reminder_config || settings.non_auto_charge_invoice_reminder_config.grouping_name === "")) {
                data["non_auto_charge_invoice_reminder_config"] = { grouping_name: "lenient" }
            }
            await updateSettings(data);
            if (value) {
                setNonAutoChargeInvoiceReminderEditing(true);
            }
        } else if (name === "card_expiry_reminder_enabled") {
            setCardExpiryReminderEnabled(value);
            const data = {
                card_expiry_reminder_enabled: value
            }
            if (value && (!settings.card_expiry_reminder_config || settings.card_expiry_reminder_config.grouping_name === "")) {
                data["card_expiry_reminder_config"] = { grouping_name: "lenient" }
            }
            await updateSettings(data);
            if (value) {
                setCardExpiryReminderEditing(true);
            }
        }
    }

    const onShowAdditionalFieldsForm = (event) => {
        event.stopPropagation();
        event.preventDefault();
        setShowAdditionalFieldsForm(true);
    }

    const deleteAdditionalField = (index) => {
        const newFields = _.filter(settings.invoice_additional_display_fields, (f, i) => String(i) !== String(index));
        const data = {
            invoice_additional_display_fields: newFields
        }
        updateSettings(data);
    }

    const updateAdditionalFields = (fieldsData) => {
        const data = {
            invoice_additional_display_fields: _.map(fieldsData.invoice_additional_display_fields, (d) => d)
        }
        updateSettings(data);
    }

    const retryScheduleTitleMap = {
        "basic": "Basic",
        "lenient": "Lenient",
        "aggressive": "Persistent",
        "custom": "Custom"
    }

    const retryScheduleDescriptionMap = {
        "basic": "",
        "lenient": "",
        "aggressive": "",
        "custom": "Create your own custom schedule that suits your needs."
    }

    const reminderScheduleTitleMap = {
        "basic": "Basic",
        "lenient": "Lenient",
        "aggressive": "Persistent",
        "custom": "Custom"
    }

    const reminderScheduleDescriptionMap = {
        "basic": "",
        "lenient": "",
        "aggressive": "",
        "custom": "Create your own custom schedule that suits your needs."
    }

    const cardExpiryScheduleTitleMap = {
        "basic": "Basic",
        "lenient": "Lenient",
        "aggressive": "Persistent",
        "custom": "Custom"
    }

    const cardExpiryScheduleDescriptionMap = {
        "basic": "",
        "lenient": "",
        "aggressive": "",
        "custom": "Create your own custom schedule that suits your needs."
    }

    const onRetryScheduleSelected = async (type) => {
        if (type !== "custom") {
            // Standard schedule picked, just change it in the settings.
            await updateSettings({
                auto_charge_invoice_payment_retry_config: {
                    grouping_name: type
                }
            });
            setAutoChargeInvoicePaymentRetryEditing(false);
        } else {
            setShowEditAutoChargeRetryModal(true);
        }
    }

    const onReminderScheduleSelected = async (type) => {
        if (type !== "custom") {
            // Standard schedule picked, just change it in the settings.
            await updateSettings({
                non_auto_charge_invoice_reminder_config: {
                    grouping_name: type
                }
            });
            setNonAutoChargeInvoiceReminderEditing(false);
        } else {
            setShowEditNonAutoChargeScheduleModal(true);
        }
    }

    const onCardExpiryScheduleSelected = async (type) => {
        if (type !== "custom") {
            // Standard schedule picked, just change it in the settings.
            await updateSettings({
                card_expiry_reminder_config: {
                    grouping_name: type
                }
            });
            setCardExpiryReminderEditing(false);
        } else {
            setShowEditCardExpiryScheduleModal(true);
        }
    }

    const onCustomAutoChargeRetryScheduleSelected = async (scheduleItems) => {
        await updateSettings({
            auto_charge_invoice_payment_retry_config: {
                grouping_name: "custom",
                config: {
                    items: scheduleItems
                }
            }
        });
        setShowEditAutoChargeRetryModal(false);
        setAutoChargeInvoicePaymentRetryEditing(false);
    }

    const onCustomNonAutoChargeReminderScheduleSelected = async (scheduleItems) => {
        await updateSettings({
            auto_charge_invoice_payment_retry_config: {
                grouping_name: "custom",
                config: {
                    items: scheduleItems
                }
            }
        });
        setNonAutoChargeInvoiceReminderEditing(false);
        setShowEditNonAutoChargeScheduleModal(false);
    }

    const onCustomCardExpiryReminderScheduleSelected = async (scheduleItems) => {
        await updateSettings({
            auto_charge_invoice_payment_retry_config: {
                grouping_name: "custom",
                config: {
                    items: scheduleItems
                }
            }
        });
        setCardExpiryReminderEditing(false);
        setShowEditCardExpiryScheduleModal(false);
    }

    const renderConfigRow = (row) => {
        let description = "";
        if (row.action_type === "payment_retry") {
            description = "Retry, with email"
        } else if (row.action_type === "notification") {
            description = "Email"
        } else if (row.action_type === "cancel_subscription") {
            description = "Cancel subscription"
        } else if (row.action_type === "unpaid_subscription") {
            description = "Mark as unpaid"
        }

        if (row.interval_from_date >= 0) {
            return `Day ${row.interval_from_date/86400}, ${description}`
        } else {
            return `${-1*row.interval_from_date/86400} day(s) before, ${description}`
        }
    }

    const getConfigItemDescription = (row) => {
        let description = "";
        if (row.action_type === "payment_retry") {
            description = "Retry, with email"
        } else if (row.action_type === "notification") {
            description = "Email"
        } else if (row.action_type === "cancel_subscription") {
            description = "Cancel subscription"
        } else if (row.action_type === "unpaid_subscription") {
            description = "Mark as unpaid"
        }

        if (row.interval_from_date >= 0) {
            return `Day ${row.interval_from_date/86400} (${description})`
        } else {
            return `${-1*row.interval_from_date/86400} day(s) before (${description})`
        }
    }

    const renderSchedule = (title, description, items, isSelected, editable, onClick) => {
        return <div className="schedule-wrapper">
            <div className="schedule-top">
                <h4>{ title }</h4>
                <span className="body1 flex-grow-1">{ description }</span>
                {
                    editable &&
                        <Button variant="outline-primary" className="btn-complete" onClick={onClick}>
                            <i className="fa fa-edit"/> Edit
                        </Button>
                }
                <Button variant="primary" className="btn-complete" onClick={onClick}
                        disabled={isSelected}>
                    { isSelected ? "Selected" : "Select" }
                </Button>
            </div>
            {
                !_.isEmpty(items) &&
                    <div className="schedule-bottom">
                        <span className="body2">Schedule</span>
                        <div className="d-flex flex-column">
                            {
                                _.map(items, (c, i) =>
                                    <span key={i}>{ renderConfigRow(c) }</span>
                                )
                            }
                        </div>
                    </div>
            }
        </div>
    }

    const renderRetrySchedule = (type) => {
        const isSelected = settings.auto_charge_invoice_payment_retry_config && (settings.auto_charge_invoice_payment_retry_config.grouping_name === type);
        const config = (isSelected) ?
            settings.auto_charge_invoice_payment_retry_config && settings.auto_charge_invoice_payment_retry_config.config.items
            : getConfigForPaymentRetrySchedule(type);
        return renderSchedule(retryScheduleTitleMap[type], retryScheduleDescriptionMap[type], config, isSelected,
            type === "custom" && isSelected, () => onRetryScheduleSelected(type));
    }

    const renderReminderSchedule = (type) => {
        const isSelected = settings.non_auto_charge_invoice_reminder_config && (settings.non_auto_charge_invoice_reminder_config.grouping_name === type);
        const config = (isSelected) ?
            settings.non_auto_charge_invoice_reminder_config && settings.non_auto_charge_invoice_reminder_config.config.items
            : getConfigForInvoiceReminderSchedule(type);
        return renderSchedule(reminderScheduleTitleMap[type], reminderScheduleDescriptionMap[type], config, isSelected,
            type === "custom" && isSelected, () => onReminderScheduleSelected(type));
    }

    const renderCardExpirySchedule = (type) => {
        const isSelected = settings.card_expiry_reminder_config && (settings.card_expiry_reminder_config.grouping_name === type);
        const config = (isSelected) ?
            settings.card_expiry_reminder_config && settings.card_expiry_reminder_config.config.items
            : getConfigForCardExpirySchedule(type);
        return renderSchedule(cardExpiryScheduleTitleMap[type], cardExpiryScheduleDescriptionMap[type], config, isSelected,
            type === "custom" && isSelected, () => onCardExpiryScheduleSelected(type));
    }

    const reminderToEdit = (settings.invoice_reminder_config && !_.isEmpty(settings.invoice_reminder_config.items) && reminderIndexToEdit !== -1) ?
        settings.invoice_reminder_config.items[reminderIndexToEdit]: null;

    return (
        <BaseContainer>
            <ContentContainer>
                <Section title="Invoice Settings">
                    <ContentBox>
                        <ContentBox.Body>
                            <Row>
                                <Col md="6">
                                    <BaseForm initialFormFields={initialFields} onSubmit={updateSettings}>
                                        <Row>
                                            <BaseForm.Input type="text" colSpan="6" name="invoice_prefix" label="Invoice Number Prefix" />
                                            <BaseForm.Input type="number" colSpan="6" name="invoice_next_seq_num" label="Invoice Number Next" />
                                            <BaseForm.Input type="file" colSpan="12" name="invoice_logo_url" label="Invoice Logo" fileType="invoiceLogo" />
                                            <BaseForm.Input type="number" colSpan="12" name="invoice_due_date_from_creation" label="Invoice Due Date From Creation" step="1" min="0" />
                                        </Row>
                                        <Row>
                                            <Col md="4">
                                                <SubmitButton>Save</SubmitButton>
                                            </Col>
                                        </Row>
                                    </BaseForm>
                                </Col>
                            </Row>
                        </ContentBox.Body>
                    </ContentBox>
                </Section>
                <Section
                    className="mt-4"
                    title="Additional Information"
                    subtitle="Add any additional information you would like to include in the invoice PDFs. E.g. Tax IDs, contact information..."
                >
                    <ContentBox>
                        <ContentBox.Body>
                            <BaseForm initialFormFields={initialFields} onSubmit={updateAdditionalFields}>
                                <Row>
                                    <Col lg="9">
                                        <Table borderless className="no-background">
                                            <tbody>
                                            {
                                                _.map(settings.invoice_additional_display_fields, (df, i) =>
                                                    <tr className="thin-horizontal" key={i}>
                                                        <td width="40%">
                                                            <BaseForm.Input type="text" name={`invoice_additional_display_fields.${i}.name`} label="Name" hideLabel disabled/>
                                                        </td>
                                                        <td width="40%">
                                                            <BaseForm.Input type="text" name={`invoice_additional_display_fields.${i}.value`} label="Value" hideLabel disabled/>
                                                        </td>
                                                        <td><DeleteButton onDelete={() => deleteAdditionalField(i)}></DeleteButton></td>
                                                    </tr>
                                                )
                                            }
                                            {
                                                showAdditionalFieldsForm ?
                                                    <>
                                                        <tr className="thin-horizontal caption">
                                                            <td width="40%">
                                                                <BaseForm.Input type="text" name={`invoice_additional_display_fields.${settings.invoice_additional_display_fields.length}.name`} placeholder="Name" label="Name" hideLabel required />
                                                            </td>
                                                            <td width="40%">
                                                                <BaseForm.Input type="text" name={`invoice_additional_display_fields.${settings.invoice_additional_display_fields.length}.value`} placeholder="Value" label="Value" hideLabel required />
                                                            </td>
                                                            <td>
                                                                <Button variant="text-danger" onClick={() => setShowAdditionalFieldsForm(false)}><i className="fa fa-trash"/></Button>
                                                            </td>
                                                        </tr>
                                                        <tr className="thin-horizontal">
                                                            <td colSpan="3">
                                                                <SubmitButton>Save</SubmitButton>
                                                            </td>
                                                        </tr>
                                                    </>
                                                : <tr className="thin-horizontal">
                                                        <td colSpan="3">
                                                            <a href="#" onClick={onShowAdditionalFieldsForm}><i className="fa fa-plus"/> Add Field</a>
                                                        </td>
                                                    </tr>
                                            }
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            </BaseForm>
                        </ContentBox.Body>
                    </ContentBox>
                </Section>
                <Section title="Invoice Email Settings">
                    <ContentBox>
                        <ContentBox.Body>
                            <Row>
                                <Col md="8">
                                    <BaseForm initialFormFields={initialFields} onSubmit={updateSettings}>
                                        <Row>
                                            <BaseForm.Input
                                                type="text" colSpan="12" name="reply_to_email_address" label="Reply-to Address"
                                                description="Responses to the invoice emails from customers will be sent to this email address"
                                                validations={{ validEmail: true }}
                                            />
                                            <BaseForm.Input type="switch" colSpan="12" name="attach_pdf" label="Attach PDF to invoice emails" />
                                            <BaseForm.Input
                                                type="switch" colSpan="12" name="send_invoice_generation_email"
                                                label="Invoice Generation Email"
                                                description="Send an email to the customer automatically when an invoice is generated, regardless of how it is expected to be paid"
                                            />
                                            <BaseForm.Input
                                                type="switch" colSpan="12" name="send_invoice_payment_success"
                                                label="Invoice Payment Success Email"
                                                description="Send a payment success email to the customer when a payment is made against an invoice."
                                            />
                                            <BaseForm.Input
                                                type="switch" colSpan="12" name="send_invoice_payment_failure"
                                                label="Invoice Payment Failure Email"
                                                description="Send a payment failure email to the customer when a payment attempt ends in a failure."
                                            />
                                        </Row>
                                        <Row>
                                            <Col md="4">
                                                <SubmitButton>Save</SubmitButton>
                                            </Col>
                                        </Row>
                                    </BaseForm>
                                </Col>
                            </Row>
                        </ContentBox.Body>
                    </ContentBox>
                </Section>
                <Section className="mt-4" title="Invoice Reminders">
                    <ContentBox>
                        <ContentBox.Body>
                            <Row>
                                <Col md="12">
                                    <div className="text-base font-semibold">Manage Failed Payment Retries</div>
                                    <span className="body1">Configure the retry schedule you'd like to use when a customer's payment method fails. Note that the retry schedule only applies to invoices whose billing method is set to automatic.</span>
                                    <br/>
                                    <BaseForm initialFormFields={initialFields} onFieldChange={onFieldChange}>
                                        <Row>
                                            <BaseForm.Input
                                                colSpan="4" type="switch" name="auto_charge_invoice_payment_retry_enabled"
                                                label="Send Reminders" formClassName="thin"
                                            />
                                            {
                                                autoChargeInvoicePaymentRetryEnabled && !autoChargeInvoicePaymentRetryEditing &&
                                                    <span className="body1">
                                                        {
                                                            settings.auto_charge_invoice_payment_retry_config &&
                                                                <><span className="body2">{_.capitalize(settings.auto_charge_invoice_payment_retry_config.grouping_name)}</span> -&nbsp;</>
                                                        }
                                                        {
                                                            settings.auto_charge_invoice_payment_retry_config && _.map(settings.auto_charge_invoice_payment_retry_config.config.items, (item, i) =>
                                                                <React.Fragment key={i}>{ getConfigItemDescription(item) }{ i === settings.auto_charge_invoice_payment_retry_config.config.items.length-1 ? "": ", " }</React.Fragment>
                                                            )
                                                        }
                                                        <br/>
                                                        <a href="#" onClick={(event) => {
                                                            event.stopPropagation();
                                                            event.preventDefault();
                                                            setAutoChargeInvoicePaymentRetryEditing(true)
                                                        }}>Change</a>
                                                    </span>
                                            }
                                        </Row>
                                    </BaseForm>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="12">
                                    {
                                        autoChargeInvoicePaymentRetryEnabled && autoChargeInvoicePaymentRetryEditing &&
                                            <Row>
                                                <Col lg="3">{renderRetrySchedule("basic")}</Col>
                                                <Col lg="3">{renderRetrySchedule("lenient")}</Col>
                                                <Col lg="3">{renderRetrySchedule("aggressive")}</Col>
                                                <Col lg="3">{renderRetrySchedule("custom")}</Col>
                                            </Row>
                                    }
                                </Col>
                            </Row>
                            <br/>
                            <Row>
                                <Col md="12">
                                    <div className="text-base font-semibold">Manage Invoice Reminder Schedule</div>
                                    <span className="body1">Configure the reminder schedule you'd like to use when sending out invoices. Note that the reminder schedule only applies to invoices whose billing method is set to manual.</span>
                                    <br/>
                                    <BaseForm initialFormFields={initialFields} onFieldChange={onFieldChange}>
                                        <Row>
                                            <BaseForm.Input colSpan="4" type="switch" name="non_auto_charge_invoice_reminder_enabled" label="Send Reminders" />
                                            {
                                                nonAutoChargeInvoiceReminderEnabled && !nonAutoChargeInvoiceReminderEditing &&
                                                <span className="body1">
                                                    {
                                                        settings.non_auto_charge_invoice_reminder_config &&
                                                            <><span className="body2">{_.capitalize(settings.non_auto_charge_invoice_reminder_config.grouping_name)}</span> -&nbsp;</>
                                                    }
                                                    {
                                                        settings.non_auto_charge_invoice_reminder_config && _.map(settings.non_auto_charge_invoice_reminder_config.config.items, (item, i) =>
                                                            <React.Fragment key={i}>{ getConfigItemDescription(item) }{ i === settings.non_auto_charge_invoice_reminder_config.config.items.length-1 ? "": ", " }</React.Fragment>
                                                        )
                                                    }
                                                    <br/>
                                                    <a href="#" onClick={(event) => {
                                                        event.stopPropagation();
                                                        event.preventDefault();
                                                        setNonAutoChargeInvoiceReminderEditing(true)
                                                    }}>Change</a>
                                                </span>
                                            }
                                        </Row>
                                    </BaseForm>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="12">
                                    {
                                        nonAutoChargeInvoiceReminderEnabled && nonAutoChargeInvoiceReminderEditing &&
                                            <Row>
                                                <Col lg="3">{renderReminderSchedule("basic")}</Col>
                                                <Col lg="3">{renderReminderSchedule("lenient")}</Col>
                                                <Col lg="3">{renderReminderSchedule("aggressive")}</Col>
                                                <Col lg="3">{renderReminderSchedule("custom")}</Col>
                                            </Row>
                                    }
                                </Col>
                            </Row>
                            <br/>
                            <Row>
                                <Col md="12">
                                    <div className="text-base font-semibold">Card Expiry Reminder Schedule</div>
                                    <span className="body1">Configure the reminder schedule you'd like to use when a customer has an expiring card that is being used for a subscription.</span>
                                    <br/>
                                    <BaseForm initialFormFields={initialFields} onFieldChange={onFieldChange}>
                                        <Row>
                                            <BaseForm.Input colSpan="4" type="switch" name="card_expiry_reminder_enabled" label="Send Reminders" />
                                            {
                                                cardExpiryReminderEnabled && !cardExpiryReminderEditing &&
                                                <span className="body1">
                                                    {
                                                        settings.card_expiry_reminder_config &&
                                                            <><span className="body2">{_.capitalize(settings.card_expiry_reminder_config.grouping_name)}</span> -&nbsp;</>
                                                    }
                                                    {
                                                        settings.card_expiry_reminder_config && _.map(settings.card_expiry_reminder_config.config.items, (item, i) =>
                                                            <React.Fragment key={i}>{ getConfigItemDescription(item) }{ i === settings.card_expiry_reminder_config.config.items.length-1 ? "": ", " }</React.Fragment>
                                                        )
                                                    }
                                                    <br/>
                                                    <a href="#" onClick={(event) => {
                                                        event.stopPropagation();
                                                        event.preventDefault();
                                                        setCardExpiryReminderEditing(true)
                                                    }}>Change</a>
                                                </span>
                                            }
                                        </Row>
                                    </BaseForm>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="12">
                                    {
                                        cardExpiryReminderEnabled && cardExpiryReminderEditing &&
                                        <Row>
                                            <Col lg="3">{renderCardExpirySchedule("basic")}</Col>
                                            <Col lg="3">{renderCardExpirySchedule("lenient")}</Col>
                                            <Col lg="3">{renderCardExpirySchedule("aggressive")}</Col>
                                            <Col lg="3">{renderCardExpirySchedule("custom")}</Col>
                                        </Row>
                                    }
                                </Col>
                            </Row>
                        </ContentBox.Body>
                    </ContentBox>
                </Section>
                <AddOrEditInvoiceReminderModal show={showInvoiceReminderModal} onClose={onModalClose}
                    reminderToEdit={reminderToEdit} reminderIndex={reminderIndexToEdit}
                    onAdd={addReminder} onUpdate={updateReminder} />
                <EditScheduleModal
                    show={showEditAutoChargeRetryModal}
                    actions={["payment_retry", "notification", "cancel_subscription", "unpaid_subscription"]}
                    onClose={onModalClose}
                    onScheduleUpdated={onCustomAutoChargeRetryScheduleSelected}
                    defaultItems={settings.auto_charge_invoice_payment_retry_config && settings.auto_charge_invoice_payment_retry_config.config.items} />
                <EditScheduleModal
                    show={showEditNonAutoChargeScheduleModal}
                    actions={["notification", "cancel_subscription", "unpaid_subscription"]}
                    onClose={onModalClose}
                    onScheduleUpdated={onCustomNonAutoChargeReminderScheduleSelected}
                    defaultItems={settings.non_auto_charge_invoice_reminder_config && settings.non_auto_charge_invoice_reminder_config.config.items} />
                <EditScheduleModal
                    show={showEditCardExpiryScheduleModal}
                    actions={["notification"]}
                    onClose={onModalClose}
                    onScheduleUpdated={onCustomCardExpiryReminderScheduleSelected}
                    defaultItems={settings.card_expiry_reminder_config && settings.card_expiry_reminder_config.config.items} />
            </ContentContainer>
        </BaseContainer>
    );
}

/*
<BaseForm.Input colSpan="12" type="select" name="replyFrom" label="Send replies to" options={replyToOptions} />
*/

export default InvoiceSettings;

import '../../App.scss';
import React, { useContext, useEffect } from 'react';
import { BaseContext } from '../../helpers/common';
import BaseContainer from '../../components/BaseContainer';
import InvoiceListSection from '../../components/InvoiceListSection';
import ContentContainer from '../../components/ContentContainer';

function Invoices() {
    const { setPageTitle } = useContext(BaseContext);

    useEffect(() => {
        setPageTitle(`Invoices`);
    }, []);

    return (
        <BaseContainer>
            <ContentContainer>
                <InvoiceListSection showTableActions={true} />
            </ContentContainer>
        </BaseContainer>
    );
}

export default Invoices;

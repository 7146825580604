import '../../../App.scss';
import React, { useEffect, useState, useContext } from 'react';
import { serverPost, serverDelete } from '../../../helpers/server';
import {  BaseContext, UserContext } from '../../../helpers/common';
import BaseContainer from '../../../components/BaseContainer';
import ContentContainer from '../../../components/ContentContainer';
import AddOrEditApiTokenModal from '../../../components/modals/AddOrEditApiTokenModal';
import DeleteButton from '../../../components/DeleteButton';
import CopyableComponent from '../../../components/CopyableComponent';
import ContentBox from '../../../components/ContentBox';
import Section from '../../../components/Section';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import MapleTable from "../../../components/MapleTable";
import {CommandLineIcon} from "@heroicons/react/20/solid";
import EmptyState from "../../../components/EmptyState";
const _ = require('lodash');

function ApiTokens() {
    const { t } = useTranslation('common');
    const { userInfo } = useContext(UserContext);
    const { company, getApiUrl, setPageTitle, hasAccess } = useContext(BaseContext);
    const [tokens, setTokens] = useState([]);
    const [addedToken, setAddedToken] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showAddOrEditApiTokenModal, setShowAddOrEditApiTokenModal] = useState(false);

    const hasWritePermissions = hasAccess("developers", userInfo, "write");

    useEffect(() => {
        setPageTitle(`API Tokens`);
    }, []);

    const fetchData = () => {
        serverPost(getApiUrl("/api_tokens/find"), { company_id: company.id }).then((res) => {
            setTokens(res);
            setLoading(false);
        });
    }

    useEffect(() => {
        fetchData();
    }, []);

    const onAddApiToken = () => {
        setShowAddOrEditApiTokenModal(true);
    }

    const onModalClose = (didUpdate) => {
        setShowAddOrEditApiTokenModal(false);
        if (didUpdate) {
            fetchData();
        }
    }

    const onTokenAdded = (token) => {
        onModalClose(true);
        setAddedToken(token);
    }

    const onDeleteToken = (token) => {
        setAddedToken(null);
        serverDelete(getApiUrl(`/api_tokens/${token.id}`)).then((res) => {
            if (res) {
                fetchData();
            }
        })
    }

    const isEmpty = tokens.length === 0;
    return (
        <BaseContainer>
            <ContentContainer>
                <Section title={t('api_tokens.title')}
                         loading={loading}
                         subtitle={<CopyableComponent
                             className="thin"
                             value={`Company ID: ${company.id}`}
                             copyableValue={company.id}>
                         </CopyableComponent>}
                         actions={hasWritePermissions && !isEmpty && [{
                        variant: "primary",
                        icon: "fa-plus",
                        label: "Create API Token",
                        onClick: onAddApiToken
                    }]}>
                    {
                        addedToken &&
                            <ContentBox>
                                <ContentBox.Body>
                                    <div className="body2">Newly added token - Keep your key safe</div>
                                    <div className="body1">Save and store this new key to a secure place, such as a password
                                    manager or secret store. You won’t be able to see it again.</div><br/>
                                    <CopyableComponent value={addedToken.token} />
                                </ContentBox.Body>
                            </ContentBox>
                    }
                    {
                        isEmpty ?
                            <EmptyState
                                icon={CommandLineIcon}
                                title={"No API Tokens."}
                                subtitle={"Get started by creating a new one."}
                                hasWritePermissions={hasWritePermissions}
                                buttonLabel={"New API Token"}
                                onClick={onAddApiToken}
                            />
                        : <MapleTable>
                            <MapleTable.Content>
                                <thead>
                                <tr>
                                    <MapleTable.TH>Name</MapleTable.TH>
                                    <MapleTable.TH>Created At</MapleTable.TH>
                                    <MapleTable.TH></MapleTable.TH>
                                </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200">
                                {
                                    _.map(tokens, (row, i) =>
                                        <tr key={i}>
                                            <td>{ row.name }</td>
                                            <td>{ moment(row.created_at).format("MMM D, YYYY hh:mm:ssa") }</td>
                                            <td className="text-end">
                                                <DeleteButton size="sm" onDelete={() => onDeleteToken(row)} />
                                            </td>
                                        </tr>
                                    )
                                }
                                </tbody>
                            </MapleTable.Content>
                        </MapleTable>
                    }
                </Section>
            </ContentContainer>
            <AddOrEditApiTokenModal show={showAddOrEditApiTokenModal} onClose={setShowAddOrEditApiTokenModal} onTokenAdded={onTokenAdded} />
        </BaseContainer>
    );
}

export default ApiTokens;

import '../../App.scss';
import '../../css/invoice.scss';
import React, { useEffect, useState, useContext, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { serverFetch } from '../../helpers/server';
import { BaseContext } from '../../helpers/common';
import Loader from '../../components/Loader';
import InvoiceComponent from '../../components/InvoiceComponent';
const _ = require('lodash');

function InvoiceView() {
    const [ searchParams ] = useSearchParams();
    let uuid = searchParams.get('uuid');
    const { getApiUrl, setPageTitle } = useContext(BaseContext);

    const [invoiceDetails, setInvoiceDetails] = useState({});
    const [settings, setSettings] = useState({});

    useEffect(() => {
        setPageTitle(`Invoice - ${invoiceDetails.number}`);
    }, [invoiceDetails]);

    const fetchData = (skipCache=false) => {
        serverFetch(getApiUrl(`/settings`)).then((res) => {
            setSettings(res)
        })

        serverFetch(getApiUrl(`/invoices/${uuid}`)).then((res) => {
            setInvoiceDetails(res)
        })
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <Loader loading={_.isEmpty(invoiceDetails) || _.isEmpty(settings)}>
            <InvoiceComponent invoice={invoiceDetails} settings={settings} />
        </Loader>
    );
}

export default InvoiceView;

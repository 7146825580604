import '../../App.scss';
import '../../css/modals.scss';
import '../../css/forms.scss';
import React from 'react';
import BaseForm from '../BaseForm';
import BaseAddOrEditItemModal from '../modals/BaseAddOrEditItemModal';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function AddOrEditCustomerModal(props) {
    const { t } = useTranslation('common');

    const cleanupFields = (fields) => {
        return {
            ...fields,
            identifier: (fields.identifier && fields.identifier.trim()) || fields.email
        };
    }

    return (
        <BaseAddOrEditItemModal
            {...props}
            processAddFields={cleanupFields}
            processUpdateFields={cleanupFields}
            itemLabel={t('customers.customer')}
            itemBaseUrl="/customers"
        >
            <Row>
                <BaseForm.Input colSpan="12" type="text" name="name" label="Contact Name" />
                <BaseForm.Input colSpan="12" type="text" name="email" label="Email" transformations={["lowercase"]} validations={{ required: true, validEmail: true }} />
                <BaseForm.Input colSpan="12" type="text" name="org_name" label="Organization Name (optional)" />
                <BaseForm.Input colSpan="12" type="text" name="phone" label="Phone Number (optional)" />
                <BaseForm.Input colSpan="12" type="simple_address" name="address" label="Address (optional)" />
                <BaseForm.Input colSpan="12" type="text" name="identifier" label="External Identifier (optional)" />
            </Row>
        </BaseAddOrEditItemModal>
    );

}

export default AddOrEditCustomerModal;

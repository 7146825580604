import '../App.scss';
import React from 'react';

function Columns(props) {
    return (
        <></>
    );
}

function CustomerName(props) {
    const name = props.customer.org_name || props.customer.name || props.customer.email;
    const parts = name.split(" ");
    let nameLabel = "";
    if (parts.length > 1) {
        nameLabel = parts[0].substring(0, 1) + parts[1].substring(0, 1);
    } else {
        nameLabel = name.substring(0, 2);
    }
    return (
        <div>
            <div className="flex flex-row items-center gap-3">
                <div className="profile-icon">
                    { nameLabel }
                </div>
                <div className="flex flex-col">
                    <p className="font-medium text-gray-700">{ props.customer.org_name || props.customer.name }</p>
                    <p className="font-normal text-gray-500 break-all">{ props.customer.email }</p>
                </div>
            </div>
        </div>
    )
}
Columns.CustomerName = CustomerName;

function CustomerAvatar(props) {
    const name = props.customer.name || props.customer.email;
    const parts = name.split(" ");
    let nameLabel = "";
    if (parts.length > 1) {
        nameLabel = parts[0].substring(0, 1) + parts[1].substring(0, 1);
    } else {
        nameLabel = name.substring(0, 2);
    }
    return (
        <div>
            <div className="d-flex flex-row align-items-center">
                <div className="profile-icon">
                    { nameLabel }
                </div>
            </div>
        </div>
    )
}
Columns.CustomerAvatar = CustomerAvatar;

function CustomerName2(props) {
    const name = props.customer.org_name || props.customer.name || props.customer.email;
    const parts = name.split(" ");
    let nameLabel = "";
    if (parts.length > 1) {
        nameLabel = parts[0].substring(0, 1) + parts[1].substring(0, 1);
    } else {
        nameLabel = name.substring(0, 2);
    }
    return (
        <div className="flex items-center">
            <div className="h-11 flex-shrink-0">
            </div>
            <div>
                <div className="font-medium text-gray-900">{ props.customer.org_name || props.customer.name }</div>
                <div className="mt-1 text-gray-500">{ props.customer.email }</div>
            </div>
        </div>
    )
}
Columns.CustomerName2 = CustomerName2;

function CustomerAvatar2(props) {
    const name = props.customer.name || props.customer.email;
    const parts = name.split(" ");
    let nameLabel = "";
    if (parts.length > 1) {
        nameLabel = parts[0].substring(0, 1) + parts[1].substring(0, 1);
    } else {
        nameLabel = name.substring(0, 2);
    }
    return (
        <span className="inline-flex h-9 w-9 items-center justify-center rounded-full bg-primary-600">
            <span className="text-sm font-medium leading-none text-white">{ nameLabel }</span>
        </span>
    )
}
Columns.CustomerAvatar2 = CustomerAvatar2;

export default Columns;

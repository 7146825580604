import SubmitButton from '../components/SubmitButton';
import BaseForm from '../components/BaseForm';
import { Image } from 'react-bootstrap';
const _ = require("lodash");

const featureMap = {
    "CRM": {
        key: "CRM",
        shortTitle: "CRM",
        title: "Customer Relationship Management",
        description: "The integration allow you to pull leads, deals, persons, contacts from the CRM tool.",
        pillClassName: "bg-purple-100 text-purple-700",
    },
    "E_SIGN_PROVIDER": {
        key: "E_SIGN_PROVIDER",
        shortTitle: "E-Sign",
        title: "E-Signature Provider",
        description: "Use the e-signature provider to handle signature requests in Maple.",
        pillClassName: "bg-yellow-100 text-yellow-700",
    },
    "TAX_CALC": {
        key: "TAX_CALC",
        shortTitle: "Tax Calc",
        title: "Tax Calculation",
        description: "Use the API to calculate taxes on your invoices in Maple.",
        pillClassName: "bg-indigo-100 text-indigo-700",
    },
    "ACCOUNTING": {
        key: "ACCOUNTING",
        shortTitle: "Accounting",
        title: "Accounting",
        description: "Push customers, invoices, payments, refunds from Maple onto your accounting software.",
        pillClassName: "bg-pink-100 text-pink-700",
    }
}

export function getIntegrationInterfaceForKey(key) {
    if (key === "intercom") {
        return new IntegrationInterface();
    } else if (key === "pipedrive") {
        return new PipedriveIntegrationInterface();
    } else {
        return new IntegrationInterface();
    }
}

export function isFieldMappingEnabled(integration) {
    if (integration && _.includes(integration.features_enabled, "BLAH")) {
        return true;
    }
    return false;
}

export function isSyncEnabled(integration) {
    if (integration && _.includes(integration.features_enabled, "SYNC")) {
        return true;
    }
    return false;
}

export function getFeatureDescription(feature) {
    return featureMap[feature] || {};
}

export function getFeatureDescriptions(integration) {
    return _.map(integration.available_features, (f) => getFeatureDescription(f));
}

export function getExternalCustomerReferenceURL(customer) {
    if (customer.imported_from === "STRIPE") {
        return `https://dashboard.stripe.com/customers/${customer.identifier}`
    }
}

class IntegrationInterface {
    getWelcomePageContent() {
        return (
            <p/>
        )
    }

    getApiInstructions(updateApiKey, initialFields) {
        return (
            <div>
                <BaseForm initialFormFields={initialFields} onSubmit={updateApiKey}>
                    <p>Please refer to the application&#39;s documentation for the instruction on obtaining the api key.</p>
                    <p>If you ever need your personal API key, it can be found under <b>Settings > Personal preferences > API</b>.</p>
                    <Image className="instructions" src="/images/integrations/pipedrive-api.webp"/>
                    <br/><br/>
                    <BaseForm.Input type="text" name="apikey_authorization.access_token" label="API Key" required/>
                    <br/>
                    <SubmitButton>Update</SubmitButton>
                </BaseForm>
            </div>
        )
    }
}

class PipedriveIntegrationInterface extends IntegrationInterface {
    getWelcomePageContent() {
        return (
            <>
            <p>The integration with Pipedrive ensures that you can enrich contacts in the CRM with critical
                subscription data including subscription, invoice, customer and company details.</p>
            </>
        )
    }

    getApiInstructions(updateApiKey) {
        return (
            <div>
                <BaseForm onSubmit={updateApiKey}>
                    <p>You can look at <a href="https://support.pipedrive.com/en/article/how-can-i-find-my-personal-api-key">this
                        article</a> to learn about where to find your personal API key.</p>
                    <p>If you ever need your personal API key, it can be found under <b>Settings > Personal preferences > API</b>.</p>
                    <Image className="instructions" src="/images/integrations/pipedrive-api.webp"/>
                    <br/><br/>
                    <BaseForm.Input type="text" name="apikey_authorization.access_token" label="API Key" required/>
                    <br/>
                    <SubmitButton>Update</SubmitButton>
                </BaseForm>
            </div>
        )
    }
}

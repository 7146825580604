import { Routes, Route } from "react-router-dom";
import './App.scss';
import React from 'react';

import Playground from './pages/internal/Playground.js';
import SuperUserDashboard from './pages/internal/SuperUserDashboard.js';
import SuperUserTasks from './pages/internal/Tasks.js';

import OptionalAuthentication from './components/OptionalAuthentication.js';
import RequireAuthentication from './components/RequireAuthentication.js';
import RequirePermission from './components/RequirePermission.js';
import RequireSuperUser from './components/RequireSuperUser.js';
import CustomerAuthentication from './components/CustomerAuthentication.js';

import Login from './pages/Login.js';
import Register from './pages/Register.js';
import ForgotPassword from './pages/ForgotPassword.js';
import ChangePassword from './pages/ChangePassword.js';
import VerifyEmail from './pages/VerifyEmail.js';
import NotFound from './pages/NotFound.js';
import UserHome from './pages/user/Home.js'

import Flow from './components/Flow.js'

import IntegrationComponent from './components/IntegrationComponent.js';
import IntegrationContainer from './components/IntegrationContainer.js';
import IntegrationWelcome from './pages/company/integrations/Welcome.js';
import IntegrationAPI from './pages/company/integrations/API.js';
import IntegrationDone from './pages/company/integrations/Done.js';
import IntegrationOauthCallback from './pages/company/integrations/OauthCallback.js';
import IntegrationDashboard from './pages/company/integrations/Dashboard.js';
import IntegrationSettings from './pages/company/integrations/Settings.js';
import IntegrationSyncs from './pages/company/integrations/Syncs.js';

import CompanyComponent from './components/CompanyComponent.js';
import CompanyHome from './pages/company/CompanyHome.js';
import Reports from './pages/company/Reports.js';
import Metrics from './pages/company/Metrics.js';
import MRRLog from './pages/company/MRRLog.js';
import Campaigns from './pages/company/Campaigns.js';
import Customers from './pages/company/Customers.js';
import Migration from './pages/company/Migration.js';
import Coupons from './pages/company/Coupons.js';
import Coupon from './pages/company/Coupon.js';
import Contracts from './pages/company/Contracts.js';
import ContractsMetrics from './pages/company/ContractsMetrics.js';
import Contract from './pages/company/Contract.js';
import ContractComponent from './components/ContractComponent.js';
import ContractView from './pages/company/ContractView.js';
import ContractAccept from './pages/company/ContractAccept.js';
import ContractPay from './pages/company/ContractPay.js';
import ContractDone from './pages/company/ContractDone.js';
import ContractCreate from './pages/company/ContractCreate.js';
import ContractTemplateCreateOrUpdate from './pages/company/ContractTemplateCreateOrUpdate.js';
import PaymentLinkComponent from './components/PaymentLinkComponent.js';
import PaymentLinks from './pages/company/PaymentLinks.js';
import PaymentLinkView from './pages/company/PaymentLinkView.js';
import PaymentLinkPay from './pages/company/PaymentLinkPay.js';
import PaymentLinkDone from './pages/company/PaymentLinkDone.js';
import PaymentLinkCreateOrUpdate from './pages/company/PaymentLinkCreateOrUpdate.js';
import Activity from './pages/company/Activity.js';
import Integrations from './pages/company/integrations/Integrations.js';
import Payments from './pages/company/Payments.js';
import Payment from './pages/company/Payment.js';
import Subscriptions from './pages/company/Subscriptions.js';
import Subscription from './pages/company/Subscription.js';
import SubscriptionCreate from './pages/company/SubscriptionCreate.js';
import Products from './pages/company/Products.js';
import BillableItems from './pages/company/BillableItems.js';
import BillableMetrics from './pages/company/BillableMetrics.js';
import BillableItemPricings from './pages/company/BillableItemPricings.js';
import BillableObjects from './pages/company/BillableObjects.js';
import Entitlements from './pages/company/Entitlements.js';
import Invoice from './pages/company/Invoice.js';
import Invoices from './pages/company/Invoices.js';
import InvoiceView from './pages/company/InvoiceView.js';
import InvoicePrintView from './pages/company/InvoicePrintView.js';
import InvoiceNext from './pages/company/InvoiceNext.js';
import Product from './pages/company/Product.js';
import ProductPricing from './pages/company/ProductPricing.js';
import Customer from './pages/company/Customer.js';
import OauthCallback from './pages/company/OauthCallback.js';
import CustomerPortal from './pages/company/CustomerPortal.js';

import Settings from './pages/company/settings/Settings.js';
import PaymentProviders from './pages/company/settings/PaymentProviders.js';
import Taxes from './pages/company/Taxes.js';
import ContractSettings from './pages/company/settings/ContractSettings.js';
import InvoiceSettings from './pages/company/settings/InvoiceSettings.js';
import InvoiceTemplates from './pages/company/settings/InvoiceTemplates.js';
import Team from './pages/company/settings/Team.js';
import Developers from './pages/company/settings/Developers.js';
import ApiTokens from './pages/company/settings/ApiTokens.js';
import Webhooks from './pages/company/settings/Webhooks.js';


function App() {
    function integrationSetupFlow() {
        const childRoutes = [
            <Route key={0} path="welcome" element={<IntegrationWelcome/>} />,
            <Route key={1} path="oauth_callback" element={<IntegrationOauthCallback/>} />,
            <Route key={1} path="api" element={<IntegrationAPI/>} />,
            <Route key={5} path="done" element={<IntegrationDone/>} />,
        ]
        return (
            <Route path="setup" element={
                <Flow childRoutes={childRoutes} />
            } >
                { childRoutes }
            </Route>
        )
    }

    return (
        <div className="App">
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/forgot_password" element={<ForgotPassword />} />
                <Route path="/change_password" element={<ChangePassword />} />
                <Route path="/verify_email" element={<VerifyEmail />} />
                <Route path="/login" element={<Login />} />

                <Route path="/integrations/:key/oauth/callback" element={<OauthCallback />} />

                <Route path="" element={<OptionalAuthentication />}>
                    <Route path="/c/:companyId" element={<CompanyComponent />}>
                        <Route path="invoice/view" element={<InvoiceView />} />
                        <Route path="invoice/pview" element={<InvoicePrintView />} />
                        <Route path="contract/:uuid/view" element={<ContractView />} />

                        <Route path="contract/:uuid" element={<ContractComponent />} >
                            <Route path="view" element={<ContractView />} />
                            <Route path=":sid/accept" element={<ContractAccept />} />
                            <Route path="pay" element={<ContractPay />} />
                            <Route path="done" element={<ContractDone />} />
                        </Route>
                        <Route path="buy/:uuid" element={<PaymentLinkComponent />} >
                            <Route path="" element={<PaymentLinkView />} />
                            <Route path="pay" element={<PaymentLinkPay />} />
                            <Route path="done" element={<PaymentLinkDone />} />
                        </Route>
                        <Route path="" element={<CustomerAuthentication />} >
                            <Route path="customer/:customerId/portal" element={<CustomerPortal />} />
                        </Route>
                    </Route>

                    <Route path="" element={<RequireAuthentication />}>
                        <Route path="" element={<RequireSuperUser />} >
                            <Route path="/internal/playground" element={<Playground />} />
                            <Route path="/internal/super/dashboard" element={<SuperUserDashboard />} />
                            <Route path="/internal/super/tasks" element={<SuperUserTasks />} />
                        </Route>
                        <Route path="/user/home" element={<UserHome />} />
                        <Route path="/c/:companyId" element={<CompanyComponent />}>
                            <Route index element={<CompanyHome />} />
                            <Route path="home" element={<CompanyHome />} />

                            <Route path="" element={<RequirePermission feature="customers" />}>
                                <Route path="customers" element={<Customers />} />
                                <Route path="customer/:customerId" element={<Customer />} />
                                <Route path="migration" element={<Migration />} />
                            </Route>

                            <Route path="" element={<RequirePermission feature="pricing" />}>
                                <Route path="products" element={<Products />} />
                                <Route path="product/:productId" element={<Product />} />
                                <Route path="pricing/:pricingId" element={<ProductPricing />} />
                                <Route path="billable_items" element={<BillableItems />} />
                                <Route path="billable_metrics" element={<BillableMetrics />} />
                                <Route path="billable_item_pricings" element={<BillableItemPricings />} />
                                <Route path="objects" element={<BillableObjects />} />
                                <Route path="coupons" element={<Coupons />} />
                                <Route path="coupon/:couponId" element={<Coupon />} />
                                <Route path="entitlements" element={<Entitlements />} />
                            </Route>

                            <Route path="" element={<RequirePermission feature="payments" />}>
                                <Route path="payments" element={<Payments />} />
                                <Route path="payment/:paymentId" element={<Payment />} />
                            </Route>

                            <Route path="" element={<RequirePermission feature="invoices" />}>
                                <Route path="invoices" element={<Invoices />} />
                                <Route path="invoice/:uuid" element={<Invoice />} />
                            </Route>

                            <Route path="" element={<RequirePermission feature="subscriptions" />}>
                                <Route path="subscriptions" element={<Subscriptions />} />
                                <Route path="subscription/create" element={<SubscriptionCreate />} />
                                <Route path="subscription/:subscriptionId" element={<Subscription />} />
                                <Route path="subscription/:subscriptionId/next_invoice" element={<InvoiceNext />} />
                            </Route>

                            <Route path="" element={<RequirePermission feature="reports" />}>
                                <Route path="metrics" element={<Metrics />} />
                                <Route path="metrics/:key" element={<Metrics />} />
                                <Route path="reports" element={<Reports />} />
                                <Route path="campaigns" element={<Campaigns />} />
                                <Route path="mrr_logs" element={<MRRLog />} />
                            </Route>

                            <Route path="" element={<RequirePermission feature="settings" />}>
                                <Route path="activity" element={<Activity />} />
                                <Route path="taxes" element={<Taxes />} />
                                <Route path="settings" element={<Settings />} />
                                <Route path="settings/payment_providers" element={<PaymentProviders />} />
                                <Route path="settings/invoice_extra" element={<InvoiceSettings />} />
                                <Route path="settings/invoice_templates" element={<InvoiceTemplates />} />
                                <Route path="settings/invoices" element={<InvoiceSettings />} />
                                <Route path="settings/contracts" element={<ContractSettings />} />
                                <Route path="settings/team" element={<Team />} />
                            </Route>

                            <Route path="" element={<RequirePermission feature="developers" />}>
                                <Route path="developers" element={<Developers />} />
                                <Route path="developers/api_tokens" element={<ApiTokens />} />
                                <Route path="developers/webhooks" element={<Webhooks />} />
                            </Route>

                            <Route path="" element={<RequirePermission feature="proposal" />}>
                                <Route path="contracts" element={<Contracts />} />
                                <Route path="contracts/metrics" element={<ContractsMetrics />} />
                                <Route path="contract/create" element={<ContractCreate />} />
                                <Route path="contract/view/:uuid" element={<Contract />} />
                                <Route path="contract/template/edit/:uuid" element={<ContractTemplateCreateOrUpdate />} />
                                <Route path="contract/template/create" element={<ContractTemplateCreateOrUpdate />} />
                            </Route>

                            <Route path="" element={<RequirePermission feature="proposal" />}>
                                <Route path="payment_links" element={<PaymentLinks />} />
                                <Route path="payment_link/create" element={<PaymentLinkCreateOrUpdate />} />
                                <Route path="payment_link/edit/:uuid" element={<PaymentLinkCreateOrUpdate />} />
                            </Route>

                            <Route path="" element={<RequirePermission feature="integrations" />}>
                                <Route path="integrations" element={<Integrations />} />
                                <Route path="integrations/:id" element={<IntegrationComponent />} >
                                    { integrationSetupFlow() }
                                    <Route path="" element={<IntegrationContainer />} >
                                        <Route path="dashboard" element={<IntegrationDashboard />} />
                                        <Route path="syncs" element={<IntegrationSyncs />} />
                                        <Route path="settings" element={<IntegrationSettings />} />
                                    </Route>
                                </Route>
                            </Route>

                            <Route path="*" element={<NotFound />} />
                        </Route>
                    </Route>
                </Route>
                <Route path="*" element={<NotFound />} />
            </Routes>
        </div>
    );
}

export default App;

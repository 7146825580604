import React, {useState} from 'react';
import '../../App.scss';
import '../../css/table.scss';
import '../../css/forms.scss';
import {serverPost, getUrl} from '../../helpers/server';
import {storageLogout} from '../../helpers/storage';
import BaseForm from '../../components/BaseForm';
import SubmitButton from '../../components/SubmitButton';
import SuperContainer from '../../components/SuperContainer';
import ContentBox from '../../components/ContentBox';
import {Row, Table, Button} from 'react-bootstrap';

const _ = require('lodash');

function SuperUserDashboard() {
    const [spoofError, setSpoofError] = useState(null);
    const [searchResults, setSearchResults] = useState([]);

    const createDemoAccount = (data) => {
        serverPost(getUrl("/super_user/demo"), data).then((res) => {
            if (res) {
                // do something
                console.log("Demo account created");
            }
        });
    }

    const sweepSubscriptions = (data) => {
        const newData = {
            company_id: data.company_id
        }
        serverPost(getUrl("/super_user/sweep_subscriptions"), newData).then((res) => {
            if (res) {
                // do something
                console.log("Subscriptions sweep started");
            }
        });
    }

    const sweepReports = (data) => {
        const newData = {
            company_id: data.company_id,
            report_id: data.report_id || null
        }
        serverPost(getUrl("/super_user/sweep_reports"), newData).then((res) => {
            if (res) {
                // do something
                console.log("Reports sweep started");
            }
        });
    }

    const cacheConversionRates = (data) => {
        const newData = {}
        serverPost(getUrl("/super_user/cache_conversion_rates"), newData).then((res) => {
            if (res) {
                // do something
                console.log("Cache conversion rates started");
            }
        });
    }

    const generateInvoice = (data) => {
        const newData = {
            company_id: data.company_id,
            subscription_id: data.subscription_id
        }
        serverPost(getUrl("/super_user/generate_invoice"), newData).then((res) => {
            if (res) {
                // do something
                console.log("Invoice generated");
            }
        });
    }

    const onFieldChange = (name, value) => {
        if (name === "search") {
            serverPost(getUrl("/super_user/search"), { query: value }).then((res) => {
                if (res) {
                    // do something
                    setSearchResults(res);
                }
            });
        }
    }

    const spoofUser = (data) => {
        const handleError = async (error) => {
            const message = await error.json();
            setSpoofError("Error: " + error.status + ": " + message.error)
        }
        if (_.isEmpty(data.user_id)) {
            data.user_id = null;
        }
        serverPost(getUrl("/super_user/spoof"), data, {}, handleError).then((res) => {
            if (res) {
                // do something
                // Store the current user info so we can restore later;
                const existingToken = localStorage.getItem("auth_token");

                storageLogout();
                localStorage.setItem("spoof_auth_token", existingToken);
                localStorage.setItem("auth_token", res['token']);
                window.location.href = "/user/home";
            }
        });
    }

    const triggerStripeCustomersImport = (data) => {
        const newData = {
            company_id: data.company_id,
            stripe_account_id: data.stripe_account_id,
            stripe_customer_ids: data.stripe_customer_ids
        }
        serverPost(getUrl("/super_user/customer_import"), newData).then((res) => {
            if (res) {
                // do something
                console.log("Customer import triggered");
            }
        });
    }

    const triggerSubscriptionsImport = (data) => {
        const newData = {
            company_id: data.company_id,
            stripe_account_id: data.stripe_account_id
        }
        serverPost(getUrl("/super_user/subscriptions_import"), newData).then((res) => {
            if (res) {
                // do something
                console.log("Subscriptions import triggered");
            }
        });
    }

    const triggerSubscriptionsUsageMetricsImport = (data) => {
        const newData = {
            company_id: data.company_id,
            stripe_account_id: data.stripe_account_id
        }
        serverPost(getUrl("/super_user/subscriptions_usage_metrics_import"), newData).then((res) => {
            if (res) {
                // do something
                console.log("Subscriptions usage metrics import triggered");
            }
        });
    }

    const triggerCustomersPaymentsImport = (data) => {
        const newData = {
            company_id: data.company_id,
            stripe_customer_id: data.stripe_customer_id,
            customer_id: data.customer_id
        }
        serverPost(getUrl("/super_user/payments_import"), newData).then((res) => {
            if (res) {
                // do something
                console.log("Payments import triggered");
            }
        });
    }

    const triggerCustomersInvoicesAndPaymentsImport = (data) => {
        const newData = {
            company_id: data.company_id,
            stripe_account_id: data.stripe_account_id
        }
        serverPost(getUrl("/super_user/invoices_and_payments_import"), newData).then((res) => {
            if (res) {
                // do something
                console.log("Invoices and Payments import triggered");
            }
        });
    }

    const triggerCustomerInvoicesImport = (data) => {
        const newData = {
            company_id: data.company_id,
            stripe_invoice_id: data.stripe_invoice_id,
            stripe_customer_id: data.stripe_customer_id,
            stripe_account_id: data.stripe_account_id,
            handle_subscription: data.handle_subscription,
        }
        serverPost(getUrl("/super_user/invoices_import"), newData).then((res) => {
            if (res) {
                // do something
                console.log("Invoice import triggered");
            }
        });
    }

    const triggerProductImport = (data) => {
        const newData = {
            company_id: data.company_id,
            stripe_product_id: data.stripe_product_id,
            stripe_account_id: data.stripe_account_id,
        }
        serverPost(getUrl("/super_user/products_import"), newData).then((res) => {
            if (res) {
                // do something
                console.log("Product import triggered");
            }
        });
    }

    const triggerProductTaxCategories = (data) => {
        serverPost(getUrl("/super_user/import_tax_categories"), {}).then((res) => {
            if (res) {
                // do something
                console.log("Tax categories import triggered");
            }
        });
    }

    const sweepMRR = (data) => {
        const newData = {
            company_id: data.company_id,
            customer_id: data.customer_id
        }
        serverPost(getUrl("/super_user/sweep_mrr"), newData).then((res) => {
            if (res) {
                // do something
                console.log("MRR updated");
            }
        });
    }

    const updateMRRCache = (data) => {
        const newData = {
            company_id: data.company_id,
            time: data.time || null
        }
        serverPost(getUrl("/super_user/update_mrr_cache"), newData).then((res) => {
            if (res) {
                // do something
                console.log("MRR cache updated");
            }
        });
    }

    return (
        <SuperContainer>
            <ContentBox>
                <ContentBox.Body>
                    <BaseForm onSubmit={createDemoAccount}>
                        <h3>Create Demo Account</h3>
                        <Row>
                            <BaseForm.Input colSpan="6" type="text" label="Company Name" name="company_name" required/>
                            <BaseForm.Input colSpan="6" type="text" label="Company Email" name="company_email"
                                            required/>
                            <BaseForm.Input colSpan="4" type="text" label="User Name" name="user_name" required/>
                            <BaseForm.Input colSpan="4" type="text" label="User Email" name="user_email" required/>
                            <BaseForm.Input colSpan="4" type="text" label="User Password" name="password" required/>
                        </Row>
                        <SubmitButton>Create Demo Account</SubmitButton>
                    </BaseForm>
                </ContentBox.Body>
            </ContentBox>
            <ContentBox>
                <ContentBox.Body>
                    <BaseForm onSubmit={spoofUser} onFieldChange={onFieldChange}>
                        <h3>Spoof</h3>
                        <Row>
                            <BaseForm.Input colSpan="12" type="text" label="Search" name="search"/>
                        </Row>
                        <Table>
                            <tbody>
                            {
                                _.map(searchResults, (sr, k) =>
                                    <tr>
                                        <td>{ sr.company.name }</td>
                                        <td>
                                            <span>{ sr.user.name }</span><br/>
                                            <span>{ sr.user.email }</span>
                                        </td>
                                        <td><Button variant="primary" onClick={() => spoofUser({ user_id: sr.user.id })}>Spoof</Button></td>
                                    </tr>
                                )
                            }
                            </tbody>
                        </Table>
                        <Row className="hide">
                            <BaseForm.Input colSpan="6" type="text" label="User ID" name="user_id"/>
                            <BaseForm.Input colSpan="6" type="text" label="User Email" name="user_email"
                                            validations={{validateEmail: true}}/>
                        </Row>
                        <div className="form-error-message">{spoofError}</div>
                        <SubmitButton className="hide">Spoof User</SubmitButton>
                    </BaseForm>
                </ContentBox.Body>
            </ContentBox>
            <ContentBox>
                <ContentBox.Body>
                    <BaseForm onSubmit={sweepSubscriptions}>
                        <h3>Sweep Subscriptions For Company</h3>
                        <Row>
                            <BaseForm.Input colSpan="4" type="text" label="Company ID" name="company_id" required/>
                        </Row>
                        <SubmitButton>Sweep Subscriptions</SubmitButton>
                    </BaseForm>
                </ContentBox.Body>
                <ContentBox.Body>
                    <BaseForm onSubmit={sweepSubscriptions}>
                        <h3>Sweep Subscriptions For All Companies</h3>
                        <SubmitButton>Sweep Subscriptions</SubmitButton>
                    </BaseForm>
                </ContentBox.Body>
            </ContentBox>
            <ContentBox>
                <ContentBox.Body>
                    <BaseForm onSubmit={sweepReports}>
                        <h3>Sweep Reports For Company</h3>
                        <Row>
                            <BaseForm.Input colSpan="4" type="text" label="Company ID" name="company_id" required/>
                            <BaseForm.Input colSpan="4" type="text" label="Report ID" name="report_id"/>
                        </Row>
                        <SubmitButton>Sweep Reports</SubmitButton>
                    </BaseForm>
                </ContentBox.Body>
                <ContentBox.Body>
                    <BaseForm onSubmit={sweepReports}>
                        <h3>Sweep Reports For All Companies</h3>
                        <SubmitButton>Sweep Reports</SubmitButton>
                    </BaseForm>
                </ContentBox.Body>
            </ContentBox>
            <ContentBox>
                <ContentBox.Body>
                    <BaseForm onSubmit={sweepMRR}>
                        <h3>Sweep MRR For Customer</h3>
                        <Row>
                            <BaseForm.Input colSpan="4" type="text" label="Company ID" name="company_id" required/>
                            <BaseForm.Input colSpan="4" type="text" label="Customer ID" name="customer_id" required/>
                        </Row>
                        <SubmitButton>Sweep MRR</SubmitButton>
                    </BaseForm>
                </ContentBox.Body>
                <ContentBox.Body>
                    <BaseForm onSubmit={updateMRRCache}>
                        <h3>Update MRR Cache For Company</h3>
                        <Row>
                            <BaseForm.Input colSpan="4" type="text" label="Company ID" name="company_id" required/>
                            <BaseForm.Input colSpan="4" type="date" label="Time" name="time" includeTime={true}/>
                        </Row>
                        <SubmitButton>Update MRR Cache</SubmitButton>
                    </BaseForm>
                </ContentBox.Body>
            </ContentBox>
            <ContentBox>
                <ContentBox.Body>
                    <BaseForm onSubmit={cacheConversionRates}>
                        <h3>Cache Conversion Rates</h3>
                        <SubmitButton>Cache Rates</SubmitButton>
                    </BaseForm>
                </ContentBox.Body>
            </ContentBox>
            <ContentBox>
                <ContentBox.Body>
                    <BaseForm onSubmit={generateInvoice}>
                        <h3>Generate Invoice</h3>
                        <Row>
                            <BaseForm.Input colSpan="4" type="text" label="Company ID" name="company_id" required/>
                            <BaseForm.Input colSpan="4" type="text" label="Subscription ID" name="subscription_id"
                                            required/>
                        </Row>
                        <SubmitButton>Generate Invoice</SubmitButton>
                    </BaseForm>
                </ContentBox.Body>
            </ContentBox>
            <ContentBox>
                <ContentBox.Body>
                    <BaseForm onSubmit={triggerStripeCustomersImport}>
                        <h3>Trigger Stripe Customers Import</h3>
                        <Row>
                            <BaseForm.Input colSpan="4" type="text" label="Company ID" name="company_id" required/>
                            <BaseForm.Input colSpan="4" type="text" label="Stripe Customer IDs" name="stripe_customer_ids"
                                            required/>
                            <BaseForm.Input colSpan="4" type="text" label="Stripe Account ID" name="stripe_account_id"
                                            required/>
                        </Row>
                        <SubmitButton>Trigger Stripe Customer Import</SubmitButton>
                    </BaseForm>
                </ContentBox.Body>
                <ContentBox.Body>
                    <BaseForm onSubmit={triggerCustomersInvoicesAndPaymentsImport}>
                        <h3>Trigger Stripe Customers Invoices and Payments Import</h3>
                        <Row>
                            <BaseForm.Input colSpan="4" type="text" label="Company ID" name="company_id" required/>
                            <BaseForm.Input colSpan="4" type="text" label="Stripe Account ID" name="stripe_account_id"
                                            required/>
                        </Row>
                        <SubmitButton>Trigger Stripe Payments Import</SubmitButton>
                    </BaseForm>
                </ContentBox.Body>
                <ContentBox.Body>
                    <BaseForm onSubmit={triggerCustomersPaymentsImport}>
                        <h3>Trigger Stripe Customers Payments Import</h3>
                        <Row>
                            <BaseForm.Input colSpan="4" type="text" label="Company ID" name="company_id" required/>
                            <BaseForm.Input colSpan="4" type="text" label="Stripe Customer ID" name="stripe_customer_id"
                                            required/>
                            <BaseForm.Input colSpan="4" type="text" label="Customer ID" name="customer_id" required/>
                        </Row>
                        <SubmitButton>Trigger Stripe Payments Import</SubmitButton>
                    </BaseForm>
                </ContentBox.Body>
                <ContentBox.Body>
                    <BaseForm onSubmit={triggerSubscriptionsImport}>
                        <h3>Trigger Stripe Subscriptions Import</h3>
                        <Row>
                            <BaseForm.Input colSpan="4" type="text" label="Company ID" name="company_id" required/>
                            <BaseForm.Input colSpan="4" type="text" label="Stripe Account ID" name="stripe_account_id"
                                            required/>
                        </Row>
                        <SubmitButton>Trigger Stripe Subscriptions Import</SubmitButton>
                    </BaseForm>
                </ContentBox.Body>
                <ContentBox.Body>
                    <BaseForm onSubmit={triggerSubscriptionsUsageMetricsImport}>
                        <h3>Trigger Stripe Subscriptions Usage Metrics Import</h3>
                        <Row>
                            <BaseForm.Input colSpan="4" type="text" label="Company ID" name="company_id" required/>
                        </Row>
                        <SubmitButton>Trigger Stripe Subscriptions Usage Metrics Import</SubmitButton>
                    </BaseForm>
                </ContentBox.Body>
                <ContentBox.Body>
                    <BaseForm onSubmit={triggerCustomerInvoicesImport}>
                        <h3>Trigger Stripe Customers Invoices Import</h3>
                        <Row>
                            <BaseForm.Input colSpan="4" type="text" label="Company ID" name="company_id" required/>
                            <BaseForm.Input colSpan="4" type="text" label="Stripe Account ID" name="stripe_account_id"
                                            required/>
                            <BaseForm.Input colSpan="4" type="text" label="Stripe Invoice ID" name="stripe_invoice_id"/>
                            <BaseForm.Input colSpan="4" type="text" label="Stripe Customer ID"
                                            name="stripe_customer_id"/>
                            <BaseForm.Input colSpan="4" name="handle_subscription" type="switch"
                                            label="Handle Subscriptions" value="true"
                                            formInputClassName="transparent"/>
                        </Row>
                        <SubmitButton>Trigger Stripe Invoice Import</SubmitButton>
                    </BaseForm>
                </ContentBox.Body>
                <ContentBox.Body>
                    <BaseForm onSubmit={triggerProductImport}>
                        <h3>Trigger Stripe Product Import</h3>
                        <Row>
                            <BaseForm.Input colSpan="4" type="text" label="Company ID" name="company_id" required/>
                            <BaseForm.Input colSpan="4" type="text" label="Stripe Account ID" name="stripe_account_id" required/>
                            <BaseForm.Input colSpan="4" type="text" label="Stripe Product ID" name="stripe_product_id"/>
                        </Row>
                        <SubmitButton>Trigger Stripe Product Import</SubmitButton>
                    </BaseForm>
                </ContentBox.Body>
            </ContentBox>
            <ContentBox>
                <ContentBox.Body>
                    <BaseForm onSubmit={triggerProductTaxCategories}>
                        <h3>Trigger Tax Categories Import</h3>
                        <SubmitButton>Trigger Tax Categories Import</SubmitButton>
                    </BaseForm>
                </ContentBox.Body>
            </ContentBox>
        </SuperContainer>
    );
}

export default SuperUserDashboard;

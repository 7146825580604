import '../../App.scss';
import '../../css/modals.scss';
import React, { useState, useEffect } from 'react';
import BaseModal from './BaseModal';
import StripeInvoicePaymentModal from './StripeInvoicePaymentModal';
import { useTranslation } from 'react-i18next';

function InvoicePaymentModal(props) {
    const { t } = useTranslation('common');
    const [paymentConfig, setPaymentConfig] = useState({});

    useEffect(() => {
        setPaymentConfig(props.paymentConfig);
    }, [props.paymentConfig])

    if (paymentConfig && paymentConfig.provider === "STRIPE") {
        return <StripeInvoicePaymentModal {...props} />
    } else {
        return (
            <BaseModal {...props}>
                <BaseModal.Header>
                    <BaseModal.Title>{t('common.edit')} Provider not supported</BaseModal.Title>
                </BaseModal.Header>
                <BaseModal.Body>
                    The provider { paymentConfig && paymentConfig.provider } is not supported. Please contact your company admin.
                </BaseModal.Body>
                <BaseModal.Footer>
                </BaseModal.Footer>
            </BaseModal>
        )
    }
}

export default InvoicePaymentModal;

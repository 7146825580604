import '../../App.scss';
import '../../css/modals.scss';
import React from 'react';
import BaseModal from '../modals/BaseModal';
import { useContext } from 'react';
import { BaseContext } from '../../helpers/common';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
const _ = require('lodash');

function SimpleModal(props) {
    const { t } = useTranslation('common');
    const { company, getApiUrl } = useContext(BaseContext);

    const onConfirm = () => {
        if (props.onConfirm) {
            props.onConfirm();
        }
        props.onClose();
    }

    return (
        <BaseModal {...props}>
            <BaseModal.Header>
                <BaseModal.Title>{props.title ? props.title : "Confirm?"}</BaseModal.Title>
            </BaseModal.Header>
            <BaseModal.Body>
                <div className="body1">
                    {props.body ? props.body : "Are you sure you want to proceed?"}
                </div>
            </BaseModal.Body>
            <BaseModal.Footer>
                <Button variant={props.buttonVariant || "primary"} className={props.buttonClassName} onClick={onConfirm}>{ props.buttonTitle || "Confirm" }</Button>
            </BaseModal.Footer>
        </BaseModal>
    );
}

export default SimpleModal;

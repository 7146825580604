import '../../App.scss';
import React, { useEffect, useState, useContext, createRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { serverFetch, serverPost, serverPatch } from '../../helpers/server';
import {
    BaseContext,
    renderContractStatusLabel,
    downloadBlob,
    getLabelForSubscriptionLength,
    convertVariablesIntoHtml,
    UserContext
} from '../../helpers/common';
import SingleSelectDropdown from '../../components/SingleSelectDropdown';
import BaseContainer from '../../components/BaseContainer';
import ContentContainer from '../../components/ContentContainer';
import SubmitButton from '../../components/SubmitButton';
import CustomerInput from '../../components/CustomerInput';
import Section from '../../components/Section';
import SectionNav from '../../components/SectionNav';
import BaseForm from '../../components/BaseForm';
import Loader from '../../components/Loader';
import Label from '../../components/Label';
import ProductPricingSelection from '../../components/ProductPricingSelection';
import BundlePricingTable from '../../components/BundlePricingTable';
import ConfirmationButton from '../../components/ConfirmationButton';
import KeyValueDisplay from '../../components/KeyValueDisplay2';
import SingleContractEmailModal from '../../components/modals/SingleContractEmailModal';
import ShareContractLinkModal from '../../components/modals/ShareContractLinkModal';
import ContentBox from '../../components/ContentBox';
import { Button, Row, Col } from 'react-bootstrap';
import { useParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Notification from "../../components/Notification";
const _ = require('lodash');

function Contract() {
    const navigate = useNavigate();
    const { t } = useTranslation('common');
    const { uuid } = useParams();
    const [value, setValue] = useState("");

    const { isSuperUser } = useContext(UserContext);
    const { company, getApiUrl, setPageTitle, getCompanySpecificUrl } = useContext(BaseContext);
    const [loadingContract, setLoadingContract] = useState(true);
    const [contractDetails, setContractDetails] = useState({});
    const [activeNav, setActiveNav] = useState("details");
    const [teamMembers, setTeamMembers] = useState([]);
    const [settings, setSettings] = useState({});
    const [productPricings, setProductPricings] = useState([]);
    const [preselectedProductPricingIds, setPreselectedProductPricingIds] = useState([]);
    const [initialFields, setInitialFields] = useState({});
    const [templateFields, setTemplateFields] = useState({});
    const [showEmailModal, setShowEmailModal] = useState(false);
    const [showLinkModal, setShowLinkModal] = useState(false);
    const [startDateType, setStartDateType] = useState("SIGN_DATE");
    const [details, setDetails] = useState([]);
    const [error, setError] = useState(null);
    const [sort, setSort] = useState("createdAtDesc");
    const [isEditingTemplate, setIsEditingTemplate] = useState(false);
    const pricingsRef = createRef();

    useEffect(() => {
        setPageTitle(`Contract - ${contractDetails.title}`);
    }, [contractDetails]);

    useEffect(() => {
        if (!_.isNil(uuid)) {
            fetchData();
        }
    }, [uuid])

    useEffect(() => {
        serverFetch(getApiUrl("/settings")).then((res) => {
            setSettings(res);
        });

        serverFetch(getApiUrl(`/proposals/templates/fields`)).then((res) => {
            if (res) {
                setTemplateFields(_.keyBy(res, 'key'))
            }
        })

        serverFetch(getApiUrl("/users")).then((res) => {
            setTeamMembers(_.filter(res, (r) => !r.pending));
        });

        const limit = 100
        const params = {
            company_id: company.id,
            sort_key: sort,
            pagination: {
                limit: limit
            },
        }
        serverPost(getApiUrl("/product_pricings/find"), params).then((res) => {
            if (res) {
                const results = res.results || [];
                setProductPricings(results);
            }
        });
    }, []);

    const fetchData = (skipCache=false) => {
        serverFetch(getApiUrl(`/proposals/${uuid}`), { skipCache: skipCache }).then((res) => {
            setContractDetails(res);
            const dateType = res.start_date ? "SPECIFIC_DATE": "SIGN_DATE";
            setInitialFields({
                owner: res.owner && res.owner.id,
                expiration_date: res.expiration_date,
                start_date: res.start_date,
                customer: res.customer,
                term: res.term,
                title: res.title,
                signatories: res.signatories,
                start_date_type: dateType,
                auto_charges: String(res.auto_charges),
                config: _.keyBy(res.config_items, 'product_metric_pricing_id'),
                discounts: _.mapValues(_.keyBy(res.discounts, 'item_pricing_id'), (v) => { return {percentage: v.percent * 100} })
            })
            const subsIds = _.map(res.bundle_pricing.bundle_product_pricings, (pp) => pp.product_pricing_id);
            const oneIds = _.map(res.one_time_billables, (b) => b.product_pricing_id);
            setPreselectedProductPricingIds(_.concat(subsIds, oneIds));
            setLoadingContract(false);
            setStartDateType(dateType);
            const data = {
                'Customer': <Link to={getCompanySpecificUrl(`/customer/${res.customer.id}`)}>{res.customer.org_name || res.customer.name || res.customer.email}</Link>,
                'ID': res.id,
                'Created At': moment(res.created_at).format("DD MMM, YYYY h:mma"),
                'Expiration Date': res.expiration_date ? moment(res.expiration_date).format("DD MMM, YYYY h:mma") : null,
                'Owner': <div>{res.owner.user.name} <span className="text-gray-500">({res.owner.user.email})</span></div>,
                'Signatory': <div>{res.signatories[0].name} <span className="text-gray-500">({res.signatories[0].email})</span></div>,
            };
            setDetails(data);
        })
    }

    const finalizeContract = () => {
        const data = {
            status: "PENDING_SIGNATURES"
        }
        serverPatch(getApiUrl(`/proposals/${uuid}`), data).then(res => {
            fetchData(true);
        })
    }

    const onNavClick = (itemId) => {
        setActiveNav(itemId);
    }

    const updateContract = async (data) => {
        let pricingData = {};
        if (pricingsRef.current) {
            const validationResult = pricingsRef.current.validate(data);
            if (!_.isNil(validationResult)) {
                setError(validationResult)
                return;
            }

            pricingData = pricingsRef.current.getPricingSelectionFields(data);
        }
        let isToday = false;
        let startDate = null;
        if (data.start_date_type === "SPECIFIC_DATE") {
            isToday = moment(0, "HH").diff(data.start_date, "days") == 0;
            startDate = isToday ? moment(): moment(data.start_date)
        }
        const contractData = {
            ...pricingData,
            owner: data.owner || null,
            start_date: startDate,
            title: data.title,
            expiration_date: data.expiration_date || null,
            term: {
                frequency: data.term.frequency,
                count: parseInt(data.term.count)
            },
            auto_charges: data.auto_charges === "true",
            signatories: Object.keys(data.signatories).map(key => (data.signatories[key])),
        }
        const result = await serverPatch(getApiUrl(`/proposals/${uuid}`), contractData)
        if (result) {
            Notification.Success(`${contractDetails.title} updated`);
        }
        fetchData(true);
    }

    const updateContractContentTemplate = (data) => {
        serverPatch(getApiUrl(`/proposals/${uuid}`), data).then((res) => {
            fetchData(true);
            setIsEditingTemplate(false);
        })
    }

    const downloadContract = () => {
        const filename = `${contractDetails.title || "Contract"}.pdf`;
        serverPost(getApiUrl(`/proposals/${uuid}/preview`), {}, { noJson: true }).then((res) => {
            downloadBlob(res, filename);
        })
    }

    const downloadContract2 = () => {
        serverFetch(getApiUrl(`/proposals/${uuid}/contract_pdf_url`)).then((res) => {
            if (res) {
                const filename = `${contractDetails.title || "Contract"}.pdf`;
                serverFetch(res.url, { noJson: true }).then((res) => {
                    downloadBlob(res, filename);
                })
            } else {
                Notification.Danger("Unable to download contract. Please try again later.")
            }
        });
    }

    const onFieldChange = (name, value) => {
        if (name === "start_date_type") {
            setStartDateType(value);
        }
    }

    const tabItems = [{
        'label': 'Details',
        'id': 'details',
        active: activeNav === "details"
    }];
    if (_.includes(["PENDING_SIGNATURES", "DRAFT"], contractDetails.status)) {
        tabItems.push({
            'label': 'Preview',
            'id': 'content',
            active: activeNav === "content"
        })
    }

    const teamMemberOptions = _.map(teamMembers, (member) => {
        return {
            value: member.id,
            label: member.user.name || member.user.email
        }
    })
    teamMemberOptions.unshift({ value: null, label: "No one" })

    const canEdit = _.includes(["DRAFT"], contractDetails.status);
    const canRevertToDraft = contractDetails.status === "PENDING_SIGNATURES";
    const planLengthOptions = [
        { value: "DAY", label: "Day" },
        { value: "WEEK", label: "Week" },
        { value: "MONTH", label: "Month" },
        { value: "QUARTER", label: "Quarter" },
        { value: "YEAR", label: "Year" },
    ]
    const paymentMechanismOptions = [
        { value: "true", label: "Collect payment information and charge automatically.",
            disabled: _.isNil(settings.payment_config), disabledDescription: "* Payment Provider needs to be setup" },
        { value: "false", label: "Email invoice to customer manually" },
    ]

    const startDateTypeOptions = [
        { value: "SIGN_DATE", label: 'When contract is signed' },
        { value: "SPECIFIC_DATE", label: 'On a specific date' },
    ]

    const renderEditableDetails = () => {
        return (
            <BaseForm initialFormFields={initialFields} onFieldChange={onFieldChange} onSubmit={updateContract}>
                <br/>
                <Row>
                    <CustomerInput colSpan="4" defaultCustomer={contractDetails.customer} />
                    <Col md="4" />
                    <BaseForm.Input colSpan="4" name="owner" label={"Owner"} type="select" options={teamMemberOptions}/>
                </Row>
                <hr/>
                <Row>
                    <div className="body2">Contract Details</div>
                    <div className="body1">Details about the contract and the signatory.</div>
                    <BaseForm.Input colSpan="5" name="title" label="Title" type="text" required />
                </Row>
                <Row>
                    <BaseForm.Input colSpan="5" name="expiration_date" label={"Expiration Date"} type="date" includeTime={true} minDate={moment()} />
                    <BaseForm.Input colSpan="5" name="auto_charges" label={t('subscriptions.payment_mechanism')} type="select"
                                    options={paymentMechanismOptions} showSearch={false}/>
                </Row>
                <div className="body2">Signatory</div>
                {
                    _.map(contractDetails.signatories, (signatory, i) =>
                        <Row key={i}>
                            <BaseForm.Input colSpan="5" name={`signatories.${i}.name`} label="Name" type="text" />
                            <BaseForm.Input colSpan="5" name={`signatories.${i}.email`} label="Email" type="text" />
                        </Row>
                    )
                }
                <hr/>
                <Row>
                    <div className="body2">Plan Details</div>
                    <div className="body1">Details about the plan and pricing.</div>
                    <BaseForm.Input colSpan="5" name="start_date_type" label={t('subscriptions.start_date')} type="select"
                                    options={startDateTypeOptions} showSearch={false}/>
                    {
                        startDateType === "SPECIFIC_DATE" &&
                        <BaseForm.Input colSpan="5" name="start_date" label={'Specific Date'} type="date"
                                        required minDate={moment()} />
                    }
                </Row>
                <Row>
                    <Col md="5">
                        <BaseForm.InputGroup label="Term">
                            <BaseForm.Number name="term.count" min="1" />
                            <BaseForm.Divider />
                            <BaseForm.SingleSelect name="term.frequency" options={planLengthOptions} showSearch={false}/>
                        </BaseForm.InputGroup>
                    </Col>
                </Row>
                <br/>
                <ProductPricingSelection ref={pricingsRef} productPricings={productPricings}
                                         preselectedProductPricingIds={preselectedProductPricingIds} editable={true}
                                         allowDiscounts={true} requireRecurring/>
                <br/><br/>
                {
                    error &&
                        <div className="form-error-message">{ error }</div>
                }
                <Row>
                    <Col md="3">
                        <SubmitButton>Update</SubmitButton>
                    </Col>
                </Row>
            </BaseForm>
        );
    }

    const renderFinalizedDetails = () => {
        return (
            <>
                <Section title="Details" className="mt-2" actions={[]}>
                    <div className="d-flex flex-row flex-wrap gap-3">
                        <KeyValueDisplay items={details} className="half-flex"/>
                        {
                            contractDetails.status === "PENDING_SIGNATURES" ?
                                <div className="half-flex align-self-center text-center d-flex flex-column gap-3" style={{ paddingTop: "50px", paddingBottom: "50px" }}>
                                    <h3 className="gray4">{ contractDetails.title }</h3>
                                    <p className="gray4 align-self-center" style={{ maxWidth: "300px" }}>The contract is pending signatures.</p>
                                    <div>
                                        <Label.Danger>Pending Signatures</Label.Danger>
                                    </div>
                                </div>
                            : <div className="half-flex align-self-center text-center d-flex flex-column gap-3" style={{ paddingTop: "50px", paddingBottom: "50px" }}>
                                <h3 className="gray4">{ contractDetails.title }</h3>
                                <p className="gray4 align-self-center" style={{ maxWidth: "300px" }}>&#127881; Yay! The contract has been signed by all parties on {moment(contractDetails.signed_date).format("DD MMM, YYYY h:mma")}.</p>
                                <div>
                                    <Button variant="primary" onClick={downloadContract}>Download Contract</Button>
                                    {
                                        isSuperUser &&
                                            <Button variant="primary" onClick={downloadContract2}>Download Contract2</Button>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </Section>
                <Section title="Plan Details">
                    <div className="body1">Details about the plan and pricing.</div>
                    <div className="body1"><strong>Start Date:</strong> { contractDetails.start_date ? moment(contractDetails.start_date).format("DD MMM, YYYY") : "When executed" }</div>
                    <div className="body1"><strong>Term:</strong> { contractDetails.term.count } { getLabelForSubscriptionLength(contractDetails.term.frequency) }(s)</div>
                    <br/>
                    <BundlePricingTable
                        bundlePricing={contractDetails.bundle_pricing}
                        oneTimeBillables={contractDetails.one_time_billables}
                        configItems={contractDetails.config_items}
                        discounts={contractDetails.discounts}
                    />
                </Section>
            </>
        )
    }

    const renderDeclinedDetails = () => {
        return (
            <>
                <Section title="Details" actions={[]}>
                    <div className="d-flex flex-row flex-wrap gap-3">
                        <KeyValueDisplay items={details} className="half-flex"/>
                        <div className="half-flex align-self-center text-center d-flex flex-column gap-3" style={{ paddingTop: "50px", paddingBottom: "50px" }}>

                        </div>
                    </div>
                </Section>
                <Section title="Plan Details">
                    <div className="body1">Details about the plan and pricing.</div>
                    <div className="body1"><strong>Start Date:</strong> { moment(contractDetails.start_date).format("DD MMM, YYYY") }</div>
                    <div className="body1"><strong>Term:</strong> { contractDetails.term.count } { getLabelForSubscriptionLength(contractDetails.term.frequency) }(s)</div>
                    <br/>
                    <BundlePricingTable
                        bundlePricing={contractDetails.bundle_pricing}
                        oneTimeBillables={contractDetails.one_time_billables}
                        configItems={contractDetails.config_items}
                        discounts={contractDetails.discounts}
                    />
                </Section>
            </>
        )
    }

    const onActionSelected = (type) => {
        if (type === "pdf") {
            serverPost(getApiUrl(`/proposals/${contractDetails.id}/preview`), {}, { noJson: true }).then((res) => {
                downloadBlob(res, `Contract-${contractDetails.title}`);
            })
        } else if (type === "email") {
            setShowEmailModal(true);
        } else if (type === "link") {
            setShowLinkModal(true);
        }
    }

    const actionOptions = [
        { id: "link", label: "Share via Link" },
        { id: "email", label: "Share via Email" },
        { id: "pdf", label: "Download" },
    ];

    return (
        <BaseContainer>
            <ContentContainer>
                <div className="d-flex flex-column">
                    <div className="contract-header">
                        <div className="d-flex flex-row gap-3">
                            <h3 className="gray4">{ contractDetails.title }</h3>
                            <div className="d-flex align-items-center">
                                { renderContractStatusLabel(contractDetails) }
                            </div>
                            <div className="flex-grow-1"/>
                            {
                                contractDetails.status === "DRAFT" &&
                                    <Button variant="primary" onClick={finalizeContract}>Finalize</Button>
                            }
                            {
                                (contractDetails.status === "PENDING_SIGNATURES" || contractDetails.status === "PENDING_PAYMENT") &&
                                    <SingleSelectDropdown className="inline" toggleLabel={<span>Actions</span>} items={actionOptions} onSelect={onActionSelected} align="end" menuOnly/>
                            }
                        </div>
                        {
                            contractDetails.status === "DRAFT" &&
                                <div>
                                    <span className="body1 gray3">Contract is in draft. Please review and finalize before sending it out.</span>
                                </div>
                        }
                    </div>
                </div>
                {
                    tabItems.length > 1 &&
                        <SectionNav items={tabItems} onClick={onNavClick} />
                }
                {
                    activeNav === "details" &&
                        <Loader loading={loadingContract}>
                            { () => {
                                if (contractDetails.status === "DECLINED") {
                                    return renderDeclinedDetails();
                                } else if (canEdit) {
                                    return renderEditableDetails();
                                } else {
                                    return renderFinalizedDetails();
                                }
                            }}
                        </Loader>
                }
                {
                    activeNav === "content" && isEditingTemplate &&
                        <ContentBox>
                            <ContentBox.Body className="scrollable">
                                <div className="contract-content">
                                    <div className="d-flex flex-row gap-3 align-items-center">
                                        <span className="body1 flex-grow-1">
                                            Please free to update the content as you choose. Note that, any changes to the content will not be reflected in the template it is adopted from.
                                        </span>
                                        <span className="flex-shrink-0">
                                            <Button variant="text-primary" onClick={(event) => setIsEditingTemplate(false)}>
                                                <i className="fa fa-x"/>&nbsp;Cancel
                                            </Button>
                                        </span>
                                    </div>
                                </div>
                                <div className="contract-content">
                                    <BaseForm initialFormFields={contractDetails} onSubmit={updateContractContentTemplate}>
                                        <Row>
                                            <BaseForm.Input colSpan="12" type="text" name="title" label="Title" />
                                            <BaseForm.Input colSpan="12" type="editor2" name="content_template" height="800" tokens={templateFields}/>
                                        </Row>
                                        <Row>
                                            <Col md="4">
                                                <SubmitButton>Update</SubmitButton>
                                            </Col>
                                        </Row>
                                    </BaseForm>
                                </div>
                            </ContentBox.Body>
                        </ContentBox>
                }
                {
                    activeNav === "content" && !isEditingTemplate &&
                        <ContentBox>
                            <ContentBox.Body className="scrollable">
                                <div className="contract-content">
                                    <div className="d-flex flex-row gap-3 align-items-center">
                                        <span className="body1 flex-grow-1">
                                            Here is the preview of the contents of the contract.
                                        </span>
                                        <span className="flex-shrink-0">
                                            {
                                                canRevertToDraft ?
                                                    <ConfirmationButton
                                                        variant="text-primary"
                                                        title={"Move to Draft?"}
                                                        body={"Are you sure you want to move the contract back to draft? Doing so will make the contract links unavailable until it is finalized again."}
                                                        onConfirm={(event) => {
                                                            serverPost(getApiUrl(`/proposals/${uuid}/revert`)).then(res => {
                                                                if (res) {
                                                                    fetchData(true);
                                                                    setIsEditingTemplate(true)
                                                                }
                                                            })
                                                        }}>
                                                        <i className="fa fa-edit"/>&nbsp;Move to Draft & Edit
                                                    </ConfirmationButton>
                                                : <Button variant="text-primary" onClick={(event) => setIsEditingTemplate(true)}>
                                                        <i className="fa fa-edit"/>&nbsp;Edit
                                                    </Button>
                                            }
                                        </span>
                                    </div>
                                </div>
                                <div className="content-body1">
                                    <div className="contract-content" dangerouslySetInnerHTML={{__html: convertVariablesIntoHtml(contractDetails.content) }} />
                                </div>
                            </ContentBox.Body>
                        </ContentBox>
                }
            </ContentContainer>
            <SingleContractEmailModal show={showEmailModal} onClose={setShowEmailModal} contract={contractDetails} />
            <ShareContractLinkModal show={showLinkModal} onClose={setShowLinkModal} contract={contractDetails} />
        </BaseContainer>
    );
}

export default Contract;

import '../../../App.scss';
import React, { useEffect, useState, useContext } from 'react';
import { serverPost, serverDelete } from '../../../helpers/server';
import {BaseContext, UserContext} from '../../../helpers/common';
import AddOrEditWebhookModal from '../../../components/modals/AddOrEditWebhookModal';
import BaseContainer from '../../../components/BaseContainer';
import DeleteButton from '../../../components/DeleteButton';
import ContentContainer from '../../../components/ContentContainer';
import Section from '../../../components/Section';
import Label from '../../../components/Label';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import MapleTable from "../../../components/MapleTable";
import CopyableComponent from "../../../components/CopyableComponent";
import ContentBox from "../../../components/ContentBox";
import {CommandLineIcon} from "@heroicons/react/20/solid";
import EmptyState from "../../../components/EmptyState";
const _ = require('lodash');

function Webhooks() {
    const { t } = useTranslation('common');
    const { userInfo } = useContext(UserContext);
    const { company, getApiUrl, setPageTitle, hasAccess } = useContext(BaseContext);
    const [showAddOrEditWebhookModal, setShowAddOrEditWebhookModal] = useState(false);
    const [webhookToEdit, setWebhookToEdit] = useState(null);
    const [webhooks, setWebhooks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [addedWebhook, setAddedWebhook] = useState(null);

    const hasWritePermissions = hasAccess("developers", userInfo, "write");

    useEffect(() => {
        setPageTitle(`Webhooks`);
    }, [setPageTitle]);

    const fetchData = (skipCache=false) => {
        serverPost(getApiUrl("/webhooks/find"), {}, { skipCache: skipCache }).then((res) => {
            setWebhooks(res.results || []);
            setLoading(false);
        });
    }

    useEffect(() => {
        fetchData();
    }, []);

    const onModalClose = () => {
        setShowAddOrEditWebhookModal(false);
        fetchData(true);
    }

    const onEditWebhook = (webhook) => {
        setShowAddOrEditWebhookModal(true);
        setWebhookToEdit(webhook);
    }

    const onCreateWebhook = () => {
        setShowAddOrEditWebhookModal(true);
        setWebhookToEdit(null);
    }

    const onDeleteWebhook = (webhook) => {
        serverDelete(getApiUrl(`/webhooks/${webhook.id}`)).then((res) => {
            console.log("Deleted webhooks");
            fetchData(true);
        });
    }

    const onWebhookAdded = (webhook) => {
        onModalClose(true);
        setAddedWebhook(webhook);
    }

    const isEmpty = webhooks.length === 0;
    return (
        <BaseContainer>
            <ContentContainer>
                <Section title={t('webhooks.title')} loading={loading}
                         subtitle={<CopyableComponent
                             className="thin"
                             value={`Company ID: ${company.id}`}
                             copyableValue={company.id}>
                         </CopyableComponent>}
                         actions={hasWritePermissions && !isEmpty && [{
                        variant: "btn btn-primary",
                        icon: "fa-plus",
                        label: t('webhooks.create'),
                        onClick: () => onCreateWebhook()
                    }]}>
                    {
                        addedWebhook &&
                            <ContentBox>
                                <ContentBox.Body>
                                    <div className="body2">Newly added webhook - Keep your secret safe</div>
                                    <div className="body1">Save and store this secret securely, such as a password
                                        manager or secret store. You won’t be able to see it again.</div>
                                    <CopyableComponent value={addedWebhook.secret} />
                                </ContentBox.Body>
                            </ContentBox>
                    }
                    {
                        isEmpty ?
                            <EmptyState
                                icon={CommandLineIcon}
                                title={"No webhooks."}
                                subtitle={"Get started by creating a new one."}
                                hasWritePermissions={hasWritePermissions}
                                buttonLabel={"New Webhook"}
                                onClick={onCreateWebhook}
                            />
                        : <MapleTable>
                            <MapleTable.Content>
                                <thead>
                                <tr>
                                    <MapleTable.TH>Endpoint</MapleTable.TH>
                                    <MapleTable.TH>Events</MapleTable.TH>
                                    <MapleTable.TH className="whitespace-nowrap">Status</MapleTable.TH>
                                    <MapleTable.TH></MapleTable.TH>
                                </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200">
                                {
                                    _.map(webhooks, (row, i) =>
                                        <tr key={i}>
                                            <td>{ row.endpoint }</td>
                                            <td><span className="text-gray-500">{ row.events.length } events</span></td>
                                            <td><Label.Success>Active</Label.Success></td>
                                            <td className="text-end">
                                                <Button variant="text-primary" size="sm" onClick={() => onEditWebhook(row)} >
                                                    <i className="fa fa-edit"/>
                                                </Button>
                                                <DeleteButton size="sm" onDelete={() => onDeleteWebhook(row)} />
                                            </td>
                                        </tr>
                                    )
                                }
                                </tbody>
                            </MapleTable.Content>
                        </MapleTable>
                    }
                </Section>
            </ContentContainer>
            <AddOrEditWebhookModal show={showAddOrEditWebhookModal} onClose={onModalClose} onWebhookAdded={onWebhookAdded} itemToEdit={webhookToEdit} />
        </BaseContainer>
    );
}

export default Webhooks;

import '../App.scss';
import '../js/forms.js';
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
const _ = require("lodash");

function BaseLeftNav(props) {
    const { t } = useTranslation('common');
    const [shouldAutoMatch, setShouldAutoMatch] = useState(true);
    const [items, setItems] = useState([]);

    useEffect(() => {
        if (!_.isNil(props.shouldAutoMatch)) {
            setShouldAutoMatch(props.shouldAutoMatch);
        }
    }, [props.shouldAutoMatch]);

    useEffect(() => {
        setItems(props.items);
    }, [props.items])

    const isItemActive = (item) => {
        if (item.active) {
            return item.active;
        } else if (shouldAutoMatch) {
            if (item.matchPathnameOnly) {
                return item.link === window.location.pathname;
            } else {
                return item.link === (window.location.pathname + window.location.search);
            }
        } else {
            return false;
        }
    }

    const hasActiveSubItem = (item) => {
        return _.some(item.items, (si) => isItemActive(si));
    }

    return (
        <div id="left-nav" className={classnames("left-nav", "noprint", props.className)}>
            <div className="nav-header">
                <div className="nav-header-logo"/>
            </div>
            <div className="nav-items">
                {
                    _.map(items, (item, i) => {
                        if (item) {
                            return (
                                <MenuItem key={i} label={item.label} icon={item.icon} link={item.hasSubMenu ? null: item.link}
                                    active={!item.hasSubMenu && isItemActive(item)} showSubMenu={hasActiveSubItem(item)}
                                    hasSubMenu={item.hasSubMenu}>
                                {
                                    item.hasSubMenu &&
                                        <div className={hasActiveSubItem(item) ? "show" : ""}>
                                        {
                                            _.map(item.items, (subItem, j) => {
                                                if (subItem) {
                                                    return <MenuItem key={j} label={subItem.label} link={subItem.link} active={isItemActive(subItem)}/>
                                                }
                                            })
                                        }
                                        </div>
                                }
                                </MenuItem>
                            )
                        }
                    })
                }
            </div>
        </div>
    );
}

function MenuItem(props) {
    const [isOpen, setIsOpen] = useState(props.showSubMenu);
    const navigate = useNavigate();

    useEffect(() => {
        setIsOpen(props.showSubMenu || false);
    }, [props.showSubMenu])

    const handleClick = (event) => {
        event.preventDefault();
        if (props.onClick) {
            props.onClick();
        } else if (props.link) {
            navigate(props.link);
        } else if (props.hasSubMenu) {
            setIsOpen(!isOpen);
        }
    }

    const activeClass = props.active ? "active": "";
    const openClass = isOpen ? "open": "";
    return (
        <div className={classnames("nav-item-group", openClass)}>
            <div className={classnames("nav-item", activeClass)} onClick={(event) => handleClick(event)}>
                <div className={classnames("nav-item-logo", props.icon)}/>
                <div className="nav-title">
                    <span className="text-base">{props.label}</span>
                </div>
                {
                    !_.isEmpty(props.children) &&
                        <div className={classnames("nav-item-action", openClass)}/>
                }
            </div>
            {
                isOpen &&
                    <>{props.children}</>
            }
        </div>
    )
}

export default BaseLeftNav;

import '../App.scss';
import '../css/invoice.scss';
import React, { useEffect, useState, useContext, useCallback } from 'react';
import { useSearchParams, Link, useNavigate } from 'react-router-dom';
import { serverPost, serverPatch } from '../helpers/server';
import {
    currencyFormatFromPrice,
    BaseContext,
    downloadBlob,
    UserContext
} from '../helpers/common';
import { useTranslation } from 'react-i18next';
import Loader from './Loader';
import Notification from './Notification';
import InvoicePaymentModal from './modals/InvoicePaymentModal';
import SingleSelectDropdown from './SingleSelectDropdown';
import ContentContainer from './ContentContainer';
import CopyableComponent from './CopyableComponent';
import InvoiceDetailsComponent from './InvoiceDetailsComponent';
import SingleInvoiceEmailModal from './modals/SingleInvoiceEmailModal';
import MarkInvoiceAsPaidModal from './modals/MarkInvoiceAsPaidModal';
import SimpleModal from './modals/SimpleModal';
import Label from './Label';
import {Button, Alert } from 'react-bootstrap';
import moment from 'moment';
import classnames from 'classnames';
import EditInvoiceModal from "./modals/EditInvoiceModal";
const _ = require('lodash');

function InvoiceComponent(props) {
    const [ searchParams ] = useSearchParams();
    const navigate = useNavigate();
    let redirectStatus = searchParams.get('redirect_status');
    const { company, getApiUrl, getCompanySpecificUrl } = useContext(BaseContext);
    const { isSuperUser } = useContext(UserContext);

    const { t } = useTranslation('common');
    const [invoiceDetails, setInvoiceDetails] = useState({});
    const [settings, setSettings] = useState({});
    const [showInvoiceEmailModal, setShowInvoiceEmailModal] = useState(false);
    const [showInvoicePaidModal, setShowInvoicePaidModal] = useState(false);
    const [showInvoicePaymentModal, setShowInvoicePaymentModal] = useState(false);
    const [showShareLinkModal, setShowShareLinkModal] = useState(false);
    const [showVoidModal, setShowVoidModal] = useState(false);
    const [showEditInvoiceModal, setShowEditInvoiceModal] = useState(false);
    const [campaign, setCampaign] = useState(null);
    const [hideFooter, setHideFooter] = useState(false);

    const [showPaymentOptions, setShowPaymentOptions] = useState(false);
    const [isPaid, setIsPaid] = useState(false);
    const [isOverdue, setIsOverdue] = useState(false);
    const [isImportedInvoice, setIsImportedInvoice] = useState(false);

    useEffect(() => {
        setInvoiceDetails(props.invoice);
        setIsImportedInvoice(!_.isNil(props.invoice) && !_.isNil(props.invoice.imported_from));
    }, [props.invoice, props.readOnly])

    useEffect(() => {
        setSettings(props.settings);
    }, [props.settings])

    useEffect(() => {
        if (!_.isNil(props.hideFooter)) {
            setHideFooter(props.hideFooter);
        } else {
            setHideFooter(false)
        }
    }, [props.hideFooter])

    useEffect(() => {
        if (_.isNil(redirectStatus)) {
            return;
        }
        setTimeout(() => {
            let urlParser = new URL(window.location.href);
            urlParser.searchParams.delete('payment_intent');
            urlParser.searchParams.delete('payment_intent_client_secret');
            urlParser.searchParams.delete('redirect_status');
            navigate(urlParser.pathname + urlParser.search, { replace: true });
        }, 1000);
    }, [redirectStatus])

    useEffect(() => {
        if (invoiceDetails.status === "VOID") {
            setIsPaid(false);
            setIsOverdue(false);
            setShowPaymentOptions(false);
            return;
        }

        const _isPaymentProviderSetup = !_.isNil(settings.payment_config);
        const _isNotReadOnly = !props.readOnly;
        const _hasDues = !_.isEmpty(invoiceDetails.due) && invoiceDetails.due.value_in_cents > 0;
        const _isPaid = !_.isEmpty(invoiceDetails.due) && invoiceDetails.due.value_in_cents === 0;
        const _isDueDateInPast = invoiceDetails.due_date && moment(invoiceDetails.due_date).isBefore(moment());
        setShowPaymentOptions(_isPaymentProviderSetup && _isNotReadOnly && _hasDues && !isImportedInvoice);

        setIsPaid(_isPaid);
        setIsOverdue(_hasDues && _isDueDateInPast);
    }, [settings, invoiceDetails, props.readOnly, isImportedInvoice])

    useEffect(() => {
        if (props.isAdmin && !_.isEmpty(invoiceDetails)) {
            const params = {
                company_id: company.id,
                sort_key: "timeDesc",
                pagination: {
                    from_key: null,
                    limit: 100
                },
                query: {
                    type: "invoice_reminder",
                    invoice_uuid: invoiceDetails.uuid
                }
            }
            serverPost(getApiUrl("/campaigns/find"), params).then((res) => {
                if (res) {
                    setCampaign(res.results[0]);
                }
            });
        }
    }, [invoiceDetails])

    const onModalClose = (didUpdate) => {
        setShowInvoicePaymentModal(false);
        setShowShareLinkModal(false);
        setShowInvoicePaidModal(false);
        setShowVoidModal(false);
        setShowEditInvoiceModal(false);
        if (didUpdate) {
            if (props.onUpdate) {
                props.onUpdate();
            }
        }
    }

    const onPay = () => {
        setShowInvoicePaymentModal(true);
    }

    const onActionSelected = (type) => {
        if (type === "edit") {
            setShowEditInvoiceModal(true);
        } else if (type === "email") {
            setShowInvoiceEmailModal(true);
        } else if (type === "link") {
            setShowShareLinkModal(true);
        } else if (type === "pdf") {
            serverPost(getApiUrl(`/invoices/${invoiceDetails.uuid}/pdf`), {}, { noJson: true }).then((res) => {
                downloadBlob(res, `Invoice-${invoiceDetails.number}`);
            })
        } else if (type === "mark_paid") {
            setShowInvoicePaidModal(true);
        } else if (type === "mark_void") {
            setShowVoidModal(true);
        } else if (type === "trigger_campaign") {
            const data = {
                type: "invoice_reminder",
                invoice_uuid: invoiceDetails.uuid,
                customer_id: invoiceDetails.customer_id
            }
            serverPost(getApiUrl("/campaigns/trigger"), data).then(() => {

            })
        }
    }

    const canRetryPayment = props.isAdmin && invoiceDetails.auto_charges && invoiceDetails.status === "PENDING";

    const canEdit = props.isAdmin && _.includes(["PENDING", "DRAFT"], invoiceDetails.status);
    const canAddPayment = props.isAdmin && !isPaid;
    const canVoidInvoice = props.isAdmin && !isPaid;
    const canTriggerCampaign = _.includes(["PENDING"], invoiceDetails.status) && _.isNil(campaign) && isSuperUser;
    const hasActions = canAddPayment || canVoidInvoice;

    const actionOptions = hasActions ? [
        canEdit && { id: "edit", label: "Edit" },
        canAddPayment && { id: "mark_paid", label: "Mark As Paid" },
        { divider: true },
        canVoidInvoice && { id: "mark_void", label: "Mark As Void" },
        canTriggerCampaign && { id: "trigger_campaign", label: "Start Reminder Schedule" },
    ]: [];

    const shareOptions = [
        { id: "link", label: "Share Via Link" },
        { id: "email", label: "Share Via Email" },
        { id: "pdf", label: "Download PDF" },
    ];

    const onRetryPayment = () => {
        serverPost(getApiUrl(`/invoices/${invoiceDetails.uuid}/charge`), {}).then((res) => {
            if (res && res.success) {
                Notification.Success("Payment attempt initiated.");
                if (props.onUpdate) {
                    props.onUpdate();
                }
            }
        })
    }

    const voidInvoice = () => {
        const data = {
            'status': "VOID"
        }
        serverPatch(getApiUrl(`/invoices/${invoiceDetails.uuid}`), data).then((res) => {
            if (res && res.success) {
                Notification.Success("Successfully voided invoice.");
                if (props.onUpdate) {
                    props.onUpdate();
                }
            }
        });
    }

    const getShareLinkUrl = () => {
        const relativeUrl = getCompanySpecificUrl(`/invoice/view?uuid=${invoiceDetails.uuid}`);
        const baseUrl = process.env.REACT_APP_BASE_WEB_URL || "https://app.maplebilling.com";
        return `${baseUrl}${relativeUrl}`
    }

    const renderStatus = () => {
        if (props.readOnly) {
            return;
        }

        if (isPaid) {
            return <Label.Success>Paid&nbsp;<i className="fa fa-check"/></Label.Success>
        } else if (isOverdue) {
            return <Label.Danger>Overdue</Label.Danger>
        } else if (invoiceDetails.status === "VOID") {
            return <Label.Info>Void</Label.Info>
        } else if (invoiceDetails.status === "PENDING") {
            return <Label.Info>Pending</Label.Info>
        }
    }

    return (
        <>
            <Loader loading={_.isEmpty(invoiceDetails)}>
            {
                () =>
                    <ContentContainer>
                        <div className="d-flex justify-content-center">
                            <div className={classnames("invoice-wrapper", props.wrapperClassName)}>
                                {
                                    redirectStatus &&
                                        <div className="invoice-wrapper-header">
                                        {
                                            redirectStatus === "succeeded" &&
                                                <div className="flex-grow-1 text-center">
                                                    <Alert variant="success">Payment has been successfully processed.</Alert>
                                                </div>
                                        }
                                        {
                                            redirectStatus === "failed" &&
                                                <div className="flex-grow-1 text-center">
                                                    <Alert variant="danger">Payment processing has failed. Please try again later.</Alert>
                                                </div>
                                        }
                                        </div>
                                }
                                <div className="invoice-wrapper-header">
                                    <div className="flex-grow-0 d-flex flex-row gap-3 align-items-center" >
                                    {
                                        showPaymentOptions &&
                                            <Button variant="primary" onClick={onPay}>Pay Now</Button>
                                    }
                                    {
                                        canRetryPayment &&
                                            <Button variant="primary" onClick={() => onRetryPayment()}>Retry Payment</Button>
                                    }
                                    { renderStatus() }
                                    {
                                        !props.readOnly && isPaid && invoiceDetails.total.value_in_cents > 0 &&
                                            <h4>{ currencyFormatFromPrice(invoiceDetails.total) }</h4>
                                    }
                                    {
                                        showPaymentOptions &&
                                            <h4>{ currencyFormatFromPrice(invoiceDetails.due) }</h4>
                                    }
                                    </div>
                                    <div className="flex-grow-1"/>
                                    <div className="flex-grow-0 d-flex gap-2">
                                    {
                                        !props.readOnly && !isImportedInvoice && !_.isEmpty(actionOptions) &&
                                            <SingleSelectDropdown className="inline" toggleLabel={<span>Actions</span>} items={actionOptions} onSelect={onActionSelected} align="end" menuOnly/>
                                    }
                                    {
                                        !props.readOnly && !isImportedInvoice &&
                                            <SingleSelectDropdown className="inline" toggleLabel={<span><i className="fa fa-small fa-share"/> Share</span>} items={shareOptions} onSelect={onActionSelected} align="end" menuOnly/>
                                    }
                                    </div>
                                </div>
                                <InvoiceDetailsComponent
                                    {...props}
                                    showTaxAsEstimate={props.showTaxAsEstimate}
                                    onChangeIncludeTaxes={props.onChangeIncludeTaxes} />
                                {
                                    !hideFooter &&
                                    <div className="invoice-wrapper-footer">
                                        <div className="flex-grow-1"/>
                                        <div className="flex-grow-0 d-flex flex-row gap-3 align-items-center" >
                                            <span>Powered by Maple</span>
                                            <a href="https://www.maplebilling.com/terms" target="_blank">Terms</a>
                                            <a href="https://www.maplebilling.com/privacy" target="_blank">Privacy</a>
                                        </div>
                                        <div className="flex-grow-1"/>
                                    </div>
                                }
                            </div>
                        </div>
                    </ContentContainer>
            }
            </Loader>
            <SingleInvoiceEmailModal show={showInvoiceEmailModal} onClose={setShowInvoiceEmailModal} invoice={invoiceDetails} />
            {
                props.isAdmin && !isPaid && showPaymentOptions &&
                    <MarkInvoiceAsPaidModal show={showInvoicePaidModal} onClose={onModalClose} invoice={invoiceDetails} />
            }
            <InvoicePaymentModal show={showInvoicePaymentModal} onClose={onModalClose} invoice={invoiceDetails} paymentConfig={settings.payment_config} />
            <SimpleModal show={showShareLinkModal} onClose={onModalClose} title="Share Invoice" buttonTitle="Done"
                body={
                    <>
                    <p className="body1">Please feel free to share this link with any relevant party.</p>
                    <CopyableComponent
                        className="mt-2"
                        value={<a className="">{getShareLinkUrl()}</a>}
                        copyableValue={getShareLinkUrl()}
                    />
                    </>
                } />
            <SimpleModal
                show={showVoidModal}
                onClose={onModalClose}
                title="Confirm"
                buttonTitle="Confirm"
                onConfirm={voidInvoice}
                body={
                    <>
                        <p>Are you sure you want to void the invoice?</p>
                        <span className="caption italic">Note: This action can not be reversed once completed.</span>
                    </>
                }
            />
            <EditInvoiceModal
                show={showEditInvoiceModal}
                onClose={onModalClose}
                invoice={invoiceDetails}
            />
        </>
    );
}

export default InvoiceComponent;

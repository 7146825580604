import '../../App.scss';
import React, { useEffect, useState, useContext, useCallback } from 'react';
import { serverPost } from '../../helpers/server';
import { BaseContext, getDescriptionForMetric } from '../../helpers/common';
import { useTranslation } from 'react-i18next';
import BaseContainer from '../../components/BaseContainer';
import AddOrEditBillableMetricModal from '../../components/modals/AddOrEditBillableMetricModal';
import AddOrEditBillableItemModal from '../../components/modals/AddOrEditBillableItemModal';
import ContentContainer from '../../components/ContentContainer';
import Section from '../../components/Section';
import MapleTable from '../../components/MapleTable';
import { Button } from 'react-bootstrap';
import {FolderPlusIcon} from "@heroicons/react/24/outline";
import {PlusIcon} from "@heroicons/react/20/solid";
import EmptyState from "../../components/EmptyState";
const _ = require('lodash');

function BillableMetrics() {
    const { t } = useTranslation('common');
    const { company, getApiUrl, setPageTitle } = useContext(BaseContext);
    const [billableItems, setBillableItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [billableMetrics, setBillableMetrics] = useState([]);
    const [billableMetricToEdit, setBillableMetricToEdit] = useState(null);
    const [showAddOrEditBillableItemModal, setShowAddOrEditBillableItemModal] = useState(false);
    const [showAddOrEditBillableMetricModal, setShowAddOrEditBillableMetricModal] = useState(false);

    useEffect(() => {
        setPageTitle(`Billable Metrics`);
    }, []);

    const fetchData = (skipCache=false) => {
        setLoading(true);
        serverPost(getApiUrl("/billable/items/find"), { company_id: company.id }, { skipCache }).then((res) => {
            setBillableItems(_.filter(res, (r) => !r.standard));
        });

        serverPost(getApiUrl("/billable/metrics/find"), { company_id: company.id }, { skipCache }).then((res) => {
            setLoading(false);
            setBillableMetrics(_.filter(res, (r) => !r.standard));
        });
    }

    useEffect(() => {
        fetchData();
    }, []);

    const onModalClose = (didUpdate) => {
        setShowAddOrEditBillableMetricModal(false);
        setShowAddOrEditBillableItemModal(false);
        if (didUpdate) {
            fetchData(true);
        }
    }

    const onAddBillableMetric = () => {
        setShowAddOrEditBillableMetricModal(true);
        setBillableMetricToEdit(null);
    }

    const onEditBillableMetric = (metric) => {
        setShowAddOrEditBillableMetricModal(true);
        setBillableMetricToEdit(metric);
    }

    const onCreateBillableItem = (event) => {
        if (event) {
            event.preventDefault();
        }
        onModalClose(false);
        setShowAddOrEditBillableItemModal(true);
    }

    const renderEmptyBillableItemsState = () => {
        return (
            <div className="text-center m-auto p-5 rounded-md shadow-sm ring-1 ring-black ring-opacity-5 bg-white">
                <FolderPlusIcon className="mx-auto h-12 w-12 text-gray-400"/>
                <h3 className="mt-2 text-sm font-semibold text-gray-900">No billable items.</h3>
                <p className="mt-1 text-sm text-gray-500">Get started by creating a new one.</p>
                <div className="mt-6">
                    <button
                        type="button"
                        className="inline-flex items-center rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        onClick={onCreateBillableItem}
                    >
                        <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
                        New Item
                    </button>
                </div>
            </div>
        )
    }

    return (
        <BaseContainer>
            <ContentContainer>
                <Section title="Billable Metrics" loading={loading} actions={!_.isEmpty(billableMetrics) && !loading && [
                    {variant: "primary", icon: "fa-plus", label: "Create Metric", onClick: onAddBillableMetric, disabled: _.isEmpty(billableItems)}
                ]}>
                {
                    !_.isEmpty(billableMetrics) &&
                        <MapleTable>
                            <MapleTable.Content>
                                <thead>
                                    <tr>
                                        <MapleTable.TH>{ t('common.name') }</MapleTable.TH>
                                        <MapleTable.TH>{ t('common.description') }</MapleTable.TH>
                                        <MapleTable.TH></MapleTable.TH>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200">
                                {
                                    _.map(billableMetrics, (metric, i) =>
                                        <tr key={i}>
                                            <td>
                                                { metric.name }
                                                <div className="caption">{ metric.description }</div>
                                            </td>
                                            <td>
                                                { getDescriptionForMetric(metric, _.find(billableItems, i => i.id === metric.item_id)) }
                                            </td>
                                            <td className="text-end">
                                                <Button variant="text-primary" size="sm" onClick={() => onEditBillableMetric(metric)}>Edit</Button>
                                            </td>
                                        </tr>
                                    )
                                }
                                </tbody>
                            </MapleTable.Content>
                        </MapleTable>
                }
                { _.isEmpty(billableMetrics) && !_.isEmpty(billableItems) &&
                    <EmptyState
                        title={"No metrics."}
                        subtitle={"Get started by creating a new one."}
                        buttonLabel={"New Metric"}
                        onClick={onAddBillableMetric}
                    />
                }
                { _.isEmpty(billableMetrics) && _.isEmpty(billableItems) && renderEmptyBillableItemsState() }
                </Section>
            </ContentContainer>
            <AddOrEditBillableMetricModal
                show={showAddOrEditBillableMetricModal}
                onClose={onModalClose}
                itemToEdit={billableMetricToEdit}
                billableItems={billableItems}
                onCreateBillableItem={onCreateBillableItem}
            />
            <AddOrEditBillableItemModal
                show={showAddOrEditBillableItemModal}
                onClose={onModalClose}
            />
        </BaseContainer>
    );
}

export default BillableMetrics;

import '../../App.scss';
import '../../css/modals.scss';
import '../../css/forms.scss';
import React, {useContext, useEffect, useMemo, useState} from 'react';
import BaseForm from '../BaseForm';
import Label from '../Label';
import BaseAddOrEditItemModal from './BaseAddOrEditItemModal';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import {serverPost} from "../../helpers/server";
import Notification from "../Notification";
import {BaseContext} from "../../helpers/common";
const _ = require('lodash');

function AddOrEditWebhookModal(props) {
    const { t } = useTranslation('common');
    const { getApiUrl } = useContext(BaseContext);
    const [isShowing, setIsShowing] = useState([false, false]);
    const [selectedEvents, setSelectedEvents] = useState([]);
    const [errorFields, setErrorFields] = useState({});

    useEffect(() => {
        if (props.itemToEdit) {
            setSelectedEvents(props.itemToEdit.events);
        }
    }, [props.itemToEdit])

    const cleanupFields = (fields) => {
        fields.events = selectedEvents;
        return fields;
    }

    const addItem = (fields) => {
        if (fields.events.length === 0) {
            setErrorFields({error: "Select at least one event"})
            return;
        }
        serverPost(getApiUrl("/webhooks"), fields).then((res) => {
            if (res) {
                Notification.Success(`Webhook created`);
                if (props.onWebhookAdded) {
                    props.onWebhookAdded(res);
                } else {
                    props.onClose(true);
                }
            }
        })
    }

    const updateItem = (fields) => {
        if (fields.events.length === 0) {
            setErrorFields({error: "Select at least one event"})
            return;
        }
        serverPost(getApiUrl("/webhooks"), fields).then((res) => {
            if (res) {
                Notification.Success(`Webhook updated`);
                props.onClose(true);
            }
        })
    }

    const groupedEventOptions = useMemo(() => [{
        title: "Customer",
        events: [
            { value: "customer.created", label: "Customer Created" },
            { value: "customer.updated", label: "Customer Updated" },
            { value: "customer.upgraded", label: "Customer Upgraded" },
            { value: "customer.downgraded", label: "Customer Downgraded" },
            { value: "customer.churned", label: "Customer Churned" },
            { value: "customer.activated", label: "Customer Activated" },
            { value: "customer.reactivated", label: "Customer Reactivated" },
            { value: "customer.discount_created", label: "Customer Discount Created" },
            { value: "customer.discount_revoked", label: "Customer Discount Revoked" },
            { value: "customer.deleted", label: "Customer Deleted" },
            { value: "customer.mrr_changed", label: "MRR Changed" },
        ]
    },{
        title: "Subscription",
        events: [
            { value: "subscription.created", label: "Subscription Created" },
            { value: "subscription.updated", label: "Subscription Updated" },
            { value: "subscription.renewed", label: "Subscription Renewed" },
            { value: "subscription.restored", label: "Subscription Restored" },
            { value: "subscription.unpaid", label: "Subscription Unpaid" },
            { value: "subscription.expired", label: "Subscription Expired" },
            { value: "subscription.revoked", label: "Subscription Revoked" },
            { value: "subscription.changed", label: "Subscription Changed" },
            { value: "subscription.deleted", label: "Subscription Deleted" },
        ]
    },{
        title: "Invoice",
        events: [
            { value: "invoice.created", label: "Invoice Created" },
        ]
    },{
        title: "Item",
        events: [
            { value: "item.created", label: "Item Created" },
            { value: "item.updated", label: "Item Updated" },
        ]
    },{
        title: "Metric",
        events: [
            { value: "metric.created", label: "Metric Created" },
            { value: "metric.updated", label: "Metric Updated" },
        ]
    },{
        title: "Item Pricing",
        events: [
            { value: "item_pricing.created", label: "Item Pricing Created" },
            { value: "item_pricing.updated", label: "Item Pricing Updated" },
        ]
    },{
        title: "Product Pricing",
        events: [
            { value: "product_pricing.created", label: "Product Pricing Created" },
            { value: "product_pricing.updated", label: "Product Pricing Updated" },
        ]
    },{
        title: "Objects",
        events: [
            { value: "object.created", label: "Object Created" },
            { value: "object.updated", label: "Object Updated" },
            { value: "object.deleted", label: "Object Deleted" },
        ]
    }], []);

    /*
	EventTypeOneTimeBillableCreated         EventType = "onetime.created"
     */

    const allEvents = useMemo(() => {
        let alle = [];
        _.each(groupedEventOptions, (gev) => {
            _.each(gev.events, (ev) => {
                alle.push(ev.value);
            })
        })
        return alle;
    }, [groupedEventOptions])

    const toggleShow = (event, index) => {
        event.preventDefault();
        event.stopPropagation();
        setIsShowing(prevShowing => {
            const newShowing = [...prevShowing];
            newShowing[index] = !newShowing[index]
            return newShowing;
        })
    }

    const toggleChecked = (event, values) => {
        const alreadySelectedValues = _.intersection(selectedEvents, values);
        const allSelected = alreadySelectedValues.length === values.length;
        if (allSelected) {
            setSelectedEvents(prevEvents => {
                return [..._.filter(prevEvents, (ev) => !_.includes(values, ev))];
            });
        } else {
            setSelectedEvents(_.union(selectedEvents, values));
        }
    }

    return (
        <BaseAddOrEditItemModal
            {...props}
            size="lg"
            addItem={addItem}
            updateItem={updateItem}
            errorFields={errorFields}
            processAddFields={cleanupFields}
            processUpdateFields={cleanupFields}
            itemLabel={t('webhooks.webhook')}
            itemBaseUrl="/webhooks"
        >
            <p className="text-gray-500">Set up your webhook endpoint to receive live events from Maple or <a target="_blank" href="https://maplebilling.readme.io/reference/webhooks">learn more about Webhooks.</a></p>
            <Row className="mt-2">
                <BaseForm.Input colSpan="12" name="endpoint" label={t('webhooks.target_url')} type="text"
                                placeholder="https://www.example.com/webhook_url" validations={{ validURL: true, required: true }} />
                <BaseForm.Input colSpan="12" name="name" label={t('common.name')} type="text" required />
                <BaseForm.Input colSpan="12" name="description" label={t('common.description')} type="textarea" required />

                <Col md="12">
                    <p className="body2">Events</p>
                    <div className="relative flex items-start">
                        <div className="flex h-6 items-center">
                            <input
                                id={`field-all`}
                                name={`all`}
                                type="checkbox"
                                checked={selectedEvents.length === allEvents.length}
                                onChange={(event) => toggleChecked(event, allEvents)}
                                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            />
                        </div>
                        <div className="ml-2 text-sm leading-6">
                            <label htmlFor={`field-all`} className="text-sm text-gray-700">
                                Select All Events
                            </label>
                            <p className="text-gray-500"/>
                        </div>
                    </div>
                    <div className="divide-y divide-gray-200">
                    {
                        _.map(groupedEventOptions, (group, i) =>
                            <div key={i} className="flex flex-col justify-start overflow-hidden text-gray-900 py-1">
                                <div className="flex flex-row items-center gap-2 cursor-pointer" onClick={(event) => toggleShow(event, i)}>
                                    <label className="grow py-1 text-sm cursor-pointer" htmlFor="collapse">{ group.title }</label>
                                    <input
                                        className="peer mx-1 my-1 h-0 w-0 appearance-none rounded border text-slate-800 accent-slate-600 opacity-0"
                                        type="checkbox" name="collapse" id="collapse"/>
                                    <Label.Success className="text-sm text-gray-500">{ _.filter(group.events, (ev) => _.includes(selectedEvents, ev.value)).length } events</Label.Success>
                                    <span className="text-sm text-gray-500">{ group.events.length } events</span>
                                    {
                                        isShowing[i] ?
                                            <ChevronUpIcon className="h-6 w-6 mx-1 my-1"/>
                                        : <ChevronDownIcon className="h-6 w-6 mx-1 my-1"/>
                                    }
                                </div>
                                {
                                    isShowing[i] &&
                                        <fieldset className="mb-2">
                                            <div className="space-y-1">
                                                <div className="relative flex items-start ml-2">
                                                    <div className="flex h-6 items-center">
                                                        <input
                                                            id={`field-${i}-all`}
                                                            name={`${i}-all`}
                                                            type="checkbox"
                                                            checked={_.intersection(selectedEvents, _.map(group.events, ev => ev.value)).length === group.events.length}
                                                            onChange={(event) => toggleChecked(event, _.map(group.events, ev => ev.value))}
                                                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                        />
                                                    </div>
                                                    <div className="ml-2 text-sm leading-6">
                                                        <label htmlFor={`field-${i}-all`} className="text-sm text-gray-900">
                                                            Select All
                                                        </label>
                                                        <p className="text-gray-500"/>
                                                    </div>
                                                </div>
                                                {
                                                    _.map(group.events, (ev, j) =>
                                                        <div key={j} className="relative flex items-start ml-2">
                                                            <div className="flex h-6 items-center">
                                                                <input
                                                                    id={`field-${i}-${j}`}
                                                                    name={ev.value}
                                                                    type="checkbox"
                                                                    checked={_.includes(selectedEvents, ev.value)}
                                                                    onChange={(event) => toggleChecked(event, [ev.value])}
                                                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                                />
                                                            </div>
                                                            <div className="ml-2 text-sm leading-6">
                                                                <label htmlFor={`field-${i}-${j}`} className="text-sm text-gray-900">
                                                                    { ev.label }
                                                                </label>
                                                                <p className="text-gray-500">
                                                                    { ev.description }
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </fieldset>
                                }

                            </div>
                        )
                    }
                    </div>
                </Col>
            </Row>
        </BaseAddOrEditItemModal>
    );

}

export default AddOrEditWebhookModal;

import '../../App.scss';
import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { serverPost } from '../../helpers/server';
import {BaseContext, currencyFormatFromPrice, getCurrencyOptions} from '../../helpers/common';
import BaseContainer from '../../components/BaseContainer';
import ContentContainer from '../../components/ContentContainer';
import Section from '../../components/Section';
import Columns from '../../components/Columns';
import MapleTable from '../../components/MapleTable';
import InfiniteScroll from "react-infinite-scroll-component";
import { Table } from 'react-bootstrap';
import SortableTableHeader from "../../components/SortableTableHeader";
import SectionNav from "../../components/SectionNav";
import Label from "../../components/Label";
import MapleTableHeaderWithActions from "../../components/MapleTableHeaderWithActions";
const _ = require('lodash');

function Campaigns(props) {
    const { company, getApiUrl, getCompanySpecificUrl, setPageTitle } = useContext(BaseContext);
    const [campaigns, setCampaigns] = useState([]);

    const [activeNav, setActiveNav] = useState("payment_recovery");
    const [meta, setMeta] = useState({});
    const [sort, setSort] = useState("timeDesc");
    const [hasMore, setHasMore] = useState(true);
    const [fromKey, setFromKey] = useState(null);
    const [filters, setFilters] = useState([]);

    useEffect(() => {
        setPageTitle(`Email Campaigns`);
    }, []);

    useEffect(() => {
        onSearch(true);
    }, [activeNav]);

    useEffect(() => {
        const typeOptions = [
            { value: null, label: "All" },
            { value: "invoice_reminder", label: "Invoice Recovery" },
            { value: "payment_method_expired", label: "Card Expiry" },
        ]

        setFilters([
            { title: "Type", type: "select", name: "type", options: typeOptions}
        ])
    }, [])

    const getStatusForCampaign = (campaign) => {
        if (campaign.status === "RECOVERED") {
            return <Label.Success>Recovered</Label.Success>
        } else if (campaign.status === "ACTIVE") {
            return <Label.Warning>Active</Label.Warning>
        } else if (campaign.status === "COMPLETED") {
            return <Label.Danger>Completed</Label.Danger>
        }
    }

    const onSearch = (restart = true) => {
        const limit = 100;
        const params = {
            company_id: company.id,
            sort_key: sort || "timeDesc",
            pagination: {
                from_key: restart ? null: fromKey,
                limit: limit
            },
            query: {
                type: activeNav,
                ...props.extraQueryFilters
            },
            include_meta: restart
        }
        serverPost(getApiUrl("/campaigns/find"), params).then((res) => {
            if (res) {
                const results = (res.results && res.results.length) > 0 ? res.results: [];
                if (restart) {
                    setCampaigns(results);
                    setMeta(res.meta);
                } else {
                    setCampaigns(_.concat(campaigns, results));
                }
                setFromKey(res.pagination.from_key);
                setHasMore(results.length === limit);
            }
        });
    };

    const onNavClick = (tabId) => {
        setActiveNav(tabId);
    }

    const onParamsChange = (params) => {
        console.log("Params changed " + JSON.stringify(params))
        // setSelectedFilters(params);
    }

    const tabItems = [{
        'label': 'Payment Recovery',
        'id': 'payment_recovery',
        active: activeNav === "payment_recovery"
    }, {
        'label': 'Reminder',
        'id': 'invoice_reminder',
        active: activeNav === "invoice_reminder"
    }, {
        'label': 'Expired Cards',
        'id': 'payment_method_expired',
        active: activeNav === "payment_method_expired"
    }]

    return (
        <BaseContainer>
            <ContentContainer>
                <InfiniteScroll
                    dataLength={campaigns.length}
                    next={() => onSearch(false)}
                    hasMore={hasMore}
                    scrollableTarget="content-wrapper"
                >
                    <Section title="Email Campaigns">
                        <SectionNav items={tabItems} onClick={onNavClick} />
                    {
                        <MapleTable>
                            {
                                false && <MapleTableHeaderWithActions
                                    showSearch={false}
                                    searchPlaceholder="Search Invoices"
                                    showFilters={true}
                                    filters={filters}
                                    meta={meta}
                                    onParamsChange={onParamsChange}
                                />
                            }
                            <Table hover>
                                <thead>
                                    <tr>
                                        <th>Customer</th>
                                        <th>Status</th>
                                        <th>Campaign Progress</th>
                                        <th>Next Action</th>
                                        <th>Time Delinquent</th>
                                        <SortableTableHeader
                                            onSortChange={setSort} sortKeyUp="amountAsc" sortKeyDown="amountDesc"
                                            currentSort={sort} className="no-stretch">
                                            Amount
                                        </SortableTableHeader>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    _.map(campaigns, (row, i) =>
                                         <tr key={i}>
                                             <td>
                                             {
                                                 row.customer &&
                                                     <Link className="gray4" to={getCompanySpecificUrl(`/customer/${row.customer.id}`)}>
                                                         <Columns.CustomerName customer={row.customer} />
                                                     </Link>
                                             }
                                             </td>
                                             <td>{ getStatusForCampaign(row) }</td>
                                             <td>{ `${row.current_step}/${row.total_steps} steps` }</td>
                                             <td>{ row.next_step ? "": "-" }</td>
                                             <td></td>
                                             <td className="no-stretch">
                                                 {
                                                     row.invoice ?
                                                         <Link to={getCompanySpecificUrl(`/invoice/${row.invoice.uuid}`)}>
                                                             { currencyFormatFromPrice(row.amount) }
                                                         </Link>
                                                     : <span>{ currencyFormatFromPrice(row.amount) }</span>
                                                 }
                                             </td>
                                         </tr>
                                    )
                                }
                                {
                                    hasMore &&
                                        <tr>
                                            <td colSpan={4} className="text-center">
                                                <div className="spinner-border text-secondary"/>
                                            </td>
                                        </tr>
                                }
                                </tbody>
                            </Table>
                        </MapleTable>
                    }
                    </Section>
                </InfiniteScroll>
            </ContentContainer>
        </BaseContainer>
    );
}

export default Campaigns;
